<!-- ************
*****************
                *
 MODALITA' EASY
*
****************
************ -->

<div fxLayout="column" fxLayoutAlign="center stretch" class="easyContainer">

  <div [formGroup]="pagamentoAvanzatoForm" fxLayout="column" fxLayoutAlign="center stretch">

    <!--  *
          *
          STEP 1
          *
          * -->


    <div *ngIf="flagImporto" ngClass.xs="importoXs" ngClass.sm="importoSm">


      <!-- INSERIMENTO LOGO INPUT -->
      <div fxHide.xs fxHide.sm fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="importoAnimationConfiguration" [width]="400" [height]="200"
          (animCreated)="handleImportoAnimation($event)" style="margin-bottom: 20px"></ng-lottie>
      </div>
      <div fxHide.md fxHide.lg fxHide.xl fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="importoAnimationConfiguration" [height]="200"
          (animCreated)="handleImportoAnimation($event)" style="margin-bottom: 20px"></ng-lottie>
      </div>

      <!-- STRUTTURA E INSERIMENTO DETTAGLIO INPUT -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <span ngClass.xs="dettaglioInputXs" ngClass.sm="dettaglioInput" ngClass.md="dettaglioInput"
          ngClass.lg="dettaglioInput" ngClass.xl="dettaglioInput">
          {{ pagamentoAvanzatoForm.value.isPaymentRecurrent ? ('RICHIEDIPAGAMENTO.DETTIMPORTO_RECURRENZE' | translate) :
          ('RICHIEDIPAGAMENTO.DETTIMPORTO' | translate)}}
        </span>
      </div>
      <!-- STRUTTURA INPUT E TOOGLE  SM XS -->
      <div fxLayout="row" fxLayoutAlign="space-around center">

        <!-- INSERIMENTO INPUT -->
        <mat-form-field ngClass.xs="campoSm" ngClass.sm="campoSm" ngClass.md="campo" ngClass.lg="campo"
          ngClass.xl="campo">

          <input currencyMask [options]="{ prefix: '', decimal: ',', thousands: '.', align: 'left'}" type="tel"
            pattern="\d+((\.|,)\d+)?" formnovalidate matInput placeholder="{{'RICHIEDIPAGAMENTO.IMPORTO' | translate }}"
            formControlName="amount" required>
          <mat-error *ngIf="pagamentoAvanzatoForm.controls['amount'].hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          <mat-error *ngIf="pagamentoAvanzatoForm.controls['amount'].hasError('min')"
            [innerHTML]="'MESSAGE.MIN_AMOUNT' | translate"></mat-error>
          <mat-error *ngIf="pagamentoAvanzatoForm.controls['amount'].hasError('max')"
            [innerHTML]="'MESSAGE.MAX_AMOUNT' | translate"></mat-error>
        </mat-form-field>


      </div>

      <div fxLayout="column" fxLayoutAlign="space-around center">


        <div fxLayout="row" fxLayoutAlign="space-between start" ngClass.xs="widthTogglesSm" ngClass.sm="widthToggles"
          ngClass.md="widthToggles" ngClass.lg="widthToggles" ngClass.xl="widthToggles">
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <mat-slide-toggle class="toogle" color="primary" Style="margin-left:5px;margin-right:5px;"
              (click)="clickToogleImporto()" formControlName="isAmountFree"
              (change)="onToggle($event, 'SINGLE')"></mat-slide-toggle>
          </div>
          <div fxLayout='column' fxLayoutAlign="flex-start" style='width: 80%'>
            <span ngClass.xs="dettaglioSpanSm" ngClass.sm="dettaglioSpan" ngClass.md="dettaglioSpan"
              ngClass.lg="dettaglioSpan" ngClass.xl="dettaglioSpan">
              {{ 'RICHIEDIPAGAMENTO.IMPORTOLIBERO' | translate }}</span>
          </div>
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <button disabled mat-icon-button>
              <mat-icon Style="margin-left:5px;margin-right:5px;margin-bottom:5px;color: #616060;"
                matTooltip="{{'PAYMENT_WIZARD.ISAMOUNTFREE_TOOLTIP' | translate }}">help</mat-icon>
            </button>
          </div>
          <!-- INSERIMENTO TOOGLE INPUT AUTOMATICO -->

        </div>


        <div *ngIf='donationActive' fxLayout="row" fxLayoutAlign="space-between center" ngClass.xs="widthTogglesSm"
          ngClass.sm="widthToggles" ngClass.md="widthToggles" ngClass.lg="widthToggles" ngClass.xl="widthToggles">
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <mat-slide-toggle class="toogle" color="primary" Style="margin-left:5px;margin-right:5px;"
              formControlName="isDonation" (change)="onToggle($event, 'SINGLE')"></mat-slide-toggle>
          </div>
          <div fxLayout='column' fxLayoutAlign="flex-start" style='width: 80%'>
            <span ngClass.xs="dettaglioSpanSm" ngClass.sm="dettaglioSpan" ngClass.md="dettaglioSpan"
              ngClass.lg="dettaglioSpan" ngClass.xl="dettaglioSpan">
              {{ 'RICHIEDIPAGAMENTO.DONAZIONE' | translate }}</span>
          </div>
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <button disabled mat-icon-button>
              <mat-icon Style="margin-left:5px;margin-right:5px;margin-bottom:5px;color: #616060;"
                matTooltip="{{'PAYMENT_WIZARD.ISDONATION_TOOLTIP' | translate }}">help</mat-icon>
            </button>
          </div>
        </div>


        <div *ngIf="handleTransactionType('AUTH')" fxLayout="row" fxLayoutAlign="space-between center"
          ngClass.xs="widthTogglesSm" ngClass.sm="widthToggles" ngClass.md="widthToggles" ngClass.lg="widthToggles"
          ngClass.xl="widthToggles">
          <!-- INSERIMENTO TOOGLE INPUT AUTOMATICO -->
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <mat-slide-toggle class="toogle" color="primary" Style="margin-left:5px;margin-right:5px;"
              formControlName="toggleAuth" (change)="onToggle($event, 'SINGLE')"></mat-slide-toggle>
          </div>
          <div fxLayout='column' fxLayoutAlign="center start" style='width: 80%'>

            <span style="float: inline-start" ngClass.xs="dettaglioSpanSm" ngClass.sm="dettaglioSpan"
              ngClass.md="dettaglioSpan" ngClass.lg="dettaglioSpan" ngClass.xl="dettaglioSpan">
              {{ 'RICHIEDIPAGAMENTO.PREAUTORIZZAZIONE' | translate }}</span>
          </div>
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <button disabled mat-icon-button>
              <mat-icon Style="margin-left:5px;margin-right:5px;margin-bottom:5px;color: #616060;"
                matTooltip="{{'PAYMENT_WIZARD.AUTH_TOOLTIP' | translate }}">help</mat-icon>
            </button>
          </div>

        </div>


        <div *ngIf="(flagQRCODE || flagLINK || flagWHATSAPP) && isPaymentOpenActive" fxLayout="row"
          fxLayoutAlign="space-between center" ngClass.xs="widthTogglesSm" ngClass.sm="widthToggles"
          ngClass.md="widthToggles" ngClass.lg="widthToggles" ngClass.xl="widthToggles">
          <!-- INSERIMENTO TOOGLE INPUT AUTOMATICO -->
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <mat-slide-toggle class="toogle" color="primary" Style="margin-left:5px;margin-right:5px;"
              (click)="clickToggleOpenPayment()" formControlName="isPaymentOpen"
              (change)="onToggle($event, 'SINGLE')"></mat-slide-toggle>
          </div>
          <div fxLayout='column' fxLayoutAlign="flex-start" style='width: 80%'>
            <div fxLayout='row'>
              <span ngClass.xs="dettaglioSpanSm" ngClass.sm="dettaglioSpan" ngClass.md="dettaglioSpan"
                ngClass.lg="dettaglioSpan" ngClass.xl="dettaglioSpan">
                {{ 'RICHIEDIPAGAMENTO.PAGAMENTOAPERTO' | translate }}</span>
            </div>

          </div>
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <button disabled mat-icon-button>
              <mat-icon style="margin-left:5px;margin-right:5px;margin-bottom:5px;color: #616060;"
                matTooltip="{{'PAYMENT_WIZARD.ISPAYMENTOPEN_TOOLTIP' | translate }}">help</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf='isDeferredActive' fxLayout="row" fxLayoutAlign="space-between center" ngClass.xs="widthTogglesSm"
          ngClass.sm="widthToggles" ngClass.md="widthToggles" ngClass.lg="widthToggles" ngClass.xl="widthToggles">
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <mat-slide-toggle class="toogle" color="primary" Style="margin-left:5px;margin-right:5px;"
              formControlName="isPaymentDeferred" (change)="onToggle($event, 'DEFERRED')"></mat-slide-toggle>
          </div>
          <div fxLayout='column' fxLayoutAlign="flex-start" style='width: 80%'>
            <span ngClass.xs="dettaglioSpanSm" ngClass.sm="dettaglioSpan" ngClass.md="dettaglioSpan"
              ngClass.lg="dettaglioSpan" ngClass.xl="dettaglioSpan">
              {{ 'RICHIEDIPAGAMENTO.DILAZIONARE_PAGAMENTO' | translate }}</span>
          </div>
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <button disabled mat-icon-button>
              <mat-icon Style="margin-left:5px;margin-right:5px;margin-bottom:5px;color: #616060;"
                matTooltip="{{'PAYMENT_WIZARD.ISDEFERRED_TOOLTIP' | translate }}">help</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf='isRecurrentActive' fxLayout="row" fxLayoutAlign="space-between center" ngClass.xs="widthTogglesSm"
          ngClass.sm="widthToggles" ngClass.md="widthToggles" ngClass.lg="widthToggles" ngClass.xl="widthToggles">
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <mat-slide-toggle class="toogle" color="primary" Style="margin-left:5px;margin-right:5px;"
              formControlName="isPaymentRecurrent" (change)="onToggle($event, 'RECURRENT')"></mat-slide-toggle>
          </div>
          <div fxLayout='column' fxLayoutAlign="flex-start" style='width: 80%'>
            <span ngClass.xs="dettaglioSpanSm" ngClass.sm="dettaglioSpan" ngClass.md="dettaglioSpan"
              ngClass.lg="dettaglioSpan" ngClass.xl="dettaglioSpan">
              {{ 'RICHIEDIPAGAMENTO.RICORRENZE_PAGAMENTO' | translate }}</span>
          </div>
          <div fxLayout='column' fxLayoutAlign="space-between start">
            <button disabled mat-icon-button>
              <mat-icon Style="margin-left:5px;margin-right:5px;margin-bottom:5px;color: #616060;"
                matTooltip="{{'PAYMENT_WIZARD.ISRECURRENT_TOOLTIP' | translate }}">help</mat-icon>
            </button>
          </div>
        </div>

      </div>

      <!-- STRUTTURA E INSERIMENTO BOTTONE PROSEGUI -->
      <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="bottoniSm" ngClass.sm="bottoniSm"
        ngClass.md="bottoni" ngClass.lg="bottoni" ngClass.xl="bottoni">
        <!-- BOTTONE VERDE -->

        <button disabled class="buttonEasy" ngClass.xs="buttonEasyXs" mat-flat-button color="primary">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 10px; margin-right: 20px;">
            <mat-icon alt="previous button icon" svgIcon="arrow-left" class="arrow-left">
            </mat-icon> {{'RICHIEDIPAGAMENTO.BOTTONEIINDIETRO' | translate}}
          </div>
        </button>
        <button *ngIf="pagamentoAvanzatoForm.controls.isAmountFree.value == false"
          [disabled]="pagamentoAvanzatoForm.controls.amount.status !== 'VALID'" class="buttonEasy"
          ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickImporto()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 20px; margin-right: 10px;">
            {{'RICHIEDIPAGAMENTO.BOTTONEPROSEGUI' | translate}}
            <mat-icon alt="next button icon" svgIcon="arrow-right">
            </mat-icon>
          </div>
        </button>
        <button *ngIf="pagamentoAvanzatoForm.controls.isAmountFree.value == true" class="buttonEasy"
          ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickImporto()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 20px; margin-right: 10px;">
            {{'RICHIEDIPAGAMENTO.BOTTONEPROSEGUI' | translate}}
            <mat-icon alt="next button icon" svgIcon="arrow-right">
            </mat-icon>
          </div>
        </button>

      </div>
    </div>



    <!--  *
          *
          STEP 2
          *
          * -->


    <div *ngIf="flagNomeCognome" ngClass.xs="nomeCognomeXs" ngClass.sm="nomeCognomeSm">

      <!-- INSERIMENTO LOGO INPUT -->
      <div fxHide.xs fxHide.sm fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="nominativoAnimationConfiguration" [width]="400" [height]="200"
          (animCreated)="handleNominativoAnimation($event)" style="margin-bottom: 20px"></ng-lottie>
      </div>
      <div fxHide.md fxHide.lg fxHide.xl fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="nominativoAnimationConfiguration" [height]="200"
          (animCreated)="handleNominativoAnimation($event)" style="margin-bottom: 20px"></ng-lottie>
      </div>

      <!-- STRUTTURA E INSERIMENTO DETTAGLIO INPUT -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <span ngClass.xs="dettaglioInputXs" ngClass.sm="dettaglioInput" ngClass.md="dettaglioInput"
          ngClass.lg="dettaglioInput" ngClass.xl="dettaglioInput">{{'RICHIEDIPAGAMENTO.DETTNOMECOGNOME' | translate
          }}</span>
      </div>
      <!-- STRUTTURA INPUT E TOOGLE  SM XS -->
      <div fxLayout="row" fxLayoutAlign="space-around center">

        <mat-form-field formGroupName="customerForm" ngClass.xs="campoSm" ngClass.sm="campoSm" ngClass.md="campo"
          ngClass.lg="campo" ngClass.xl="campo">
          <input matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.NOME' | translate}}" [matAutocomplete]="auto"
            formControlName="name" required>

          <mat-icon matSuffix (click)="clearRegionesociale()">clear</mat-icon>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="ricercaNomeCliente($event)">
            <mat-option [ngStyle]="{'display': showFormSearchPanel ? 'block' : 'none'}"
              *ngFor="let customer of filteredCustomer | async" [value]="customer._id">

              <span>{{ customer.name }}</span>

            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      </div>

      <!-- STRUTTURA E INSERIMENTO BOTTONE PROSEGUI -->
      <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="bottoniSm" ngClass.sm="bottoniSm"
        ngClass.md="bottoni" ngClass.lg="bottoni" ngClass.xl="bottoni">
        <!-- BOTTONE VERDE -->

        <button class="buttonEasy" ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickInizio()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 10px; margin-right: 20px;">
            <mat-icon alt="previous button icon" svgIcon="arrow-left" class="arrow-left">
            </mat-icon> {{'RICHIEDIPAGAMENTO.BOTTONEIINDIETRO' | translate}}
          </div>
        </button>
        <button [disabled]="!pagamentoAvanzatoForm.controls.customerForm.controls.name.valid" class="buttonEasy"
          ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickNomeCognome()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 20px; margin-right: 10px;">
            {{'RICHIEDIPAGAMENTO.BOTTONEPROSEGUI' | translate}}
            <mat-icon alt="next button icon" svgIcon="arrow-right">
            </mat-icon>
          </div>
        </button>

      </div>
    </div>


    <!--  *
          *
          STEP 2.99 ONLY RECURRENT/DEFERRED 
          *
          * -->

    <div *ngIf="flagGenerateRecurrentPlan" ngClass.xs="nomeCognomeXs" ngClass.sm="nomeCognomeSm">

      <!-- INSERIMENTO LOGO INPUT -->
      <div fxHide.xs fxHide.sm fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="importoAnimationConfiguration" [width]="400" [height]="200"
          (animCreated)="handleImportoAnimation($event)"></ng-lottie>
      </div>
      <div fxHide.md fxHide.lg fxHide.xl fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="importoAnimationConfiguration" [height]="200"
          (animCreated)="handleImportoAnimation($event)"></ng-lottie>
      </div>

      <!-- STRUTTURA E INSERIMENTO DETTAGLIO INPUT -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <span style="text-align: center;" ngClass.xs="dettaglioInputXs" ngClass.sm="dettaglioInput"
          ngClass.md="dettaglioInput" ngClass.lg="dettaglioInput" ngClass.xl="dettaglioInput">
          {{ pagamentoAvanzatoForm.value.isPaymentRecurrent ? ('RICHIEDIPAGAMENTO.IMPOSTARICORRENZA' | translate) :
          ('RICHIEDIPAGAMENTO.IMPOSTADILAZIONE' | translate)}}

        </span>
      </div>
      <!-- STRUTTURA INPUT E TOOGLE  SM XS -->
      <div formGroupName="recurrentForm">
        
        <div class="flex-row">
          <mat-form-field style="width: 100%; position: relative; z-index: 900 !important">
            <mat-label>{{ 'RICHIEDIPAGAMENTOAVANZATO.NUMERORICORRENZE' | translate}}</mat-label>
            <mat-select formControlName="rounds">
              <mat-option style="position: relative; z-index: 999;"
                *ngFor="let type of pagamentoAvanzatoForm.value.isPaymentRecurrent ? recurrentList : deferredList"
                [value]="type.value">
                {{ type.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field style="width: 160px; z-index: 900 !important">
              <mat-label>{{ 'RICHIEDIPAGAMENTOAVANZATO.RICORRENZA' | translate}}</mat-label>
              <mat-select formControlName="frequency">
                <mat-option *ngFor="let type of frequencyList" [value]="type.value">
                  {{ type.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <button  class="previewButton" ngClass.xs="buttonEasyXs" mat-flat-button color="primary"
              (click)="recurrentPreview()" style="min-width: auto;">
              {{'RICHIEDIPAGAMENTOAVANZATO.BOTTONEANTEPRIMA' | translate}}
            </button>
          </span>
        </div>

      </div>

      <!-- STRUTTURA E INSERIMENTO BOTTONE PROSEGUI -->
      <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="bottoniSm" ngClass.sm="bottoniSm"
        ngClass.md="bottoni" ngClass.lg="bottoni" ngClass.xl="bottoni">
        <!-- BOTTONE VERDE -->

        <button class="buttonEasy" ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickInizio()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 10px; margin-right: 20px;">
            <mat-icon alt="previous button icon" svgIcon="arrow-left" class="arrow-left">
            </mat-icon> {{'RICHIEDIPAGAMENTO.BOTTONEIINDIETRO' | translate}}
          </div>
        </button>
        <button [disabled]="!pagamentoAvanzatoForm.controls.recurrentForm.valid" class="buttonEasy"
          ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickGenerateRecurrentPlan()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 20px; margin-right: 10px;">
            {{'RICHIEDIPAGAMENTO.BOTTONEPROSEGUI' | translate}}
            <mat-icon alt="next button icon" svgIcon="arrow-right">
            </mat-icon>
          </div>
        </button>

      </div>
    </div>



    <!--  *
          *
          STEP 3
          *
          * -->



    <div *ngIf="flagDescrizione" ngClass.xs="descrizioneXs" ngClass.sm="descrizioneSm">
      <div fxLayout="row" fxLayoutAlign="space-around center" style="margin-bottom: 20px;">
        <ng-lottie [options]="descrizioneAnimationConfiguration" [width]="200" [height]="250"
          (animCreated)="handleDescrizioneAnimation($event)" ngClass.xs="lottieDescrizioneSm"
          ngClass.sm="lottieDescrizioneSm" ngClass.md="lottieDescrizione" ngClass.lg="lottieDescrizione"
          ngClass.xl="lottieDescrizione"></ng-lottie>
      </div>

      <!-- STRUTTURA E INSERIMENTO DETTAGLIO INPUT -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <span ngClass.xs="dettaglioInputXs" ngClass.sm="dettaglioInput" ngClass.md="dettaglioInput"
          ngClass.lg="dettaglioInput" ngClass.xl="dettaglioInput">{{'RICHIEDIPAGAMENTO.DETTDESCRIZIONE' | translate
          }}</span>
      </div>
      <!-- STRUTTURA INPUT E TOOGLE  SM XS -->
      <div fxLayout="row" fxLayoutAlign="space-around center">

        <!-- INSERIMENTO INPUT -->
        <mat-form-field ngClass.xs="campoSm" ngClass.sm="campoSm" ngClass.md="campo" ngClass.lg="campo"
          ngClass.xl="campo">
          <input matInput placeholder="{{'RICHIEDIPAGAMENTO.DESCRIZIONE' | translate }}" formControlName="description"
            maxlength='100' required>
          <mat-error *ngIf="pagamentoAvanzatoForm.controls['description'].hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          <mat-error *ngIf="pagamentoAvanzatoForm.controls['description'].hasError('pattern')"
            [innerHTML]="'MESSAGE.PATTERN_ERROR' | translate"></mat-error>
        </mat-form-field>


      </div>


      <!-- STRUTTURA E INSERIMENTO BOTTONE PROSEGUI -->
      <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="bottoniSm" ngClass.sm="bottoniSm"
        ngClass.md="bottoni" ngClass.lg="bottoni" ngClass.xl="bottoni">
        <!-- BOTTONE VERDE -->

        <button class="buttonEasy" ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickImporto()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 10px; margin-right: 20px;">
            <mat-icon alt="previous button icon" svgIcon="arrow-left" class="arrow-left">
            </mat-icon> {{'RICHIEDIPAGAMENTO.BOTTONEIINDIETRO' | translate}}
          </div>
        </button>
        <button [disabled]="pagamentoAvanzatoForm.controls['description'].status !== 'VALID'" class="buttonEasy"
          ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickDescrizione()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 20px; margin-right: 10px;">
            {{'RICHIEDIPAGAMENTO.BOTTONEPROSEGUI' | translate}}
            <mat-icon alt="next button icon" svgIcon="arrow-right">
            </mat-icon>
          </div>
        </button>


      </div>


    </div>


    <!--  *
          *
          STEP 4
          *
          * -->


    <div *ngIf="flagAllegato" ngClass.xs="allegatoSm" ngClass.sm="allegatoSm" ngClass.md="allegato"
      ngClass.lg="allegato" ngClass.xl="allegato">


      <div fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="allegatoAnimationConfiguration" [width]="200" [height]="250"
          (animCreated)="handleAllegatoAnimation($event)" style="margin-bottom: 20px"></ng-lottie>
      </div>

      <!-- STRUTTURA E INSERIMENTO DETTAGLIO INPUT -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <span ngClass.xs="dettaglioInputXs" ngClass.sm="dettaglioInput" ngClass.md="dettaglioInput"
          ngClass.lg="dettaglioInput" ngClass.xl="dettaglioInput">{{'RICHIEDIPAGAMENTO.DETTALLEGATO' | translate
          }}</span>
      </div>
      <!-- STRUTTURA INPUT E TOOGLE  SM XS -->


      <!-- STRUTTURA E INSERIMENTO DETTAGLIO INPUT -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <!-- INSERIMENTO TOOGLE INPUT AUTOMATICO -->
        <mat-form-field class="campo">
          <input [formControl]="file" matInput placeholder="{{'RICHIEDIPAGAMENTO.ALLEGATO' | translate }}">
          <input #inputfile type="file" ng2FileSelect [uploader]="uploader" style="display: none;" />
        </mat-form-field>
        <button mat-button class="outline-button classeBottone"
          (click)="onFileUploadClick()">{{'RICHIEDIPAGAMENTO.BUTTONSFOGLIA' | translate}}</button>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" Style="margin-top:-20px;">
        <!-- INSERIMENTO TOOGLE INPUT AUTOMATICO -->
        <span ngClass.xs="dettaglioSpanSm" ngClass.sm="dettaglioSpanSm" ngClass.md="dettaglioSpan"
          ngClass.lg="dettaglioSpan" ngClass.xl="dettaglioSpan"
          Style="margin-left:5px;margin-right:5px;">{{'RICHIEDIPAGAMENTO.CARICAMENTOFILEDETT' | translate}}</span>
      </div>
      <!-- STRUTTURA E INSERIMENTO BOTTONE PROSEGUI -->
      <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="bottoniSm" ngClass.sm="bottoniSm"
        ngClass.md="bottoni" ngClass.lg="bottoni" ngClass.xl="bottoni">
        <!-- BOTTONE VERDE -->

        <button class="buttonEasy" ngClass.xs="buttonEasyXs" mat-flat-button color="primary"
          (click)="clickNomeCognome()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 10px; margin-right: 20px;">
            <mat-icon alt="previous button icon" svgIcon="arrow-left" class="arrow-left">
            </mat-icon> {{'RICHIEDIPAGAMENTO.BOTTONEIINDIETRO' | translate}}
          </div>
        </button>
        <button class="buttonEasy" ngClass.xs="buttonEasyXs" mat-flat-button color="primary" (click)="clickAllegato()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 20px; margin-right: 10px;">
            {{'RICHIEDIPAGAMENTO.BOTTONEPROSEGUI' | translate}}
            <mat-icon alt="next button icon" svgIcon="arrow-right">
            </mat-icon>
          </div>
        </button>

      </div>
    </div>


    <!--  *
          *
          STEP 5
          *
          * -->

    <div *ngIf="flagSmsEmail" ngClass.xs="emailSm" ngClass.sm="emailSm" ngClass.md="email" ngClass.lg="email"
      ngClass.xl="email">


      <!-- INSERIMENTO LOGO INPUT -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <ng-lottie [options]="emailAnimationConfiguration" [width]="200" [height]="250"
          (animCreated)="handleEmailAnimation($event)" class="emailBoxDistance"></ng-lottie>
      </div>

      <!-- STRUTTURA E INSERIMENTO DETTAGLIO INPUT -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <span ngClass.xs="dettaglioInputXs" ngClass.sm="dettaglioInput" ngClass.md="dettaglioInput"
          ngClass.lg="dettaglioInput" ngClass.xl="dettaglioInput">{{'RICHIEDIPAGAMENTO.DETTSMSEMAIL' | translate
          }}</span>
      </div>
      <!-- STRUTTURA INPUT E TOOGLE  SM XS -->
      <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="radioGroupSm" ngClass.sm="radioGroupSm"
        ngClass.md="radioGroup" ngClass.lg="radioGroup" ngClass.xl="radioGroup">
        <mat-radio-group formControlName="operation_type">
          <mat-radio-button *ngIf="flagSMS && user.isSmsActive" color='primary' class="radioButton"
            [disabled]="onlyQRCODELINK" value="SMS">{{'PAYMENT_WIZARD.SMS' | translate }} </mat-radio-button>
          <mat-radio-button *ngIf="flagEMAIL && user.isEmailActive" color='primary' class="radioButton"
            [disabled]="onlyQRCODELINK" value="EMAIL">{{'PAYMENT_WIZARD.EMAIL' | translate }} </mat-radio-button>
          <mat-radio-button *ngIf="flagQRCODE && user.isQrcodeActive" color='primary' class="radioButton"
            value="QRCODE">{{'PAYMENT_WIZARD.QRCODE' | translate }} </mat-radio-button>
          <mat-radio-button *ngIf="flagLINK && user.isLinkActive" color='primary' class="radioButton" value="LINK">
            {{'PAYMENT_WIZARD.LINK' | translate }} </mat-radio-button>
          <mat-radio-button *ngIf="flagWHATSAPP && user.isWhatsAppActive" color='primary' class="radioButton"
            [disabled]="onlyQRCODELINK && !isPaymentOpenActive" value="WHATSAPP">{{'PAYMENT_WIZARD.WHATSAPP' | translate
            }} </mat-radio-button>
        </mat-radio-group>
      </div>
      <div formGroupName="customerForm" class="countryMargin" fxLayout="row" fxLayoutAlign="space-around center">

        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="pagamentoAvanzatoForm.controls.operation_type.value === 'SMS' || (pagamentoAvanzatoForm.controls.operation_type.value === 'WHATSAPP' && !pagamentoAvanzatoForm.controls.isPaymentOpen.value)"
          class="numberContainer countryOptions">
          <mat-form-field class="countryBox">
            <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code"
              (change)="selectFlag(event)">
              <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">

                <img alt="flag icon" class="flagCountry"
                  src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                <span>{{ type.CODE }}</span>
                <span>{{ type.DESC }}</span>
                <span> - {{ type.COUNTRY | uppercase }}</span>

              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field ngClass.xs="campoSm" ngClass.sm="campoSm" ngClass.md="campo" ngClass.lg="campo"
            ngClass.xl="campo" style="width: 100%;">
            <input type="tel" matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.TELEFONO' | translate}}"
              formControlName="smartphone">
            <mat-error
              *ngIf="pagamentoAvanzatoForm.controls['customerForm']['controls'].smartphone.hasError('myCustomSmartphoneValidator')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>

          </mat-form-field>
        </div>

        <mat-form-field ngClass.xs="campoSm" ngClass.sm="campoSm" ngClass.md="campo" ngClass.lg="campo"
          ngClass.xl="campo" *ngIf="pagamentoAvanzatoForm.controls.operation_type.value === 'EMAIL'">
          <input type="email" matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.EMAIL' | translate}}"
            formControlName="email">
          <mat-error
            *ngIf="pagamentoAvanzatoForm.controls['customerForm']['controls'].email.hasError('myCustomEmailValidator')"
            [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
          <mat-error *ngIf="pagamentoAvanzatoForm.controls['customerForm']['controls'].email.hasError('pattern')"
            [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>


        </mat-form-field>
        <mat-form-field ngClass.xs="campoSm" ngClass.sm="campoSm" ngClass.md="campo" ngClass.lg="campo"
          ngClass.xl="campo"
          *ngIf="pagamentoAvanzatoForm.controls.operation_type.value === 'QRCODE' || pagamentoAvanzatoForm.controls.operation_type.value === 'LINK'">
          <input disabled matInput>
        </mat-form-field>


      </div>



      <!-- STRUTTURA E INSERIMENTO BOTTONE PROSEGUI -->
      <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="bottoniSm" ngClass.sm="bottoniSm"
        ngClass.md="bottoni" ngClass.lg="bottoni" ngClass.xl="bottoni">
        <!-- BOTTONE VERDE -->

        <button class="buttonEasy" ngClass.xs="buttonEasyXs" mat-flat-button color="primary"
          (click)="clickDescrizione()">
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style=" margin-left: 10px; margin-right: 20px;">
            <mat-icon alt="previous button icon" svgIcon="arrow-left" class="arrow-left">
            </mat-icon> {{'RICHIEDIPAGAMENTO.BOTTONEIINDIETRO' | translate}}
          </div>
        </button>
        <button mat-flat-button color="primary" (click)="salvaPagamentoEasy()"
          [disabled]="!pagamentoAvanzatoForm.valid">
          <div fxLayout="row" fxLayoutAlign="space-evenly center"> {{'RICHIEDIPAGAMENTO.BOTTONEINVIA' | translate}}
          </div>
        </button>


      </div>






    </div>

  </div>
</div>