<div [ngClass]="theme" style="width: 100vw; overflow: scroll; height: 100vh; background-color: #f9f9f9">
  <div fxLayout="row" fxLayoutAlign="end" class="topRow">
    <!-- TEMPLATE ICONS -->
    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;" *ngIf="!isApproveView">
      <mat-icon [@smallBig]="channel === 'EMAIL' ? 'big' : 'small'" class="overlayIcons" [ngClass]="templateToEdit?.email ? 'icone-sms-email-page-true' : 'icone-sms-email-page-false'" style="right: 30px;" svgIcon="email" (click)="toggleTemplate('EMAIL')" matTooltip="{{'PROFILO.EMAILTEMPLATE' | translate}}"></mat-icon>
      <mat-icon [@smallBig]="channel === 'SMS' ? 'big' : 'small'" class="overlayIcons" [ngClass]="templateToEdit?.sms ? 'icone-sms-email-page-true' : 'icone-sms-email-page-false'" style="right: 10px;" svgIcon="sms" (click)="toggleTemplate('SMS')" matTooltip="{{'PROFILO.SMSTEMPLATE' | translate}}"></mat-icon>
      <mat-icon [@smallBig]="channel === 'PAGE' ? 'big' : 'small'" class="overlayIcons" [ngClass]="templateToEdit?.page ? 'icone-sms-email-page-true' : 'icone-sms-email-page-false'" style="right: -10px;" svgIcon="page" (click)="toggleTemplate('PAGE')" matTooltip="{{'PROFILO.PAGETEMPLATE' | translate}}"></mat-icon>
    </div>

    <!-- TEMPLATE ICONS: APPROVE VIEW -->
    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;" *ngIf="isApproveView">
      <mat-icon [@smallBig]="channel === 'EMAIL' ? 'big' : 'small'" class="overlayIcons" [ngClass]="templateToEdit?.email && templateToEdit?.isEmailRead ? 'icone-sms-email-page-true' : 'icone-sms-email-page-pending'" style="right: 30px;" svgIcon="email" (click)="toggleTemplate('EMAIL')" matTooltip="{{'PROFILO.EMAILTEMPLATE' | translate}}"></mat-icon>
      <mat-icon [@smallBig]="channel === 'SMS' ? 'big' : 'small'" class="overlayIcons" [ngClass]="templateToEdit?.sms && templateToEdit?.isSmsRead ? 'icone-sms-email-page-true' : 'icone-sms-email-page-pending'" style="right: 10px;" svgIcon="sms" (click)="toggleTemplate('SMS')" matTooltip="{{'PROFILO.SMSTEMPLATE' | translate}}"></mat-icon>
      <mat-icon [@smallBig]="channel === 'PAGE' ? 'big' : 'small'" class="overlayIcons" [ngClass]="templateToEdit?.page && templateToEdit?.isPageRead ? 'icone-sms-email-page-true' : 'icone-sms-email-page-pending'" style="right: -10px;" svgIcon="page" (click)="toggleTemplate('PAGE')" matTooltip="{{'PROFILO.PAGETEMPLATE' | translate}}"></mat-icon>
    </div>

    <mat-icon class="overlayIcons" svgIcon="close" aria-hidden="false" aria-label="close" (click)="closeDetail()"></mat-icon>
  </div>

  <app-template-code
    (savedTemplate)="toggleCompiledIcon($event)"
    [template]="data?.templateToEdit"
    [channel]="channel"
    [isApproveView]="data?.isApproveView">
  </app-template-code>
</div>
