<form *ngIf="userForm" [formGroup]="userForm">
  <div *ngIf="!isSelfEnrollment || (isSelfEnrollment && !userData?._id)" class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField" *ngIf="!isProfile && !isSelfEnrollment">
        <mat-select *ngIf="merchantService.isAdminMode && (!userData || userData?.type !== 'CUSTOMER')"
          placeholder="{{ 'NUOVOUTENTE.TYPE' | translate}}" required formControlName="type"
          (selectionChange)=sceltatipo($event)>
          <mat-option *ngFor="let type of tipo" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
        <input *ngIf="!merchantService.isAdminMode || userData?.type === 'CUSTOMER'" matInput
          placeholder="{{'NUOVOUTENTE.TYPE' | translate}}" formControlName="type">
      </mat-form-field>
      <mat-form-field class="leftInputField" *ngIf="isProfile || isSelfEnrollment">
        <input matInput
          placeholder="{{ isSelfEnrollment && merchantService.currentMerchant?.name ? merchantService.currentMerchant?.name : ('PROFILO.AZIENDA' | translate)}}"
          formControlName="merchant">
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <mat-select placeholder="{{ 'NUOVOUTENTE.RUOLO' | translate}}" required formControlName="role">
          <mat-option *ngFor="let type of ruolo" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'NUOVOUTENTE.FIRSTNAME' | translate}}" formControlName="first_name" required>
        <mat-error *ngIf="userForm?.controls['first_name'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'NUOVOUTENTE.LASTNAME' | translate}}" required formControlName="last_name"
          required>
        <mat-error *ngIf="userForm?.controls['last_name'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </span>
  </div>



  <div class="flex-row">
    <span class="flex-col">
      <span class="leftInputField">
        <mat-form-field style="width: 20%; float: left;">
          <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code">
            <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
              <img alt="flag icon" class="flagCountry"
                src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
              <span>{{ type.CODE }}</span>
              <span>{{ type.DESC }}</span>
              <span> - {{ type.COUNTRY | uppercase }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 75%; float: right;">
          <input type="tel" matInput placeholder="{{'PROFILO.SMARTPHONE' | translate}}" formControlName="smartphone"
            required>
          <mat-error *ngIf="userForm?.controls['smartphone'].hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
        </mat-form-field>
      </span>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField" [ngClass]="{'mailFiledStop': writable == true}">
        <input matInput placeholder="{{'NUOVOUTENTE.EMAIL' | translate}}" formControlName="email" required [readonly]="writable">
        <mat-error
          *ngIf="userForm?.controls['email'].hasError('pattern') && !userForm?.controls['email'].hasError('required')"
          [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
        <mat-error *ngIf="userForm?.controls['email'].hasError('required')"
          [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
        <mat-error *ngIf="userForm?.controls['email'].hasError('email_not_available')"
          [innerHTML]="'MESSAGE.EMAIL_NOT_AVAILABLE' | translate"></mat-error>
        <mat-error *ngIf="userForm?.controls['email'].hasError('email_taken')"
          [innerHTML]="'MESSAGE.EMAIL_NOT_AVAILABLE' | translate"></mat-error>
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'NUOVOUTENTE.PHONE' | translate}}" formControlName="phone">
      </mat-form-field>
    </span>

    <span *ngIf="userData?._id" class="flex-col">
      <mat-form-field class="rightInputField">
        <mat-select placeholder="{{ 'NUOVOUTENTE.LANGUAGE' | translate}}" formControlName="language">
          <mat-option *ngFor="let type of language" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">
    <span *ngIf="!isSelfEnrollment" class="flex-col">
      <mat-form-field class="leftInputField">
        <mat-select placeholder="{{ 'NUOVOUTENTE.STATE' | translate}}" required formControlName="active">
          <mat-option *ngFor="let type of stato" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </div>

  <div *ngIf="!isSelfEnrollment" class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField" *ngIf="userForm?.controls.type.value == 'CUSTOMER' && profili != null">
        <mat-select (selectionChange)="changedProfile($event)" placeholder="{{ 'NUOVOUTENTE.PROFILO' | translate}}"
          formControlName="profile" *ngIf="profili != null">
          <mat-option *ngFor="let option of profili" [value]="option._id">
            {{ option.name }} - <small>{{ option.description }}</small>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="userForm?.controls.profile.hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row" style="margin-bottom: 40px;">
    <span *ngIf="!isSelfEnrollment || (isSelfEnrollment && !userData?._id)" class="flex-col">
      <div>
        <mat-checkbox color="primary" formControlName="isCreatePaymentRequestActive" class="leftInputField">
          <span class="checkFont">{{'PROFILOUTENTI.RICHIESTEPAGAMENTO' | translate}}</span>
        </mat-checkbox>
      </div>

      <div>
        <mat-checkbox color="primary" formControlName="isConfirmActive" class="leftInputField">
          <span class="checkFont">{{'PROFILOUTENTI.STORNAOPERAZIONI' | translate}}</span>
        </mat-checkbox>
      </div>

      <div>
        <mat-checkbox color="primary" formControlName="isBulkActive" class="leftInputField">
          <span class="checkFont">{{'PROFILOUTENTI.IMPORTMASSIVI' | translate}}</span>
        </mat-checkbox>
      </div>

      <div>
        <mat-checkbox color="primary" formControlName="isSupervisor" class="leftInputField">
          <span class="checkFont">{{'PROFILOUTENTI.SUPERVISOR' | translate}}</span>
        </mat-checkbox>
      </div>
    </span>

    <span *ngIf="!isSelfEnrollment || (isSelfEnrollment && !userData?._id)" class="flex-col">
      <div>
        <mat-checkbox color="primary" formControlName="isSmsActive" class="rightInputField">
          <span class="checkFont">{{'PROFILOUTENTI.SMS' | translate}}</span>
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" formControlName="isQrcodeActive" class="rightInputField">
          <span class="checkFont">{{'PROFILOUTENTI.QRCODE' | translate}}</span>
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" formControlName="isEmailActive" class="rightInputField">
          <span class="checkFont">{{'PROFILOUTENTI.EMAIL' | translate}}</span>
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" formControlName="isLinkActive" class="rightInputField">
          <span class="checkFont">{{'PROFILOUTENTI.LINK' | translate}}</span>
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" formControlName="isWhatsAppActive" class="rightInputField">
          <span class="checkFont">{{'PROFILOUTENTI.WHATSAPP' | translate}}</span>
        </mat-checkbox>
      </div>
    </span>
  </div>

  <!--MODULO PASSWORD UTENTE - VISIBILE SOLO ALLA CREAZIONE NUOVO UTENTE, SIA IN VIEW SELF ENROLLMENT CHE NORMALE -->
  <div *ngIf="!userData?._id" class="flex-row">
    <!-- REGOLE PASSWORD -->
    <div class="flex-col">
      <div class="leftInputField modpasstextuser">
        <span>{{'PROFILO.RIGA1' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA2' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA3' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA4' | translate}}</span>
        <br>
        <span> {{'PROFILO.RIGA5' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA6' | translate}}</span>
      </div>
    </div>
    <!--CAMPO PASSWORD-->
    <div class="flex-col">
      <mat-form-field class="rightInputField">
        <input type="password" matInput placeholder="{{'NUOVOUTENTE.PASSWORD' | translate}}" formControlName="password"
          required>
        <mat-error *ngIf="userForm?.get('password').hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
        <mat-error *ngIf="userForm?.get('password').hasError('pattern')"
          [innerHTML]="'MESSAGE.PASSWORD_PATTERN' | translate"></mat-error>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="isSelfEnrollment && !userData?._id" style="text-align: center; margin-top: 50px;">
    <button [disabled]="!userForm?.valid" mat-flat-button color="primary" (click)="pushUser()">{{'SELF.ADD' | translate
      | uppercase}}</button>
  </div>


</form>
