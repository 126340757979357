import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Reminder} from '../_models/reminder';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ReminderService {
  private readonly REMINDER_URL: string = environment.BASE_URL + 'reminder';
  constructor(private authService: AuthService, private http: HttpClient) {}

  /**
   * Metodo per creare uno scheduler
   * @param reminder Model
   */
  create(reminder: object[] | Reminder[]): Observable <any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.post(this.REMINDER_URL, reminder, { headers });
  }

  /**
   * Metodo per aggiornare il pagamento con i vari reminders
   * @param id String
   * @param array_id String[]
   */
  push(id: string, array_id: string[]): Observable <any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.put(`${environment.BASE_URL}payment/${id}/reminders`, array_id, { headers });
  }

  /**
   * Metodo per cancellare un reminder
   * @param id String
   * @param payment_id String
   */
  delete(id: string, payment_id: string): Observable <any> {
    const query = payment_id ? `?payment_id=${payment_id}` : '';
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.delete(`${environment.BASE_URL}reminder/${id}` + query, { headers });
  }

  /**
   * Metodo per cancellare tutti i reminders
   * @param id String
   */
  deleteAll(id: string): Observable <any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.delete(`${environment.BASE_URL}payment/${id}/reminders`, { headers });
  }

}
