import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import {
  FileItem,
  FileLikeObject,
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders,
} from "ng2-file-upload";
import { timer } from "rxjs";
import { delay, timeout } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { getPrefixes } from "../../_helpers/get-prefixes";
import { Merchant } from "../../_models/merchant";
import { Payment } from "../../_models/payment";
import { AuthService } from "../../_services/auth.service";
import { DialogService } from "../../_services/dialog.service";

@Component({
  selector: "app-request-extra-fields",
  templateUrl: "./request-extra-fields.component.html",
  styleUrls: ["./request-extra-fields.component.scss"],
})
export class RequestExtraFieldsComponent implements OnInit {
  @ViewChild("inputfile") inputfile: ElementRef;

  @Input() set sendVia(operationType: string) {
    this.toggleFormFields(operationType);
  }

  @Input() paymentType: string;
  
  @Input() set modPayment(payment: Payment) {
    if (payment) {
      this.paymentState = payment.state;

      this.extraDataForm.patchValue(payment);
      let momentDate = moment(payment.to_date).utc();
      this.extraDataForm.controls.to_date.setValue(momentDate);

      timer(200).subscribe((_) =>
        this.extraDataForm.controls.expiration_time.setValue(
          momentDate.format("HH:mm")
        )
      );
      if (payment.attachment && payment.attachment.item) {
        this.fileCtrl.setValue(payment.attachment.item.originalname);
      }
    }
  }

  @Input() merchant: Merchant;

  @Input() isPaymentOpen: boolean;

  //@Output() extraData = new EventEmitter<any>();

  extraDataForm = new UntypedFormGroup({});
  languages = [];
  countryPrefix = [];
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  paymentState: string;

  // PATH
  public readonly UPLOAD_URL: string =
    environment.BASE_URL + "payment/attachment";

  // FILEUPLOADER
  fileUploaderOptions: FileUploaderOptions;
  uploader: FileUploader;
  item = null;
  fileCtrl: UntypedFormControl;
  uploadResponseAllegato: any;

  // UTILITIES PER DATA E ORA SCADENZA
  minDate = new Date();
  minDateRecurrent = new Date();
  fifteenDays: moment.Moment;

  minTime: string;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private dialogService: DialogService
  ) {
    this.languages = [
      { value: "IT", viewValue: this.translate.instant("LANGUAGE.ITALIAN") },
      { value: "EN", viewValue: this.translate.instant("LANGUAGE.ENGLISH") },
      { value: "FR", viewValue: this.translate.instant("LANGUAGE.FRENCH") },
      { value: "DE", viewValue: this.translate.instant("LANGUAGE.GERMAN") },
      { value: "ES", viewValue: this.translate.instant("LANGUAGE.SPANISH") },
    ];
    this.fileCtrl = new UntypedFormControl();
    this.setUploaderConfig();
    moment.locale("it");
    this.fifteenDays = moment().add(15, "days") /* .format('YYYY-MM-DD') */;
    this.buildForm();
  }

  ngOnInit() {
    let todayAtElevenPm = moment().hour(23).minutes(0);
    let now = moment().minutes(0);
    this.minDate = new Date();
    if (now.isSameOrAfter(todayAtElevenPm, "minute")) {
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
    if (this.merchant) {
      this.countryPrefix = getPrefixes(this.merchant.area_code_type);
      //this.buildForm();
      if (this.merchant.defaultToDateElapsed) {
        const defaultDate = moment().add(
          parseInt(this.merchant.defaultToDateElapsed),
          "days"
        );
        this.extraDataForm.controls.to_date.setValue(defaultDate);
      }
    }
  }

  buildForm() {
    this.extraDataForm = this.fb.group({
      language: ["IT"],
      currency: ["EUR"],
      email: ["", [Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]],
      area_code: [this.countryPrefix[0] ? this.countryPrefix[0].CODE : ""],
      smartphone: [""],
      piva: [""],
      to_date: [this.fifteenDays, [Validators.required]],
      expiration_time: ["23:59", [Validators.required]],
      recurrents_merchant_email_error: [null, [Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]]
    });

    this.subscribeToExpirationDateChanges();

    //this.extraDataForm.valueChanges.subscribe(value => this.extraData.emit(value))
  }

  toggleFormFields(operationType: string) {
    if (operationType === "SMS" || operationType === "WHATSAPP") {
      this.extraDataForm.removeControl("smartphone");
      this.extraDataForm.removeControl("area_code");
      this.extraDataForm.addControl(
        "email",
        new UntypedFormControl("", [
          Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/),
        ])
      );
    }
    if (operationType === "EMAIL") {
      this.extraDataForm.removeControl("email");
      this.extraDataForm.addControl(
        "area_code",
        new UntypedFormControl(this.countryPrefix[0] ? this.countryPrefix[0].CODE : "")
      );
      this.extraDataForm.addControl("smartphone", new UntypedFormControl(""));
    }
    if (operationType === "QRCODE" || operationType === "LINK") {
      this.extraDataForm.addControl(
        "email",
        new UntypedFormControl("", [
          Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/),
        ])
      );
      this.extraDataForm.addControl(
        "area_code",
        new UntypedFormControl(this.countryPrefix[0] ? this.countryPrefix[0].CODE : "")
      );
      this.extraDataForm.addControl("smartphone", new UntypedFormControl(""));
    }
  }

  get emailControl() {
    return this.extraDataForm.get("email");
  }

  get phoneControl() {
    return this.extraDataForm.get("smartphone");
  }

  // CARICAMENTO ALLEGATI
  setUploaderConfig() {
    this.uploader = new FileUploader({});
    this.fileUploaderOptions = {
      url: this.UPLOAD_URL,
      headers: [{ name: "Authorization", value: this.authService.token }],
      method: "POST",
      queueLimit: 1,
      removeAfterUpload: true,
      allowedMimeType: ["application/pdf"],
    };
    this.uploader.setOptions(this.fileUploaderOptions);
    this.uploader.onWhenAddingFileFailed = (
      item: FileLikeObject,
      filter: any,
      options: any
    ): any => {
      if (filter.name === "fileSize") {
        this.dialogService.openDialogWARN(
          "DIALOG.SIZEFILE_ERROR_TITLE",
          "DIALOG.SIZEFILE_ERROR_MESSAGE"
        );
      } else {
        this.dialogService.openDialogWARN(
          "DIALOG.FORMATCSV_ERROR_TITLE",
          "DIALOG.FORMATIMAGE_ERROR_MESSAGE_PDF"
        );
      }

      this.fileCtrl = new UntypedFormControl("");
    };

    this.uploader.onCompleteItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ): any => {
      if (status === 200) {
        this.uploadResponseAllegato = JSON.parse(response);
      } else {
        let parsedError;
        if (JSON.parse(response)) {
          parsedError = JSON.parse(response);
        }
        if (parsedError && parsedError.message) {
          this.dialogService.openDialogWARN(
            "DIALOG.GENERIC_ERROR_TITLE",
            parsedError.message,
            status
          );
        } else {
          this.dialogService.openDialogWARN(
            "DIALOG.GENERIC_ERROR_TITLE",
            parsedError.message,
            status
          );
        }
      }
    };

    this.uploader.onAfterAddingFile = (fileItem: FileItem): any => {
      this.fileCtrl = new UntypedFormControl(fileItem._file.name);
    };
  }

  onFileUploadClick() {
    this.inputfile.nativeElement.click();
  }

  caricaAllegato() {
    if (this.uploader.queue[0]) {
      this.uploader.queue[0].upload();
      this.uploadResponseAllegato = this.uploader.response["item"];
    }
  }

  clearFile() {
    this.fileCtrl.reset();
  }

  subscribeToExpirationDateChanges() {
    this.extraDataForm.controls.to_date.valueChanges.subscribe((value) => {
      if (!moment.isMoment(value)) {
        return;
      }
      let expiration = value as moment.Moment;
      let tomorrow = moment().add(1, "day");
      let expirationIsAfterToday = expiration.isAfter(moment(), "day");
      let elevenPm = moment().hour(23).minutes(0);
      let now = moment();
      let expirationIsTomorrow = expiration.isSame(tomorrow, "day");
      let itsBeforeElevenPm = now.isBefore(elevenPm, "minute");
      if (
        (expirationIsAfterToday && !expirationIsTomorrow) ||
        (expirationIsTomorrow && itsBeforeElevenPm)
      ) {
        this.minTime = null;
        this.extraDataForm.controls.expiration_time.setErrors(null);
        return;
      }
      this.minTime = moment().add(1, "hour").format("HH:mm");
      let time = this.extraDataForm.value.expiration_time.split(":");
      let selection = moment().hours(time[0]);
      selection.minutes(time[1]);

      if (selection.isSameOrBefore(moment().add(1, "hour"), "minute")) {
        this.extraDataForm.controls.expiration_time.setErrors({
          alreadyExpired: true,
        });
        this.extraDataForm.controls.expiration_time.markAsTouched();
      } else {
        this.extraDataForm.controls.expiration_time.setErrors(null);
      }
    });
  }
}
