import {Component, OnInit, Inject, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { DETAIL_OVERLAY_DATA } from '../../_cdk/overlayData';
import { Operation } from '../../_models/operation';
import { ThemeService } from '../../_services/theme.service';
import { environment } from '../../../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DetailOverlayRef } from '../../_models/detailOverlayRef';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';

@Component({
  selector: 'app-operations-mobile-slider',
  templateUrl: './operations-mobile-slider.component.html',
  styleUrls: ['./operations-mobile-slider.component.scss']
})
export class OperationsMobileSliderComponent implements OnInit, AfterViewInit {

  @ViewChild('mySlider', {static: true}) mySlider: SwiperComponent;
  @Output() notificationEmitter = new EventEmitter<any>();
  @Output() planDeactivationEmitter = new EventEmitter<any>();
  @Output() refreshParentOutcomes = new EventEmitter<any>();

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30,
    edgeSwipeDetection: true,
    allowTouchMove: true,
    lazy: {
      loadPrevNext: false
    },
  };

  element: Operation;
  transactionList: any[] = [];
  ref: DetailOverlayRef;
  theme: string;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  activeIndex = 0;

  constructor(
    @Inject(DETAIL_OVERLAY_DATA) public data: any,
    public detailOverlayRef: DetailOverlayRef,
    private themeService: ThemeService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.element = data.element;
    this.transactionList = data.transactions;
    this.ref = detailOverlayRef;
    this.iconRegistry.addSvgIcon(
      'close', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/closeIcon.svg')
    );

    this.iconRegistry.addSvgIcon(
      'sollecita', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/sollecita.svg')
    );
  }

  ngOnInit() {
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
  }
  ngAfterViewInit() {
    this.mySlider.swiper.on('slideChange', () => {
      this.activeIndex = this.mySlider.swiper.activeIndex;
    });
  }

  closeDetail() {
    this.ref.close();
  }

  notification(recurrent: Operation) {
    this.notificationEmitter.emit(recurrent);
  }

  deactivateRecurrentPlan(payment) {
    this.planDeactivationEmitter.emit(payment);
  }

  refreshTransactions(payment) {
    this.refreshParentOutcomes.emit(payment);
  }

}
