<div>
  <div class="svg-container">
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 1334" style="enable-background:new 0 0 750 1334;" xml:space="preserve">
          <style type="text/css">
            .st0mobile{fill:#F9F9F9;}
            .st1mobile{fill:#DADADA;}
            .st2mobile{fill-rule:evenodd;clip-rule:evenodd;fill:#C6C6C6;}
            .st3mobile{fill:#C6C6C6;}
            .st4mobile{font-family:'SFUIText-Semibold', sans-serif;}
            .st5mobile{font-size:18.5241966px;}
          </style>
          <path class="st0mobile" d="M663.890686,1173.6179199c0,38.4853516-31.1984253,69.6835938-69.6835938,69.6835938H153.1159668
            c-38.4851685,0-69.6835938-31.1982422-69.6835938-69.6835938V120.968811c0-38.4851685,31.1984253-69.6835938,69.6835938-69.6835938
            h50.4685669c5.6851807,0,10.2938843,4.6087646,10.2938843,10.2939453c0,20.5296021,16.6425171,37.1721191,37.1721191,37.1721191
            h245.107605c20.5296021,0,37.1721191-16.6425171,37.1721191-37.1721191c0-5.6851807,4.6087036-10.2939453,10.2938843-10.2939453
            h50.5829468c38.4851685,0,69.6835938,31.1984253,69.6835938,69.6835938V1173.6179199z"/>
          <g>
            <g>
              <path class="st0mobile" d="M56.8399658,236.2315674c-2.3690567,0-4.2891006-1.9203186-4.2891006-4.289093v-38.8878632
                c0-2.3687744,1.9200439-4.289093,4.2891006-4.289093V236.2315674z"/>
              <path class="st1mobile" d="M57.3398438,236.7314453h-0.5c-2.640625,0-4.7890625-2.1484375-4.7890625-4.7890625v-38.8876953
                c0-2.640625,2.1484375-4.7890625,4.7890625-4.7890625h0.5V236.7314453z M56.3398438,189.2983398
                c-1.8540039,0.2456055-3.2890625,1.8364258-3.2890625,3.7563477v38.8876953c0,1.9199219,1.4350586,3.5107422,3.2890625,3.7563477
                V189.2983398z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st0mobile" d="M56.8399658,376.1134949c-2.3690567,0-4.2891006-1.9200439-4.2891006-4.289093v-85.7820129
                c0-2.3687744,1.9200439-4.289093,4.2891006-4.289093V376.1134949z"/>
              <path class="st1mobile" d="M57.3398438,376.6132812h-0.5c-2.640625,0-4.7890625-2.1484375-4.7890625-4.7890625v-85.7817383
                c0-2.640625,2.1484375-4.7890625,4.7890625-4.7890625h0.5V376.6132812z M56.3398438,282.2861328
                c-1.8540039,0.2456055-3.2890625,1.8364258-3.2890625,3.7563477v85.7817383c0,1.9199219,1.4350586,3.5107422,3.2890625,3.7558594
                V282.2861328z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st0mobile" d="M56.8399658,492.7770386c-2.3690567,0-4.2891006-1.9200439-4.2891006-4.289093v-81.2069702
                c0-2.3690491,1.9200439-4.289093,4.2891006-4.289093V492.7770386z"/>
              <path class="st1mobile" d="M57.3398438,493.2773438h-0.5c-2.640625,0-4.7890625-2.1484375-4.7890625-4.7890625V407.28125
                c0-2.640625,2.1484375-4.7890625,4.7890625-4.7890625h0.5V493.2773438z M56.3398438,403.5253906
                c-1.8540039,0.2451172-3.2890625,1.8359375-3.2890625,3.7558594v81.2070312c0,1.9199219,1.4350586,3.5107422,3.2890625,3.7558594
                V403.5253906z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st0mobile" d="M690.4830933,314.9223328c2.3690796,0,4.2891235,1.9203186,4.2891235,4.289093v139.5387573
                c0,2.3690491-1.9200439,4.289093-4.2891235,4.289093V314.9223328z"/>
              <path class="st1mobile" d="M690.4833984,463.5390625h-0.5V314.421875h0.5c2.640625,0,4.7890625,2.1484375,4.7890625,4.7900391V458.75
                C695.2724609,461.390625,693.1240234,463.5390625,690.4833984,463.5390625z M690.9833984,315.4550781v147.0507812
                c1.8535156-0.2451172,3.2890625-1.8359375,3.2890625-3.7558594V319.2119141
                C694.2724609,317.2919922,692.8369141,315.7001953,690.9833984,315.4550781z"/>
            </g>
          </g>
          <g id="Status_Bar_-_Mockup_Default_-_Dark_1_">
            <path id="Battery_Icon_-_100_x25__1_" class="st2mobile" d="M631.0540771,79.4610062
              c0,1.4924316-0.8231201,2.7014465-2.3155518,2.7014465v-5.4028931
              C630.230957,76.7595596,631.0540771,77.9685822,631.0540771,79.4610062z M627.9666748,74.4440384v10.0339432v0.0130005
              c0,1.2787399-1.0367432,2.3025284-2.3154907,2.3025284h-30.1018066c-1.2787476,0-2.3155518-1.0237885-2.3155518-2.3025284
              v-0.0130005V74.4440384c0-1.2787399,1.0368042-2.3155289,2.3155518-2.3155289h30.1018066
              C626.9299316,72.1285095,627.9666748,73.1652985,627.9666748,74.4440384z M595.5493774,72.9003525
              c-0.8513794,0-1.5437012,0.6925125-1.5437012,1.5436859v10.0339432c0,0.8511734,0.6923218,1.5436859,1.5437012,1.5436859
              h30.1018066c0.8513794,0,1.5437012-0.6925125,1.5437012-1.5436859V74.4440384c0-0.8511734-0.6923218-1.5436859-1.5437012-1.5436859
              H595.5493774z M594.7775269,74.4440384v10.0339432c0,0.4256821,0.3463135,0.771843,0.7718506,0.771843h30.1018066
              c0.4254761,0,0.7718506-0.3461609,0.7718506-0.771843V74.4440384c0-0.4256821-0.3463745-0.771843-0.7718506-0.771843h-30.1018066
              C595.1238403,73.6721954,594.7775269,74.0183563,594.7775269,74.4440384z"/>
            <text transform="matrix(1 0 0 1 122.10112 85.5995026)" class="st3mobile st4mobile st5mobile">{{currentData}}</text>
            <g id="Wi-Fi_Icon_Group_1_">
              <g>
                <path id="Wi-Fi_Icon_9_" class="st3mobile" d="M573.2305298,72.1285172c3.2518921,0,6.5359497,1.2786942,9.2681274,3.6046753
                  l-1.2330322,1.3684082c-2.2518311-1.8935928-5.0974121-2.9339523-8.0350952-2.9339523
                  c-2.9360962,0-5.7781982,1.0372849-8.0256958,2.9247131l-1.2303467-1.3684082
                  C566.7028198,73.4037476,569.9822388,72.1285172,573.2305298,72.1285172z M573.2305298,76.9229507
                  c2.1951294,0,4.3684692,0.8085632,6.1417236,2.2813187l-1.3192749,1.4646683
                  c-1.3616333-1.1031189-3.0665894-1.7083969-4.8224487-1.7083969c-1.7518921,0-3.4549561,0.6029663-4.8173218,1.7022324
                  l-1.317749-1.4658203C568.8683472,77.7292099,571.0393677,76.9229507,573.2305298,76.9229507z M573.2305298,81.6526947
                  c1.0292358,0,2.0766602,0.3792572,2.9707031,1.0715485l-2.9707031,3.2974243l-2.9675293-3.3016586
                  C571.1558838,82.0304108,572.2021484,81.6526947,573.2305298,81.6526947z"/>
              </g>
            </g>
            <rect x="554.5996704" y="72.1285172" class="st2mobile" width="3.2970901" height="14.6649933"/>
            <rect x="549.6540527" y="75.6646042" class="st2mobile" width="3.2970901" height="11.1289072"/>
            <rect x="544.6114502" y="79.2006836" class="st2mobile" width="3.2970901" height="7.5928216"/>
            <rect x="539.5688477" y="82.7367706" class="st2mobile" width="3.2970901" height="4.056736"/>
          </g>
          <g>
            <path class="st0mobile" d="M584.7702637,18.487915H162.5528564c-58.383606,0-105.7128906,47.3292847-105.7128906,105.7128906
              v1085.5983887c0,58.383667,47.3292847,105.7128906,105.7128906,105.7128906h422.2174072
              c58.383606,0,105.7128296-47.3292236,105.7128296-105.7128906V124.2008057
              C690.4830933,65.8171997,643.1538696,18.487915,584.7702637,18.487915z M663.890686,1173.6179199
              c0,38.4853516-31.1984253,69.6835938-69.6835938,69.6835938H153.1159668
              c-38.4851685,0-69.6835938-31.1982422-69.6835938-69.6835938V120.968811c0-38.4851685,31.1984253-69.6835938,69.6835938-69.6835938
              h50.4685669c5.6851807,0,10.2938843,4.6087646,10.2938843,10.2939453c0,20.5296021,16.6425171,37.1721191,37.1721191,37.1721191
              h245.107605c20.5296021,0,37.1721191-16.6425171,37.1721191-37.1721191c0-5.6851807,4.6087036-10.2939453,10.2938843-10.2939453
              h50.5829468c38.4851685,0,69.6835938,31.1984253,69.6835938,69.6835938V1173.6179199z"/>
            <path class="st1mobile" d="M584.7705078,1316.0117188H162.5527344c-58.565918,0-106.2128906-47.6464844-106.2128906-106.2128906
              V124.2006836c0-58.565918,47.6469727-106.2128906,106.2128906-106.2128906h422.2177734
              c58.5664062,0,106.2128906,47.6469727,106.2128906,106.2128906v1085.5981445
              C690.9833984,1268.3652344,643.3369141,1316.0117188,584.7705078,1316.0117188z M162.5527344,18.987793
              c-58.0146484,0-105.2128906,47.1982422-105.2128906,105.2128906v1085.5981445
              c0,58.0146484,47.1982422,105.2128906,105.2128906,105.2128906h422.2177734
              c58.0146484,0,105.2128906-47.1982422,105.2128906-105.2128906V124.2006836
              c0-58.0146484-47.1982422-105.2128906-105.2128906-105.2128906H162.5527344z M594.2070312,1243.8017578H153.1162109
              c-38.6992188,0-70.1835938-31.484375-70.1835938-70.1835938V120.96875c0-38.6992188,31.484375-70.1835938,70.1835938-70.1835938
              h50.4682617c5.9516602,0,10.7939453,4.8422852,10.7939453,10.7939453c0,20.2211914,16.4511719,36.6723633,36.6723633,36.6723633
              h245.1074219c20.2207031,0,36.671875-16.4511719,36.671875-36.6723633c0-5.9516602,4.8417969-10.7939453,10.7939453-10.7939453
              h50.5830078c38.6992188,0,70.1835938,31.484375,70.1835938,70.1835938v1052.6494141
              C664.390625,1212.3173828,632.90625,1243.8017578,594.2070312,1243.8017578z M153.1162109,51.7851562
              c-38.1479492,0-69.1835938,31.0356445-69.1835938,69.1835938v1052.6494141
              c0,38.1484375,31.0356445,69.1835938,69.1835938,69.1835938h441.0908203c38.1474609,0,69.1835938-31.0351562,69.1835938-69.1835938
              V120.96875c0-38.1479492-31.0361328-69.1835938-69.1835938-69.1835938h-50.5830078
              c-5.4003906,0-9.7939453,4.3935547-9.7939453,9.7939453c0,20.7724609-16.8994141,37.6723633-37.671875,37.6723633H251.0507812
              c-20.7724609,0-37.6723633-16.8999023-37.6723633-37.6723633c0-5.4003906-4.3935547-9.7939453-9.7939453-9.7939453H153.1162109z"/>
          </g>
          <g>
            <g>
              <path class="st0mobile" d="M403.7989807,73.9332123h-66.3442383c-4.4147644,0-8.006897-3.5918427-8.006897-8.0068741
                c0-4.4150391,3.5921326-8.0068855,8.006897-8.0068855h66.3442383c4.4147339,0,8.0068665,3.5918465,8.0068665,8.0068855
                C411.8058472,70.3413696,408.2137146,73.9332123,403.7989807,73.9332123z M337.4547424,63.6382561
                c-1.2616272,0-2.2880859,1.0264778-2.2880859,2.2880821c0,1.2615967,1.0264587,2.2880783,2.2880859,2.2880783h66.3442383
                c1.2615967,0,2.2880554-1.0264816,2.2880554-2.2880783c0-1.2616043-1.0264587-2.2880821-2.2880554-2.2880821H337.4547424z"/>
              <path class="st1mobile" d="M403.7988281,74.4331055h-66.3442383c-4.690918,0-8.5068359-3.815918-8.5068359-8.5068359
                s3.815918-8.5068359,8.5068359-8.5068359h66.3442383c4.6904297,0,8.5068359,3.815918,8.5068359,8.5068359
                S408.4892578,74.4331055,403.7988281,74.4331055z M337.4545898,58.4194336c-4.1391602,0-7.5068359,3.3676758-7.5068359,7.5068359
                s3.3676758,7.5068359,7.5068359,7.5068359h66.3442383c4.1396484,0,7.5068359-3.3676758,7.5068359-7.5068359
                s-3.3671875-7.5068359-7.5068359-7.5068359H337.4545898z M403.7988281,68.7143555h-66.3442383
                c-1.5375977,0-2.7880859-1.2509766-2.7880859-2.7880859s1.2504883-2.7880859,2.7880859-2.7880859h66.3442383
                c1.5371094,0,2.7880859,1.2509766,2.7880859,2.7880859S405.3359375,68.7143555,403.7988281,68.7143555z M337.4545898,64.1381836
                c-0.9858398,0-1.7880859,0.8022461-1.7880859,1.7880859s0.8022461,1.7880859,1.7880859,1.7880859h66.3442383
                c0.9863281,0,1.7880859-0.8022461,1.7880859-1.7880859s-0.8017578-1.7880859-1.7880859-1.7880859H337.4545898z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st0mobile" d="M442.0395508,76.5748062c-5.8718262,0-10.6484985-4.7769241-10.6484985-10.648468
                s4.7766724-10.6484795,10.6484985-10.6484795s10.648468,4.7769318,10.648468,10.6484795
                S447.911377,76.5748062,442.0395508,76.5748062z M442.0395508,60.9966621c-2.7181091,0-4.9296875,2.2115669-4.9296875,4.9296761
                c0,2.7181015,2.2115784,4.9296722,4.9296875,4.9296722s4.929657-2.2115707,4.929657-4.9296722
                C446.9692078,63.2082291,444.7576599,60.9966621,442.0395508,60.9966621z"/>
              <path class="st1mobile" d="M442.0390625,77.074707c-6.1474609,0-11.1484375-5.0009766-11.1484375-11.1484375
                s5.0009766-11.1484375,11.1484375-11.1484375s11.1494141,5.0009766,11.1494141,11.1484375
                S448.1865234,77.074707,442.0390625,77.074707z M442.0390625,55.777832c-5.5957031,0-10.1484375,4.5527344-10.1484375,10.1484375
                s4.5527344,10.1484375,10.1484375,10.1484375c5.5966797,0,10.1494141-4.5527344,10.1494141-10.1484375
                S447.6357422,55.777832,442.0390625,55.777832z M442.0390625,71.355957c-2.9931641,0-5.4287109-2.4355469-5.4287109-5.4296875
                s2.4355469-5.4296875,5.4287109-5.4296875c2.9941406,0,5.4296875,2.4355469,5.4296875,5.4296875
                S445.0332031,71.355957,442.0390625,71.355957z M442.0390625,61.496582c-2.4423828,0-4.4287109,1.9873047-4.4287109,4.4296875
                s1.9863281,4.4296875,4.4287109,4.4296875s4.4296875-1.9873047,4.4296875-4.4296875S444.4814453,61.496582,442.0390625,61.496582z
                "/>
            </g>
          </g>
        </svg>
    <div *ngIf="emailHTML" class="html-container" [innerHTML]="emailHTML"></div>
    <div *ngIf="smsText" class="sms-graphics">
      <span class="sms-round"></span>
    </div>
    <div *ngIf="smsText" class="sms-container">{{smsText}}</div>
  </div>
</div>
