<div fxLayout="column" fxLayoutAlign="space-around stretch" class="selectControlla">
  <app-operations-filter *ngIf="paymentType === 'SINGLE'" (filters)="filtraDataSource($event)"></app-operations-filter>
  <app-operations-filter-recurrent *ngIf="paymentType === 'RECURRENT'" (filters)="filtraDataSource($event)"></app-operations-filter-recurrent>
  <app-operations-filter-deferred *ngIf="paymentType === 'DEFERRED'" (filters)="filtraDataSource($event)"></app-operations-filter-deferred>
</div>

<div fxLayout="column" fxLayoutAlign="space-around stretch">
  <div fxLayout="column" fxLayoutAlign="space-around stretch" class="positionTable">
    <div class="operation-container ">
      <app-grid  [dataSource]="dataSource" [_paymentType]="paymentType" (sortEmitter)="sortData($event)"></app-grid>
    </div>
  </div>
</div>
<mat-paginator #paginator [length]="dataSource.count" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
  [showFirstLastButtons]="true" class="matPaginator"></mat-paginator>
<div *ngIf="paymentType === 'SINGLE'">
<button mat-flat-button color="primary" (click)="downloadReport()" class="downloadButton">{{ 'CONTROLLA.DOWNLOAD' |
  translate }}</button>
</div>
