import { DataSource } from "@angular/cdk/table";
import { Profile } from "../_models/profile";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ProfileService } from "../_services/profile.service";
import { catchError, finalize } from "rxjs/operators";

export class ProfilesDataSource implements DataSource<Profile> {

    private profilesSubject = new BehaviorSubject<Profile[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    count: any;

    constructor(private profileService: ProfileService) {}

    connect(): Observable<Profile[]> {
      return this.profilesSubject.asObservable();
    }

    disconnect(): void {
      this.profilesSubject.complete();
      this.loadingSubject.complete();
    }

    loadProfiles(filter: object[], sort: string = 'asc', pageIndex: number = 0, pageSize: number) {
      this.loadingSubject.next(true);
      this.profileService.searchProfiles(filter, sort, pageIndex, pageSize, true).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
        .subscribe(profiles => {
          this.count = profiles.results.items;
          this.profilesSubject.next(profiles.items);
        });
    }
}
