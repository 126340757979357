import { Terminal } from '../../../../_models/terminal';
import { TerminalService } from '../../../../_services/terminal.service';
import { Merchant } from '../../../../_models/merchant';

import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoaderService } from '../../../../_services/loader.service';
import { MerchantService } from '../../../../_services/merchant.service';
import { DialogService } from '../../../../_services/dialog.service';
// eslint-disable-next-line no-restricted-imports
import { counties } from '../../../../_models/country';
import { MyBank } from '../../../../_models/mybank';
import { ApplePay } from '../../../../_models/applePay';
import { TemplateService } from '../../../../_services/template.service';
import { Subscription } from 'rxjs';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-merchantTechnicalData',
  templateUrl: './merchantTechnicalData.component.html',
  styleUrls: ['./merchantTechnicalData.component.scss']
})

export class MerchantTechnicalDataComponent implements OnInit, OnDestroy {

  merchant: Merchant = new Merchant();
  idMerchant: string;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  valutaList = ['AED', 'AUD', 'BRL', 'CAD', 'CHF', 'DKK', 'EUR', 'GBP', 'HKD', 'JPY', 'KWD', 'MXN', 'MYR', 'NOK', 'RUB', 'SAR', 'SEK', 'SGD', 'THB', 'TWD', 'USD'];
  operationType = ['EMAIL', 'SMS', 'QRCODE', 'LINK', 'WHATSAPP'];
  transationType = ['AUTH', 'PURCHASE', 'VERIFY'];
  terminaleColumns = ['tid', 'mid', '3ds2_active', 'descrizione', 'action'];
  dataSourceTerminale = new MatTableDataSource<Terminal>();
  MYBANKColumns = ['merchantID', 'timeout', 'action'];
  dataSourceMYBANK = new MatTableDataSource<MyBank>();
  APPLEPAYColumns = ['tid', 'ksig', 'action'];
  dataSourceAPPLEPAY = new MatTableDataSource<ApplePay>();
  countryPrefixTerminal = counties;
  circuitiApple = [
    {code: 'AMEX', desc: 'American Express'},
    {code: 'DINERS', desc: 'Diners'},
    {code: 'MASTERCARD', desc: 'Master Card'},
    {code: 'MAESTRO', desc: 'Maestro'},
    {code: 'VISA', desc: 'Visa'}
  ];

  idTerminale: any;
  flagnuovoterminale = false;
  flagModificaMYBANK: boolean;
  flagnuovoMYBANK = false;
  flagModificaAPPLEPAY: boolean;
  flagnuovoAPPLEPAY = false;
  flagMYBANKEmpty = true;
  flagAPPLEPAYEmpty = true;

  datiTecniciForm: UntypedFormGroup;
  terminalForm: UntypedFormGroup;
  MYBANKForm: UntypedFormGroup;
  APPLEPAYForm: UntypedFormGroup;

  merchantSubscription: Subscription;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    private merchantService: MerchantService,
    private terminalService: TerminalService,
    private dialogService: DialogService,
    private adapter: DateAdapter<any>,
    private templateService: TemplateService,
    private route: ActivatedRoute,
    )
  {
    moment.locale('it');
    this.adapter.setLocale('it');
    this.buildMerchantDataForm();
  }

  ngOnDestroy(){
    this.merchantSubscription.unsubscribe();
    if (this.merchantService.isAdminMode) {
      this.merchantService.currentMerchant = null;
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
       this.merchantService.getMerchant(params.id).subscribe();
      }
    });
    this.merchantSubscription = this.merchantService.currentMerchant$.subscribe(
      value => {
        if (!value) {return; }
        this.merchant = value;
        this.idMerchant = value._id;
        this.setDatiTecniciConfig();
        this.dataSourceTerminale = new MatTableDataSource<Terminal>(this.merchant.terminal);
        this.setMyBankConfig(this.merchant);
        this.setApplePayConfig(this.merchant);
      }
    )

  }

  setDatiTecniciConfig() {
    this.datiTecniciForm.patchValue(this.merchant);
    if (this.merchant.defaultToDateElapsed) {
      this.datiTecniciForm.controls.defaultToDateElapsed.setValue(parseInt(this.merchant.defaultToDateElapsed));
    }
    if (this.merchant.isMyBankActive || this.merchant.isApplePayActive || this.merchant.isRememberMeActive) {
      this.datiTecniciForm.controls.isPaymentPageActive.setValue(true);
    }
  }

  setMyBankConfig(merchant: Merchant) {
    if(merchant.merchantID && merchant.merchantID != ''){
      const mybankObj = new MyBank(merchant.merchantID, merchant.timeout);
      this.dataSourceMYBANK = new MatTableDataSource<MyBank>([mybankObj])
      this.flagMYBANKEmpty = false;
      this.flagnuovoMYBANK = false;
    }
  }

  setApplePayConfig(merchant: Merchant) {
    if(merchant.appleConfig.tid && merchant.appleConfig.tid != '') {
      this.dataSourceAPPLEPAY = new MatTableDataSource<ApplePay>([merchant.appleConfig])
      this.flagAPPLEPAYEmpty = false;
      this.flagnuovoAPPLEPAY = false;
    }
  }

  buildTerminalForm() {
    this.terminalForm = this.fb.group({
      tid: ['', [Validators.required]],
      ksig: ['', [Validators.required]],
      mid_moto: [''],
      tid_moto: [''],
      ksig_moto: [''],
      mid_mit: [''],
      tid_mit: [''],
      ksig_mit: [''],
      mid_cof: [''],
      tid_cof: [''],
      ksig_cof: [''],
      description: ['', [Validators.required]],
      is3dsecure: [false],
      brand: [null, [Validators.required]],
      is3d2secure: [false],
      mid: [null]
    });
  }

  buildMYBANKForm() {
    this.MYBANKForm = this.fb.group({
      merchantID: ['', [Validators.required]],
      timeout: ['', [Validators.pattern('[0-9]*')]]
    });
  }

  buildAPPLEPAYForm() {
    this.APPLEPAYForm = this.fb.group({
      tid: ['', [Validators.required]],
      ksig: ['', [Validators.required]],
      merchantCapabilities: this.fb.group({
        supports3DS: [true],
        supportsEMV: [false],
        supportsCredit: [false],
        supportsDebit: [false]
      }),
      supportedNetworks: ['', [Validators.required]],
      countryCode: ['IT']
    });
  }

  resetMerchantTemplate(data) {
    this.templateService.getDefaultTemplate().subscribe(
      (response: any) => {
        data.templates = response;
        this.updateMerchant(data);
      },
      error => {
        this.merchant.templates = []
      }
    )
  }

  updateMerchant(merchantData: any) {
    this.merchantService.update(this.idMerchant, merchantData)
    .subscribe(
      responseMerchant => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.loaderService.stopGeneral();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }


  salvaDatiTecnici() {
    this.loaderService.startGeneral();
    const merchantData = Object.assign({}, this.datiTecniciForm.value);
    merchantData.defaultToDateElapsed = merchantData.defaultToDateElapsed.toString();
    delete merchantData.isAdditionalInfoActive;
    if (!merchantData.isCustomTemplateActive) {
      this.resetMerchantTemplate(merchantData);
    } else {
      this.updateMerchant(merchantData);
    }
  }

  NuovoMYBANK() {
    this.buildMYBANKForm();
    this.flagnuovoMYBANK = true;
    this.flagModificaMYBANK = false;
  }

  modificaMYBANK(row: MyBank) {
    this.flagnuovoMYBANK = true;
    this.flagModificaMYBANK = true;
    this.buildMYBANKForm();
    this.MYBANKForm.setValue(row);
  }

  indietroMYBANK() {
    this.flagnuovoMYBANK = false;
    this.MYBANKForm.reset();
  }

  salvaMYBANK() {
    this.loaderService.startGeneral();
    this.merchantService.update(this.idMerchant, this.MYBANKForm.value).subscribe(responseMerchant => {
      this.loaderService.stopGeneral();
      this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
    },
    error => {
      console.log(error);
      this.loaderService.stopGeneral();
      this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
    }
  )
  }

  NuovoAPPLEPAY(){
    this.buildAPPLEPAYForm();
    this.flagnuovoAPPLEPAY = true;
    this.flagModificaAPPLEPAY = false;
  }

  modificaAPPLEPAY(row: ApplePay){
    this.flagnuovoAPPLEPAY = true;
    this.flagModificaAPPLEPAY = true;
    this.buildAPPLEPAYForm();
    this.APPLEPAYForm.patchValue(row);
  }

  indietroAPPLEPAY(){
    this.flagnuovoAPPLEPAY = false;
    this.APPLEPAYForm.reset();
  }

  salvaAPPLEPAY(){
    this.loaderService.startGeneral();
    const payload = this.merchant;
    payload.appleConfig = this.APPLEPAYForm.value;
    this.merchantService.update(this.idMerchant, payload).subscribe(responseMerchant => {
      this.loaderService.stopGeneral();
      this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
    },
    error => {
      console.log(error);
      this.loaderService.stopGeneral();
      this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
    });
  }

  salvaURLNOTIFICA() {
    this.loaderService.startGeneral();
    const payload = this.merchant;
    if (!this.datiTecniciForm.controls.notify_url.value) {
      payload.notify_url = '';
    } else {
      payload.notify_url = this.datiTecniciForm.controls.notify_url.value;
    }
    this.merchantService.update(this.idMerchant, payload).subscribe(responseMerchant => {
      this.loaderService.stopGeneral();
      this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
    },
    error => {
      console.log(error);
      this.loaderService.stopGeneral();
      this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
    });
  }


  NuovoTerminale() {
    this.buildTerminalForm();
    this.flagnuovoterminale = true;
    this.idTerminale = null;
  }

  indietroTerminale() {
    this.flagnuovoterminale = false;
    this.terminalForm.reset();
    this.idTerminale = null;
  }

  modificaTerminale(row: Terminal) {
    this.buildTerminalForm();
    this.flagnuovoterminale = true;
    this.terminalForm.patchValue(row)
    this.idTerminale = row._id;

  }

  DialogEliminaTerminale(row: Terminal){
    const dialogRef = this.dialogService.openDialogDELETE("", "DIALOG.SUCCESS_TERMINAL_DELETED");
    dialogRef.afterClosed().subscribe(
      data => {
        if (!data) return
        this.eliminaTerminale(row);
      }
    );
  }

  eliminaTerminale(row: Terminal){
    this.merchantService.eliminaTerminale(this.idMerchant, row._id)
    .subscribe(
      response => {
        this.updateView();
      },
      error =>{
        console.log(error);
        this.dialogService.openDialogWARN("DIALOG.GENERIC_ERROR_MESSAGE", "DIALOG.GENERIC_ERROR_MESSAGE", error.status)
      }
    );
  }

  aggiornaTerminale() {
    this.loaderService.startGeneral();
    const terminale: Terminal = this.terminalForm.value;
    this.terminalService.update(this.idTerminale, terminale)
    .subscribe(
      responseMerchant => {
        this.updateView();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    )
  }


  changeCircuits(value: string[]) {
    this.terminalForm.controls.brand.setValue(value);
  }

  changeAppleCountryCode(value: string) {
    this.APPLEPAYForm.controls.countryCode.setValue(value);
  }

  aggiungiTerminale() {
    this.loaderService.startGeneral();
    const terminale: Terminal = this.terminalForm.value;
    if (this.dataSourceTerminale.data && this.dataSourceTerminale.data.length > 0) {
      terminale.default = false;
    } else {
      terminale.default = true;
    }

    this.terminalService.create(terminale)
    .subscribe(
      response => {
        const idTerminale = response['item']['_id'];
        this.inserisciTerminale(idTerminale);
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  inserisciTerminale(idTerminale: string) {
    this.merchantService.inserisciTerminale(this.idMerchant, idTerminale)
    .subscribe(
      responseMerchant => {
        this.updateView();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      },
    );
  }



  attivaTerminale(row: any) {
    this.loaderService.startGeneral();
    const idTerminaleRow = row._id;
    let terminal: Terminal[];
    terminal = this.merchant.terminal;

    for (let i = 0; i < terminal.length; i++) {
      const terminale = new Terminal();
      if (terminal[i]._id === idTerminaleRow) {
        terminale.default = true;
      } else {
        terminale.default = false;
      }

      this.terminalService.update(terminal[i]._id, terminale)
      .subscribe(
        responseMerchant => {
          if (i != terminal.length - 1) return;
          this.loaderService.stopGeneral();
          this.updateView();
        },
        error => {
          console.log(error);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
    }
  }

  tornaClienti() {
    this.router.navigate(['admin/merchants']);
  }

  buildMerchantDataForm(): any {
    this.datiTecniciForm = this.fb.group({
      currency: [['EUR']],
      market: [],
      operation_type: [null, [Validators.required]],
      transaction_type:  [null, [Validators.required]],
      area_code_type: [[{
        'CODE': '+39',
        'DESC': 'ITALIA',
        'COUNTRY': 'it'
      }], [Validators.required]],
      key: [{value: '', disabled: true}],
      defaultToDateElapsed: [15, [Validators.required]],
      isDonationActive: [false],
      isPayInstrToken: [false],
      isReminderActive: [false],
      isRecurrentActive: [false],
      isDeferredActive: [false],
      isPaymentOpenActive: [false],
      isImportMassivelyActive: [false],
      isFastCheckoutActive: [false],
      isPaymentPageActive: [false],
      isRememberMeActive: [false],
      isQueryStringActive: [false],
      isMyBankActive: [false],
      isApplePayActive: [false],
      isDynamicInformationsActive: [false],
      isAdditionalInfoActive: [false],
      isAdditionalInfoEditable: [false],
      addInfoCount: [0],
      isCustomTemplateActive: [false],
      isCustomTemplateSenderActive: [false],
      isNotificationActive: [false],
      //isRetryCallbackActive: [false],
      notify_url: [''],
    });
    this.datiTecniciForm.controls.isCustomTemplateActive.valueChanges.subscribe(value => {
      if (!value) { this.datiTecniciForm.controls.isCustomTemplateSenderActive.setValue(false)}
    });
    this.datiTecniciForm.controls.isPaymentPageActive.valueChanges.subscribe(value => {
      if (!value) {
        this.datiTecniciForm.controls.isMyBankActive.setValue(false);
        this.datiTecniciForm.controls.isApplePayActive.setValue(false);
        this.datiTecniciForm.controls.isRememberMeActive.setValue(false);
      }
    });
    this.datiTecniciForm.controls.isDynamicInformationsActive.valueChanges.subscribe(value => {
      if (!value) {
        this.datiTecniciForm.controls.isAdditionalInfoActive.setValue(true, {emitEvent: false});
      } else {
        this.datiTecniciForm.controls.isAdditionalInfoActive.setValue(false, {emitEvent: false});
        this.datiTecniciForm.controls.isAdditionalInfoEditable.setValue(false);
        this.datiTecniciForm.controls.addInfoCount.setValue(0, {emitEvent: false});
      }
    })
    this.datiTecniciForm.controls.isAdditionalInfoActive.valueChanges.subscribe(value => {
      if (!value) {
        this.datiTecniciForm.controls.isDynamicInformationsActive.setValue(true, {emitEvent: false});
        this.datiTecniciForm.controls.isAdditionalInfoEditable.setValue(false);
        this.datiTecniciForm.controls.addInfoCount.setValue(0, {emitEvent: false});
      } else {
        this.datiTecniciForm.controls.isDynamicInformationsActive.setValue(false, {emitEvent: false});
        this.datiTecniciForm.controls.isAdditionalInfoEditable.setValue(false);
      }
    })

    /* this.datiTecniciForm.controls.isRetryCallbackActive.valueChanges.subscribe(value => {
      if (!value) {
        this.datiTecniciForm.controls.notify_url.setValue('');
      }
    }); */
  }

  updateView() {
    this.merchantService.getMerchant(this.merchant._id)
    .subscribe(
      (response: Merchant) => {
        this.dataSourceTerminale = new MatTableDataSource<any>(response.terminal);
        this.flagnuovoterminale = false;
        this.loaderService.stopGeneral();
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      },
    );
  }

}
