import { UntypedFormGroup } from '@angular/forms';


export function customerDataValidator (group: UntypedFormGroup) {
    let hasError = false;
    const errorObj = {
        missingField: {
            phone: false,
            email: false,
            name: false,
        }
    }
    const sendMethod = group.get('operation_type');
    const phone = group.get('smartphone');
    const email = group.get('email');
    const name = group.get('name');
    const isPaymentOpen = group.get('isPaymentOpen');
    if (sendMethod.value === 'SMS' && !phone.value) {
        hasError = true;
        errorObj.missingField.phone = true;
    }
    if (sendMethod.value === 'WHATSAPP' && !isPaymentOpen.value && !phone.value) {
        hasError = true;
        errorObj.missingField.phone = true;

    }
    if (sendMethod.value === 'EMAIL' && !email.value) {
        hasError = true;
        errorObj.missingField.email = true;
    }
    if (!name.value && !isPaymentOpen.value) {
        hasError = true;
        errorObj.missingField.name = true;
    }
    return hasError ? errorObj : null;

}