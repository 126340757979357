import { Referent } from './referent';
import { ApplePay } from './applePay';

export class Merchant {

    location: any[];
    informations: any[];
    _id: string;
    currency:  any[];
    terminal:  any[];
    operation_type:  any[];
    transaction_type:  any[];
    first_access:  boolean;
    active: boolean;
    uuid:  string;
    piva: string;
    name: string;
    smartphone: string;
    email: string;
    fromAddress: string;
    description: string;
    code: string;
    insegna: string;
    market: string;
    address: string;
    number: string;
    state: string;
    province: string;
    region: string;
    cap: string;
    area_code: string;
    phone: string;
    fax: string;
    linkedin: string;
    facebook: string;
    twitter: string;
    website: string;
    referent: any;
    createdAt: Date;
    updatedAt: Date;
    district: string;
    key: string;
    logo: any;
    area_code_type: any[];
    addInfoCount: number;
    isDonationActive: boolean;
    isPayInstrToken: boolean;
    isReminderActive: boolean;
    isRecurrentActive: boolean;
    isDeferredActive: boolean;
    isPaymentOpenActive: boolean;
    isImportMassivelyActive: boolean;
    isFastCheckoutActive: boolean;
    isPaymentPageActive: boolean;
    isRememberMeActive: boolean;
    isQueryStringActive: boolean;
    isSelfEnrollmentActive: boolean;
    isMyBankActive: boolean;
    isApplePayActive: boolean;
    isCustomTemplateActive: boolean;
    isCustomTemplateSenderActive: boolean;
    isNotificationActive: boolean;
    isRetryCallbackActive: boolean;
    notify_url: string;
    merchantID: string;
    timeout: number;
    isDynamicInformationsActive: boolean;
    isAdditionalInfoEditable: boolean;
    merchants: any [];
    templates: Array<any>;
    defaultToDateElapsed: string;
    appleConfig: ApplePay;
    paymentsReceived?: any;
    paymentsPending?: any;
    constructor() {
        this.informations = [];
        this.location = [];
        this.currency =  [];
        this.terminal =  [];
        this.operation_type = [];
        this.transaction_type = [];
        this.area_code_type = [];
        this.merchants = [];
        this.templates = [];
    }

}
