import { Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ThemeService } from '../../_services/theme.service';
import * as moment from 'moment';


export const MY_DATE_FORMATS =  {
  parse: {
      dateInput: 'L'
  },
  display: {
      dateInput: 'L',
      monthYearLabel: 'MMM YYYY',
  }
};

@Component({
  selector: 'app-datepicker-modal',
  templateUrl: './datepicker-modal.component.html',
  styleUrls: ['./datepicker-modal.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
})


export class DatepickerModalComponent {
  form: UntypedFormGroup;
  isMobile: boolean;
  minDate: Date;
  minTime: string;
  theme: string;

  constructor(
    public dialogRef: MatDialogRef<DatepickerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adapter: DateAdapter<any>,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private themeService: ThemeService
  ) {
    this.isMobile = this.data.isMobile;
    if (this.translate.currentLang) {
      this.adapter.setLocale(this.translate.currentLang);
    } else {
      this.adapter.setLocale('it');
    }

    let momentDate = moment(this.data.expiration).utc();

    this.form = this.fb.group({
      to_date: [momentDate, [Validators.required]],
      expiration_time: [momentDate.format('HH:mm')]
    });

    this.form.controls.to_date.valueChanges.subscribe(value => this.recalcMinTimeAccordingToExpirationDayChanges(value))

    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });

    this.minDate = new Date();
    let todayAtElevenPm = moment().hour(23).minutes(0);
    let now = moment().minutes(0);
    if (now.isSameOrAfter(todayAtElevenPm, 'minute')) {
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
    this.recalcMinTimeAccordingToExpirationDayChanges(momentDate);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  recalcMinTimeAccordingToExpirationDayChanges(expirationDay: moment.Moment) {

    if (!moment.isMoment(expirationDay)) { return; }
    let tomorrow = moment().add(1, 'day');
    let expirationIsAfterToday = expirationDay.isAfter(moment(), 'day');
    let elevenPm = moment().hour(23).minutes(0);
    let now = moment();
    let expirationIsTomorrow = expirationDay.isSame(tomorrow, 'day');
    let itsBeforeElevenPm = now.isBefore(elevenPm, 'minute');
    if ((expirationIsAfterToday && !expirationIsTomorrow)
      || (expirationIsTomorrow && itsBeforeElevenPm)
    ) {
      this.minTime = null;
      this.form.controls.expiration_time.setErrors(null);
      return;
    }
    this.minTime = moment().add(1, 'hour').format('HH:mm');
    let time = this.form.value.expiration_time.split(':');
    let selection = moment().hours(time[0]);
    selection.minutes(time[1]);

    if (selection.isSameOrBefore(moment().add(1, 'hour'), 'minute')) {
      this.form.controls.expiration_time.setErrors({ alreadyExpired: true });
      this.form.controls.expiration_time.markAsTouched();
    } else {
      this.form.controls.expiration_time.setErrors(null);
    }
  }
}
