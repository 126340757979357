<div class="container" [ngClass]="theme">
  <!--HORIZONTAL STEPPER PER DESKTOP -->
  <div *ngIf="!isSmallScreen"
    style="background-color: #fff; border-radius: 12px;  min-height: 400px; max-height: 100vh; min-width: 1000px;">
    <!--WELCOME-->
    <div *ngIf="!hasStarted" class="welcomeStep">
      <div>
        <div class="welcomeTitle inline">{{'DIALOG.WELCOME' | translate | uppercase}}</div>
        <div Class="anteprimaLogoTop inline selfLogo"></div>
      </div>
      <button mat-flat-button color="primary" class="XXL" style="margin-top: 50px;"
        (click)="hasStarted = true;">{{'SELF.START' | translate | uppercase}}</button><br><br>
      <button mat-flat-button color="primary" style="margin-bottom: 10px;" (click)="closeOverlay()">{{'DIALOG.CLOSE' |
        translate}}</button>
    </div>

    <mat-horizontal-stepper *ngIf="hasStarted" #stepper class="horizontalStepper">

      <!--OVERRIDE DELLE ICONE DI DEFAULT CON COMPORTAMENTI CUSTOM PER I DIVERSI STATI DELLO STEP-->
      <ng-template matStepperIcon="edit" let-index="index">
        <div *ngIf="index < 5">{{index+1}}</div>
        <mat-icon svgIcon="flag" *ngIf="index===5"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done" let-index="index">
        <mat-icon *ngIf="index < 5">done</mat-icon>
        <mat-icon svgIcon="flag" *ngIf="index===5"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="complete">
        <mat-icon svgIcon="flag"></mat-icon>
      </ng-template>

      <!--STEP MERCHANT-DATA -->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.MERCHANT_DATA' | translate | uppercase}}</ng-template>
        <div class="wrapper">
          <app-merchant-data [isSelfEnrollment]="true" (outputFormValues)="saveMerchantData($event)">
          </app-merchant-data>
          <button mat-flat-button color="primary" (click)="closeOverlay()">{{'DIALOG.CLOSE' | translate}}</button>
          <button mat-fab matStepperNext class="buttonRight">
            <mat-icon class="arrowIconRight">chevron_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <!--STEP LOGO E SOCIAL-->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.LOGO_SOCIAL' | translate | uppercase}}</ng-template>
        <div class="wrapper">
          <app-logo-form [isSelfEnrollment]="true">
          </app-logo-form>
          <div style="height: 40px;"></div>
          <app-social-form [merchant]="currentMerchant" [isSelfEnrollment]="true"
            (outputFormValues)="saveSocialData($event)"></app-social-form>
          <button mat-flat-button color="primary" (click)="closeOverlay()">{{'DIALOG.CLOSE' | translate}}</button>
          <button mat-fab matStepperPrevious class="buttonLeft">
            <mat-icon class="arrowIconLeft">chevron_left</mat-icon>
          </button>
          <button mat-fab matStepperNext class="buttonRight">
            <mat-icon class="arrowIconRight">chevron_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <!--STEP USER-DATA -->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.YOUR_DATA' | translate | uppercase}}</ng-template>
        <div class="wrapper">
          <app-user-data [userData]="user" [disabledFields]="disabledFieldsUser" [isProfile]="true"
            [isSelfEnrollment]="true" (outputFormValues)="saveUserForm($event)">
          </app-user-data>
          <button mat-flat-button color="primary" (click)="closeOverlay()">{{'DIALOG.CLOSE' | translate}}</button>
          <button mat-fab matStepperPrevious class="buttonLeft">
            <mat-icon class="arrowIconLeft">chevron_left</mat-icon>
          </button>
          <button mat-fab matStepperNext class="buttonRight">
            <mat-icon class="arrowIconRight">chevron_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <!--STEP REFERENT-DATA -->
      <mat-step>
        <ng-template matStepLabel>{{'PROFILO.TITOLOBARRA3' | translate }}</ng-template>
        <div class="wrapper">
          <app-referent-data [isSelfEnrollment]="true" (outputFormValues)="saveReferentForm($event)">
          </app-referent-data>
          <button mat-flat-button color="primary" (click)="closeOverlay()">{{'DIALOG.CLOSE' | translate}}</button>
          <button mat-fab matStepperPrevious class="buttonLeft">
            <mat-icon class="arrowIconLeft">chevron_left</mat-icon>
          </button>
          <button mat-fab matStepperNext class="buttonRight">
            <mat-icon class="arrowIconRight">chevron_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <!--STEP AGGIUNGI UTENTE -->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.ADD_USER' | translate | uppercase}}</ng-template>
        <div style="padding: 0 30px 20px 30px; margin-top: -25px;">
          <mat-chip-list class="selfChips">
            <mat-chip *ngFor="let user of userList, index as i" selected removable="true" (click)="removeUser(i)"
              color="primary">
              {{user?.last_name | uppercase }}
              <mat-icon matChipRemoves style="margin: 5px -4px 0 5px; color: #fff;">close</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <app-user-data [userData]="{}" [isProfile]="false" [disabledFields]="disabledFieldsNewUser"
            [isSelfEnrollment]="true" [userList]="userList" (outputFormValues)="saveUserForm($event)"
            (newUser)="addUser($event)">
          </app-user-data>
          <div style="text-align: center;">
            <button mat-flat-button color="primary" style="margin: 10px 0" (click)="closeOverlay()">{{'DIALOG.CLOSE' |
              translate}}
            </button><br><br>
          </div>
          <button mat-fab matStepperPrevious class="buttonLeft">
            <mat-icon class="arrowIconLeft">chevron_left</mat-icon>
          </button>
          <button mat-fab matStepperNext class="buttonRight">
            <mat-icon class="arrowIconRight">chevron_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <!--STEP FINALE-->
      <mat-step state="complete">
        <ng-template matStepLabel>{{'SELF.COMPLETE' | translate | uppercase}}</ng-template>
        <div style="margin-top: 30px;" class="wrapper">
          <button mat-flat-button color="primary"
            [disabled]="merchantData?.status === 'INVALID' || userData?.status === 'INVALID'" class="XXL"
            (click)="submitStepperData()">{{'SELF.COMPLETE' | translate |
            uppercase}}</button><br><br>
          <button mat-flat-button color="primary" (click)="closeOverlay(); stepper.reset()">{{'DIALOG.CLOSE' |
            translate}}
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>


  <!--VERTICAL STEPPER PER MOBILE -->
  <div *ngIf="isSmallScreen" style="background-color: #fff; height: 100vh; width: 100vw;">
    <div
      style="display: flex; flex-direction: row; justify-content: flex-end; background-color: #fff; border-bottom: 1px solid #d5d5d5; position: fixed; z-index: 2; width: 100%;">
      <button style="background-color: transparent;" (click)="closeOverlay()">
        <mat-icon style="font-size: 34px;" class="overlayIcons" aria-hidden="false" aria-label="close">close
        </mat-icon>
      </button>
    </div>
    <!--WELCOME-->
    <div *ngIf="!hasStarted" class="welcomeMobile">
      <div class="welcomeTitle inline">{{'DIALOG.WELCOME' | translate | uppercase}}</div>
      <div Class="anteprimaLogoTop inline selfLogo"></div>
      <button mat-flat-button color="primary" class="XXL" style="margin-top: 30px;" (click)="hasStarted = true;">
        {{'SELF.START' | translate | uppercase}}
      </button><br><br>
    </div>

    <mat-vertical-stepper *ngIf="hasStarted" style="padding-top: 52px;">

      <!--OVERRIDE DELLE ICONE DI DEFAULT CON COMPORTAMENTI CUSTOM PER I DIVERSI STATI DELLO STEP-->
      <ng-template matStepperIcon="edit" let-index="index">
        <div *ngIf="index < 5">{{index+1}}</div>
        <mat-icon *ngIf="index===5">emoji_flags</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done" let-index="index">
        <mat-icon *ngIf="index < 5">done</mat-icon>
        <mat-icon *ngIf="index===5">emoji_flags</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="complete">
        <mat-icon svgIcon="flag"></mat-icon>
      </ng-template>

      <!--STEP MERCHANT-DATA -->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.MERCHANT_DATA' | translate | uppercase}}</ng-template>
        <ng-template matStepperIcon>check</ng-template>
        <div class="wrapperMobile">
          <app-merchant-data [isSelfEnrollment]="true" (outputFormValues)="saveMerchantData($event)">
          </app-merchant-data>
        </div>
      </mat-step>

      <!--STEP LOGO E SOCIAL-->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.LOGO_SOCIAL' | translate | uppercase}}</ng-template>
        <div class="wrapperMobile">
          <app-logo-form [isSelfEnrollment]="true">
          </app-logo-form>
          <div style="height: 40px;"></div>
          <app-social-form [merchant]="currentMerchant" [isSelfEnrollment]="true"
            (outputFormValues)="saveSocialData($event)">
          </app-social-form>
        </div>
      </mat-step>

      <!--STEP USER-DATA -->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.YOUR_DATA' | translate | uppercase}}</ng-template>
        <div class="wrapperMobile" style="margin-top: 42px;">
          <app-user-data [isProfile]="true" [isSelfEnrollment]="true" [userData]="user"
            [disabledFields]="disabledFieldsUser" (outputFormValues)="saveUserForm($event)">
          </app-user-data>
        </div>
      </mat-step>

      <!--STEP REFERENT-DATA -->
      <mat-step>
        <ng-template matStepLabel>{{'PROFILO.TITOLOBARRA3' | translate }}</ng-template>
        <div class="wrapperMobile" style="margin-top: 42px;">
          <app-referent-data [isSelfEnrollment]="true" (outputFormValues)="saveReferentForm($event)">
          </app-referent-data>
        </div>
      </mat-step>

      <!--STEP AGGIUNGI UTENTE -->
      <mat-step>
        <ng-template matStepLabel>{{'SELF.ADD_USER' | translate | uppercase}}</ng-template>
        <div>
          <mat-chip-list class="selfChips">
            <mat-chip *ngFor="let user of userList, index as i" selected removable="true" (click)="removeUser(i)"
              color="primary">
              {{user?.last_name | uppercase }}
              <mat-icon matChipRemoves style="margin: 5px -4px 0 5px; color: #fff;">close</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <app-user-data [userData]="{}" [isProfile]="false" [disabledFields]="disabledFieldsNewUser"
            [isSelfEnrollment]="true" [userList]="userList" (outputFormValues)="saveUserForm($event)"
            (newUser)="addUser($event)">
          </app-user-data>
        </div>
      </mat-step>

      <!--STEP FINALE-->
      <mat-step state="complete">
        <ng-template matStepLabel>{{'SELF.COMPLETE' | translate | uppercase}}</ng-template>
        <div class="wrapperMobile">
          <button mat-flat-button color="primary" style="margin-top: 24px"
            [disabled]="merchantData?.status === 'INVALID' || userData?.status === 'INVALID'" class="XXL"
            (click)="submitStepperData()">
            {{'SELF.COMPLETE' | translate | uppercase}}
          </button><br><br>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
