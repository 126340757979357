export class OpenPaymentData {
  constructor(
    public pan?: string,
    public date?: string,
    public amount?: any,
    public transactions?: Array<any>
) {
    this.pan = pan ? pan : 'N/D';
    this.date = date ? date : '';
    this.amount = amount ? amount : '';
    this.transactions = transactions ? transactions : [];
  }
}
