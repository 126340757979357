<div ngClass.xs="auth-boxXs-Widget" ngClass.sm="auth-boxSm-Widget" ngClass.md="auth-boxMd-Widget"
  ngClass.lg="auth-boxLg-Widget" ngClass.xl="auth-boxXl-Widget">
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="discoWidget">
        <div>
          <span class="textDiscoWidget">{{numeroPagamenti}}</span>
        </div>
      </div>
      <img alt="Not paid icon" *ngIf='PaymentType === "NotPaid"'
        [src]="ASSETS_BASE + 'svg/iconsvg/pagamento-in-attesaicona.svg'" class="logoControllaWidget">
      <img alt="Paid icon" *ngIf='PaymentType === "Paid"'
        [src]="ASSETS_BASE + 'svg/iconsvg/pagamento-ricevutoicona.svg'" class="logoControllaWidget">
    </div>

  </div>
  <mat-divider class="controllaDivider"></mat-divider>
  <div fxLayout="row" fxLayoutAlign="space-between start" style="margin: 0 18px;">
    <span *ngIf='PaymentType === "NotPaid"' ngClass.xs="titoloButtonControllaWidgetXs"
      ngClass.sm="titoloButtonControllaWidgetSm" ngClass.md="titoloButtonControllaWidgetMd"
      ngClass.lg="titoloButtonControllaWidgetLg"
      ngClass.xl="titoloButtonControllaWidgetXl">{{'CONTROLLA.TOTPAGAMENTIATTESA'
      | translate}}</span>
    <span *ngIf='PaymentType === "Paid"' ngClass.xs="titoloButtonControllaWidgetXs"
      ngClass.sm="titoloButtonControllaWidgetSm" ngClass.md="titoloButtonControllaWidgetMd"
      ngClass.lg="titoloButtonControllaWidgetLg"
      ngClass.xl="titoloButtonControllaWidgetXl">{{'CONTROLLA.TOTPAGAMENTIRICEVUTI'
      | translate}}</span>
    <span ngClass.xs="dettButtonControllaWidgetXs" ngClass.sm="dettButtonControllaWidgetSm"
      ngClass.md="dettButtonControllaWidgetMd" ngClass.lg="dettButtonControllaWidgetLg"
      ngClass.xl="dettButtonControllaWidgetXl">{{pagamenti}}</span>
  </div>
</div>
