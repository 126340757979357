import { ValidatorFn, UntypedFormControl } from '@angular/forms';

export function MyCustomEmailValidator(element: string): ValidatorFn {
  let otherControl: UntypedFormControl;
  let thisControl: UntypedFormControl;

  return function myCustomEmailValidator (control: UntypedFormControl): {[key: string]: any} | null {

    if (!control.parent) {
      return null;
    }

    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.parent.controls[element] as UntypedFormControl;
      if (!otherControl) {
        console.log("ERRORE!!!!");
      }

      otherControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });

    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value == 'EMAIL') {
      if (!thisControl.value) {
        return {
          myCustomEmailValidator: true
        };
      }
    }

    return null;
  };
}
