import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../_services/auth.service';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../_models/user';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../../../../_services/merchant.service';



@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class OperationsComponent {
  pageSizeList = 5;

  recipient: string;
  filter: any[];
  settings: any;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  isSmallScreen: boolean;
  isImportMassivelyActive: boolean;

  cardDataSource: any;
  user: User;
  filters: Object;
  transactionsOpen: any[];

  activeTab: string;

  constructor(
    private merchantService: MerchantService,
    private authService: AuthService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.user = this.authService.user;
    this.iconRegistry.addSvgIcon(
      'openPayment', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/open-payment.svg')
    );
    this.iconRegistry.addSvgIcon(
      'disable', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/disable-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'download', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/download.svg')
    );
  }

}
