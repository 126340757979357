<div style='background-color: #fff; padding: 20px 0; margin-top: -19px;'>
  <!-- Filter -->
  <app-holding-filter-table (syncFilters)='syncFilters = $event' (asyncFilters)='asyncFilters = $event'></app-holding-filter-table>

  <!-- Info amount total operation -->
  <div fxLayout="row" fxFlexFill fxLayout.lt-md="column" fxLayoutGap.sm="20px" fxLayoutGap.xs="20px">
    <app-holding-operation-widget PaymentType='Paid' [infoStatus]='infoStatusPayment?.received' fxFlex></app-holding-operation-widget>
    <app-holding-operation-widget PaymentType='NotPaid' [infoStatus]='infoStatusPayment?.pending' fxFlex></app-holding-operation-widget>
  </div>
</div>

<!-- Table list merchats holding  -->
<app-holding-merchants-table [merchants]='merchantsFiltred' ></app-holding-merchants-table>
