import { Component, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { User } from '../../_models/user';
import { PaymentService } from '../../_services/payment.service';
import * as moment from 'moment';
import { Report } from '../../_models/report';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../_services/auth.service';
import { MerchantService } from '../../_services/merchant.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-operation-widget',
  templateUrl: './operation-widget.component.html',
  styleUrls: ['./operation-widget.component.scss']
})
export class OperationWidgetComponent implements OnInit {

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  user: User = new User();
  idMerchant: string;
  currentDay = '';

  numeroPagamenti: any;
  pagamenti: any;

  _currentDayInput: string;

  payment_type: string;

  @Input() currentDayInput: string;
  @Input() PaymentType: string;

  constructor(
    private authService: AuthService,
    private merchantService: MerchantService,
    private paymentService: PaymentService,
    private activatedRoute: ActivatedRoute
  ) {
    this.currentDay = moment().format('YYYY-MM-DD');
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentDayInput: SimpleChange = changes.currentDayInput;
    this._currentDayInput = currentDayInput.currentValue;

    if (this.activatedRoute.snapshot.url && this.activatedRoute.snapshot.url[0]) {
      this.checkRoute(this.activatedRoute.snapshot.url[0].path);
      this.getData();
    }
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.url && this.activatedRoute.snapshot.url[0]) {
      this.checkRoute(this.activatedRoute.snapshot.url[0].path);
    }
  }

  checkRoute(route: string) {
    this.payment_type = route.toUpperCase();
  }

  getData() {
    this.user = this.authService.user;
    this.idMerchant = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : undefined;
    if (this._currentDayInput) {
      if (this.PaymentType === 'Paid') {
        this.paymentService.getPaid(this.idMerchant, this._currentDayInput, this.currentDay, this.payment_type).subscribe(res => {
          this.pagamenti = <Report>res['received']['amount'] + '  €';
          this.numeroPagamenti = <Report>res['received']['items'];
        });
      } else {
        this.paymentService.getNotPaid(this.idMerchant, this._currentDayInput, this.currentDay, this.payment_type).subscribe(res => {
          this.pagamenti = <Report>res['onhold']['amount'] + '  €';
          this.numeroPagamenti = <Report>res['onhold']['items'];
        });
      }

    } else {
      if (this.PaymentType === 'Paid') {
        this.paymentService.getPaid(this.idMerchant, this.currentDay, this.currentDay, this.payment_type).subscribe(res => {
          this.pagamenti = <Report>res['received']['amount'] + '  €';
          this.numeroPagamenti = <Report>res['received']['items'];
        })
      } else {
        this.paymentService.getNotPaid(this.idMerchant, this.currentDay, this.currentDay, this.payment_type).subscribe(res => {
          this.pagamenti = <Report>res['onhold']['amount'] + '  €';
          this.numeroPagamenti = <Report>res['onhold']['items'];
        })
      }

    }
  }

}