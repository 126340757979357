export const counties = [
    {
        'CODE': '+39',
        'DESC': 'ITALIA',
        'COUNTRY': 'it'
    },
    {
        'CODE': '+93',
        'DESC': 'AFGHANISTAN',
        'COUNTRY': 'af'
    },
    {
        'CODE': '+1',
        'DESC': 'ALASKA',
        'COUNTRY': 'ala'
    },
    {
        'CODE': '+355',
        'DESC': 'ALBANIA',
        'COUNTRY': 'al'
    },
    {
        'CODE': '+213',
        'DESC': 'ALGERIA',
        'COUNTRY': 'dz'
    },
    {
        'CODE': '+376',
        'DESC': 'ANDORRA',
        'COUNTRY': 'ad'
    },
    {
        'CODE': '+244',
        'DESC': 'ANGOLA',
        'COUNTRY': 'ao'
    },
    {
        'CODE': '+966',
        'DESC': 'ARABIA SAUDITA',
        'COUNTRY': 'sa'
    },
    {
        'CODE': '+54',
        'DESC': 'ARGENTINA',
        'COUNTRY': 'ar'
    },
    {
        'CODE': '+374',
        'DESC': 'ARMENIA',
        'COUNTRY': 'am'
    },
    {
        'CODE': '+297',
        'DESC': 'ARUBA',
        'COUNTRY': 'aw'
    },
    {
        'CODE': '+61',
        'DESC': 'AUSTRALIA',
        'COUNTRY': 'au'
    },
    {
        'CODE': '+43',
        'DESC': 'AUSTRIA',
        'COUNTRY': 'at'
    },
    {
        'CODE': '+973',
        'DESC': 'BAHREIN',
        'COUNTRY': 'bh'
    },
    {
        'CODE': '+880',
        'DESC': 'BANGLADESH',
        'COUNTRY': 'bd'
    },
    {
        'CODE': '+32',
        'DESC': 'BELGIO',
        'COUNTRY': 'be'
    },
    {
        'CODE': '+501',
        'DESC': 'BELIZE',
        'COUNTRY': 'bz'
    },
    {
        'CODE': '+229',
        'DESC': 'BENIN',
        'COUNTRY': 'bj'
    },
    {
        'CODE': '+975',
        'DESC': 'BHUTAN',
        'COUNTRY': 'bt'
    },
    {
        'CODE': '+375',
        'DESC': 'BIELORUSSIA',
        'COUNTRY': 'by'
    },
    {
        'CODE': '+591',
        'DESC': 'BOLIVIA',
        'COUNTRY': 'bo'
    },
    {
        'CODE': '+387',
        'DESC': 'BOSNIA ERZEGOVINA',
        'COUNTRY': 'ba'
    },
    {
        'CODE': '+267',
        'DESC': 'BOTSWANA',
        'COUNTRY': 'bw'
    },
    {
        'CODE': '+55',
        'DESC': 'BRASILE',
        'COUNTRY': 'br'
    },
    {
        'CODE': '+673',
        'DESC': 'BRUNEI',
        'COUNTRY': 'bn'
    },
    {
        'CODE': '+359',
        'DESC': 'BULGARIA',
        'COUNTRY': 'bg'
    },
    {
        'CODE': '+226',
        'DESC': 'BURKINA FASO',
        'COUNTRY': 'bf'
    },
    {
        'CODE': '+257',
        'DESC': 'BURUNDI',
        'COUNTRY': 'bi'
    },
    {
        'CODE': '+855',
        'DESC': 'CAMBOGIA',
        'COUNTRY': 'kh'
    },
    {
        'CODE': '+237',
        'DESC': 'CAMERUN',
        'COUNTRY': 'cm'
    },
    {
        'CODE': '+1',
        'DESC': 'CANADA',
        'COUNTRY': 'ca'
    },
    {
        'CODE': '+238',
        'DESC': 'CAPO VERDE',
        'COUNTRY': 'cv'
    },
    {
        'CODE': '+235',
        'DESC': 'CIAD',
        'COUNTRY': 'td'
    },
    {
        'CODE': '+56',
        'DESC': 'CILE',
        'COUNTRY': 'cl'
    },
    {
        'CODE': '+86',
        'DESC': 'CINA',
        'COUNTRY': 'cn'
    },
    {
        'CODE': '+357',
        'DESC': 'CIPRO',
        'COUNTRY': 'cy'
    },
    {
        'CODE': '+57',
        'DESC': 'COLOMBIA',
        'COUNTRY': 'co'
    },
    {
        'CODE': '+269',
        'DESC': 'COMORE',
        'COUNTRY': 'km'
    },
    {
        'CODE': '+242',
        'DESC': 'CONGO',
        'COUNTRY': 'cg'
    },
    {
        'CODE': '+682',
        'DESC': 'COOK',
        'COUNTRY': 'ck'
    },
    {
        'CODE': '+850',
        'DESC': 'COREA DEL NORD',
        'COUNTRY': 'kp'
    },
    {
        'CODE': '+82',
        'DESC': 'COREA DEL SUD',
        'COUNTRY': 'kr'
    },
    {
        'CODE': '+225',
        'DESC': 'COSTA D AVORIO',
        'COUNTRY': 'ci'
    },
    {
        'CODE': '+506',
        'DESC': 'COSTARICA',
        'COUNTRY': 'cr'
    },
    {
        'CODE': '+385',
        'DESC': 'CROAZIA',
        'COUNTRY': 'hr'
    },
    {
        'CODE': '+53',
        'DESC': 'CUBA',
        'COUNTRY': 'cu'
    },
    {
        'CODE': '+45',
        'DESC': 'DANIMARCA',
        'COUNTRY': 'dk'
    },
    {
        'CODE': '+593',
        'DESC': 'ECUADOR',
        'COUNTRY': 'ec'
    },
    {
        'CODE': '+20',
        'DESC': 'EGITTO',
        'COUNTRY': 'eg'
    },
    {
        'CODE': '+503',
        'DESC': 'EL SALVADOR',
        'COUNTRY': 'sv'
    },
    {
        'CODE': '+971',
        'DESC': 'EMIRATI ARABI UNITI',
        'COUNTRY': 'ea'
    },
    {
        'CODE': '+291',
        'DESC': 'ERITREA',
        'COUNTRY': 'er'
    },
    {
        'CODE': '+372',
        'DESC': 'ESTONIA',
        'COUNTRY': 'ee'
    },
    {
        'CODE': '+251',
        'DESC': 'ETIOPIA',
        'COUNTRY': 'et'
    },
    {
        'CODE': '+7',
        'DESC': 'FEDERAZIONE RUSSA',
        'COUNTRY': 'ru'
    },
    {
        'CODE': '+679',
        'DESC': 'FIGI',
        'COUNTRY': 'fj'
    },
    {
        'CODE': '+63',
        'DESC': 'FILIPPINE',
        'COUNTRY': 'ph'
    },
    {
        'CODE': '+358',
        'DESC': 'FINLANDIA',
        'COUNTRY': 'fi'
    },
    {
        'CODE': '+33',
        'DESC': 'FRANCIA',
        'COUNTRY': 'fr'
    },
    {
        'CODE': '+241',
        'DESC': 'GABON',
        'COUNTRY': 'ga'
    },
    {
        'CODE': '+220',
        'DESC': 'GAMBIA',
        'COUNTRY': 'gm'
    },
    {
        'CODE': '+995',
        'DESC': 'GEORGIA',
        'COUNTRY': 'ge'
    },
    {
        'CODE': '+49',
        'DESC': 'GERMANIA',
        'COUNTRY': 'de'
    },
    {
        'CODE': '+233',
        'DESC': 'GHANA',
        'COUNTRY': 'gh'
    },
    {
        'CODE': '+81',
        'DESC': 'GIAPPONE',
        'COUNTRY': 'jp'
    },
    {
        'CODE': '+350',
        'DESC': 'GIBILTERRA',
        'COUNTRY': 'gi'
    },
    {
        'CODE': '+253',
        'DESC': 'GIBUTI',
        'COUNTRY': 'dj'
    },
    {
        'CODE': '+962',
        'DESC': 'GIORDANIA',
        'COUNTRY': 'jo'
    },
    {
        'CODE': '+44',
        'DESC': 'GRAN BRETAGNA',
        'COUNTRY': 'gb'
    },
    {
        'CODE': '+30',
        'DESC': 'GRECIA',
        'COUNTRY': 'gr'
    },
    {
        'CODE': '+299',
        'DESC': 'GROENLANDIA',
        'COUNTRY': 'gl'
    },
    {
        'CODE': '+590',
        'DESC': 'GUADALUPA',
        'COUNTRY': 'gp'
    },
    {
        'CODE': '+671',
        'DESC': 'GUAM',
        'COUNTRY': 'gu'
    },
    {
        'CODE': '+502',
        'DESC': 'GUATEMALA',
        'COUNTRY': 'gt'
    },
    {
        'CODE': '+224',
        'DESC': 'GUINEA',
        'COUNTRY': 'gn'
    },
    {
        'CODE': '+245',
        'DESC': 'GUINEA BISSAU',
        'COUNTRY': 'gw'
    },
    {
        'CODE': '+240',
        'DESC': 'GUINEA ECUATORIALE',
        'COUNTRY': 'gq'
    },
    {
        'CODE': '+592',
        'DESC': 'GUYANA',
        'COUNTRY': 'gy'
    },
    {
        'CODE': '+594',
        'DESC': 'GUYANA FRANCESE',
        'COUNTRY': 'gf'
    },
    {
        'CODE': '+509',
        'DESC': 'HAITI',
        'COUNTRY': 'ht'
    },
    {
        'CODE': '+504',
        'DESC': 'HONDURAS',
        'COUNTRY': 'hn'
    },
    {
        'CODE': '+852',
        'DESC': 'HONG KONG',
        'COUNTRY': 'hk'
    },
    {
        'CODE': '+91',
        'DESC': 'INDIA',
        'COUNTRY': 'in'
    },
    {
        'CODE': '+62',
        'DESC': 'INDONESIA',
        'COUNTRY': 'id'
    },
    {
        'CODE': '+98',
        'DESC': 'IRAN',
        'COUNTRY': 'ir'
    },
    {
        'CODE': '+964',
        'DESC': 'IRAQ',
        'COUNTRY': 'iq'
    },
    {
        'CODE': '+353',
        'DESC': 'IRLANDA',
        'COUNTRY': 'ie'
    },
    {
        'CODE': '+354',
        'DESC': 'ISLANDA',
        'COUNTRY': 'is'
    },
    {
        'CODE': '+298',
        'DESC': 'ISOLE FæR OER',
        'COUNTRY': 'fo'
    },
    {
        'CODE': '+500',
        'DESC': 'ISOLE FALKLAND',
        'COUNTRY': 'fk'
    },
    {
        'CODE': '+670',
        'DESC': 'ISOLE MARIANNE',
        'COUNTRY': 'mp'
    },
    {
        'CODE': '+692',
        'DESC': 'ISOLE MARSHALL',
        'COUNTRY': 'mh'
    },
    {
        'CODE': '+672',
        'DESC': 'ISOLE NORFOLK',
        'COUNTRY': 'nf'
    },
    {
        'CODE': '+677',
        'DESC': 'ISOLE SALOMONE',
        'COUNTRY': 'sb'
    },
    {
        'CODE': '+972',
        'DESC': 'ISRAELE',
        'COUNTRY': 'il'
    },
    {
        'CODE': '+996',
        'DESC': 'KAZAKISTAN',
        'COUNTRY': 'kz'
    },
    {
        'CODE': '+254',
        'DESC': 'KENIA',
        'COUNTRY': 'ke'
    },
    {
        'CODE': '+686',
        'DESC': 'KIRIBATI',
        'COUNTRY': 'ki'
    },
    {
        'CODE': '+965',
        'DESC': 'KUWAIT',
        'COUNTRY': 'kw'
    },
    {
        'CODE': '+856',
        'DESC': 'LAOS',
        'COUNTRY': 'la'
    },
    {
        'CODE': '+266',
        'DESC': 'LESOTHO',
        'COUNTRY': 'ls'
    },
    {
        'CODE': '+371',
        'DESC': 'LETTONIA',
        'COUNTRY': 'lv'
    },
    {
        'CODE': '+961',
        'DESC': 'LIBANO',
        'COUNTRY': 'lb'
    },
    {
        'CODE': '+231',
        'DESC': 'LIBERIA',
        'COUNTRY': 'lr'
    },
    {
        'CODE': '+218',
        'DESC': 'LIBIA',
        'COUNTRY': 'ly'
    },
    {
        'CODE': '+423',
        'DESC': 'LIECHTENSTEIN',
        'COUNTRY': 'li'
    },
    {
        'CODE': '+370',
        'DESC': 'LITUANIA',
        'COUNTRY': 'lt'
    },
    {
        'CODE': '+352',
        'DESC': 'LUSSEMBURGO',
        'COUNTRY': 'lu'
    },
    {
        'CODE': '+853',
        'DESC': 'MACAO',
        'COUNTRY': 'mo'
    },
    {
        'CODE': '+389',
        'DESC': 'MACEDONIA',
        'COUNTRY': 'mk'
    },
    {
        'CODE': '+261',
        'DESC': 'MADAGASCAR',
        'COUNTRY': 'mg'
    },
    {
        'CODE': '+265',
        'DESC': 'MALAWI',
        'COUNTRY': 'mw'
    },
    {
        'CODE': '+960',
        'DESC': 'MALDIVE',
        'COUNTRY': 'mv'
    },
    {
        'CODE': '+60',
        'DESC': 'MALESIA',
        'COUNTRY': 'my'
    },
    {
        'CODE': '+223',
        'DESC': 'MALI',
        'COUNTRY': 'ml'
    },
    {
        'CODE': '+356',
        'DESC': 'MALTA',
        'COUNTRY': 'mt'
    },
    {
        'CODE': '+212',
        'DESC': 'MAROCCO',
        'COUNTRY': 'ma'
    },
    {
        'CODE': '+596',
        'DESC': 'MARTINICA',
        'COUNTRY': 'mq'
    },
    {
        'CODE': '+222',
        'DESC': 'MAURITANIA',
        'COUNTRY': 'mr'
    },
    {
        'CODE': '+230',
        'DESC': 'MAURITIUS',
        'COUNTRY': 'mu'
    },
    {
        'CODE': '+52',
        'DESC': 'MESSICO',
        'COUNTRY': 'mx'
    },
    {
        'CODE': '+373',
        'DESC': 'MOLDAVIA',
        'COUNTRY': 'md'
    },
    {
        'CODE': '+377',
        'DESC': 'MONACO (PRINCIPATO DI)',
        'COUNTRY': 'mc'
    },
    {
        'CODE': '+976',
        'DESC': 'MONGOLIA',
        'COUNTRY': 'mn'
    },
    {
        'CODE': '+258',
        'DESC': 'MOZAMBICO',
        'COUNTRY': 'mz'
    },
    {
        'CODE': '+264',
        'DESC': 'NAMIBIA',
        'COUNTRY': 'na'
    },
    {
        'CODE': '+977',
        'DESC': 'NEPAL',
        'COUNTRY': 'np'
    },
    {
        'CODE': '+505',
        'DESC': 'NICARAGUA',
        'COUNTRY': 'ni'
    },
    {
        'CODE': '+234',
        'DESC': 'NIGERIA',
        'COUNTRY': 'ng'
    },
    {
        'CODE': '+683',
        'DESC': 'NIUE',
        'COUNTRY': 'nu'
    },
    {
        'CODE': '+47',
        'DESC': 'NORVEGIA',
        'COUNTRY': 'no'
    },
    {
        'CODE': '+687',
        'DESC': 'NUOVA CALEDONIA',
        'COUNTRY': 'nc'
    },
    {
        'CODE': '+64',
        'DESC': 'NUOVA ZELANDA',
        'COUNTRY': 'nz'
    },
    {
        'CODE': '+968',
        'DESC': 'OMAN',
        'COUNTRY': 'om'
    },
    {
        'CODE': '+31',
        'DESC': 'PAESI BASSI (OLANDA)',
        'COUNTRY': 'nl'
    },
    {
        'CODE': '+92',
        'DESC': 'PAKISTAN',
        'COUNTRY': 'pk'
    },
    {
        'CODE': '+680',
        'DESC': 'PALAU',
        'COUNTRY': 'pw'
    },
    {
        'CODE': '+507',
        'DESC': 'PANAMA',
        'COUNTRY': 'pa'
    },
    {
        'CODE': '+675',
        'DESC': 'PAPUA NUOVA GUINEA',
        'COUNTRY': 'pg'
    },
    {
        'CODE': '+595',
        'DESC': 'PARAGUAY',
        'COUNTRY': 'py'
    },
    {
        'CODE': '+51',
        'DESC': 'PERù',
        'COUNTRY': 'pe'
    },
    {
        'CODE': '+689',
        'DESC': 'POLINESIA FRANCESE',
        'COUNTRY': 'pf'
    },
    {
        'CODE': '+48',
        'DESC': 'POLONIA',
        'COUNTRY': 'pl'
    },
    {
        'CODE': '+351',
        'DESC': 'PORTOGALLO',
        'COUNTRY': 'pt'
    },
    {
        'CODE': '+974',
        'DESC': 'QATAR',
        'COUNTRY': 'qa'
    },
    {
        'CODE': '+420',
        'DESC': 'REP. CECA',
        'COUNTRY': 'cz'
    },
    {
        'CODE': '+236',
        'DESC': 'REP. CENTRAFRICA',
        'COUNTRY': 'cf'
    },
    {
        'CODE': '+243',
        'DESC': 'REP. DEMOCRATICA DEL CONGO',
        'COUNTRY': 'cd'
    },
    {
        'CODE': '+1',
        'DESC': 'REP. DOMINICANA',
        'COUNTRY': 'ro'
    },
    {
        'CODE': '+262',
        'DESC': 'REUNION (FRANCIA)',
        'COUNTRY': 're'
    },
    {
        'CODE': '+40',
        'DESC': 'ROMANIA',
        'COUNTRY': 'ro'
    },
    {
        'CODE': '+250',
        'DESC': 'RUANDA',
        'COUNTRY': 'rw'
    },
    {
        'CODE': '+290',
        'DESC': 'SAINT ELENA',
        'COUNTRY': 'sh'
    },
    {
        'CODE': '+508',
        'DESC': 'SAINT PIERRE E MIQUELON',
        'COUNTRY': 'pm'
    },
    {
        'CODE': '+684',
        'DESC': 'SAMOA AMERICANE',
        'COUNTRY': 'as'
    },
    {
        'CODE': '+685',
        'DESC': 'SAMOA OCCIDENTALE',
        'COUNTRY': 'ws'
    },
    {
        'CODE': '+239',
        'DESC': 'SAO TOMè E PRINCIPE',
        'COUNTRY': 'st'
    },
    {
        'CODE': '+221',
        'DESC': 'SENEGAL',
        'COUNTRY': 'sn'
    },
    {
        'CODE': '+381',
        'DESC': 'SERBIA',
        'COUNTRY': 'sr'
    },
    {
        'CODE': '+248',
        'DESC': 'SEYCHELLES',
        'COUNTRY': 'sc'
    },
    {
        'CODE': '+232',
        'DESC': 'SIERRA LEONE',
        'COUNTRY': 'sl'
    },
    {
        'CODE': '+65',
        'DESC': 'SINGAPORE',
        'COUNTRY': 'sg'
    },
    {
        'CODE': '+963',
        'DESC': 'SIRIA',
        'COUNTRY': 'sy'
    },
    {
        'CODE': '+421',
        'DESC': 'SLOVACCHIA',
        'COUNTRY': 'sk'
    },
    {
        'CODE': '+386',
        'DESC': 'SLOVENIA',
        'COUNTRY': 'si'
    },
    {
        'CODE': '+252',
        'DESC': 'SOMALIA',
        'COUNTRY': 'so'
    },
    {
        'CODE': '+34',
        'DESC': 'SPAGNA',
        'COUNTRY': 'es'
    },
    {
        'CODE': '+94',
        'DESC': 'SRI LANKA',
        'COUNTRY': 'lk'
    },
    {
        'CODE': '+27',
        'DESC': 'SUDAFRICA',
        'COUNTRY': 'za'
    },
    {
        'CODE': '+249',
        'DESC': 'SUDAN',
        'COUNTRY': 'sd'
    },
    {
        'CODE': '+46',
        'DESC': 'SVEZIA',
        'COUNTRY': 'se'
    },
    {
        'CODE': '+41',
        'DESC': 'SVIZZERA',
        'COUNTRY': 'ch'
    },
    {
        'CODE': '+268',
        'DESC': 'SWAZILAND',
        'COUNTRY': 'sz'
    },
    {
        'CODE': '+886',
        'DESC': 'TAIWAN',
        'COUNTRY': 'tw'
    },
    {
        'CODE': '+737',
        'DESC': 'TAJIKISTAN',
        'COUNTRY': 'tj'
    },
    {
        'CODE': '+255',
        'DESC': 'TANZANIA',
        'COUNTRY': 'tz'
    },
    {
        'CODE': '+66',
        'DESC': 'THAILANDIA',
        'COUNTRY': 'th'
    },
    {
        'CODE': '+228',
        'DESC': 'TOGO',
        'COUNTRY': 'tg'
    },
    {
        'CODE': '+676',
        'DESC': 'TONGA',
        'COUNTRY': 'to'
    },
    {
        'CODE': '+216',
        'DESC': 'TUNISIA',
        'COUNTRY': 'tn'
    },
    {
        'CODE': '+90',
        'DESC': 'TURCHIA',
        'COUNTRY': 'tr'
    },
    {
        'CODE': '+993',
        'DESC': 'TURKMENISTAN',
        'COUNTRY': 'tm'
    },
    {
        'CODE': '+688',
        'DESC': 'TUVALU',
        'COUNTRY': 'tv'
    },
    {
        'CODE': '+380',
        'DESC': 'UCRAINA',
        'COUNTRY': 'ua'
    },
    {
        'CODE': '+256',
        'DESC': 'UGANDA',
        'COUNTRY': 'ug'
    },
    {
        'CODE': '+36',
        'DESC': 'UNGHERIA',
        'COUNTRY': 'hu'
    },
    {
        'CODE': '+598',
        'DESC': 'URUGUAY',
        'COUNTRY': 'uy'
    },
    {
        'CODE': '+1',
        'DESC': 'USA',
        'COUNTRY': 'us'
    },
    {
        'CODE': '+998',
        'DESC': 'UZBEKISTAN',
        'COUNTRY': 'uz'
    },
    {
        'CODE': '+678',
        'DESC': 'VANUATO',
        'COUNTRY': 'vu'
    },
    {
        'CODE': '+58',
        'DESC': 'VENEZUELA',
        'COUNTRY': 've'
    },
    {
        'CODE': '+84',
        'DESC': 'VIETNAM',
        'COUNTRY': 'vn'
    },
    {
        'CODE': '+681',
        'DESC': 'WALLIS E FUTUNA',
        'COUNTRY': 'wf'
    },
    {
        'CODE': '+967',
        'DESC': 'YEMEN',
        'COUNTRY': 'ye'
    },
    {
        'CODE': '+260',
        'DESC': 'ZAMBIA',
        'COUNTRY': 'zm'
    },
    {
        'CODE': '+263',
        'DESC': 'ZIMBABWE',
        'COUNTRY': 'zw'
    }
];

