<div fxLayout="column" fxLayoutAlign="space-around stretch" class="selectControlla">
  <mat-form-field class="classSelect">
    <mat-select #nobackgiorni placeholder="{{'CONTROLLA.GIORNI' | translate}}" [formControl]="daySelected" (change)="statisticheData($event);">
      <mat-option class="no-background" *ngFor="let item of days" [value]="item.value">
        {{ item.viewValue | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="flex-row">
    <div class="leftInputField">
      <app-operation-widget [PaymentType]='paymentTypePaid' [currentDayInput]='toData'></app-operation-widget>
    </div>
    <div class="rightInputField">
      <app-operation-widget [PaymentType]='paymentTypeNotPaid' [currentDayInput]='toData'></app-operation-widget>
    </div>
  </div>
</div>
