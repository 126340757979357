import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-operations-widgets',
  templateUrl: './operations-widgets.component.html',
  styleUrls: ['./operations-widgets.component.scss']
})
export class OperationsWidgetsComponent implements OnInit {
  daySelected = new UntypedFormControl('3');
  toData: any;
  paymentTypeNotPaid: string = 'NotPaid';
  paymentTypePaid: string = 'Paid';

  blockInput: Object;
  href = 'controlla';
  labels = ['controlla', 'importOperations'];
  titles = [this.translate.instant('BLOCK_MENU.MIEOPERAZIONI'),
            this.translate.instant('BLOCK_MENU.OPERAZIONIIMPORTATE')];

  currentDay = '';
  sevenDaysAgo = '';
  fifteenDaysAgo = '';
  oneMonthAgo = '';

  days = [
    { value: '3', viewValue: 'OPERAZIONI.PERIODO_OGGI' },
    { value: '0', viewValue: 'OPERAZIONI.PERIODO_7GIORNI' },
    { value: '1', viewValue: 'OPERAZIONI.PERIODO_15GIORNI' },
    { value: '2', viewValue: 'OPERAZIONI.PERIODO_MESE' }
  ];

  constructor(
    private adapter: DateAdapter<any>,
    private translate: TranslateService
  )
  {
    this.adapter.setLocale('it');
    moment.locale('it');
    this.currentDay = moment().format('YYYY-MM-DD');
    this.sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
    this.fifteenDaysAgo = moment().subtract(15, 'days').format('YYYY-MM-DD');
    this.oneMonthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
    this.daySelected.valueChanges.subscribe(data => this.statisticheData(data));
  }

  ngOnInit() {


    if(localStorage.getItem("daySelected")){
      this.daySelected.setValue(localStorage.getItem("daySelected"));
      if(this.daySelected.value != "3"){
        this.statisticheData(this.daySelected.value);
      }
    }
  }

  statisticheData(id: any) {

    switch (id) {
      case '0': this.toData = this.sevenDaysAgo; break;
      case '1': this.toData = this.fifteenDaysAgo; break;
      case '2': this.toData = this.oneMonthAgo; break;
      case '3': this.toData = this.currentDay; break;
    }

    localStorage.setItem("daySelected", id);
  }

}
