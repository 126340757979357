import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as numeral from 'numeral';
import { PreviewRicComponent } from '../previewRic/previewRic.component';

@Component({
  selector: 'app-request-recurrents',
  templateUrl: './request-recurrents.component.html',
  styleUrls: ['./request-recurrents.component.scss']
})
export class RequestRecurrentsComponent implements OnInit {
  @Input() name: string;
  @Input()
  set paymentType(value: "RECURRENT" | "DEFERRED") {
    if (!value) {
      return;
    }
    if (value === "RECURRENT") {
      this.loopedList = this.recurrentList
    } else {
      this.loopedList = this.deferredList
    }
    this._paymentType = value;
  }
  get paymentType() {
    return this._paymentType;
  }


  @Output() recurrentsData: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  recurrentsForm: FormGroup;
  recurrentsPayForm: FormGroup;
  minTime: string;
  minDate: Date = new Date();
  private _paymentType: "RECURRENT" | "DEFERRED";

  private recurrentList = [
    { value: 2, viewValue: '2' },
    { value: 3, viewValue: '3' },
    { value: 4, viewValue: '4' },
    { value: 5, viewValue: '5' },
    { value: 6, viewValue: '6' },
    { value: 7, viewValue: '7' },
    { value: 8, viewValue: '8' },
    { value: 9, viewValue: '9' },
    { value: 10, viewValue: '10' },
    { value: 11, viewValue: '11' },
    { value: 12, viewValue: '12' },
    { value: 0, viewValue: 'Senza termine' }
  ];

  private deferredList = [
    { value: 3, viewValue: '3' },
    { value: 4, viewValue: '4' }
  ];

  loopedList = []

  RicorrenteList = [];

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.RicorrenteList = this._paymentType === "RECURRENT" ? [
      { value: 2, viewValue: 'Ogni 2 settimane', type: 'week', interval: 2 },
      { value: 1, viewValue: 'Mensile', type: 'month', interval: 1 },
      { value: 3, viewValue: 'Trimestrale', type: 'month', interval: 3 },
      { value: 6, viewValue: 'Semestrale', type: 'month', interval: 6 },
      { value: 12, viewValue: 'Annuale', type: 'month', interval: 12 }
    ] : [
      { value: 2, viewValue: 'Ogni 2 settimane', type: 'week', interval: 2 },
      { value: 1, viewValue: 'Mensile', type: 'month', interval: 1 },
      { value: 3, viewValue: 'Trimestrale', type: 'month', interval: 3 },
      { value: 6, viewValue: 'Semestrale', type: 'month', interval: 6 },
    ];
    this.recurrentsForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(0.01)]],
      totalAmount: [''],
      causale: ['', [Validators.required]],
      to_date: [new Date()],
      firstRecurrentLinkDate: [new Date(moment().endOf('day').toISOString())],
      frequency: [1],
      rounds: this.paymentType === 'RECURRENT' ? [2, [Validators.required]] : [3, [Validators.required]],
      expiration_time: [moment().endOf('day').format('HH:mm')],
      recurrents_type: ['']
    });

    this.recurrentsData.emit(this.recurrentsForm);

    this.recurrentsForm.valueChanges.subscribe(
      () => {

        if (this.paymentType === 'RECURRENT') {
          if (this.recurrentsForm.value.rounds == 0) {
            if (this.recurrentsForm.value.totalAmount !== "NO_TERM") {
              this.recurrentsForm.controls.totalAmount.setValue("NO_TERM");
            }
          } else {
            let total = numeral(this.recurrentsForm.value.amount * this.recurrentsForm.value.rounds).format('0.00');
            if (total !== this.recurrentsForm.value.totalAmount) {
              this.recurrentsForm.controls.totalAmount.setValue(total);
            }
          }
        } else if (this.paymentType === 'DEFERRED') {
          let total = numeral(this.recurrentsForm.value.amount).format('0.00');
          if (total !== this.recurrentsForm.value.totalAmount) {
            this.recurrentsForm.controls.totalAmount.setValue(total);
          }
        }

        this.recurrentsData.emit(this.recurrentsForm)
      }
    );

  }

  recurrentLooped(count: number) {
    if (this.recurrentsForm.value.frequency === 2) {
      return 'Dopo ' + (++count * 2) + ' Settimane'
    } else if (this.recurrentsForm.value.frequency === 1) {
      return 'Dopo ' + (count + 1) + (count + 1 === 1 ? ' Mese' : ' Mesi')
    } else if (this.recurrentsForm.value.frequency === 3) {
      return 'Dopo ' + (++count * 3) + ' Mesi'
    } else if (this.recurrentsForm.value.frequency === 6) {
      return 'Dopo ' + (++count * 6) + ' Mesi'
    } else if (this.recurrentsForm.value.frequency === 12) {
      return 'Dopo ' + (++count * 12) + ' Mesi'
    }
  }

  recurrentNoExpiration() {
    if (this.recurrentsForm.value.frequency === 2) {
      return 'Ogni due settimane fino a sospensione piano ricorrenze'
    } else if (this.recurrentsForm.value.frequency === 1) {
      return 'Ogni mese fino a sospensione piano riccorenze'
    } else if (this.recurrentsForm.value.frequency === 3) {
      return 'Ogni 3 mesi fino a sospensione piano riccorenze'
    } else if (this.recurrentsForm.value.frequency === 6) {
      return 'Ogni 6 mesi fino a sospensione piano riccorenze'
    } else if (this.recurrentsForm.value.frequency === 12) {
      return 'Ogni 12 mesi fino a sospensione piano riccorenze'
    }
  }

  amountPerRound(amount: number, rounds: number): string {
    const roundedValuePerRound: number = Math.round((amount / rounds) * 100) / 100;
    const roundedValueTotal: number = roundedValuePerRound * rounds;
    const roundedAmount: number = amount - roundedValueTotal;
    const roundedValueFinal: number = roundedValuePerRound + Math.round(roundedAmount * 100) / 100;
    return roundedValueFinal.toFixed(2);
  }

  recurrentPreview() {
    let data = this.recurrentsForm.value.to_date;
    let to_date: any = moment(this.recurrentsForm.value.to_date);
    const paymentRounds = [];
    const preTable = {
      'rata': 1,
      'data': 'Entro il ' + moment(to_date).format('DD-MM-YYYY'),
      // 'data': 'Entro il ' + moment(this.recurrentsForm.value.firstRecurrentLinkDate).format('DD-MM-YYYY'),
      'azione': this.paymentType === 'RECURRENT'
        ? 'Adesione piano ricorrenze'
        : 'Adesione piano dilazionato',
      'importo': this.paymentType === 'RECURRENT' ? numeral(this.recurrentsForm.value.amount).format('0.00')
        : this.amountPerRound(this.recurrentsForm.value.amount, this.recurrentsForm.value.rounds),
      'noLimit': false
    };
    const postTable = {
      'rata': 2,
      'data': this.recurrentNoExpiration(),
      'azione': 'Addebito automatico',
      'importo': this.paymentType === 'RECURRENT'
        ? numeral(this.recurrentsForm.value.amount).format('0.00')
        : this.amountPerRound(this.recurrentsForm.value.amount, this.recurrentsForm.value.rounds),
      'noLimit': false
    }

    paymentRounds.push(preTable);

    if (this.recurrentsForm.value.rounds === 0) {
      paymentRounds.push(postTable)
    }
    let totalImportRecurrent = numeral(this.recurrentsForm.value.amount * this.recurrentsForm.value.rounds).format('0.00');

    let mobileImporto = this.paymentType === 'RECURRENT'
      ? numeral(this.recurrentsForm.value.amount).format('0.00')
      : numeral(this.recurrentsForm.value.amount / this.recurrentsForm.value.rounds).format('0.00')

    for (let i = 0; i < this.recurrentsForm.value.rounds; i++) {
      if (i === 0) continue;

      data = moment(data).add(this.recurrentsForm.value.frequency, 'months').format('YYYY-MM-DD');
        paymentRounds.push({
          'data': moment(data).format('DD-MM-YYYY'),
          'importo': (i + 1) === 1?
            this.amountPerRound(this.recurrentsForm.value.amount, this.recurrentsForm.value.rounds)
            : mobileImporto,
          'nome': this.name,
          'stato': 'WAIT',
          'rata': i + 1,
          'recurrentTotal': this.paymentType === 'RECURRENT' ? totalImportRecurrent : '',
          'frequency': this.recurrentLooped(i - 1)
        });
    }
    if (this.paymentType === 'DEFERRED') {
      this.checkAmountReduction(paymentRounds, this.recurrentsForm.value.amount)
    }

    this.dialog.open(PreviewRicComponent, {
      autoFocus: false,
      disableClose: true,
      data: { paymentRounds: paymentRounds }
    });
  }

  checkAmountReduction(objArray: { importo: string }[], amount: number): boolean {
    const importoSum = objArray.reduce((acc, obj) => acc + Number(obj.importo), 0);
    const importoSumFixed = Number(importoSum.toFixed(2));
    const amountFixed = Number(amount.toFixed(2));
    const isAmountReduced = amountFixed === importoSumFixed;
    if (!isAmountReduced) {
      console.error(`Amount ${amountFixed} is not equal to ${importoSumFixed}.`);
      window.alert(`Amount ${amountFixed} is not equal to ${importoSumFixed}.`);
    }

    return isAmountReduced;
  }
}
