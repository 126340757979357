import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Payment } from '../../../../../_models/payment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '../../../../../_services/loader.service';
import { BulkService } from '../../../../../_services/bulk.service';
import {GridComponent} from '../../../../../_components/grid/grid.component';
import { saveFile } from '../../../../../_services/file-download-helper';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-import-operations-payments',
  templateUrl: './import-operations-payments.component.html',
  styleUrls: ['./import-operations-payments.component.scss'],
})
export class ImportOperationsPaymentsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('grid') grid: GridComponent;

  dataSource = new MatTableDataSource<Payment>();
  bulkId: string;

  constructor(
    private translate: TranslateService,
    private loaderService: LoaderService,
    private bulkService: BulkService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loaderService.startGeneral();
    this.activatedRoute.params.subscribe(
      params => {
        if (params.id) {
          this.bulkId = params.id
          this.bulkService.getBulkPayments(this.bulkId).subscribe(
            (response: Payment[]) => {
              this.loaderService.stopGeneral();
              this.dataSource = new MatTableDataSource<Payment>(response);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.grid.basicSort;
            }
          )
        }
      }
    )
    
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  downloadReport() {
    this.bulkService.export(this.bulkId).subscribe(
      response => saveFile(response, 'operations_report.xlsx'),
    )
  }

}

