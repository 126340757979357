<span [ngClass]="theme">
  <div mat-dialog-content style="text-align: center;" [formGroup]="form">
    <mat-form-field style="margin-top: 15px; width: 100%">
      <input formControlName="to_date" (click)="picker.open()" matInput [matDatepicker]="picker"
        [placeholder]="'OPERAZIONI.MODIFICASCADENZA' | translate" [min]="minDate">
      <mat-error *ngIf="form?.controls.to_date.errors"
        [innerHTML]="'RICHIEDIPAGAMENTOAVANZATO.EXPIRATION_DAY_ERROR' | translate"></mat-error>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [touchUi]="isMobile"></mat-datepicker>
    </mat-form-field>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>{{'RICHIEDIPAGAMENTOAVANZATO.SCADENZA_ORARIO' | translate}}</mat-label>
        <input matInput formControlName="expiration_time"
          placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.SCADENZA_ORARIO' | translate}}" [ngxTimepicker]="myTimePicker"
          [format]="24" [min]="minTime" readonly>
        <mat-error *ngIf="form?.controls.expiration_time.hasError('alreadyExpired')"
          [innerHTML]="'RICHIEDIPAGAMENTOAVANZATO.EXPIRATION_ERROR' | translate"></mat-error>
      </mat-form-field>
      <ngx-material-timepicker #myTimePicker></ngx-material-timepicker>
    </div>
  </div>
  <mat-dialog-actions class="respectPadding multipleButtons noWrap">
    <button mat-flat-button color="primary" class="rounded-button buttonDialog" (click)="onNoClick()">{{'DIALOG.CANCEL'
      | translate
      }}</button>
    <button mat-flat-button color="primary" class="rounded-button buttonDialog" [disabled]="!form?.valid"
      [mat-dialog-close]="form?.value" cdkFocusInitial>{{'DIALOG.OK' | translate }}</button>
  </mat-dialog-actions>
</span>
