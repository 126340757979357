import { Template } from '../../../_models/template';
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { ThemeService } from '../../../_services/theme.service';
import { DetailOverlayRef } from '../../../_models/detailOverlayRef';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DETAIL_OVERLAY_DATA } from '../../../_cdk/overlayData';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-template-overlay',
  templateUrl: './template-overlay.component.html',
  styleUrls: ['./template-overlay.component.scss'],
  animations: [
    trigger('smallBig', [
      state('small', style({
        transform: 'scale(1)',
      })),
      state('big', style({
        transform: 'scale(1.4)',
      })),
      transition('small => big', [
        animate('0.2s ease-in')
      ]),
      transition('big => small', [
        animate('0.2s ease-in')
      ]),
    ]),
  ]
})
export class TemplateOverlayComponent implements OnInit {

  @Output() emitUpdate = new EventEmitter();

  theme: string;
  templateToEdit: Template;
  channel: string;
  iconSelected: string;
  isApproveView: boolean;

  constructor(
    @Inject(DETAIL_OVERLAY_DATA) public data,
    public detailOverlayRef: DetailOverlayRef,
    private themeService: ThemeService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    if (!data) {return; }
    this.templateToEdit = data.templateToEdit;
    this.channel = data.channel;
    this.isApproveView = data.isApproveView;
    this.initializeIcons();
  }

  ngOnInit() {
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
  }

  initializeIcons() {
    this.iconRegistry.addSvgIcon(
      'close', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/closeIcon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'email', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/mail-editor-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sms', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/sms-editor-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'page', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/page-editor-icon.svg')
    );
  }

  closeDetail() {
    this.detailOverlayRef.close();
  }

  toggleTemplate(template: string) {
    this.channel = template;
  }

  toggleCompiledIcon(event: Template) {
    this.templateToEdit = event;
  }

}
