<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important" [expanded]="true">

      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{ 'PROFILO.DATITECNICI' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="datiTecniciForm">
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <mat-select placeholder="{{'PROFILO.VALUTA' | translate}}" formControlName="currency" multiple>
                <mat-select-trigger>
                  {{datiTecniciForm.controls.currency.value ? datiTecniciForm.controls.currency.value[0] : ''}}
                  <span *ngIf="datiTecniciForm.controls.currency.value?.length > 1"
                    class="example-additional-selection">
                    (+{{datiTecniciForm.controls.currency.value.length - 1}} Valute)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let valutaItem of valutaList" [value]="valutaItem">{{valutaItem}}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="{{'PROFILO.MERCATO' | translate}}" formControlName="market">
            </mat-form-field>
          </span>
        </div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <mat-select placeholder="{{'PROFILO.TIPODIOPERAZIONE' | translate}}" formControlName="operation_type"
                multiple>
                <mat-select-trigger>
                  {{datiTecniciForm.controls.operation_type.value ? datiTecniciForm.controls.operation_type.value[0] :
                  ''}}
                  <span *ngIf="datiTecniciForm.controls.operation_type.value?.length > 1"
                    class="example-additional-selection">
                    (+{{datiTecniciForm.controls.operation_type.value.length - 1}} Operazioni)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let operationTypeItem of operationType" [value]="operationTypeItem">
                  {{operationTypeItem}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <mat-select placeholder="{{'PROFILO.TIPOTRANSAZIONE' | translate}}" formControlName="transaction_type"
                multiple>
                <mat-select-trigger>
                  {{datiTecniciForm.controls.transaction_type.value ? datiTecniciForm.controls.transaction_type.value[0]
                  : ''}}
                  <span *ngIf="datiTecniciForm.controls.transaction_type.value?.length > 1"
                    class="example-additional-selection">
                    (+{{datiTecniciForm.controls.transaction_type.value.length - 1}} Transazioni)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let transationTypeItem of transationType" [value]="transationTypeItem">
                  {{transationTypeItem}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <mat-select placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}"
                formControlName="area_code_type" multiple>
                <mat-select-trigger>
                  {{datiTecniciForm.controls.area_code_type.value ? datiTecniciForm.controls.area_code_type.value[0] :
                  ''}}
                  <span *ngIf="datiTecniciForm.controls.area_code_type.value?.length > 1"
                    class="example-additional-selection">
                    (+{{datiTecniciForm.controls.area_code_type.value.length - 1}} Nazioni)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let type of countryPrefixTerminal" [value]="type.CODE">
                  <img alt="flag icon" class="flagCountry"
                    src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                  <span>{{ type.CODE }}</span>
                  <span>{{ type.DESC }}</span>
                  <span> - {{ type.COUNTRY | uppercase }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="{{'PROFILO.KEY' | translate}}" formControlName="key">
            </mat-form-field>
          </span>
        </div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'PROFILO.DATE' | translate}}" formControlName="defaultToDateElapsed"
                type="amount">
            </mat-form-field>
          </span>
          <span class="flex-col">
          </span>
        </div>

        <div class="flex-row">
          <!-- COLONNA SINISTRA -->
          <span class="flex-col">
            <div fxLayout="column" fxLayoutAlign="center start" class="leftInputField">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isDonationActive">
                    <span class="checkFont">{{'PROFILO.DONATIONACTIVE' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isPayInstrToken">
                    <span class="checkFont">{{'PROFILO.ATTIVASTRUPAG' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isReminderActive">
                    <span class="checkFont">{{'PROFILO.ATTIVASOLLECITO' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isRecurrentActive">
                    <span class="checkFont">{{'PROFILO.ATTIVARICORRENTI' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isDeferredActive">
                    <span class="checkFont">{{'PROFILO.ATTIVADILAZIONATI' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isQueryStringActive">
                    <span class="checkFont">{{'PROFILO.QUERYSTRING' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isDynamicInformationsActive">
                    <span class="checkFont">{{'PROFILO.DYNAMICFIELDS' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isAdditionalInfoActive">
                    <span class="checkFont">{{'RICHIEDIPAGAMENTOAVANZATO.INFOAGG' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>

              <div *ngIf="datiTecniciForm?.value.isAdditionalInfoActive" fxLayout="row"
                fxLayoutAlign="space-between center">
                <div>
                  <mat-form-field style="width: 280px; margin-top: 20px; margin-bottom: -20px;">
                    <mat-label>{{ 'PROFILO.SPECIFY_INFO_COUNT' | translate}}</mat-label>
                    <mat-select formControlName="addInfoCount">
                      <mat-option [value]="0">0</mat-option>
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </div>

              <div *ngIf="datiTecniciForm?.value.isAdditionalInfoActive" fxLayout="row"
                fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isAdditionalInfoEditable">
                    <span class="checkFont">{{'RICHIEDIPAGAMENTOAVANZATO.INFOEDIT' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>



              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isPaymentOpenActive">
                    <span class="checkFont">{{'PROFILO.ATTIVAAPERTI' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isImportMassivelyActive">
                    <span class="checkFont">{{'PROFILO.ATTIVABULK' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isFastCheckoutActive">
                    <span class="checkFont">{{'PROFILO.FASTCHECKOUT' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isNotificationActive">
                    <span class="checkFont">{{'RICHIEDIPAGAMENTOAVANZATO.SCHEDULATORENOTIFICHE' | translate | titlecase
                      }}</span>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <mat-checkbox color="primary" formControlName="isCustomTemplateActive">
                  <span class="checkFont">{{'PROFILO.CUSTOMTEMPLATE' | translate}}</span>
                </mat-checkbox>
              </div>
            </div>
            <div *ngIf="datiTecniciForm.controls.isCustomTemplateActive?.value" fxLayout="column"
              fxLayoutAlign="space-between">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isCustomTemplateSenderActive">
                    <span class="checkFont">{{'PROFILO.CUSTOMSENDERTEMPLATE' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <mat-checkbox color="primary" formControlName="isRetryCallbackActive">
                  <span class="checkFont">{{'PROFILO.ATTIVAURLNOTIFICA' | translate}}</span>
                </mat-checkbox>
              </div>
            </div> -->
          </span>

          <!-- COLONNA DESTRA -->
          <span class="flex-col">
            <div fxLayout="column" fxLayoutAlign="center start" class="rightInputField">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <mat-checkbox color="primary" formControlName="isPaymentPageActive">
                    <span class="checkFont">{{'PROFILO.PAYMENTPAGE' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
              <div *ngIf="datiTecniciForm.controls.isPaymentPageActive.value" fxLayout="column"
                fxLayoutAlign="space-between">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    <mat-checkbox color="primary" formControlName="isMyBankActive">
                      <span class="checkFont">{{'PROFILO.ATTIVAMYBANK' | translate}}</span>
                    </mat-checkbox>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    <mat-checkbox color="primary" formControlName="isApplePayActive">
                      <span class="checkFont">{{'PROFILO.ATTIVAAPPLEPAY' | translate}}</span>
                    </mat-checkbox>
                  </div>
                </div>
                <div>
                  <mat-checkbox color="primary" formControlName="isRememberMeActive">
                    <span class="checkFont">{{'PROFILO.REMEMBERME' | translate}}</span>
                  </mat-checkbox>
                </div>
              </div>

            </div>
          </span>

        </div>

      </form>


      <div class="buttonBar">
        <button mat-flat-button color="primary" (click)="salvaDatiTecnici()"
          [disabled]="!datiTecniciForm.valid || !idMerchant">{{'PROFILO.FILTRO1BUTTON' | translate}}</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</div>


<div *ngIf="!merchant?.isCustomTemplateActive" fxLayout="column" fxLayoutAlign="space-around stretch"
  ngClass.xs="barraFiltro" ngClass.sm="barraFiltro" ngClass.md="barraFiltro" ngClass.lg="barraFiltro"
  ngClass.xl="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>

        <mat-panel-title class="titoloFiltro">
          {{ 'PROFILO.AVAILABLETEMPLATE' | translate}}
        </mat-panel-title>

      </mat-expansion-panel-header>

      <app-mail-template></app-mail-template>

    </mat-expansion-panel>
  </mat-accordion>
</div>



<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{ 'PROFILO.TERMINALE' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="!flagnuovoterminale">
        <mat-table #table [dataSource]="dataSourceTerminale">
          <ng-container matColumnDef="tid">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;"> {{'PROFILO.TABELLATID'
              | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="text-align: center;"> {{element?.tid}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="mid">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;"> {{'PROFILO.TABELLAMID'
              | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="text-align: center;"> {{element?.mid}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="3ds2_active">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
              {{'PROFILO.TABELLA3DS2ACTIVE' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element " style="text-align: center;"> {{element?.is3d2secure}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="descrizione">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
              {{'PROFILO.TABELLADESCRIZIONE' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element " style="text-align: center;"> {{element?.description}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
              {{'PROFILO.TABELLAACTION' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="text-align: center;">
              <button mat-icon-button (click)="modificaTerminale(element)">
                <mat-icon class="edit icon-modifica" matTooltip="{{'PROFILO.MODIFICA' | translate}}  "></mat-icon>
              </button>
              <button mat-icon-button *ngIf="!element?.default" (click)="attivaTerminale(element)">
                <mat-icon class="delete icon-utente-disattivo" matTooltip="{{'PROFILO.TOOLTIPATTIVA' | translate}}  ">
                </mat-icon>
              </button>
              <button mat-icon-button *ngIf="element?.default" disabled>
                <mat-icon class="abilita icon-completato" matTooltip="{{'PROFILO.ATTIVO' | translate}}"></mat-icon>
              </button>
              <button mat-icon-button (click)='DialogEliminaTerminale(element)'>
                <mat-icon class='delete icon-cancella' matTooltip="{{'PROFILO.ELIMINA' | translate}}"></mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="terminaleColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: terminaleColumns;"></mat-row>
        </mat-table>
        <div class="buttonBar">
          <button mat-flat-button color="primary" (click)="NuovoTerminale()"
            [disabled]="idMerchant === null || merchant?.terminal.length >= 1">{{'PROFILO.AGGIUNGI' |
            translate}}</button>
        </div>
      </div>
      <!-- EDIT MODE -->

      <div *ngIf="flagnuovoterminale" [formGroup]="terminalForm">
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'PROFILO.MID' | translate}}" formControlName="mid">
            </mat-form-field>
          </span>
          <span class="flex-col">
            <app-dialog-circuiti [data]="terminalForm.value.brand" (selectChanged)="changeCircuits($event)"
              class="rightInputField"></app-dialog-circuiti>
          </span>
        </div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'PROFILO.TID' | translate}}" formControlName="tid" required>
              <mat-error *ngIf="terminalForm.controls.tid.hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="{{'PROFILO.KSIG' | translate}}" formControlName="ksig" required>
              <mat-error *ngIf="terminalForm.controls.ksig.hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>
          </span>
        </div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'PROFILO.DESCRIZIONE' | translate}}" formControlName="description"
                required>
              <mat-error *ngIf="terminalForm.controls.description.hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>
          </span>
        </div>

        <div style="margin-bottom: 20px;"><span class="titoloFiltro">TERMINALE MOTO</span></div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="Mid Moto" formControlName="mid_moto">
            </mat-form-field>
          </span>
        </div>
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'PROFILO.TID_MOTO' | translate}}" formControlName="tid_moto">
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="{{'PROFILO.KSIG_MOTO' | translate}}" formControlName="ksig_moto">
            </mat-form-field>
          </span>
        </div>

        <div style="margin-bottom: 20px;"><span class="titoloFiltro">TERMINALE MIT</span></div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="Mid Mit" formControlName="mid_mit">
            </mat-form-field>
          </span>
        </div>
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="Tid MIT" formControlName="tid_mit">
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="Ksig MIT" formControlName="ksig_mit">
            </mat-form-field>
          </span>
        </div>

        <div style="margin-bottom: 20px;"><span class="titoloFiltro">TERMINALE COF</span></div>

        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="Mid Cof" formControlName="mid_cof">
            </mat-form-field>
          </span>
        </div>
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="Tid COF" formControlName="tid_cof">
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="Ksig COF" formControlName="ksig_cof">
            </mat-form-field>
          </span>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch">
          <mat-checkbox color="primary" formControlName="is3dsecure">
            <span class="checkFont">{{'PROFILO.IS3DSECURE' | translate}}</span>
          </mat-checkbox>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch">
          <mat-checkbox color="primary" formControlName="is3d2secure">
            <span class="checkFont">{{'PROFILO.IS3D2SECURE' | translate}}</span>
          </mat-checkbox>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch">
          <div class="buttonBar">
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
              <button style="margin-right: 5px;" mat-flat-button color="primary"
                (click)="indietroTerminale()">{{'PROFILO.INDIETRO' | translate}}</button>
              <button style="margin-left: 5px;" [disabled]="!terminalForm.valid" *ngIf="!idTerminale" mat-flat-button
                color="primary" (click)="aggiungiTerminale()">{{'PROFILO.AGGIUNGI' | translate}}</button>
              <button style="margin-left: 5px;" [disabled]="!terminalForm.valid" *ngIf="idTerminale" mat-flat-button
                color="primary" (click)="aggiornaTerminale()">{{'PROFILO.MODIFICA' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<div *ngIf="merchant.isMyBankActive" fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{ 'PROFILO.MYBANK' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="!flagnuovoMYBANK">
        <mat-table #table [dataSource]="dataSourceMYBANK">
          <ng-container matColumnDef="merchantID">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
              {{'PROFILO.TABELLAMERCHANTID' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="text-align: center;"> {{element?.merchantID}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="timeout">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
              {{'PROFILO.TABELLATIMEOUT' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element " style="text-align: center;"> {{element?.timeout}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
              {{'PROFILO.TABELLAACTION' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="text-align: center;">
              <button mat-icon-button (click)="modificaMYBANK(element)">
                <mat-icon class="edit icon-modifica" matTooltip="{{'PROFILO.MODIFICA' | translate}}  "></mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="MYBANKColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: MYBANKColumns;"></mat-row>
        </mat-table>
        <div class="buttonBar">
          <button mat-flat-button color="primary" (click)="NuovoMYBANK()"
            [disabled]="idMerchant === null || !flagMYBANKEmpty">{{'PROFILO.AGGIUNGI' | translate}}</button>
        </div>
      </div>
      <!-- EDIT MODE -->

      <div *ngIf="flagnuovoMYBANK" [formGroup]="MYBANKForm">
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'PROFILO.MERCHANTID' | translate}}" formControlName="merchantID" required>
              <mat-error *ngIf="MYBANKForm.controls.merchantID.hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="{{'PROFILO.TIMEOUT' | translate}}" formControlName="timeout" required>
              <mat-error *ngIf="MYBANKForm.controls.timeout.hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_NUMERICO' | translate"></mat-error>
            </mat-form-field>
          </span>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch">
          <div class="buttonBar">
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
              <button style="margin-right: 5px;" mat-flat-button color="primary"
                (click)="indietroMYBANK()">{{'PROFILO.INDIETRO' | translate}}</button>
              <button style="margin-left: 5px;" [disabled]="!MYBANKForm.valid" *ngIf="!flagModificaMYBANK"
                mat-flat-button color="primary" (click)="salvaMYBANK()">{{'PROFILO.AGGIUNGI' | translate}}</button>
              <button style="margin-left: 5px;" [disabled]="!MYBANKForm.valid" *ngIf="flagModificaMYBANK"
                mat-flat-button color="primary" (click)="salvaMYBANK()">{{'PROFILO.MODIFICA' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div *ngIf="merchant.isApplePayActive" fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{ 'PROFILO.APPLEPAY' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="!flagnuovoAPPLEPAY">
        <mat-table #table [dataSource]="dataSourceAPPLEPAY">
          <ng-container matColumnDef="tid">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;"> {{'PROFILO.TID' |
              translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="text-align: center;"> {{element?.tid}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ksig">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;"> {{'PROFILO.KSIG' |
              translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element " style="text-align: center;"> {{element?.ksig}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
              {{'PROFILO.TABELLAACTION' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="text-align: center;">
              <button mat-icon-button (click)="modificaAPPLEPAY(element)">
                <mat-icon class="edit icon-modifica" matTooltip="{{'PROFILO.MODIFICA' | translate}}  "></mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="APPLEPAYColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: APPLEPAYColumns;"></mat-row>
        </mat-table>
        <div class="buttonBar">
          <button mat-flat-button color="primary" (click)="NuovoAPPLEPAY()"
            [disabled]="idMerchant === null || !flagAPPLEPAYEmpty">{{'PROFILO.AGGIUNGI' | translate}}</button>
        </div>
      </div>
      <!-- EDIT MODE -->

      <div *ngIf="flagnuovoAPPLEPAY" [formGroup]="APPLEPAYForm">
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'PROFILO.TID' | translate}}" formControlName="tid" required>
              <mat-error *ngIf="APPLEPAYForm.controls.tid.hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput placeholder="{{'PROFILO.KSIG' | translate}}" formControlName="ksig" required>
              <mat-error *ngIf="APPLEPAYForm.controls.ksig.hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_NUMERICO' | translate"></mat-error>
            </mat-form-field>
          </span>
        </div>
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field style="width: 100%">
              <mat-select placeholder="Inserire i circuiti di pagamento abilitati" formControlName="supportedNetworks"
                multiple required>
                <mat-option *ngFor="let circuito of circuitiApple" [value]="circuito.code">
                  <img alt="circuit icon" class="circuitoImg"
                    src="{{ ASSETS_BASE + 'svg/circuiti/' + circuito.code + '.png' }}" />
                  <span> {{ circuito.desc | uppercase }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <app-dialog-country-code [data]="APPLEPAYForm.controls.countryCode.value"
              (selectChanged)="changeAppleCountryCode($event)" class="rightInputField"></app-dialog-country-code>
          </span>
        </div>
        <div class="flex-row">
          <span class="flex-col" formGroupName='merchantCapabilities'>
            <mat-checkbox color="primary" class='applepayCheckbox' formControlName="supports3DS">
              <span class="checkFont">supports3DS</span>
            </mat-checkbox>
            <mat-checkbox color="primary" class='applepayCheckbox' formControlName="supportsEMV">
              <span class="checkFont">supportsEMV</span>
            </mat-checkbox>
            <mat-checkbox color="primary" class='applepayCheckbox' formControlName="supportsCredit">
              <span class="checkFont">supportsCredit</span>
            </mat-checkbox>
            <mat-checkbox color="primary" class='applepayCheckbox' formControlName="supportsDebit">
              <span class="checkFont">supportsDebit</span>
            </mat-checkbox>
          </span>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch">
          <div class="buttonBar">
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
              <button style="margin-right: 5px;" mat-flat-button color="primary"
                (click)="indietroAPPLEPAY()">{{'PROFILO.INDIETRO' | translate}}</button>
              <button style="margin-left: 5px;" [disabled]="!APPLEPAYForm.valid" *ngIf="!flagModificaAPPLEPAY"
                mat-flat-button color="primary" (click)="salvaAPPLEPAY()">{{'PROFILO.AGGIUNGI' | translate}}</button>
              <button style="margin-left: 5px;" [disabled]="!APPLEPAYForm.valid" *ngIf="flagModificaAPPLEPAY"
                mat-flat-button color="primary" (click)="salvaAPPLEPAY()">{{'PROFILO.MODIFICA' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- URL DI NOTIFICA -->
<div *ngIf="merchant.isRetryCallbackActive" fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{ 'PROFILO.URLNOTIFICA' | translate | uppercase}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" [formGroup]="datiTecniciForm">
        <div class="flex-row">
          <mat-form-field class="singleField" fxLayout="row" fxLayoutAlign="space-between center">
            <input matInput placeholder="{{'PROFILO.URLNOTIFICA' | translate}}" formControlName="notify_url">
            <button type="button" matSuffix mat-icon-button aria-label="Clear"
              *ngIf="datiTecniciForm.controls.notify_url?.value" (click)="datiTecniciForm.controls.notify_url.reset()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="buttonBar" style="margin-top: 0">
          <button style="margin-left: 5px;" mat-flat-button color="primary"
            (click)="salvaURLNOTIFICA()">{{'PROFILO.BUTTONSAVE' | translate}}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>