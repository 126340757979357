import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, FormGroup } from "@angular/forms";
import { LoaderService } from '../../_services/loader.service';
import { TerminalService } from '../../_services/terminal.service';
import { environment } from '../../../environments/environment';
import { FileUploaderOptions, FileUploader, FileLikeObject, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { AuthService } from '../../_services/auth.service';
import { DialogService } from '../../_services/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../_components/dialog/dialog.component';

@Component({
  selector: 'app-utils',
  templateUrl: './utils.component.html',
  styleUrls: ['./utils.component.scss']
})
export class UtilsComponent implements OnInit {

  private readonly UPLOAD_URL: string = environment.BASE_URL + 'terminal/bulkUpload';
  // FILEUPLOADER
  fileUploaderOptions: FileUploaderOptions;
  uploader: FileUploader;
  logoFile: UntypedFormControl;
  spinnerdisplay = 'none';
  item = null;
  fileCtrl: UntypedFormControl;
  @ViewChild('inputfile', { static: true }) inputfile: ElementRef;

  dialogRef: MatDialogRef<DialogComponent>;
  
  constructor(
    private loaderService: LoaderService,
    private terminalService: TerminalService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) { 
    this.logoFile = new UntypedFormControl({ value: '', disabled: true });
  }

  ngOnInit(): void {
    this.setupUploader();
  }

  onFileUploadClick() {
    this.inputfile.nativeElement.click();
  }

  clearInputUpload() {
    this.inputfile.nativeElement.value='';
    this.uploader.clearQueue();
    this.uploader.cancelAll();
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  setupUploader() {
    this.uploader = new FileUploader({});

    // DEFINISCO LE OPZIONI DI UPLOAD
    this.fileUploaderOptions = {
      url: this.UPLOAD_URL,
      headers: [{ name: 'Authorization', value: this.authService.token }],
      method: 'POST',
      queueLimit: 1,
      removeAfterUpload: false,
    };

    this.uploader.setOptions(this.fileUploaderOptions);

    // HOOK IN CASO DI AGGIUNTA FILE IN ERRORE
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any): any => {
      this.dialogService.openDialogWARN('DIALOG.FORMATCSV_ERROR_TITLE', 'DIALOG.FORMATCSV_ERROR_MESSAGE');
      this.clearInputUpload();
    };

    // HOOK IN CASO DI AGGIUNTA FILE A BUON FINE
    this.uploader.onAfterAddingFile = (fileItem: FileItem): any => {
      this.logoFile = new UntypedFormControl(fileItem._file.name);
      this.uploader.queue[0].upload();
    };

    // HOOK IN CASO DI UPLOAD A BUON FINE (FACCIO PARTIRE LA RICHIESTA DI IMPORT)
    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      const data = JSON.parse(response); // success server response

      if(data && data.status && data.status !== 'error'){
        this.loaderService.stopGeneral();
        this.dialogService.openDialogPRIMARY('DIALOG.IMPORTSUCCESSTITLE', 'DIALOG.IMPORTSUCCESSMESSAGE');
        this.clearInputUpload();
      } else {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.IMPORTERRORTITLE', data.message ? data.message : JSON.stringify(data), data.code ? data.code : undefined);
        this.clearInputUpload();
      }
      
    };

    // HOOK IN CASO DI ERRORE NELL'UPLOAD...
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
        // let error = JSON.parse(response); //error server response
        this.loaderService.stopGeneral();
        let error: any;
        if (this.isJson(response)) {
          error = JSON.parse(response);
          let errorMsg;
          if (typeof error == 'string') {
            errorMsg = error;
          } else {
            errorMsg = error.message != undefined ? error.message : '';
          }
          this.dialogService.openDialogWARN('DIALOG.UPLOADERROR', errorMsg);
        } else {
          error = response;
          this.dialogService.openDialogWARN('DIALOG.UPLOADERROR', error);
        }
        console.log(error);
    };
  }

}
