import { Component, OnInit, Input, ElementRef, ViewChild, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { saveFile } from '../../_services/file-download-helper';
import { PaymentService } from '../../_services/payment.service';
import { DialogService } from '../../_services/dialog.service';
import { LoaderService } from '../../_services/loader.service';
import { AuthService } from '../../_services/auth.service';



@Component({
  selector: 'app-operations-detail-third',
  templateUrl: './operations-detail-third.component.html',
  styleUrls: ['./operations-detail-third.component.scss']
})
export class OperationsDetailThirdComponent implements OnInit, AfterViewInit {
  @Input() element: any;
  @Input() transactionList?: Array<any>;

  @Input() set isActive(value: number | undefined) {
    this._isActive = value;
    if (value === 2) {
      if (!this.element) { return; }
      this.getAuthor();
      this.getTransactions();
      this.getDates();
      this.getHistory(true);
    }
  }

  @Input() isMobile?: boolean;
  @Output() refreshOutcomes?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('transactions') transactionListDom: QueryList<ElementRef>;
  @ViewChildren('buttons') buttons: QueryList<ElementRef>;
  @ViewChild('container') container: ElementRef;
  @ViewChild('containerOps', { static: true }) containerOps: ElementRef;

  dates = [];
  transactions = [];
  filtered: string;
  success: any;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  adminName: string;
  adminOp = [];
  operations = [];
  selectedIndex = '0';

  isOperationsDisabled = true;
  _isActive: number;

  constructor(
    private paymentService: PaymentService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    if (this.isMobile && this._isActive !== 2) { return; }

    if (!this.element) { return; }
    this.getAuthor();
    this.getTransactions();
    this.getDates();
    this.getHistory(false);
  }

  ngAfterViewInit() {
    if (this.isMobile && this._isActive === 2) {
      return;
    }
    setTimeout(() => this.scrollToLast(), 500);
  }

  getAuthor() {
    if (!this.element.user) {
      return;
    }
    if (this.element.user.first_name && this.element.user.last_name) {
      const name = this.element.user.first_name + ' ' + this.element.user.last_name;
      this.adminName = name.toLowerCase();
    }
  }

  getDates() {
    if (this.transactions.length < 1) {
      return;
    }
    let date: any;
    this.transactions.forEach(el => {
      if (el.date) {
        date = el.date.slice(0, 10);
      }
      const dateExists = this.dates.find(y => y === date);
      if (!dateExists) {
        this.dates.push(date);
      }
    });
  }

  getTypeOperation(type) {
    switch (type) {
      case 'PURCHASE':
      case 'AUTH':
      case 'VERIFY':
        return "TRANSACTION";

      case 'CREDIT':
      case 'VOID':
      case 'CONFIRM':
        return "OPERATION"

      default:
        return "TRANSACTION"
    }
  }

  getTransactions() {
    //Controllo se pagamento ricorrente
    if (/RECURRENT|DEFERRED/.test(this.element.payment_type)) {
      this.transactions = this.paymentService.transactions;
      this.operations = this.paymentService.operations;
      this.sortTransactions(this.transactions, 'date');
    } else {
      if (!this.element.outcomes || this.element.outcomes.length < 1) {
        return;
      }

      // SE IL PAGAMENTO E APERTO LE TRANSAZIONI VENGONO PASSATE IN INPUT GIA RAGGRUPPATE PER MASKED PAN
      if (this.element.isPaymentOpen && this.transactionList) {
        this.transactionList.forEach(el => {
          const transaction = {
            response: el.outcome_response,
            receipt: el.outcome_receipt,
            date: el.outcome_update,
            amount: el.outcome_amount,
          };
          this.transactions.unshift(transaction);
        });
      } else {
        this.element.outcomes.forEach(el => {
          const transaction = {
            response: el.outcome_response,
            receipt: el.outcome_receipt,
            date: el.outcome_update,
            amount: el.outcome_amount,
          };

          this.transactions.unshift(transaction);
        });
      }
      this.transactions.forEach(el => {
        const nameString = el.response.accountName;
        if (!nameString) {
          return;
        }
        const nameArray = nameString.split(',');
        if (!nameArray[1]) {
          el.response.accountName = nameArray[0];
          return;
        }
        el.response.accountName = nameArray[1] + ' ' + nameArray[0];
      });
      this.sortTransactions(this.transactions);
    }


    // ORDINA LE TRANSAZIONI CRONOLOGICAMENTE
    // CONTROLLA SE ESISTE ALMENO UNA TRANSAZIONE ANDATA A BUON FINE
    this.findSuccess();

  }

  notification(recurr_id) {
    this.loaderService.startGeneral();
    this.paymentService.recurrentNotify(recurr_id).subscribe((res) => {
      this.loaderService.stopGeneral();
      if (res) {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      } else {
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', "Generic Notification Error");
      }
    });
  }

  getHistory(isInitializing: boolean) {
    if (!this.transactions || this.transactions.length < 1) {
      return;
    }
    if (!this.element.version || this.element.version < 2) {
      return;
    }
    this.loaderService.startGeneral();
    this.paymentService.history(this.element._id)
      .subscribe(
        response => {
          const history = response['history'].filter((el: any) => el.autcome.response.rc === 'IGFS_000');
          if (!history || history.length < 1) {
            this.loaderService.stopGeneral();
            return;
          }
          let i = 0;
          if (this.element.isPaymentOpen) {
            if (!this.success) {
              this.loaderService.stopGeneral();
              return;
            }
            i = history.findIndex((el: any) => el.autcome.response.maskedPan === this.success.response.maskedPan && el.autcome.gateway !== 'MYBANK');
          }

          if (history[i]) {
            this.adminOp = history[i].transaction;
            if (this.adminOp.length > 0) {
              this.isOperationsDisabled = false;
              this.adminOp.forEach(el => {
                el.amount = el.amount.replace('.', ',');
                if (el.response.pendingAmount) {
                  el.response.pendingAmount = this.parseAmount(el.response.pendingAmount.toString());
                }
              });
              this.operations = history[i].operations;
              this.operations.forEach(el => el.pendingAmount = this.parseAmount(el.pendingAmount.toString()));
              this.selectedIndex = '1';
              this.loaderService.stopGeneral();
            } else {
              this.loaderService.stopGeneral();
            }
          }
        },
        error => {
          console.log(error);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        },
        () => {
          if (isInitializing) {
            setTimeout(() => this.scrollToLast(), 500);
          }
        }
      );
  }

  refreshTransactions() {
    if (/RECURRENT|DEFERRED/.test(this.element.payment_type)) {
      this.refreshOutcomes.emit(this.element);
    } else {
      this.getHistory(false);
    }
  }


  findSuccess() {
    this.success = this.transactions.find(el => el.response.rc === 'IGFS_000');
  }

  scrollToDate(date: any) {
    const index = this.transactions.findIndex(el => {
      const elDate = el.date.slice(0, 10);
      this.filtered = elDate;
      return elDate === date;
    });
    const chosen = this.transactionListDom.find((el, i) => i === index);
    const offset = chosen.nativeElement.offsetTop - 20;
    this.container.nativeElement.scrollTo({
      top: offset,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToOk() {
    const index = this.transactions.findIndex(el => {
      this.filtered = 'ok';
      return el.response.rc === 'IGFS_000';
    });
    const chosen = this.transactionListDom.find((el, i) => i === index);
    const offset = chosen.nativeElement.offsetTop - 20;
    this.container.nativeElement.scrollTo({
      top: offset,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToLast() {
    if (!this.buttons || !this.buttons.last || !this.buttons.last.nativeElement) {
      return;
    }
    const chosen = this.buttons.last;
    chosen.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  downloadReceipt(transaction: any) {
    this.paymentService.downloadReceipt(transaction.receipt._id)
      .subscribe(
        response => saveFile(response, 'receipt.pdf')
      );
  }

  parseAmount(amount: string) {
    amount = `${amount}`;
    if (amount.length < 2) {
      amount = '00' + amount;
    }
    const amountArr = amount.split('');

    amountArr.splice(amountArr.length - 2, 0, '.');
    amount = amountArr.join('');
    if (amount.length < 4) {
      amount = '0' + amount;
    }
    return amount;
  }

  sortTransactions(array: any[], field=null) {
    if (!field) {
      array = array.sort((a, b) => a.date - b.date);
    } else {
      array = array.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        } else if (a[field] < b[field]) {
          return -1;
        }

        return 0;
      });
    }
  }

  calcShieldColor(trx) {
    if (!trx || !trx.enrStatus) {
      return 'grigio';
    }
    if (trx.enrStatus === 'N') {
      return 'rosso';
    }
    if (trx.enrStatus === 'Y') {
      if (trx.authStatus === 'Y') { return 'verde'; }
      if (trx.authStatus === 'N') { return 'rosso'; }
      if (trx.authStatus === 'A') { return 'giallo'; }
      if (trx.authStatus === 'E') { return 'rosa'; }
    }
    if (trx.enrStatus === 'E' || trx.enrStatus === 'U') {
      return 'giallo';
    }
  }
}


