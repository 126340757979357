<div fxLayout="row" fxLayoutAlign="center center" [ngClass]="theme" style="overflow-x: hidden;">

  <!-- COLONNA SPACER SINISTRA-->
  <div fxLayout="column" fxLayoutAlign="center center" style="min-width: 30%" fxHide.xs fxHide.sm></div>

  <!-- COLONNA CENTRALE CON FORM LOGIN-->
  <div fxLayout="column" fxLayoutAlign="center center" ngClass.lt-md="formColSm" ngClass.lt-sm="formColXs"
    ngClass.gt-sm="formCol">
    <div ngClass.xs="my-auth-boxSm auth-boxXs" ngClass.sm="my-auth-boxLg auth-boxMd"
      ngClass.md="my-auth-boxLg auth-boxMd" ngClass.gt-md="my-auth-boxLg auth-boxLg">

      <!-- ANIMATED LOGO -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <ng-lottie [options]="clicPayAnimationConfiguration" width="200px" height="100px"></ng-lottie>
      </div>

      <!-- SUBTITLE -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="subtitle" *ngIf="firstAccessForm">{{'LOGIN.CHOOSE_YOUR_PWD' | translate}}</p>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-around center">
        <!-- FORM LOGIN STANDARD -->
        <form *ngIf="loginForm" (ngSubmit)="onLogin()" fxLayout="column" class="loginForm" ngClass.lt-md="formSm"
          ngClass.md="formMd" ngClass.lg="formLg" ngClass.xl="formXl" [formGroup]="loginForm">
          <!--CAMPO EMAIL-->
          <mat-form-field class="full-width">
            <input #inputLogin matInput placeholder="{{'LOGIN.EMAIL' | translate | titlecase }}"
              formControlName="email">
            <mat-error
              *ngIf="loginForm?.controls['email'].hasError('pattern') && !loginForm?.controls['email'].hasError('required')"
              [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
            <mat-error *ngIf="loginForm?.controls['email'].hasError('required')"
              [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
          </mat-form-field>
          <!--CAMPO PASSWORD-->
          <mat-form-field class="full-width ">
            <input matInput [type]="isHidden ? 'password' : 'text'"
              placeholder="{{'LOGIN.PASSWORD' | translate | titlecase }}  " formControlName="password">
            <button type="button" mat-icon-button matSuffix (click)="isHidden = !isHidden"
              [attr.aria-label]="isHidden ? 'Show password' : 'Hide password'">
              <mat-icon style="height: 24px; width: 24px;">{{ isHidden ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </button>
            <mat-error *ngIf="loginForm?.controls['password'].hasError('required')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          </mat-form-field>
          <!-- BOTTONE RECUPERA PASSWORD -->
          <div fxLayout fxLayoutAlign="end">
            <button class="recuperaPass" type="button" (click)="buildForgotForm()">{{'LOGIN.RECUPERAPASS' |
              translate}}
            </button>
          </div>
          <!-- BOTTONE VERDE ENTRA -->
          <div fxLayout fxLayoutAlign="center" class="buttonRow">
            <button mat-flat-button color="primary" class="rounded-button" [disabled]="!loginForm?.valid" type="submit">
              {{'LOGIN.ENTRA' | translate}}
            </button>
          </div>
        </form>

        <!-- FORM PRIMO ACCESSO -->
        <form *ngIf="firstAccessForm" [formGroup]="firstAccessForm" (ngSubmit)="primoAccesso()" class="loginForm"
          ngClass.lt-md="formSm" ngClass.md="formMd" ngClass.lg="formLg" ngClass.xl="formXl">
          <!--CAMPO NUOVA PASSWORD-->
          <mat-form-field class="full-width">
            <input matInput [type]="isHidden ? 'password' : 'text'"
              placeholder="{{'NEWPASS.NUOVPASS' | translate | titlecase }}  " formControlName="password"
              [errorStateMatcher]="matchOldErrorMatcher">
            <button type="button" mat-icon-button matSuffix (click)="isHidden = !isHidden"
              [attr.aria-label]="isHidden ? 'Show password' : 'Hide password'">
              <mat-icon style="height: 24px; width: 24px;">{{ isHidden ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </button>
            <mat-error *ngIf="firstCtrl?.password.hasError('required')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            <mat-error *ngIf="firstCtrl?.password.hasError('pattern')"
              [innerHTML]="'MESSAGE.PASSWORD_PATTERN' | translate"></mat-error>
            <mat-error *ngIf="firstAccessForm?.hasError('matchOld') && !firstAccessForm?.controls.password.errors"
              [innerHTML]="'MESSAGE.PASSWORD_MATCHPRECEDENTE' | translate"></mat-error>
          </mat-form-field>
          <!--CAMPO CONFERMA PASSWORD-->
          <mat-form-field class="full-width">
            <input matInput [type]="isHiddenConfirm ? 'password' : 'text'"
              placeholder="{{'NEWPASS.RIPPASS' | translate | titlecase }}" formControlName="confirm"
              [errorStateMatcher]="mismatchErrorMatcher">
            <button type="button" mat-icon-button matSuffix (click)="isHiddenConfirm = !isHiddenConfirm"
              [attr.aria-label]="isHiddenConfirm ? 'Show password' : 'Hide password'">
              <mat-icon style="height: 24px; width: 24px;">{{ isHiddenConfirm ? 'visibility_off' :
                'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="firstCtrl?.confirm.hasError('required')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            <mat-error *ngIf="firstAccessForm?.hasError('mismatch') && firstAccessForm?.value.confirm"
              [innerHTML]="'MESSAGE.PASSWORD_MATCH' | translate"></mat-error>
          </mat-form-field>
          <!-- REQUISITI PASSWORD -->
          <div class="full-width">
            <!-- Almeno una lettera minuscola -->
            <div fxLayout="row" fxFlexAlign="start center"
              [ngClass]="firstAccessForm?.get('password').hasError('noLowerCase') ? 'notChecked' : 'checked'">
              <mat-icon class="iconLogin">{{firstAccessForm?.get('password').hasError('noLowerCase') ?
                'close' : 'check'}}</mat-icon>
              <p class="notice">{{'LOGIN.PWD_LETTERA_MINUSCOLA' | translate}}</p>
            </div>
            <!-- Almeno una lettera maiuscola -->
            <div fxLayout="row" fxFlexAlign="start center"
              [ngClass]="firstAccessForm?.get('password').hasError('noCapitalized') ? 'notChecked' : 'checked'">
              <mat-icon class="iconLogin">{{firstAccessForm?.get('password').hasError('noCapitalized') ?
                'close' : 'check'}}</mat-icon>
              <p class="notice">{{'LOGIN.PWD_LETTERA_MAIUSCOLA' | translate}}</p>
            </div>
            <!-- Almeno un numero -->
            <div fxLayout="row" fxFlexAlign="start center"
              [ngClass]="firstAccessForm?.get('password').hasError('noNumber') ? 'notChecked' : 'checked'">
              <mat-icon class="iconLogin">{{firstAccessForm?.get('password').hasError('noNumber') ?
                'close' : 'check'}}</mat-icon>
              <p class="notice">{{'LOGIN.PWD_NUMERO' | translate}}</p>
            </div>
            <!-- Almeno un simbolo -->
            <div fxLayout="row" fxFlexAlign="start center"
              [ngClass]="firstAccessForm?.get('password').hasError('noSymbol') ? 'notChecked' : 'checked'">
              <mat-icon class="iconLogin">{{firstAccessForm?.get('password').hasError('noSymbol') ?
                'close' : 'check'}}</mat-icon>
              <p class="notice">{{'LOGIN.PWD_SIMBOLO' | translate}}</p>
            </div>
            <!-- Min. 8 caratteri -->
            <div fxLayout="row" fxFlexAlign="start center"
              [ngClass]="firstAccessForm?.get('password').hasError('tooShort') ? 'notChecked' : 'checked'">
              <mat-icon class="iconLogin">{{firstAccessForm?.get('password').hasError('tooShort') ?
                'close' : 'check'}}</mat-icon>
              <p class="notice">{{'LOGIN.PWD_MINIMO' | translate}}</p>
            </div>
            <!-- Max. 20 aratteri -->
            <div fxLayout="row" fxFlexAlign="start center"
              [ngClass]="firstAccessForm?.get('password').hasError('tooLong') ? 'notChecked' : 'checked'">
              <mat-icon class="iconLogin">{{firstAccessForm?.get('password').hasError('tooLong') ? 'close'
                : 'check'}}</mat-icon>
              <p class="notice">{{'LOGIN.PWD_MASSIMO' | translate}}</p>
            </div>
            <!-- Composizione password -->
            <div fxLayout="row" fxFlexAlign="start center"
              [ngClass]="firstAccessForm?.get('password').hasError('invalidChar') ? 'notChecked' : 'checked'">
              <mat-icon class="iconLogin">{{firstAccessForm?.get('password').hasError('invalidChar') ?
                'close' : 'check'}}</mat-icon>
              <p class="notice">{{'LOGIN.PWD_SUMMARY' | translate}} <br>" £ $ % & / ( ) = ?</p>
            </div>
          </div>

          <!-- BOTTONE RECUPERA PASSWORD -->
          <!-- <div fxLayout fxLayoutAlign="end">
                        <button class="recuperaPass" type="button"
                            (click)="buildForgotForm()">{{'LOGIN.RECUPERAPASS' | translate}}
                        </button>
                    </div> -->
          <!-- BOTTONE VERDE ENTRA -->
          <div fxLayout fxLayoutAlign="center" class="buttonRow">
            <button #entraButton mat-flat-button color="primary" class="buttonGreenLogin" type="submit"
              [disabled]="!firstAccessForm?.valid">{{'NEWPASS.ENTRA' | translate}}</button>
          </div>
        </form>

        <!-- FORM RECUPERA PASSWORD -->
        <form *ngIf="forgotForm" [formGroup]="forgotForm" (ngSubmit)="richiestaRecuperoPass()" fxLayout="column"
          class="loginForm" ngClass.lt-md="formSm" ngClass.md="formMd" ngClass.lg="formLg" ngClass.xl="formXl">
          <!-- CAMPO EMAIL -->
          <mat-form-field class="full-width">
            <input matInput placeholder="{{'LOGIN.EMAIL' | translate | titlecase }}" formControlName="email">
            <mat-error
              *ngIf="forgotForm?.controls.email.hasError('pattern') && !forgotForm?.controls.email.hasError('required')"
              [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
            <mat-error *ngIf="forgotForm?.controls.email.hasError('required')"
              [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
          </mat-form-field>
          <!-- BOTTONE VERDE INVIA MAIL RECUPERO PASSWORD -->
          <div fxLayout="row wrap" class="buttonRow" fxLayoutAlign="space-around center" fxFlex="100">
            <button mat-flat-button color="primary" class="buttonGreenLogin" type="button"
              (click)="goBack()">{{'DIALOG.CANCEL' |
              translate}}
            </button>
            <button [disabled]="!forgotForm?.valid" mat-flat-button color="primary" class="buttonGreenLogin"
              type="submit">
              {{'LOGIN.RECUPINVIA' | translate}}
            </button>
          </div>
        </form>

      </div>

      <!-- INDICAZIONE VERSIONE APP -->
      <div fxLayout="column" fxLayoutAlign="end end" class="versioneApp">
        <span>{{'APP.VERSION' | translate}}</span>
      </div>
    </div>
  </div>

  <!-- COLONNA SPACER DESTRA CON BUBBLE SUPPORT -->
  <div fxLayout="column" fxLayoutAlign="end end" style="min-width: 30%; height: 90vh;" fxHide.xs fxHide.sm>
    <div fxLayout="column" fxLayoutAlign="end end" fxHide.xs fxHide.sm>
      <mobilesoft-support [language]="currentLang" [isLoggedIn]="false" (supportValues)="createSupportRequest($event)">
      </mobilesoft-support>
    </div>
  </div>

</div>

<!-- FOOTER -->
<div fxHide.lt-sm>
  <app-footer></app-footer>
</div>
