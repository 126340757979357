import { environment } from './../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { HoldingService } from '../../_services/holding.service';
import { IHoldingStats } from '../../_models/holding-table-merchant';

@Component({
  selector: 'app-holding-operation-widget',
  templateUrl: './holding-operation-widget.component.html',
  styleUrls: ['./holding-operation-widget.component.scss']
})
export class HoldingOperationWidgetComponent implements OnInit {

  @Input() PaymentType: string;
  @Input() set infoStatus(value: IHoldingStats) {
    if(value) {
      this.setInfoStatus(value);
    } else {
      this.nPayments = 0;
      this.tAmountPayment = 0;
    }
  };

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  nPayments: number = 0;
  tAmountPayment: number = 0;

  constructor(
    public holdingService: HoldingService
  ) { }

  ngOnInit() {
  }

  setInfoStatus(infoStatus: IHoldingStats) {
    this.nPayments = infoStatus.count ? infoStatus.count : 0;
    this.tAmountPayment = infoStatus.amount ? infoStatus.amount : 0;
  }

}
