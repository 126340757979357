import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../_services/theme.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {
  theme: string;
  
  constructor(
    private themeService: ThemeService,
  ) { }

  ngOnInit() {
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });

  }

}
