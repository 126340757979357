<div [ngClass]="theme" style="width: 100vw; overflow: scroll; height: 100vh; background-color: #f9f9f9">
  <div fxLayout="row" fxLayoutAlign="end" class="topRow">
    <mat-icon class="overlayIcons" svgIcon="close" aria-hidden="false" aria-label="close" (click)="closeDetail()">
    </mat-icon>
  </div>

  <div *ngIf="element?.isPaymentOpen" style="height: 100%; background-color: #f9f9f9; padding: 0 20px">
    <app-grid style="width: 100%" [dataSource]="[element]" [isFullScreen]="true" [_paymentType]="element.payment_type"></app-grid>
  </div>

  <div *ngIf="!element?.isPaymentOpen" fxLayout="row" fxLayoutAlign="center"
    style="background-color: #f9f9f9; padding: 0 20px; font-weight: 100; height: 100%">
    <app-grid style="width: 100%" [dataSource]="dataSource" [isFullScreen]="true" [_paymentType]="element.payment_type"></app-grid>
  </div>
</div>
