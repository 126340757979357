<span [ngClass]="theme">
  <div mat-dialog-content class="dialogContent">
    <p>{{"DIALOG.REASON_REJECT" | translate}}</p>
    <mat-form-field style="margin-top: 25px; width: 100%;">
      <textarea matInput [(ngModel)]="reason" required class="textArea"
        placeholder="{{'DIALOG.REASON' | translate}}"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="multipleButtons respectPadding">
    <button mat-flat-button color="primary" [mat-dialog-close]="false" class="buttonDialog">{{"DIALOG.CANCEL" |
      translate}}</button>
    <button color="warn" mat-flat-button [mat-dialog-close]="reason" [disabled]="!reason"
      class="buttonDialog">{{"DIALOG.CONFERMA" | translate}}</button>
  </div>
</span>
