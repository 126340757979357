<div fxLayout="row" fxLayoutAlign="space-between" style="padding: 20px;">

  <!-- COLONNA SINISTRA -->
  <div [formGroup]="templateCodeForm" fxLayout="column" class="flex-col" id="editor-col">

    <!-- TEMPLATE INFO -->
    <div fxLayout="row" style="width: 100%;">
      <div fxLayout="column" style="width: 100%;">
        <div *ngIf="channel === 'EMAIL'" class="titoloFiltro" style="margin: 20px 0 50px 0;">{{"TEMPLATE.EMAILEDITTITLE"
          | translate}}</div>
        <div *ngIf="channel === 'SMS'" class="titoloFiltro" style="margin: 20px 0 50px 0;">{{"TEMPLATE.SMSEDITTITLE" |
          translate}}</div>
        <div *ngIf="channel === 'PAGE'" class="titoloFiltro" style="margin: 20px 0 50px 0;">{{"TEMPLATE.PAGEEDITTITLE" |
          translate}}</div>
      </div>
    </div>

    <!-- EMAIL FORM -->
    <div fxLayout="row" [formGroup]="emailForm" *ngIf="channel === 'EMAIL'" style="width: 100%; height: 100%;">
      <div fxLayout="column" fxLayoutAlign="space-between" style="width: 100%; height: 100%;">
        <div fxLayout="column" style="width: 100%;">
          <!-- LANGUAGE -->
          <div fxLayout="column" style="margin-top:-20px;">
            <div class="fakeLabel">{{"TEMPLATE.LANGUAGE" | translate}}</div>
            <mat-form-field class="templateInput">
              <mat-select formControlName="language">
                <mat-option value="it">{{"LANGUAGE.ITALIAN" | translate}}</mat-option>
                <mat-option value="en">{{"LANGUAGE.ENGLISH" | translate}}</mat-option>
                <mat-option value="fr">{{"LANGUAGE.FRENCH" | translate}}</mat-option>
                <mat-option value="de">{{"LANGUAGE.GERMAN" | translate}}</mat-option>
                <mat-option value="es">{{"LANGUAGE.SPANISH" | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- SUBJECT -->
          <div [ngStyle]="{'margin-top': emailForm.controls.subject?.value ? '-20px' : '0'}">
            <div class="fakeLabel" *ngIf="emailForm['controls'].subject?.value">{{"TEMPLATE.SUBJECT" | translate}}</div>
            <mat-form-field style="width: 100%;" class="templateInput">
              <mat-label *ngIf="!emailForm['controls'].subject?.value">{{"TEMPLATE.SUBJECT" | translate}}</mat-label>
              <input matInput formControlName="subject" required>
            </mat-form-field>
          </div>

          <!-- EDITOR HTML/ EMAIL -->
          <div style="margin: 20px 0;">
            <div class="titoloFiltro" style="margin-bottom: 10px;">{{"TEMPLATE.EDITORHTML" | translate}}</div>
            <mat-tab-group [(selectedIndex)]="tabIndex">
              <mat-tab label='{{"TEMPLATE.EDITOR_BASE" | translate}}'>
                <ng-template matTabContent>
                  <ck-editor #ckEmailEditor name="editor-1" formControlName="content" [config]="config"
                    [fullPage]="true"></ck-editor>
                </ng-template>
              </mat-tab>
              <mat-tab label='{{"TEMPLATE.EDITOR_AVANZATO" | translate}}'>
                <ng-template matTabContent>
                  <ngx-codemirror #emailMirror formControlName="content"
                    [options]="{lineNumbers: true, theme: 'material-darker', mode: 'htmlmixed'}"></ngx-codemirror>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
            <div style="margin: 25px 0 30px 0" #emailChips>
              <div class="variables-container" id="emailChips">
                <mat-chip-list aria-label="Variable selection">
                  <mat-chip color="primary" class="copy" selected *ngFor="let word of placeholders"
                    (click)="addChipToEmailEditor(variablesSchema[word].value)">{{ variablesSchema[word].label }}
                  </mat-chip>
                  <mat-chip color="primary" selected (click)="addDefaultContentToEmail()">{{"TEMPLATE.DEFAULT_EMAIL" |
                    translate}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>

          <!-- SIMPLE MESSAGE -->
          <div fxLayout="column">
            <div class="titoloFiltro" style="margin-bottom: 10px;">{{"TEMPLATE.SIMPLETEXT" | translate}}</div>
            <!-- SIMPLE TEXT EMAIL -->
            <mat-form-field class="templateInput" floatLabel='never' appearance="legacy">
              <textarea matInput formControlName="text" class="textArea" style="height: 200px"
                placeholder="{{'TEMPLATE.EMAILMESSAGE' | translate}}" (click)="updateSelection($event)"
                (change)="updateSelection($event)" required></textarea>
              <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="emailForm.controls.text?.value"
                (click)="emailForm.controls.text.reset()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-chip-list aria-label="Variable selection">
              <mat-chip color="primary" class="copy" selected *ngFor="let word of placeholders"
                (click)="addChipToTextArea(variablesSchema[word].value, emailForm, 'text')">{{
                variablesSchema[word].label }}</mat-chip>
            </mat-chip-list>
          </div>
        </div>

        <!-- SAVE -->
        <div *ngIf="!isApproveView && template?.state !== 'PENDING'" fxLayout="row" fxFlexAlign="end"
          style="margin-bottom: 20px;">
          <button mat-flat-button color="primary" [disabled]="!emailForm.valid"
            (click)="onSaveEmailPage()">{{"TEMPLATE.SAVE" | translate | uppercase}}</button>
        </div>
        <!-- READ -->
        <div *ngIf="isApproveView && !template?.isEmailRead" fxLayout="row" fxFlexAlign="end"
          style="margin-bottom: 20px;">
          <button mat-flat-button color="primary" (click)="onRead(template, 'EMAIL')">{{"TEMPLATE.READ" |
            translate}}</button>
        </div>
      </div>
    </div> <!-- FINE EMAIL FORM -->

    <!--SMS FORM -->
    <div fxLayout="row" [formGroup]="smsForm" *ngIf="channel === 'SMS'" style="width: 100%; height: 100%;">
      <div fxLayout="column" fxLayoutAlign="space-between" style="width: 100%; height: 100%;">
        <div fxLayout="column" style="width: 100%;">
          <!-- LANGUAGE -->
          <div fxLayout="column" style="margin-top:-20px;">
            <div class="fakeLabel">{{"TEMPLATE.LANGUAGE" | translate}}</div>
            <mat-form-field class="templateInput">
              <mat-select formControlName="language">
                <mat-option value="it">{{"LANGUAGE.ITALIAN" | translate}}</mat-option>
                <mat-option value="en">{{"LANGUAGE.ENGLISH" | translate}}</mat-option>
                <mat-option value="fr">{{"LANGUAGE.FRENCH" | translate}}</mat-option>
                <mat-option value="de">{{"LANGUAGE.GERMAN" | translate}}</mat-option>
                <mat-option value="es">{{"LANGUAGE.SPANISH" | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- SENDER -->
          <div fxLayout="column" [ngStyle]="{'margin-top': smsForm.controls.sender?.value ? '-20px' : '0'}"
            *ngIf="customSender">
            <div class="fakeLabel" *ngIf="smsForm['controls'].sender?.value">{{"TEMPLATE.SENDER" | translate}}</div>
            <mat-form-field style="width: 100%;" class="templateInput">
              <mat-label *ngIf="!smsForm['controls'].sender?.value">{{"TEMPLATE.SENDER" | translate}}</mat-label>
              <input matInput formControlName="sender" required>
            </mat-form-field>
          </div>

          <!-- SIMPLE MESSAGE -->
          <div fxLayout="column">
            <div class="titoloFiltro" style="margin-bottom: 10px;">{{"TEMPLATE.SIMPLETEXT" | translate}}</div>
            <!-- SMS TEXT -->
            <mat-form-field class="templateInput" floatLabel='never' appearance="legacy">
              <textarea matInput formControlName="content" class="textArea" style="height: 250px"
                placeholder="{{'TEMPLATE.SMSMESSAGE' | translate}}" (click)="updateSelection($event)"
                (change)="updateSelection($event)" required></textarea>
              <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="smsForm.controls.content?.value"
                (click)="smsForm.controls.content.reset()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-chip-list aria-label="Variable selection">
              <mat-chip color="primary" class="copy" selected *ngFor="let word of placeholders"
                (click)="addChipToTextArea(variablesSchema[word].value, smsForm, 'content')">{{
                variablesSchema[word].label }}</mat-chip>
              <mat-chip color="primary" class="copy" selected
                (click)="addChipToTextArea(toDateString, smsForm, 'content')">{{"RICHIEDIPAGAMENTO.TO_DATE" |
                translate}}</mat-chip>
            </mat-chip-list>
          </div>
        </div>

        <!-- SAVE -->
        <div *ngIf="!isApproveView && template?.state !== 'PENDING'" fxLayout="row" fxFlexAlign="end"
          style="margin-bottom: 20px;">
          <button mat-flat-button color="primary" [disabled]="!smsForm.valid" (click)="onSaveSms()">{{"TEMPLATE.SAVE" |
            translate | uppercase}}</button>
        </div>
        <!-- READ -->
        <div *ngIf="isApproveView && !template?.isSmsRead" fxLayout="row" fxFlexAlign="end"
          style="margin-bottom: 20px;">
          <button mat-flat-button color="primary" (click)="onRead(template, 'SMS')">{{"TEMPLATE.READ" |
            translate}}</button>
        </div>
      </div>
    </div> <!-- FINE SMS FORM -->

    <!-- PAGE FORM -->
    <div fxLayout="column" [formGroup]="pageForm" *ngIf="channel === 'PAGE'" style="width: 100%; height: 100%;">
      <div fxLayout="column" fxLayoutAlign="space-between" style="width: 100%; height: 100%;">
        <div fxLayout="column" style="width: 100%;">
          <!-- LANGUAGE -->
          <div fxLayout="column" style="margin-top:-20px;">
            <div class="fakeLabel">{{"TEMPLATE.LANGUAGE" | translate}}</div>
            <mat-form-field class="templateInput">
              <mat-select formControlName="language">
                <mat-option value="it">{{"LANGUAGE.ITALIAN" | translate}}</mat-option>
                <mat-option value="en">{{"LANGUAGE.ENGLISH" | translate}}</mat-option>
                <mat-option value="fr">{{"LANGUAGE.FRENCH" | translate}}</mat-option>
                <mat-option value="de">{{"LANGUAGE.GERMAN" | translate}}</mat-option>
                <mat-option value="es">{{"LANGUAGE.SPANISH" | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- PAGE TITLE -->
          <div fxLayout="column" [ngStyle]="{'margin-top': pageForm.controls.title?.value ? '-20px' : '0'}">
            <div class="fakeLabel" *ngIf="pageForm['controls'].title?.value">{{"TEMPLATE.PAGETITLE" | translate}}</div>
            <mat-form-field style="width: 100%;" class="templateInput">
              <mat-label *ngIf="!pageForm['controls'].title?.value">{{"TEMPLATE.PAGETITLE" | translate}}</mat-label>
              <input matInput formControlName="title" required>
            </mat-form-field>
          </div>

          <!-- EDITOR HTML/ PAGE -->
          <div style="margin: 20px 0;">
            <div class="titoloFiltro" style="margin-bottom: 10px;">{{"TEMPLATE.EDITORHTML" | translate}}</div>
            <mat-tab-group [(selectedIndex)]="tabIndex">
              <mat-tab label='{{"TEMPLATE.EDITOR_BASE" | translate}}'>
                <ng-template matTabContent>
                  <ck-editor #ckPageEditor name="editor-2" formControlName="content" [config]="config"
                    [fullPage]="true"></ck-editor>
                </ng-template>
              </mat-tab>
              <mat-tab label='{{"TEMPLATE.EDITOR_AVANZATO" | translate}}'>
                <ng-template matTabContent>
                  <ngx-codemirror #pageMirror formControlName="content"
                    [options]="{lineNumbers: true, theme: 'material-darker', mode: 'htmlmixed'}"></ngx-codemirror>
                </ng-template>
              </mat-tab>
            </mat-tab-group>

            <div style="width: 100%; margin-top: 25px;">
              <mat-chip-list aria-label="Variable selection">
                <mat-chip color="primary" class="copy" selected *ngFor="let word of placeholders"
                  (click)="addChipToPageEditor(variablesSchema[word].value)">{{ variablesSchema[word].label }}
                </mat-chip>
                <mat-chip color="primary" selected (click)="addDefaultContentToPage()">{{"TEMPLATE.DEFAULT_PAGE" |
                  translate}}</mat-chip>
              </mat-chip-list>


            </div>
          </div>
        </div>

        <!-- SAVE -->
        <div *ngIf="!isApproveView && template?.state !== 'PENDING'" fxLayout="row" fxFlexAlign="end"
          style="margin-bottom: 20px;">
          <button mat-flat-button color="primary" [disabled]="!pageForm.valid"
            (click)="onSaveEmailPage()">{{"TEMPLATE.SAVE" | translate | uppercase}}</button>
        </div>
        <!-- READ -->
        <div *ngIf="isApproveView && !template?.isPageRead" fxLayout="row" fxFlexAlign="end"
          style="margin-bottom: 20px;">
          <button mat-flat-button color="primary" (click)="onRead(template, 'PAGE')">{{"TEMPLATE.READ" |
            translate}}</button>
        </div>
      </div>
    </div> <!-- FINE PAGE FORM -->

  </div>

  <!-- COLONNA DESTRA -->
  <div class="flex-col" id="preview-col">

    <div class="titoloFiltro" style="margin: 20px 0 50px 0;">{{"TEMPLATE.PREVIEW" | translate}}</div>

    <!-- PREVIEW EMAIL/SMS/PAGE -->
    <div class="preview">
      <div *ngIf="channel !== 'SMS'">
        <iframe title="Template Preview"
          [srcdoc]="(channel === 'EMAIL' ? emailForm.controls.content?.value : pageForm.controls.content?.value) | safe : 'html'"
          seamless="seamless" style="width: 100%; height: 700px; border: none;"></iframe>
      </div>
      <app-mobile-preview *ngIf="channel === 'SMS'" [smsText]="smsText">
      </app-mobile-preview>
    </div>

  </div>

</div>
