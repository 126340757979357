export class Content {
  _id: string;
  name: string;
  content: string;
}

export class MultiLanguageContent {
  it: string;
  en: string;
  fr: string;
  de: string;
  es: string;
}

export enum Type {
  supplier = 'SUPPLIER',
  customer = 'CUSTOMER',
}

export enum Disposition {
  payment = 'PAYMENT',
  outcome = 'OUTCOME',
}

export class Template {
  _id: string;
  name: string;
  short_description: string;
  description: string;
  action: string;
  subject: string;
  from_name: string;
  content: Array<any>;
  merchant: string;
  active: boolean;
  state: string;
  email: {
    subject: MultiLanguageContent;
    content: MultiLanguageContent;
    simpleText: MultiLanguageContent;
  };
  sms: {
    sender: MultiLanguageContent;
    content: MultiLanguageContent;
  };
  page: {
    title: MultiLanguageContent;
    content: MultiLanguageContent;
  };
  class: string;
  isEmailRead: boolean;
  isSmsRead: boolean;
  isPageRead: boolean;

  constructor(data: any, merchant: string) {
    this.name = data.name;
    this.short_description = data.short_description;
    this.description = data.description;
    this.active = false;
    this.from_name = 'Clicpay';
    this.merchant = merchant;
    this.state = data.state;
    this.class = 'CUSTOM';
  }
}
