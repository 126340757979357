<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltroPrincipale">
    <mat-accordion>
      <mat-expansion-panel style="margin-bottom: 40px !important">
        <mat-expansion-panel-header>

          <mat-panel-title class="titoloFiltro">
                  {{ 'PROFILO.AVAILABLETEMPLATE' | translate}}
          </mat-panel-title>

        </mat-expansion-panel-header>

        <app-mail-template #availableTemplates></app-mail-template>

      </mat-expansion-panel>
    </mat-accordion>
</div>

<!--ACCORDION TEMPLATE -->

<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro">
        <mat-accordion class="mediaQMatExpansion">
                <mat-expansion-panel style="margin-bottom: 40px !important" [expanded]="true">
                        <mat-expansion-panel-header>
                                <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro" ngClass.lg="titoloFiltro"
                                ngClass.xl="titoloFiltro">
                                        {{'PROFILO.TITOLO6FILTRO' | translate}}
                                </mat-panel-title>
                        </mat-expansion-panel-header>

                        <app-template-form
                        [isApproveView]="true"
                        [merchant]="merchant"
                        (updateTemplates)="onTemplatesUpdate($event)">
                        </app-template-form>

                </mat-expansion-panel>
        </mat-accordion>
</div> <!--FINE ACCORDION TEMPLATE-->
