import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from '../../../../_services/auth.service';
import { Merchant } from '../../../../_models/merchant';
import { MailTemplateComponent } from '../merchantTechnicalData/mail-template/mail-template.component';
import { MerchantService } from '../../../../_services/merchant.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit, OnDestroy {
  @ViewChild('availableTemplates') availableTemplates: MailTemplateComponent;

  merchant: Merchant;
  private merchantSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private merchantService: MerchantService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
       this.merchantService.getMerchant(params.id).subscribe();
      }
    });
    this.merchantSubscription = this.merchantService.currentMerchant$.subscribe(
      value => this.merchant = value
    )
  }

  ngOnDestroy() {
    this.merchantSubscription.unsubscribe();
    if (this.merchantService.isAdminMode) {
      this.merchantService.currentMerchant = null;
    }
  }

  onTemplatesUpdate(event: undefined) {
    this.availableTemplates.getTemplates();
  }

}
