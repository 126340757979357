// eslint-disable-next-line no-restricted-imports
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
  private _generalLoader = new Subject<boolean>();
  private isStartGeneral = false;
  public generalLoader$ = this._generalLoader.asObservable();

  constructor() { }

  public startGeneral() {
    if (this.isStartGeneral) { return; }
    this.isStartGeneral = true;
    this._generalLoader.next(true);
  }

  public stopGeneral() {
    if (!this.isStartGeneral) { return; }
    this.isStartGeneral = false;
    this._generalLoader.next(false);
  }
}
