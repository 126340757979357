import { Directive, HostBinding, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appOptHover]'
})
export class OptHoverDirective {
  @Input() value: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {  }

  @HostListener('mouseover') onMouseOver() {
    const check = this.el.nativeElement.querySelector('.checkedIcon');
    this.renderer.setStyle(check, 'display', 'inline-block');
  }

  @HostListener('mouseout') onMouseOut() {
    const check = this.el.nativeElement.querySelector('.checkedIcon');
    if (!this.value) {
      this.renderer.setStyle(check, 'display', 'none');
    }
  }

  /* @HostListener('click') onClick() {
    const check = this.el.nativeElement.querySelector('.checkedIcon');
    if (!this.value) {
      this.renderer.setElementStyle(check, 'display', 'none');
    }
  } */

}
