import { MerchantService } from '../../../_services/merchant.service';
import { LoaderService } from '../../../_services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from './../../../_services/dialog.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Template } from '../../../_models/template';
import { TemplateService } from '../../../_services/template.service';
import { CKEditorComponent } from 'ngx-ckeditor';
import { AuthService } from '../../../_services/auth.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { DEFAULT_TEMPLATE_PAGE } from '../../../_helpers/default-template_page';
import { DEFAULT_TEMPLATE_EMAIL } from '../../../_helpers/default-template-email';

@Component({
  selector: 'app-template-code',
  templateUrl: './template-code.component.html',
  styleUrls: ['./template-code.component.scss']
})
export class TemplateCodeComponent implements OnInit {

  @ViewChild('ckEmailEditor') ckEmailEditor: CKEditorComponent;
  @ViewChild('ckPageEditor') ckPageEditor: CKEditorComponent;
  @ViewChild('emailMirror') emailMirror: CodemirrorComponent;
  @ViewChild('pageMirror') pageMirror: CodemirrorComponent;


  @Input() template: Template;
  @Input() set channel(value: string) {
    this._channel = value;
  }
  @Input() isApproveView: boolean;
  @Output() savedTemplate = new EventEmitter();

  _channel: string;
  toDateString: string = '{{to_date}}';


  get channel() {
    return this._channel;
  }

  config = {
    readOnly: 'true',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] }
    ],
    removeButtons: 'Save,NewPage,Preview,Print,Templates,Find,SelectAll,Scayt,CopyFormatting,RemoveFormat,Language,Flash,About,BidiLtr,BidiRtl,Cut,Copy,Paste,PasteText,PasteFromWord,Replace,Anchor,CreateDiv,Blockquote,Outdent,Indent,NumberedList,BulletedList,Maximize,ShowBlocks',
    resize_enabled: false,
    height: '250px',
  };

  templateCodeForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  smsForm: UntypedFormGroup;
  pageForm: UntypedFormGroup;
  variablesSchema: any;
  placeholders: string[] = [];
  selectionStart: number;
  customSender = false;
  tabIndex = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private templateService: TemplateService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private authService: AuthService,
    private merchantService: MerchantService,
  ) {
    this.initializeTemplateCodeForm();
    this.initializeEmailForm();
    this.initializeSmsForm();
    this.initializePageForm();
    this.variablesSchema = this.templateService.variablesSchema;
    if (this.variablesSchema) {
      this.placeholders = Object.keys(this.variablesSchema);
    }
  }

  ngOnInit() {
    if (!this.template) { return; }
    this.templateCodeForm.patchValue(this.template);
    this.emailForm.controls.language.setValue('it');
    this.smsForm.controls.language.setValue('it');
    this.pageForm.controls.language.setValue('it');

    this.setSmsSender();
  }

  get f() {
    return this.templateCodeForm.controls;
  }

  get smsText() {
    return this.smsForm.controls.content.value;
  }

  initializeTemplateCodeForm() {
    this.templateCodeForm = this.formBuilder.group({
      name: [{ value: null, disabled: true }],
      short_description: [{ value: null, disabled: true }],
      description: [{ value: null, disabled: true }],
      from_name: [{ value: null, disabled: true }],
    });
  }

  initializeEmailForm() {
    this.emailForm = this.formBuilder.group({
      language: [''],
      subject: ['', [Validators.required]],
      content: ['', [Validators.required]],
      text: ['', [Validators.required]],
    });
    this.emailForm.controls.language.valueChanges.subscribe(
      value => {
        if (!value) { return; }
        this.showContentByLanguage(value, this.emailForm, 'email');
      }
    );
  }

  initializeSmsForm() {
    this.smsForm = this.formBuilder.group({
      language: [''],
      sender: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });
    this.smsForm.controls.language.valueChanges.subscribe(
      value => {
        if (!value) { return; }
        this.showContentByLanguage(value, this.smsForm, 'sms');
      }
    );
  }

  initializePageForm() {
    this.pageForm = this.formBuilder.group({
      language: [''],
      title: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });
    this.pageForm.controls.language.valueChanges.subscribe(
      value => {
        if (!value) { return; }
        this.showContentByLanguage(value, this.pageForm, 'page');
      }
    );
  }

  showContentByLanguage(lang: string, form: UntypedFormGroup, type: string) {
    const tempContent = this.template[type];
    if (!tempContent) { return; }
    const keys = Object.keys(tempContent);
    keys.forEach(key => {
      const newValue = tempContent[key][lang];
      const ctrl = form.get(key);
      if (ctrl) {
        ctrl.setValue(newValue ? newValue : ''); // controllo per evitare di stampare UNDEFINED a vista
      }
    });
  }

  onSaveEmailPage() {
    const body = this.channel === 'EMAIL' ? this.emailForm.value : this.pageForm.value;
    body.merchant = this.template.merchant;
    body.type = this.channel.toLowerCase();
    this.loaderService.startGeneral();
    this.templateService.addContentEmailPage(this.template._id, body).subscribe(
      (response: Template) => {
        if (response.state !== 'EDIT') {
          this.setStateToEdit();
          return;
        }
        this.loaderService.stopGeneral();
        this.getTemplateUpdated(response);
        this.dialogService.openDialogPRIMARY('', 'DIALOG.SUCCESS_TEMPLATE_SAVED');
      },
      error => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  onSaveSms() {
    const lang = this.smsForm.value.language;
    const body = {
      sender: {},
      content: {}
    };
    if (this.template.sms && this.template.sms.sender) {
      body.sender = this.template.sms.sender;
    }
    if (this.template.sms && this.template.sms.content) {
      body.content = this.template.sms.content;
    }

    body.sender[lang] = this.customSender ? this.smsForm.value.sender : 'ClicPay';
    body.content[lang] = this.smsForm.value.content;

    this.loaderService.startGeneral();

    this.templateService.addContentSms(this.template._id, body).subscribe(
      (response: Template) => {
        response.merchant = this.template.merchant;  //RIASSEGNAZIONE NECESSARIA IN QUANTO LA PUT RESTITUISCE IL MERCHANT COME UN OGGETTO E NON COME UNA STRINGA
        this.loaderService.stopGeneral();
        this.getTemplateUpdated(response);
        this.dialogService.openDialogPRIMARY('', 'DIALOG.SUCCESS_TEMPLATE_SAVED');
      },
      error => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  getTemplateUpdated(updatedTemplate: Template) {
    this.template = updatedTemplate;
    this.savedTemplate.emit(updatedTemplate);
  }

  setStateToEdit() {
    this.templateService.update(this.template._id, { state: 'EDIT' })
      .subscribe(
        (response: Template) => {
          response.merchant = this.template.merchant; //RIASSEGNAZIONE NECESSARIA IN QUANTO LA PUT RESTITUISCE IL MERCHANT COME UN OGGETTO E NON COME UNA STRINGA
          this.loaderService.stopGeneral();
          this.getTemplateUpdated(response);
          this.dialogService.openDialogPRIMARY('', 'DIALOG.SUCCESS_TEMPLATE_SAVED');
        },
        error => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
  }

  updateSelection(event: any) {
    this.selectionStart = event.target.selectionStart;
  }

  addChipToEmailEditor(text: string) {
    if (this.tabIndex === 0) {
      this.ckEmailEditor.instance.insertHtml('<span>' + text + '</span>');
    } else {
      const from = this.emailMirror.codeMirror.getCursor();
      this.emailMirror.codeMirror.replaceRange('<span>' + text + '</span>', from);
    }
  }
  addChipToPageEditor(text: string) {
    if (this.tabIndex === 0) {
      this.ckPageEditor.instance.insertHtml('<span>' + text + '</span>');
    } else {
      const from = this.pageMirror.codeMirror.getCursor();
      this.pageMirror.codeMirror.replaceRange('<span>' + text + '</span>', from);
    }
  }

  addChipToTextArea(text: string, form: UntypedFormGroup, type: string) {
    let textBefore = this.selectionStart
      ? form.value[type].substring(0, this.selectionStart)
      : form.value[type].substring(0, form.value[type].length);
    let textAfter = this.selectionStart
      ? form.value[type].substring(this.selectionStart, form.value[type].length)
      : '';
    let completeText = textBefore + text + textAfter;
    form.controls[type].setValue(completeText);
    this.selectionStart += text.length;
  }

  addDefaultContentToEmail() {
    if (this.tabIndex === 0) {
      this.ckEmailEditor.instance.insertHtml(DEFAULT_TEMPLATE_EMAIL);
    } else {
      const from = this.emailMirror.codeMirror.getCursor();
      this.emailMirror.codeMirror.replaceRange(DEFAULT_TEMPLATE_EMAIL, from);
    }
  }

  addDefaultContentToPage() {
    if (this.tabIndex === 0) {
      this.ckPageEditor.instance.insertHtml(DEFAULT_TEMPLATE_PAGE);
    } else {
      const from = this.pageMirror.codeMirror.getCursor();
      this.pageMirror.codeMirror.replaceRange(DEFAULT_TEMPLATE_PAGE, from);
    }
  }

  setSmsSender() {
    this.customSender = this.merchantService.currentMerchant.isCustomTemplateSenderActive;
    if (!this.customSender) {
      this.smsForm.controls.sender.disable();
    }
  }

  onRead(template: Template, channel: string) {
    if (channel === 'EMAIL') {
      this.template.isEmailRead = true;
    }
    if (channel === 'SMS') {
      this.template.isSmsRead = true;
    }
    if (channel === 'PAGE') {
      this.template.isPageRead = true;
    }
  }

}
