import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { MerchantService } from './merchant.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient, 
    private merchantService: MerchantService,
    private authenticationService: AuthService,
  ) {}
  searchProfiles(objectsArray: object[], sort: string, skip: number, limit: number, populate: boolean = false) {
    let headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': this.authenticationService.token,
     'Accept-Language': 'it',
     'sort': sort,
     'limit': limit.toString(),
     'skip': (skip * limit).toString(),
     'populate': populate.toString()
    });

    let queryString: string = objectsArray.length > 0 ? '?' : '';

    objectsArray.forEach((query, index) => {
     let and = (index !== objectsArray.length - 1) ? '&' : '';
     queryString += query['key'] + '=' + query['value'] + and;
    });

    return this.http.get<any>('/clicpay/v1/profile'+  queryString, { headers });
  }

  getProfile(id: string) {
    let headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': this.authenticationService.token,
     'Accept-Language': 'it',
    });

    return this.http.get<any>('/clicpay/v1/profile/' +  id + '?merchant=' + this.merchantService.currentMerchant._id , { headers });
  }

  create(profile: any) {
    let headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': this.authenticationService.token,
     'Accept-Language': 'it',
    });

    return this.http.post<any>('/clicpay/v1/profile/', profile, { headers });
  }

  update(id: string, profile: any) {
    let headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': this.authenticationService.token,
     'Accept-Language': 'it',
    });
    return this.http.put<any>('/clicpay/v1/profile/' + id, profile, { headers });
  }

  delete(_id: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authenticationService.token);
    headers = headers.set('Accept-Language', 'it');
    return this.http.delete('/clicpay/v1/profile/' + _id, { headers })
  }

}
