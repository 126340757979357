import { UntypedFormControl, FormGroupDirective, NgForm, AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class ErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


export function matchingFields(matchField: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
    let field = null;
    if (control.root.value) {
    field = control.root.value[matchField];
    }
    const confirmField = control.value;
    const isEqualField = (field !== confirmField);
    return isEqualField ? { 'matchingFields': { confirmField } } : null;
    };
}


export function matchingFieldsDiverso(matchField: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
    let field = null;
    if (control.root.value) {
    field = control.root.value[matchField];
    }
    const confirmField = control.value;
    const isEqualField = (field === confirmField);
    return isEqualField ? { 'matchingFieldsDiverso': { confirmField } } : null;
    };
}
