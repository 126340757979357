<div fxLayout [class.disabled]="isDisabled">
    <div *ngIf="step !== 0" style="position: relative;">
        <div class="top-arrow-left"></div>
        <div class="bottom-arrow-left"></div>
    </div>
    <!-- <button class="button_title" ngClass.gt-lg="button-min-width" [disabled]="isDisabled"
    mat-flat-button color="primary" (click)="toggleWizardEvent(step);"
    [disableRipple]="true">
        <span>{{label}}</span>
    </button> -->
    <div class="button_title" ngClass.gt-md="button-min-width">
        <span>{{label}}</span>
    </div>
    <div class="arrow-right"></div>
</div>