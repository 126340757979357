import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";

import { LoaderService } from "../_services/loader.service";
import { SupportService } from "../_services/support.service";
import { TranslateService } from "@ngx-translate/core";

import { AuthService } from "../_services/auth.service";
import { DialogService } from "../_services/dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogBookmarkComponent } from "../_components/dialog-bookmark/dialog-bookmark.component";
import { ThemeService } from "../_services/theme.service";
import {
  MatchValidator,
  MismatchErrorMatcher,
  MatchOldErrorMatcher,
} from "../_validators/match-validator";
import { customPasswordValidator } from "../_validators/custom-password-validator";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild("entraButton", { static: false }) entraButton: ElementRef;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  isBookMark: boolean = environment.MODALBOOKMARK;

  loginForm: UntypedFormGroup;
  firstAccessForm: UntypedFormGroup;
  forgotForm: UntypedFormGroup;

  mismatchErrorMatcher = new MismatchErrorMatcher();
  matchOldErrorMatcher = new MatchOldErrorMatcher();

  currentLang: string;

  clicPayAnimationConfiguration: Object;

  theme: string;

  userId: string;

  isHidden = true;
  isHiddenConfirm = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private supportService: SupportService,
    private themeService: ThemeService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.clicPayAnimationConfiguration = {
      path: environment.ASSETS_BASE_PATH + "json/clicpay.json",
      autoplay: true,
      loop: false,
      speed: 2,
    };
  }

  ngOnInit() {
    this.themeService.theme.subscribe((theme) => {
      this.theme = theme;
    });
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.id) {
        this.userId = params.id;
        this.buildFirstAccessForm();
      } else {
        this.buildLoginForm();
      }
    });
    this.checkLanguage();

    if (this.isBookMark) {
      this.dialog.open(DialogBookmarkComponent);
    }
  }

  checkLanguage() {
    // TODO Migliorare gestione traduzioni disponibili.
    const availableLangs = ["it", "en", "fr"];
    this.currentLang =
      availableLangs.indexOf(this.translateService.getBrowserLang()) > -1
        ? this.translateService.getBrowserLang()
        : "en";
  }

  buildLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/),
        ],
      ],
      password: [
        null,
        [
          Validators.required /* , Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(|=.*?[0-9])(|=.*?[#?!@$%^&*-]).{8,}$') */,
        ],
      ],
    });
  }

  buildFirstAccessForm() {
    this.firstAccessForm = this.fb.group(
      {
        password: [null, [Validators.required, customPasswordValidator]],
        confirm: [null, [Validators.required]],
      },
      {
        validators: [MatchValidator],
      }
    );
    this.loginForm = null;
  }

  buildForgotForm() {
    let currentMail: string | null = null;
    if (this.loginForm) {
      currentMail = this.loginForm.value.email;
    }
    this.forgotForm = this.fb.group({
      email: [
        currentMail,
        [
          Validators.required,
          Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/),
        ],
      ],
    });
    this.loginForm = null;
    this.firstAccessForm = null;
  }

  primoAccesso() {
    if (!this.firstAccessForm.valid) {
      this.entraButton.nativeElement.disabled = true;
      return;
    }
    this.loaderService.startGeneral();
    this.authService
      .activate(this.userId, this.firstAccessForm.value)
      .subscribe(
        (data) => {
          this.loaderService.stopGeneral();
          this.router.navigate(["/login"]);
        },
        (error) => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN(
            "DIALOG.GENERIC_ERROR_TITLE",
            "DIALOG.GENERIC_ERROR_MESSAGE",
            error.status
          );
        }
      );
  }

  onLogin() {
    this.authenticate(
      this.loginForm.value.email,
      this.loginForm.value.password
    );
  }

  authenticate(email: string, password: string) {
    this.loaderService.startGeneral();
    this.authService.login(email, password).subscribe(
      (response: any) => {
        this.translateService.currentLang =
          response.user.language.toLowerCase();
        this.router.navigate(["merchant"]);
        this.loaderService.stopGeneral();
      },
      (error) => {
        this.loaderService.stopGeneral();
        if (error.status === 401) {
          this.dialogService.openDialogWARN(
            "CONTROLLA.ERROR",
            "LOGIN.CLICCA_RECUPERA_PASSWORD"
          );
          return;
        }
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }

  richiestaRecuperoPass() {
    this.loaderService.startGeneral();
    this.authService.recuperaPassword(this.forgotForm.value.email).subscribe(
      (data) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogPRIMARY(
          "",
          "LOGIN.PWD_TEMPORANEA"
        );
        this.forgotForm = null;
        this.buildLoginForm();
      },
      (error) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN(
          "LOGIN.ATTENZIONE",
          "LOGIN.EMAIL_NOT_FOUND"
        );
      }
    );
  }

  goBack() {
    this.forgotForm = null;
    this.buildLoginForm();
  }

  createSupportRequest(event: { description: string; email: string }) {
    this.supportService.createAnonymous(event).subscribe(
      (response) => {
        this.dialogService.openDialogPRIMARY(
          "DIALOG.SUCCESS_TITLE",
          "DIALOG.GENERIC_SUCCESS_MESSAGE"
        );
      },
      (error) => {
        console.log(
          "Errore nella creazione della richiesta di supporto",
          error
        );
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }

  // GETTERS
  get firstCtrl() {
    if (!this.firstAccessForm) {
      return null;
    }
    return this.firstAccessForm.controls;
  }
}
