
<mat-accordion>
    <mat-expansion-panel class="barraFiltro" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="titoloFiltro">
            Bulk Merchant Configuration Upload
          </mat-panel-title>
        </mat-expansion-panel-header>
        
        <div fxLayout="row" class="rightInputField">
    
            <mat-form-field class="inputDiv">
              <input matInput [formControl]="logoFile" readonly
                placeholder="Upload CSV">
            </mat-form-field>
            <div class="spinnerContainer" [style.display]='spinnerdisplay'>
              <mat-progress-spinner color='primary' mode='indeterminate' diameter='30'></mat-progress-spinner>
            </div>
        
      
        <div fxLayout="row" fxLayoutAlign.xl="end center" fxLayoutAlign.xl="end" fxLayoutAlign.lg="end"
        fxLayoutAlign.md="end" fxLayoutAlign.sm="center" fxLayoutAlign.xs="center">
            <input #inputfile type="file" ng2FileSelect [uploader]="uploader" style="display: none;" />
        </div>
        <div fxLayout="column" fxLayoutAlign="space-evenly end">
            <button mat-flat-button color="primary" class="rounded-button buttonDialog" (click)="onFileUploadClick()" style="margin: 0px 20px 40px 10px">UPLOAD</button>
        </div>
    </div>
    </mat-expansion-panel>
</mat-accordion>

  
