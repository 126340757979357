import { User } from "../_models/user";
import { AbstractControl } from "@angular/forms";

export function UserEmailValidator (list: User[]) {
  return function validateUserEmail (control: AbstractControl) {
    const index = list.findIndex((el: User) => el.email === control.value);
    if (index !== -1) {
      return { 'email_taken': true };
    }
    return null;
  }
}
