import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  ElementRef,
  QueryList,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterLinkActive,
} from "@angular/router";
import { AuthService } from "../../_services/auth.service";
import { MerchantService } from "../../_services/merchant.service";
import { filter, map } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tab-menu",
  templateUrl: "./tab-menu.component.html",
  styleUrls: ["./tab-menu.component.scss"],
})
export class TabMenuComponent implements OnInit, OnDestroy {
  @ViewChildren("blocks", { read: ElementRef }) myBlocks: QueryList<ElementRef>;
  @ViewChild("myMenu", { static: true }) myMenu: ElementRef;
  blocks: Array<any> = [];

  active: string;
  id: string = "";

  routeSubscription: Subscription;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private authService: AuthService,
    private merchantService: MerchantService,
    private activatedRoute: ActivatedRoute
  ) {
    this.iconRegistry.addSvgIcon(
      "bulk-arrow-menu",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/bulk-arrow-menu.svg"
      )
    );
  }

  ngOnInit() {
    this.generateMenu();
    let firstRoute = this.getFirstRoute(this.activatedRoute);
    this.active = firstRoute.snapshot.url[0]
      ? firstRoute.snapshot.url[0].path
      : "";
    this.id = firstRoute.snapshot.params.id
      ? "/" + firstRoute.snapshot.params.id
      : "";

    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => this.getFirstRoute(route)))
      .pipe(filter((route) => route.outlet === "primary"))
      .subscribe((route) => {
        this.id = route.snapshot.params.id
          ? "/" + route.snapshot.params.id
          : "";
        this.active = route.snapshot.url[0].path;
        this.scrollToActive();
      });
  }

  ngOnDestroy() {
    this.routeSubscription && this.routeSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.scrollToActive();
  }

  scrollToActive() {
    const activeIx = this.blocks.findIndex((el) => el === this.active);
    const myBlock: ElementRef = this.myBlocks.find((_, i) => i === activeIx);
    if (!myBlock) {
      return;
    }
    let offset = myBlock.nativeElement.offsetLeft;
    offset = activeIx % 2 === 0 ? offset - 80 : offset - 40;
    this.myMenu.nativeElement.scrollTo(offset, 0);
  }

  getFirstRoute(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }


  generateMenu() {
    const availablePaths = ["single"];
    if (
      this.merchantService.currentMerchant &&
      this.merchantService.currentMerchant.isRecurrentActive
    ) {
      availablePaths.push(...["recurrent"]);
    }
    if (
      this.merchantService.currentMerchant &&
      this.merchantService.currentMerchant.isDeferredActive
    ) {
      availablePaths.push(...["deferred"]);
    }
    if (
      this.merchantService.currentMerchant &&
      this.merchantService.currentMerchant.isImportMassivelyActive &&
      this.authService.user.isBulkActive
    ) {
      availablePaths.push("import");
    }
    this.blocks = [...availablePaths];

    for (let i = availablePaths.length; i < 6; i++) {
      this.blocks.push("");
    }
  }
}
