import { OverlayRef } from '@angular/cdk/overlay';

export class DetailOverlayRef {
    constructor(
        private overlayRef: OverlayRef
    ) {}

    close() {
        this.overlayRef.dispose();
    }

    afterClosed() {
        return this.overlayRef.detachments();
    }
}