import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
// eslint-disable-next-line no-restricted-imports
import { Observable,  BehaviorSubject } from 'rxjs';
import { tap, map, finalize } from 'rxjs/operators';


import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _token: string = null;
  private _user: BehaviorSubject<User>;
  user$: Observable<User>;

  private readonly AUTHENTICATION_URL: string = environment.BASE_URL + 'authenticate';
  private readonly FORGOT_URL: string = environment.BASE_URL + 'forgot';
  private readonly RESET_URL: string = environment.BASE_URL + 'reset';
  private readonly ACTIVATE_URL: string = environment.BASE_URL + 'activate/';
  private readonly LOGOUT_URL: string = environment.BASE_URL + 'logout';
  private readonly CHANGE_URL: string = environment.BASE_URL + 'change';
  private readonly UPDATE_URL: string = environment.BASE_URL + 'update';
  private readonly ME_URL: string = environment.BASE_URL + 'me';



  constructor(private http: HttpClient) {
    this._user = new BehaviorSubject<User>(null);
    this.user$ = this._user.asObservable();
  }

  public login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept-Language', 'it');
    const params = new HttpParams().set('populate', 'true');
    return this.http.post<any>(this.AUTHENTICATION_URL, { email: email, password: password }, { headers, params })
    .pipe(
      tap(
        response => {
          this._token = response['token'];
          this._user.next(response['user'] as User);
          //this.setCookies();
          localStorage.setItem('clicpay-token', this._token);
        }
      )
    )

  }

  /* setCookies() {
    this.cookieService.set( 'clicpay-token', this._token, 1, '/', '', environment.production);
    this.cookieService.set( 'clicpay-user', this.user._id, 1, '/', '', environment.production);
  } */
  

  get token () {
    return this._token;
  }

  get user () {
    return this._user.value;
  }

  set token(token: string) {
    this._token = token;
  }

  set user(user: User) {
    this._user.next(user);
  }

  change(_id: string, password: string, confirm: string ): Observable <{} | any[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.token);
    headers = headers.set('Accept-Language', 'it');

    // eslint-disable-next-line max-len
    return this.http.put(this.CHANGE_URL + '/' + _id, { password: password, confirm: confirm}, { headers });
  }

  

  public logout(): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this._token);
    return this.http.get<boolean>(this.LOGOUT_URL, { headers })
    .pipe(
      finalize(
        () => {
          this._token = null;
          this._user = null;
          localStorage.removeItem('clicpay-token');
          localStorage.removeItem('clicpay-merchant');
          sessionStorage.removeItem('selfEnrollmentHasBeenVisited');
          location.reload()
        },
      )
    );
  }

  public recuperaPassword(email: string): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<boolean>(this.FORGOT_URL, { email: email }, { headers })
  }

  
  updatePassword(_id: string, oldPassword: string, newPassword: string ): Observable <{} | any[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.token);
    headers = headers.set('Accept-Language', 'it');
    return this.http.put(this.UPDATE_URL + '/' + _id, { password: oldPassword, confirm: newPassword}, { headers })
  }

  public reset(body: any): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept-Language', 'it');
    return this.http.post<boolean>(this.RESET_URL, body, { headers })
    .pipe(
      tap(
        response => {
          this._user.next(response['user'] as User);
          this._token = response['token'];
        }
      )
    );
  }

  public activate(id: string, body: any): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept-Language', 'it');
    return this.http.put<boolean>(this.ACTIVATE_URL + id, body, { headers });
  }

  updateMe(user: User): Observable <User> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.put<any>(this.ME_URL, user, { headers }).pipe(
      map(
        value => {
          this._user.next(value.item as User);
          return value.item as User;
        }
      )
    )
  }

  getMe(){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.token);
    headers = headers.set('Accept-Language', 'it');
    return this.http.get<any>(this.ME_URL, { headers }).pipe(
      map(
        value => {
          this._user.next(value.item as User);
          return value.item as User;
        }
      )
    )
  }

}
