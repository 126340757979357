<div style="padding: 0 20px;">
  <!-- MENU WIZARD -->
  <app-payment-wizard [modPayment]="null" (enableForm)="enableAccordion($event)" [isImport]="true"
    (wizardValue)="getWizardData($event)"></app-payment-wizard>

  <mat-card style="padding-top: 32px;">
    <form [formGroup]="bulkForm" fxLayout="column" fxLayoutAlign="space-around stretch">

      <mat-expansion-panel [disabled]="isLocked" [expanded]="!isLocked" style="margin-bottom: 20px !important;"
        class="borderStyleAcc">
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{'RICHIEDIPAGAMENTOAVANZATO.INSERISCIFILEDIPAGAMENTO' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex-row" style="margin-top: 30px;">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'RICHIEDIPAGAMENTO.DESCRIZIONE' | translate}}"
                formControlName="description" required>
              <mat-error *ngIf="bulkForm.controls['description'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input matInput [min]="minDate" readonly [matDatepicker]="picker"
                placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.DATA' | translate}}" formControlName="scheduledStartDate"
                (click)="picker.open()" required>
              <mat-error *ngIf="bulkForm.controls['scheduledStartDate'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign.xl="end center" fxLayoutAlign.xl="end" fxLayoutAlign.lg="end"
          fxLayoutAlign.md="end" fxLayoutAlign.sm="center" fxLayoutAlign.xs="center">
          <!--SFOGLIA BUTTON-->
          <div>
            <button mat-button [disabled]="!bulkForm.valid" class="outline-button"
              (click)="onFileUploadClick()">{{'RICHIEDIPAGAMENTOAVANZATO.BOTTONESFOGLIA'| translate}}</button>
            <input #inputfile type="file" ng2FileSelect [uploader]="uploader" style="display: none;" />
          </div>
          <!--DOWNLOAD BUTTON-->
          <div fxLayout='column' style="margin-left: 20px;">
            <a class="buttonGreenNormalDownload"
              (click)='downloadTemplate()'>{{'RICHIEDIPAGAMENTOAVANZATO.DOWNLOAD_TEMPLATE' | translate}}</a>
          </div>
        </div>
      </mat-expansion-panel>

    </form>

  </mat-card>
</div>