<div #myMenu fxLayout class='blockContainer'>
  <div class="blockWrapper" *ngFor='let block of blocks; index as i'>
    <a *ngIf="block" fxLayout='column'  
      [routerLink]="'./' + (block === 'import' ? block + id : block)" 
      routerLinkActive="currentblockDiv" 
      class="blockDiv" fxLayoutAlign="center center">
        <div #blocks fxLayout="row" fxLayoutAlign="center center">
          <span class="blockTitle">
          {{ 'BLOCK_MENU.RECORD_' + (block | uppercase) | translate}}</span>
        </div>
        <div *ngIf='block' class="arrowDown"></div>
    </a>
    <div *ngIf="!block" class="blockDiv"></div>
  </div>
</div>