

// eslint-disable-next-line no-restricted-imports
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Customer } from '../_models/customer';
import { AuthService } from './auth.service';
import { MerchantService } from './merchant.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerService {
  private readonly CUSTOMER_URL: string = environment.BASE_URL + 'customer';
  private readonly UPLOAD_URL = environment.BASE_URL + 'customer/csv/';


  public customer: Customer = new Customer();
  public modCustomer: boolean;

  constructor(
    private http: HttpClient, 
    private authService: AuthService,
    private merchantService: MerchantService,
   ) { }
  /**
   * Metodo per recuperare la lista dei Customer
   * @param customer (string) - nome del Customer per effetture la ricerca
   */
  list(customer: string): Observable <{} | Customer[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');


    let params = new HttpParams();
    if (customer != null && customer !== undefined && customer !== '') {
      params = params.set('_id', customer);
    }

    if (this.authService.user.type == "HOLDING"){
      params = params.set('merchant', this.merchantService.currentMerchant._id);
    }

    params = params.set('sort', '-createdAt');
    return this.http.get<{} | Customer[]>(this.CUSTOMER_URL, { headers, params });
  }
  listOrder(value: string): Observable <{} | Customer[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');


    let params = new HttpParams();
    params = params.set('sort', value );
    return this.http.get<{} | Customer[]>(this.CUSTOMER_URL, { headers, params });
  }





  listMerchant(idMerchant: string): Observable <{} | Customer[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let params = new HttpParams();
    params = params.set('merchant', idMerchant);
    params = params.set('sort', '-createdAt');

    return this.http.get<{} | Customer[]>(this.CUSTOMER_URL, { headers, params });
  }

  listMerchantOrder(idMerchant: string, value: string): Observable <{} | Customer[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let params = new HttpParams();
    params = params.set('merchant', idMerchant);
    params = params.set('sort', value );

    return this.http.get<{} | Customer[]>(this.CUSTOMER_URL, { headers, params });
  }
  /**
   * Metodo per creare un nuovo Customer
   * @param customer Model Customer del cliente
   */
  create(customer: Customer): Observable <{} | Customer> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.post(this.CUSTOMER_URL, customer, { headers });
  }

  /**
   * Metodo per aggiornare le informazioni di un Customer (Cliente)
   * @param _id identificativo univoco dell'oggetto Customer (Cliente)
   * @param customer oggetto Customer da aggiornare
   */
  update(_id: string, customer: any): Observable <{} | Customer[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.put(this.CUSTOMER_URL + '/' + _id, customer, { headers });
  }


  get(idMerchant: string, limit: number, skip: number, sort: string): Observable <{} | Customer[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let params = new HttpParams();
    params = params.set('merchant', idMerchant);
    params = params.set('skip', limit && skip ? (skip * limit).toString() : '');
    params = params.set('limit', limit ? limit.toString() : '');
    params = params.set('sort', sort);

    return this.http.get<{} | Customer[]>(this.CUSTOMER_URL, { headers, params });
  }

  getById(id: string): Observable <Customer> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');


    let params = new HttpParams();

    if (this.authService.user.type == "HOLDING"){
      params = params.set('merchant', this.merchantService.currentMerchant._id);
    }

    return this.http.get<any>(this.CUSTOMER_URL + '/' + id, { headers, params }).pipe(
      map(response => response.item)
    )
  }

  upload(file) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    //headers = headers.set('Content-Type', 'multipart/form-data');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    return this.http.post<any>(this.UPLOAD_URL  + this.merchantService.currentMerchant._id, file, { headers });
  }

  download() {
    return this.http.get("assets/template/customer_import_template.csv", {responseType: 'blob'})
  }

  /**
   * Metodo per cambiare lo stato di un Customer.
   * @param _id identificativo univoco del Customer
   * @param active true o false per attivare / disattivare un Customer
   */
  // activate(_id: string, active: boolean): Observable <{} | Customer[]> {
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Content-Type', 'application/json');
  //   headers = headers.set('Authorization', this.authenticationService.token);

  //   return this.http.put(this.CUSTOMER_URL + '/activate/' + _id, { active : active }, { headers })
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }
  


}
