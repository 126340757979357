<div>
  <div class="flex-row" [ngClass]="{'selfRow': isSelfEnrollment===true}" style="align-items: center;">
    <div class="flex-col" [ngClass]="{'selfCol': isSelfEnrollment===true}">

      <mat-card class="flex-card-logo" [ngClass]="{'selfCard': isSelfEnrollment===true}">

        <div class="row" [ngClass]="{'selfRow': isSelfEnrollment===true}">
          <div mat-card-avatar class="flex-card-avatar" style="margin-left:10px;">
            <img *ngIf="imageURL" mat-card-image [src]="imageURL" alt="profile logo" class="flex-card-logo-image">
          </div>
          <div fxLayout="column" [ngClass]="{'selfInsegna': isSelfEnrollment===true}"
            fxLayoutAlign="space-around start">
            <span class="nomeCard">{{merchant?.name}}</span>
            <span class="dettaglioNomeCard">{{merchant?.description}}</span>
          </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: center;">
          <div style="width: fit-content;" class="positionIconSocial"
            [ngClass]="{'selfIconSocial': isSelfEnrollment===true}">
            <img alt="facebook logo" *ngIf="merchant?.facebook" [src]="ASSETS_BASE + 'svg/iconsvg/facebook-icon.svg'"
              class="iconSocial">
            <img alt="twitter logo" *ngIf="merchant?.twitter" [src]="ASSETS_BASE + 'svg/iconsvg/twitter-icon.svg'"
              class="iconSocial">
            <img alt="linkedin logo" *ngIf="merchant?.linkedin" [src]="ASSETS_BASE + 'svg/iconsvg/linkedin-icon.svg'"
              class="iconSocial">
            <img alt="website logo" *ngIf="merchant?.website" [src]="ASSETS_BASE + 'svg/iconsvg/web-icon.svg'"
              class="iconSocial">
          </div>
        </div>


      </mat-card>
    </div>

    <div class="flex-col" [ngClass]="{'selfCol': isSelfEnrollment===true}">
      <div fxLayout="row" fxLayoutAlign=" center" class="rightInputField"
        [ngClass]="{'selfInput': isSelfEnrollment===true}">

        <mat-form-field class="inputDiv">
          <input matInput [formControl]="logoFile" readonly="true"
            placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.ALLEGAFILE' | translate}}">
        </mat-form-field>
        <div class="spinnerContainer" [style.display]='spinnerdisplay'>
          <mat-progress-spinner color='primary' mode='indeterminate' diameter='30'></mat-progress-spinner>
        </div>



        <div class="buttonDiv" [ngClass]="{'selfBrowse': isSelfEnrollment===true}">
          <button mat-button class="outline-button"
            style="margin-bottom: 40px; width: 100%!important; max-width: 100px!important;"
            (click)="onFileUploadClick()">{{'PROFILO.SFOGLIA' | translate}}</button>
        </div>
        <input #inputfile type="file" ng2FileSelect [uploader]="uploader" style="display: none;" />
      </div>
    </div>
  </div>

  <div class="buttonRow" [ngClass]="{'selfSave': isSelfEnrollment===true}">
    <button mat-flat-button color="primary" [disabled]="!merchant || !merchant?._id" class="rounded-button"
      (click)=invia()>{{'PROFILO.FILTRO1BUTTON' | translate}}</button>
  </div>
</div>
