import { AlertType } from '../../_enum/alert-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../../_services/theme.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  title: string;
  message: string;
  code: string;
  type: string;
  color: string;
  colorNo: string;
  actionsActive = false;
  response: boolean;
  theme: string;
  buttonText: string;
  classCustom: string;
  paymentType: string = 'SINGLE';

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public translateService: TranslateService,
      private dialogRef: MatDialogRef<DialogComponent>,
      private themeService: ThemeService,
      private activatedRoute: ActivatedRoute,
  ) {

    if (data.title) {
      translateService.get(data.title).subscribe((result: string) => {
        this.title = result;
      });
    } else {
      this.title = undefined;
    }
    if (data.message) {
      translateService.get(data.message).subscribe((result: string) => {
        this.message = result;
      });
    }

    this.code = data.code;

    if (data.confirm) {
      this.buttonText = 'DIALOG.YES';
    } else {
      this.buttonText = 'DIALOG.CLOSE';
    }


    switch (data.type) {
      case AlertType.DELETE:
        this.type = 'ms-info ms-1x ms-primary';
        this.color = AlertType.PRIMARY;
        this.colorNo = AlertType.WARN;
        this.actionsActive = true;
        break;
      case AlertType.ACCENT:
        this.type = 'ms-warning ms-1x ms-accent';
        this.color = data.type;
        break;
      case AlertType.WARN:
        this.type = 'ms-warning ms-1x ms-warn';
        this.color = data.type;
        this.classCustom = 'buttonRedNormal';
        break;
      default:
        this.type = 'ms-info ms-1x ms-primary';
        this.color = data.type;
        break;
    }

  }

  actionPassed(action) {
    this.dialogRef.close(action);
  }

  checkRoute(route: string) {
   
  }

  ngOnInit() {
    if (this.data.paymentType) {
      this.paymentType = this.data.paymentType;
    }
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
  }

}
