import { TranslateService } from '@ngx-translate/core';
import { TemplateService } from '../../../_services/template.service';
import { OverlayService } from '../../../_services/overlay.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TemplateOverlayComponent } from '../../template-overlay/template-overlay/template-overlay.component';
import { DialogService } from '../../../_services/dialog.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { themeNameValidatorDirective } from '../../../_validators/theme-name-validator.directive';
import { Template } from '../../../_models/template';
import { LoaderService } from '../../../_services/loader.service';
import { AuthService } from '../../../_services/auth.service';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TemplateRejectDialogComponent } from '../../template-reject-dialog/template-reject-dialog.component';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss']
})
export class TemplateFormComponent implements OnInit {

  @Output() updateTemplates?: EventEmitter<undefined> = new EventEmitter();

  @Input() isApproveView?: boolean;

  _merchantId;
  @Input() set merchant (value) {
    if (!value) {return; }
    this._merchantId = value._id;
    if (this.isApproveView) {
      this.getPendingTemplates();
    } else {
      this.getListTemplates();
    }
  }
  get merchant() {
    return this._merchantId;
  }

  templatesList: Array<Template> = [];
  templateFormArray: Array<UntypedFormGroup> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogService,
    private overlayService: OverlayService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private templateService: TemplateService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {
    this.initializeIcons();
  }

  ngOnInit() {
   /*  if (this.isApproveView) {
      this.getPendingTemplates();
    } else {
      this.getListTemplates();
    } */
  }

  getListTemplates() {
    this.loaderService.startGeneral();
    let params = new HttpParams();
    params = params.set('merchant', this._merchantId);
    this.templateService.list(params)
    .subscribe(
      (data: Template[]) => {
        this.templatesList = data;
        this.initializeFormArray();
        this.loaderService.stopGeneral();
      }
    );
  }

  getPendingTemplates() {
    this.loaderService.startGeneral();
    let params = new HttpParams();
    params = params.set('merchant', this._merchantId);
    params = params.set('state', 'PENDING');
    this.templateService.list(params)
    .subscribe(
      (data: Template[]) => {
        this.templatesList = data;
        this.templatesList.forEach(el => {
          el.isEmailRead = false;
          el.isSmsRead = false;
          el.isPageRead = false;
        });
        this.initializeFormArray();
        this.loaderService.stopGeneral();
      }
    );
  }

  initializeIcons() {
    this.iconRegistry.addSvgIcon(
      'email', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/mail-editor-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'sms', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/sms-editor-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'page', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/page-editor-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'remove', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/minus-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'add', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/plus-icon.svg')
    );
  }

  openEditTemplate(template: Template, channel: string) {
    const data = {
      templateToEdit: template,
      channel: channel,
      isApproveView: this.isApproveView,
    };
    this.overlayService.open(TemplateOverlayComponent, data)
    .afterClosed().subscribe(
      observer => {
        if (!this.isApproveView) {
          this.getListTemplates();
        }
        this.updateTemplates.emit();
      }
    );
  }

  saveTemplate(i: number, template: Template) {
    const formValue = this.templateFormArray[i].value;
    template = Object.assign(template, formValue);
    this.loaderService.startGeneral();
    this.templateService.createNewTemplate(template).subscribe(
      (response) => {
        this.getListTemplates();
        this.dialogService.openDialogPRIMARY('', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.loaderService.stopGeneral();
      },
      (error) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  modifyTemplate(i: number, template: Template) {
    const formValue = this.templateFormArray[i].value;
    this.loaderService.startGeneral();
    this.templateService.update(template._id, formValue).subscribe(
      (response) => {
        this.loaderService.stopGeneral();
        this.getListTemplates();
        this.updateTemplates.emit();
        this.dialogService.openDialogPRIMARY('', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      },
      (error) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  addForm() {
    this.templatesList.push(new Template({}, this._merchantId));
    this.addTemplate(null);
  }

  addTemplate(value: Template | null): void {
    this.templateFormArray.push(this.addTemplateFormGroup(value));
  }

  addTemplateFormGroup(template?: Template): UntypedFormGroup {
    return this.formBuilder.group({
      name: [
        { value: template && template.name ? template.name : null,
          disabled: template && template.name ? true : null},
        [Validators.required, Validators.pattern(/^\w*$/)],
        themeNameValidatorDirective(this.templateService)
      ],
      short_description: [
        template && template.short_description ? template.short_description : null,
        [Validators.required]
      ],
      description: [
        template && template.description ? template.description : null
      ]
    });
  }

  removeTemplate(i: number, template: Template): void {
    if (!template._id) {
      this.templateFormArray.splice(i, 1);
      this.templatesList.splice(i, 1);
      return;
    }
    const dialogRef = this.dialogService.openDialogDELETE('', this.translateService.instant('TEMPLATE.DIALOGCONFIRMDELETE') + '?');
    dialogRef.afterClosed().subscribe(
      (response) => {
        if (!response) { return; }
        this.loaderService.startGeneral();
        this.templateService.delete(template._id).subscribe(
          confirm => {
            this.loaderService.stopGeneral();
            this.updateTemplates.emit();
            this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
            this.templatesList.splice(i, 1);
            this.templateFormArray.splice(i, 1);
            this.initializeFormArray();
          },
          (deleteError) => {
            this.loaderService.stopGeneral();
            this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', deleteError.status);
          }
        );
      },
      (error) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  initializeFormArray() {
    if (this.templatesList.length === 0 && !this.isApproveView) {
      this.templatesList.push(new Template({}, this._merchantId));
    }
    this.templateFormArray = [];
    this.templatesList.forEach(el => this.addTemplate(el));
  }

  onRequestActivation(template) {
    const objWrapped = {
      state: 'PENDING',
    };
    this.loaderService.startGeneral();
    this.templateService.update(template._id, objWrapped).subscribe(
      (response) => {
        this.loaderService.stopGeneral();
        this.getListTemplates();
        this.dialogService.openDialogPRIMARY('', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      },
      error => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  approve(i: number, template: Template) {
    const objWrapped = {
      state: 'APPROVED',
    };
    this.templateService.update(template._id, objWrapped).subscribe(
      (response) => {
        // this.getPendingTemplates();
        this.templatesList.splice(i, 1);
        this.initializeFormArray();
        this.updateTemplates.emit();
        this.dialogService.openDialogPRIMARY('', 'DIALOG.SUCCESS_TEMPLATE_APPROVED');
        this.loaderService.stopGeneral();
      },
      (error) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  reject(i: number, template: Template) {
    this.dialog.open(TemplateRejectDialogComponent)
    .afterClosed().subscribe(
      data => {
        if (!data) {return; }

      const objWrapped = {
        state: 'REJECTED',
        reason: data,
      };
      this.templateService.update(template._id, objWrapped).subscribe(
        (response) => {
          // this.getPendingTemplates();
          this.templatesList.splice(i, 1);
          this.initializeFormArray();
          this.dialogService.openDialogPRIMARY('', 'DIALOG.SUCCESS_TEMPLATE_REJECTED');
          this.loaderService.stopGeneral();
        },
        (error) => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
      }
    );

  }


}
