<div class="flex-row" style="align-items: flex-end;">

  <div class="arrayTemplates">

    <div class="columnDisposition">
      <div *ngFor="let template of templatesList; index as i" class="singleTemplate">

        <div [formGroup]="templateFormArray[i]">
          <!-- COLONNA SINISTRA -->
          <div class="rowDisposition">
            <!-- THEME NAME -->
            <div class="col-sn">
              <mat-form-field class="leftInputField">
                <input matInput placeholder="{{'TEMPLATE.NAMETEMPLATE' | translate}}" formControlName="name" required>
                <mat-error *ngIf="templateFormArray[i].controls.name.hasError('required')">{{"TEMPLATE.ERRORREQUIRED" |
                  translate}}</mat-error>
                <mat-error *ngIf="templateFormArray[i].controls.name.hasError('pattern')">{{"TEMPLATE.ERRORPATTERN" |
                  translate}}</mat-error>
                <mat-error *ngIf="templateFormArray[i].controls.name.hasError('themeName_exists')">
                  {{"TEMPLATE.ERRORUNIQUENAME" | translate}}</mat-error>
              </mat-form-field>
            </div>

            <!-- SHORT DESCRIPRION -->
            <div class="col-dx">
              <mat-form-field class="leftInputField">
                <input matInput placeholder="{{'TEMPLATE.SHORTDESCRIPTION' | translate}}"
                  formControlName="short_description" required>
                <mat-error *ngIf="templateFormArray[i].controls.short_description.hasError('required')">
                  {{"TEMPLATE.ERRORREQUIRED" | translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- COLONNA DESTRA -->
          <div class="rowDisposition">
            <!-- DESCRIPTION -->
            <div class="col-sn">
              <mat-form-field class="leftInputField">
                <input matInput placeholder="{{'TEMPLATE.DESCRIPTION' | translate}}" formControlName="description">
              </mat-form-field>
            </div>

            <!-- MODIFY/REMOVE THEME -->
            <div class="col-dx rowDisposition">
              <div class="iconsTemplate" *ngIf="!isApproveView">
                <button mat-icon-button style="margin-right: 20px;" [disabled]="!template?._id"
                  [ngClass]="!template?._id ? 'icone-sms-email-page-disabled' : 'none'"
                  (click)="openEditTemplate(template, 'EMAIL')">
                  <mat-icon class="icon"
                    [ngClass]="template?.email ? 'icone-sms-email-page-true' : 'icone-sms-email-page-false'"
                    svgIcon="email" matTooltip="{{'PROFILO.EMAILTEMPLATE' | translate}}"></mat-icon>
                </button>
                <button mat-icon-button style="margin-right: 20px;" [disabled]="!template?._id"
                  [ngClass]="!template?._id ? 'icone-sms-email-page-disabled' : 'none'"
                  (click)="openEditTemplate(template, 'SMS')">
                  <mat-icon class="icon"
                    [ngClass]="template?.sms ? 'icone-sms-email-page-true' : 'icone-sms-email-page-false'" svgIcon="sms"
                    matTooltip="{{'PROFILO.SMSTEMPLATE' | translate}}"></mat-icon>
                </button>
                <button mat-icon-button [disabled]="!template?._id"
                  [ngClass]="!template?._id ? 'icone-sms-email-page-disabled' : 'none'"
                  (click)="openEditTemplate(template, 'PAGE')">
                  <mat-icon class="icon"
                    [ngClass]="template?.page ? 'icone-sms-email-page-true' : 'icone-sms-email-page-false'"
                    svgIcon="page" matTooltip="{{'PROFILO.PAGETEMPLATE' | translate}}"></mat-icon>
                </button>
              </div>

              <div class="iconsTemplate" *ngIf="isApproveView">
                <button mat-icon-button style="margin-right: 20px;" disabled>
                  <mat-icon class="icon"
                    [ngClass]="template?.isEmailRead ? 'icone-sms-email-page-true' : 'icone-sms-email-page-pending'"
                    svgIcon="email" matTooltip="{{'PROFILO.EMAILTEMPLATE' | translate}}"></mat-icon>
                </button>
                <button mat-icon-button style="margin-right: 20px;" disabled>
                  <mat-icon class="icon"
                    [ngClass]="template?.isSmsRead ? 'icone-sms-email-page-true' : 'icone-sms-email-page-pending'"
                    svgIcon="sms" matTooltip="{{'PROFILO.SMSTEMPLATE' | translate}}"></mat-icon>
                </button>
                <button mat-icon-button disabled>
                  <mat-icon class="icon"
                    [ngClass]="template?.isPageRead ? 'icone-sms-email-page-true' : 'icone-sms-email-page-pending'"
                    svgIcon="page" matTooltip="{{'PROFILO.PAGETEMPLATE' | translate}}"></mat-icon>
                </button>
              </div>

              <div *ngIf="!isApproveView" class="buttonModify">
                <div class="buttonsAction">
                  <div *ngIf="!template._id" class="buttonRow">
                    <button type="button" [disabled]="!templateFormArray[i].valid" mat-flat-button color="primary"
                      (click)="saveTemplate(i, template)">{{'TEMPLATE.CREATETEMPLATE' | translate}}</button>
                  </div>
                  <div *ngIf="template._id && template.state !== 'PENDING'" class="buttonRow">
                    <button type="button" mat-flat-button color="primary"
                      (click)="modifyTemplate(i, template)">{{'TEMPLATE.MODIFYTEMPLATE' | translate}}</button>
                  </div>
                  <div *ngIf="template?.email && template?.sms && template?.page && template?.state === 'EDIT'"
                    class="buttonRow">
                    <button type="button" mat-flat-button color="primary"
                      (click)="onRequestActivation(template, i)">{{'TEMPLATE.SENDREQUEST' | translate}}</button>
                  </div>
                  <div *ngIf="template?.state === 'PENDING'" class="review">
                    <p>{{"TEMPLATE.APPROVING" | translate}}</p>
                  </div>
                  <div *ngIf="template?.state === 'APPROVED'">
                    <p class="approved">{{"TEMPLATE.APPROVED" | translate}}</p>
                  </div>
                  <div *ngIf="template?.state === 'REJECTED'" fxLayout="row" fxLayoutAlign="start center">
                    <span class="rejected">{{"TEMPLATE.REJECTED" | translate}}</span>
                    <mat-icon style="margin-left: 5px;" class="icon-mostra-dettaglio" [matTooltip]="template?.reason">
                    </mat-icon>
                  </div>
                </div>

                <div class="buttonsRemoveAdd">
                  <button *ngIf="template?.state !== 'PENDING'" mat-icon-button (click)="removeTemplate(i, template)">
                    <mat-icon class="icon" svgIcon="remove" matTooltip="{{'PROFILO.REMOVETEMPLATE' | translate}}">
                    </mat-icon>
                  </button>
                  <button *ngIf="i === templateFormArray.length-1" mat-icon-button (click)="addForm()">
                    <mat-icon class="icon" svgIcon="add" matTooltip="{{'PROFILO.ADDTEMPLATE' | translate}}"></mat-icon>
                  </button>
                </div>
              </div>

              <div *ngIf="isApproveView" class="approveReject">
                <button class="buttonGreenCustom" mat-flat-button color="primary"
                  *ngIf="!template?.isEmailRead || !template?.isSmsRead || !template?.isPageRead"
                  (click)="openEditTemplate(template, 'EMAIL')">{{'TEMPLATE.READTOAPPROVE' | translate}}</button>
                <button class="buttonGreenCustom" mat-flat-button color="primary"
                  *ngIf="template?.isEmailRead && template?.isSmsRead && template?.isPageRead"
                  (click)="approve(i, template)">{{'TEMPLATE.APPROVE' | translate }}</button>
                <button class="buttonRedCustom" mat-flat-button color="warn"
                  (click)="reject(i, template)">{{'TEMPLATE.REJECT' |
                  translate }}</button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<div *ngIf="isApproveView && templatesList.length === 0">
  <p>{{"TEMPLATE.NO_TEMPLATES" | translate}}</p>
</div>
