<!-- input to filter operations-->
<div fxLayout="column" fxLayoutAlign="space-around stretch">
  <mat-form-field style='margin-left: 20px; margin-right: 20px; margin-bottom: -20px'>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'CONTROLLA.FILTROCERCA' | translate}}"
      autocomplete="off">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<app-grid #grid [dataSource]="dataSource" [isFullScreen]="false"></app-grid>

<mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
  class="matPaginator">
</mat-paginator>

<button mat-flat-button color="primary" (click)="downloadReport()" class="buttonGreenXl downloadButton">{{
  'CONTROLLA.DOWNLOAD' | translate }}</button>
