<span [ngClass]="theme">
  <h2 *ngIf="title !== undefined" mat-dialog-title class="titleCentered"><em class="{{type}} title">{{title}}</em>&nbsp;
  </h2>
  <mat-dialog-content class="dettaglio dialogContent">
    <span *ngIf="paymentType === 'SINGLE'">{{message}}</span>
    <strong *ngIf="paymentType !== 'SINGLE'">{{message}}</strong>
  </mat-dialog-content>
  <div *ngIf="code !== undefined" class="dettaglio codice">{{code}}</div>

  <mat-dialog-actions *ngIf='!actionsActive' class="singleButtonDialog respectPadding">
    <button mat-flat-button color="{{color}}" [mat-dialog-close]="true" class="buttonDialog {{classCustom}}">
      <span>{{ buttonText | translate}}</span>
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions *ngIf='actionsActive' class="multipleButtons respectPadding">

    <div *ngIf="paymentType === 'SINGLE'">
      <button mat-flat-button (click)='actionPassed(false)' color="{{color}}" [mat-dialog-close]='true'
        class="buttonDialog">{{'DIALOG.NO' | translate}}</button>
      <button mat-flat-button (click)='actionPassed(true)' color="{{color}}" [mat-dialog-close]='true'
        class="buttonDialog">{{'DIALOG.YES' | translate}}</button>
    </div>

    <div *ngIf="paymentType !== 'SINGLE'">
      <button mat-flat-button (click)='actionPassed(false)' [mat-dialog-close]='true'
        class="buttonDialog white-button">{{'DIALOG.CANCEL' | translate}}</button>
      <button mat-flat-button (click)='actionPassed(true)' color="{{color}}" [mat-dialog-close]='true'
        class="buttonDialog">{{'DIALOG.CONFERMA' | translate}}</button>
    </div>

  </mat-dialog-actions>
</span>
