import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-bookmark',
  templateUrl: './dialog-bookmark.component.html',
  styleUrls: ['./dialog-bookmark.component.scss']
})
export class DialogBookmarkComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogBookmarkComponent>
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // bookmarkPage() {
  //   const url = window.location.href;
  //   const title = document.title;

  //   if (window.sidebar && window.sidebar.addPanel) {
  //     // For browsers like Firefox
  //     window.sidebar.addPanel(title, url, '');
  //   } else if (window.external && 'AddFavorite' in window.external) {
  //     // For Internet Explorer
  //     window.external.AddFavorite(url, title);
  //   } else if (window.opera && window.print) {
  //     // For Opera
  //     var elem = document.createElement('a');
  //     elem.setAttribute('href', url);
  //     elem.setAttribute('title', title);
  //     elem.setAttribute('rel', 'sidebar');
  //     elem.click();
  //   } else {
  //     // For other browsers, provide instructions to the user.
  //     alert('Please press ' + (navigator.platform === 'MacIntel' ? 'Cmd' : 'Ctrl') + '+D to bookmark this page.');
  //   }
  // }

}
