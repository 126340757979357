import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as numeral from 'numeral';
import { PaymentService } from '../../_services/payment.service';
import { DialogService } from "../../_services/dialog.service";
import { LoaderService } from "../../_services/loader.service";
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-operation-buttons',
  templateUrl: './operation-buttons.component.html',
  styleUrls: ['./operation-buttons.component.scss']
})
export class OperationButtonsComponent implements OnInit {

  @Input() tranId: string;

  @Input() isAdmin?: boolean;
  @Input() type?: string;
  @Input() maxAmount: string;
  @Input() paymentId?: string;
  @Input() shopID?: string;
  @Input() recurrentId?: string;

  @Output() refreshOutput: EventEmitter<any> = new EventEmitter();

  importoInput = new UntypedFormControl('');

  constructor(
    private paymentService: PaymentService, 
    private dialogService: DialogService, 
    private translate: TranslateService, 
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    
  }

  checkImport() {  
    const maxNum = parseFloat(this.maxAmount);
    if (this.importoInput.value <= 0 || this.importoInput.value > maxNum) {
      this.dialogService.openDialogWARN('DIALOG.GENERIC_ALLERT_TITLE', 'DIALOG.ALLERT_MEX_CONTROLLA_OPP');
      return false;
    } else {
      return true;
    }
  }


  showDialog(message: string) {
    if(!this.checkImport()) return;
    let imp = numeral(this.importoInput.value).format('0.00'); 

    const myData = {
      amount: imp,
      tranID: this.tranId,
      shopID: this.shopID,
      recurrent: this.recurrentId ? this.recurrentId : null
    }
  
    this.dialogService.openDialogDELETE('', this.translate.instant('OPERAZIONI.DIALOG' + message) + imp.replace(".", ",") + ' € ?')
    .afterClosed().subscribe(
      data => {
        if (!data) {return; }
        this.loaderService.startGeneral();
        this.complete(myData, message);
      }
    );
  }

  complete(data: any, type: string) {
    let ob$: Observable<any>;
    switch(type) {
      case 'CONFERMA': ob$ = this.paymentService.confermaPagamento(this.paymentId, data);
      break;
      case 'STORNAPRE': ob$ = this.paymentService.stornaVoid(this.paymentId, data);
      break;
      default: ob$ = this.paymentService.stornaCredit(this.paymentId, data);
    }
    ob$.subscribe(
      response => {
        this.loaderService.stopGeneral();
        if (response.rc != 'IGFS_000') {
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', response.errorDesc);
        } else {
          this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        }
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      },
      () => this.refreshOutput.emit(true)
    )
  }

}


