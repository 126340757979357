import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import DateHelper from "../../_helpers/date.helper";
import * as numeral from "numeral";
import { FilterValidator } from "../../_validators/filter-validator";

@Component({
  selector: "app-operations-filter",
  templateUrl: "./operations-filter.component.html",
  styleUrls: ["./operations-filter.component.scss"],
})
export class OperationsFilterComponent implements OnInit {
  @Output() filters: EventEmitter<Object> = new EventEmitter<Object>();

  statoLink = [
    { value: "ACTIVE", viewValue: "CONTROLLA.ACTIVE" },
    { value: "COMPLETED", viewValue: "CONTROLLA.COMPLETED" },
    { value: "EXPIRED", viewValue: "CONTROLLA.EXPIRED" },
  ];

  statoPagamento = [
    { value: "SUCCESS", viewValue: "CONTROLLA.ABUONFINE" },
    { value: "FAILED", viewValue: "CONTROLLA.NONABUONFINE" },
  ];

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  filterForm: UntypedFormGroup;
  isFilterActive = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.filterForm = this.fb.group(
      {
        state: [],
        name: [],
        amount: [],
        payment_state: [],
        payment_date_start: [],
        payment_date_end: [],
        expiration_date_start: [],
        expiration_date_end: [],
      },
      { validators: FilterValidator }
    );
  }

  filter() {
    const value = this.filterForm.value;
    const filterObj = {
      state: value.state,
      name: value.name,
      amount: value.amount
        ? numeral(value.amount).format("0.00").toString()
        : undefined,
      payment_state: value.payment_state,
      from_payment_date: value.payment_date_start
        ? moment
          .utc(DateHelper.stripOffset(value.payment_date_start))
            .toISOString()
        : undefined,
      to_payment_date: value.payment_date_end
        ? moment
          .utc(DateHelper.stripOffset(value.payment_date_end))
            .endOf("day")
            .toISOString()
        : undefined,
      from_date: value.expiration_date_start
        ? moment
          .utc(DateHelper.stripOffset(value.expiration_date_start))
            .toISOString()
        : undefined,
      to_date: value.expiration_date_end
        ? moment
          .utc(DateHelper.stripOffset(value.expiration_date_end))
            .endOf("day")
            .toISOString()
        : undefined,
    };
    this.filters.emit(filterObj);
    this.isFilterActive = true;
  }

  reset() {
    this.filterForm.reset();
    this.isFilterActive = false;
    this.filters.emit(null);
  }
}
