import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaObserver, MediaChange} from '@angular/flex-layout';
import {Subscription} from 'rxjs/internal/Subscription';
import {Operation} from '../../_models/operation';
import {OperationsMobileSliderComponent} from '../operations-mobile-slider/operations-mobile-slider.component';
import {OverlayService} from '../../_services/overlay.service';
import {DetailOverlayComponent} from '../detail-overlay/detail-overlay.component';
import {OpenPaymentData} from '../../_models/open_payment_data';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Outcome} from '../../_models/outcome';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Payment } from '../../_models/payment';
import { PaymentService } from '../../_services/payment.service';
import { saveFile } from '../../_services/file-download-helper';
import { filter } from 'rxjs';

@Component({
  selector: 'app-grid-open',
  templateUrl: './grid-open.component.html',
  styleUrls: ['./grid-open.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class GridOpenComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() set payment(value: Operation) {
    this._payment = value;
    this.sortOpen(value);
  }
  @Input() isSmallScreen: boolean;
  @Input() isFullScreen?: boolean;
  private watcher: Subscription;
  private activeMediaQuery: string;
  transactionsOpen: MatTableDataSource<OpenPaymentData>;
  openColumns = ['iconOpen', 'pan', 'date', 'amountOpen'];
  private _payment: Operation;

  constructor(
    private overlayService: OverlayService,
    private mediaObserver: MediaObserver,
    private paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.observeLayoutChanges();
  }

  ngOnDestroy() {
    if (!this.watcher) {return; }
    this.watcher.unsubscribe();
  }

  observeLayoutChanges() {
    this.watcher = this.mediaObserver.asObservable().pipe(filter((change: MediaChange[]) => {
      this.activeMediaQuery = change[0] ? `'${change[0].mqAlias}' = (${change[0].mediaQuery})` : '';
      if ( change[0].mqAlias === 'xs') {
        this.openColumns = [ 'date', 'amountOpen'];
      }
      if ( change[0].mqAlias === 'sm') {
        this.openColumns = ['iconOpen', 'pan', 'date', 'amountOpen'];
      }
      if ( change[0].mqAlias === 'md') {
        this.openColumns = ['iconOpen', 'pan', 'date', 'amountOpen'];
      }
      if ( change[0].mqAlias === 'lg') {
        this.openColumns = ['iconOpen', 'pan', 'date', 'amountOpen'];
      }
      if ( change[0].mqAlias === 'xl') {
        this.openColumns = ['iconOpen', 'pan', 'date', 'amountOpen'];
      }
      return true;
    })).subscribe();
  }

  openSlider(payment: Operation, transactions: Array<any>) {
    const data = {
      'element': payment,
      'transactions': transactions
    };
    this.overlayService.open(OperationsMobileSliderComponent, data);
  }

  openDetail(payment: Operation) {
    this.overlayService.open(DetailOverlayComponent, payment);
  }

  sortOpen(element: Operation) {
    if (!element.isPaymentOpen) { return; }
    if (!element.outcomes || element.outcomes.length < 1) { return; }
    const failedPayments = new OpenPaymentData();
    const okPayments = [];
    element.outcomes.forEach((outcome: Outcome) => {
      if (!outcome.outcome_response.maskedPan) {
        failedPayments.transactions.push(outcome);
        return;
      }
      const saved = okPayments.find((el: OpenPaymentData) => el.pan === outcome.outcome_response.maskedPan);
      if (!saved) {
        const payment = new OpenPaymentData(
          outcome.outcome_response.maskedPan,
          outcome.outcome_update,
          outcome.outcome_amount ? Number.parseFloat(outcome.outcome_amount) : 0,
          [outcome],
        );
        okPayments.push(payment);
      } else {
        const toSum = outcome.outcome_amount ? Number.parseFloat(outcome.outcome_amount) : 0;
        saved.amount = saved.amount + toSum;
        saved.transactions.push(outcome);
      }
    });
    okPayments.forEach((payment: OpenPaymentData) => {
      payment.amount = Number.parseFloat(payment.amount).toFixed(2);
    });
    const transactionsOpen = okPayments;
    if (failedPayments.transactions.length > 0) {
      transactionsOpen.push(failedPayments);
    }
    this.transactionsOpen = new MatTableDataSource<OpenPaymentData>(transactionsOpen);
    this.transactionsOpen.sort = this.sort;
  }

  exportTransactions() {
    let fileName = 'transactions report.xlsx';
    if (this._payment.description) {
      fileName = this._payment.description + ' transactions report.xlsx'
    }
    this.paymentService.openOrderExport(this._payment._id)
    .subscribe(
      response => saveFile(response, fileName)
    );
  }
}


