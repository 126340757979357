export class Anteprima {
    ricRat: any;
    /* constructor(
      public importo?: string,
      public mittente?: string,
      public descrizionemittente?: string,
      public facebookUrl?: string,
      public twitterUrl?: string,
      public linkedinUrl?: string,
      public sitoUrl?: string,
      public causale?: string,
      public destinatario?: string,
      public isDonation?: boolean,
      public typeTrans?: string,
      public logoId?: string,
      ) {
    } */
}
