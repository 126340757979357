import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private readonly SUPPORT_URL: string = environment.BASE_URL + 'support';

  constructor(private http: HttpClient, private authService: AuthService) {}

  createAnonymous(support: {description: string, email: string}): Observable <{} | any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept-Language', 'it');

    let supportObj = {
      from: support.email,
      message: support.description
    }

    return this.http.post(this.SUPPORT_URL + '/anonimous', supportObj, { headers });
  }

  createLogged(support: Object): Observable <{} | any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    let supportObj = {
      from: support['email'],
      message: support['description'],
      merchant: support['merchant']
    }

    return this.http.post(this.SUPPORT_URL + '/customer', supportObj, { headers });
  }

}
