<mat-expansion-panel class="barraFiltro">
  <mat-expansion-panel-header>
    <mat-panel-title class="titoloFiltro">
      {{'PROFILO.TITOLO3FILTRO' | translate}}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <form [formGroup]="form" (ngSubmit)="changePassword()">

    <div fxLayout.lt-md="column" fxLayout.gt-md="row">
      <!-- INDICAZIONI PASSWORD -->
      <div class="modpasstext" fxFlex="auto">
        <span>{{'PROFILO.RIGA1' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA2' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA3' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA4' | translate}}</span>
        <br>
        <span> {{'PROFILO.RIGA5' | translate}}</span>
        <br>
        <span>{{'PROFILO.RIGA6' | translate}}</span>
      </div>

      <!-- CAMPI FORM -->
      <div fxLayout="column" fxFlex="auto">
        <mat-form-field *ngIf="isProfile">
          <input matInput placeholder="{{'PROFILO.PASSATTUALE' | translate}}" type="password"
            formControlName="temporary" required>
          <mat-error *ngIf="form?.controls.temporary.hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="{{'PROFILO.NUOVAPASSWORD' | translate}}" type="password"
            formControlName="password" required [errorStateMatcher]="matchOldErrorMatcher">
          <mat-error *ngIf="form?.controls.password.hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          <mat-error *ngIf="form?.controls.password.hasError('pattern')"
            [innerHTML]="'MESSAGE.PASSWORD_PATTERN' | translate"></mat-error>
          <mat-error *ngIf="form?.hasError('matchOld') && !form?.controls.password.errors"
            [innerHTML]="'MESSAGE.PASSWORD_MATCHPRECEDENTE' | translate"></mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="{{'PROFILO.RIPETIPASSWORD' | translate}}" type="password"
            formControlName="confirm" required [errorStateMatcher]="mismatchErrorMatcher">
          <mat-error *ngIf="form?.controls.confirm.hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          <mat-error *ngIf="form?.hasError('mismatch') && form?.value.confirm"
            [innerHTML]="'MESSAGE.PASSWORD_MATCH' | translate"></mat-error>
        </mat-form-field>
      </div>

    </div>

    <!-- BOTTONE VERDE -->
    <div fxLayout="column" fxLayoutAlign="space-evenly end">
      <button type="submit" [disabled]="!form?.valid" mat-flat-button color="primary">{{'PROFILO.FILTRO3BUTTON' |
        translate}}</button>
    </div>

  </form>

</mat-expansion-panel>
