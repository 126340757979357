import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Merchant } from '../../_models/merchant';
import { MerchantService } from '../../_services/merchant.service';

@Component({
  selector: 'app-social-form',
  templateUrl: './social-form.component.html',
  styleUrls: ['./social-form.component.scss']
})
export class SocialFormComponent implements OnInit {

  @Input() merchant: Merchant;
  @Input() isSelfEnrollment?: boolean;
  @Output() outputFormValues: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  socialForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private merchantService: MerchantService,
  ) { }

  ngOnInit() {
    this.merchantService.currentMerchant$.subscribe(
      value => {
        this.buildForm(this.merchant ? this.merchant : value);
        if (this.isSelfEnrollment) {
          this.onChanges();
          this.notifyChanges();
        } 
    });
  }

  buildForm(merchantData: Merchant) {
    this.socialForm = this.fb.group({
      facebook: [merchantData && merchantData.facebook ? merchantData.facebook : null],
      linkedin: [merchantData && merchantData.linkedin ? merchantData.linkedin : null],
      twitter: [merchantData && merchantData.twitter ? merchantData.twitter : null],
      website: [merchantData && merchantData.website ? merchantData.website : null],
    })
  }

  notifyChanges() {
    this.outputFormValues.emit(this.socialForm);
  }
  onChanges(): void {
    this.socialForm.statusChanges.subscribe(() => this.notifyChanges());
  }
}
