import { DataSource } from "@angular/cdk/table";
import { Operation } from "../_models/operation";
import { BehaviorSubject, Observable, of } from "rxjs";
import { PaymentService } from "../_services/payment.service";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

export class OperationsDataSource implements DataSource<Operation> {

    private operationsSubject = new BehaviorSubject<Operation[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    count: any;

    constructor(
      private paymentService: PaymentService,
      ) {}

    connect(): Observable<Operation[]> {
      return this.operationsSubject.asObservable();
    }

    disconnect(): void {
      this.operationsSubject.complete();
      this.loadingSubject.complete();
    }

    loadOperations(merchant_id: string, filters: Object, sort: string = 'desc', pageIndex: number = 0, pageSize: number, paymentType?: string) {
      this.loadingSubject.next(true);
      this.paymentService.getOperations(merchant_id, filters, false, sort, pageIndex, pageSize, true, paymentType).pipe(
        catchError(() => of([])),
        tap(operation => {
          if (!operation || !operation.items) {return; }
          return operation.items.map(item => {
            if (item.amount) {
              item.amount = Number.parseFloat(item.amount).toFixed(2);
            }
            if (item.recurrents?.length > 0) {
              let last_error = item.recurrents.find(el => el.state === 'ERROR');
              let first_payment = item.recurrents.find(el => el.occurence === 1);
              item.firstRecurrentDate = first_payment ? (first_payment?.outcomes[0]?.createdAt || first_payment?.execution_date) : '';
              item.firstPaymentAttempted = first_payment ? first_payment.state !== 'ACTIVE' : false;
              item.firstRecurrentIDFailed = last_error ? last_error?._id : null;
            }
            if (item.totalAmount) {
              item.totalAmount = Number.parseFloat(item.totalAmount).toFixed(2);
            }
            let okTrx = item.outcomes.find((el: any) => el.outcome_response.error === false)
            if(okTrx){
              this.arraymove(item.outcomes, item.outcomes.indexOf(okTrx), item.outcomes.length - 1);
            }
            return item;
          })
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(operations => {
        this.normalizeMyBankPayload(operations);
        this.count = operations.results.items;
        this.operationsSubject.next(operations.items);
      });
    }

    normalizeMyBankPayload(operations){
      if(operations.items){
        for (let i = 0; i < operations.items.length; i++) {
          const operation = operations.items[i];
          if(operation.outcomes.length > 0){
            for (let j = 0; j < operation.outcomes.length; j++) {
              if(operation.outcomes[j].outcome_type === 'MYBANK'){
                operations.items[i].outcomes[j].outcome_response = this.createMyBankPayload(operation.outcomes[j].outcome_response);
              }
            }
          }
        }
      }
    }

    createMyBankPayload(outcome){
      let output = {};
      output['rc'] = outcome.transactionStatus === 'AUTHORISED' ? 'IGFS_000' : outcome.transactionStatus === 'PENDING' ? 'IGFS_888' : 'IGFS_008';
      output['brand'] = 'MYBANK';
      output['maskedPan'] = outcome.iban ? outcome.iban : '';
      return output;
    }

    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
  }

}
