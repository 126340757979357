<div fxLayout>
  <div *ngFor="let op of _options">
    <div *ngIf="!isShowcase || (isShowcase && value === op?.value)" [matTooltip]="op?.tooltip"
      style="text-align: center; margin: 0 8px; position: relative;">
      <div id="description" role="label" class="checkboxFont">{{op?.label}}</div>
      <button mat-icon-button color="primary" aria-roledescription="radio-button" aria-labelledby="description"
        appOptHover [value]="value === op?.value" [disabled]="disabled" class="checkboxButton"
        (click)="_setValue(op?.value)">
        <mat-icon class="wizardIcon" [svgIcon]="op?.icon"></mat-icon>
        <mat-icon color="primary" [ngStyle]="{ 'display': value === op?.value ? 'inline-block' : 'none'}"
          class="checkedIcon wizardIcon" svgIcon="checked"></mat-icon>
      </button>
    </div>
  </div>
</div>
