

import { ReferentService } from '../../../../_services/referent.service';
import { Referent } from '../../../../_models/referent';
import { Merchant } from '../../../../_models/merchant';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoaderService } from '../../../../_services/loader.service';
import { MerchantService } from '../../../../_services/merchant.service';
import { UserService } from '../../../../_services/user.service';
import { User } from '../../../../_models/user';
import { DialogService } from '../../../../_services/dialog.service';
import { ErrorMatcher } from '../../../../_classes/error-matcher';
import { TemplateService } from '../../../../_services/template.service';
import { AuthService } from '../../../../_services/auth.service';
import { MailTemplateComponent } from '../merchantTechnicalData/mail-template/mail-template.component';
import { Subscription } from 'rxjs';
import { Template } from '../../../../_models/template';
import { map, tap, catchError } from 'rxjs/operators';



@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-merchantProfile',
  templateUrl: './merchantProfile.component.html',
  styleUrls: ['./merchantProfile.component.scss']
})

export class MerchantProfileComponent implements OnInit, OnDestroy {
  @ViewChild('availableTemplates', { static: true }) availableTemplates: MailTemplateComponent;

  user: User = new User();
  merchant: Merchant = new Merchant();
  disabledFields: string[] = [];
  private merchantSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private referentService: ReferentService,
    private loaderService: LoaderService,
    public merchantService: MerchantService,
    private templateService: TemplateService,
    private dialogService: DialogService,
    private adapter: DateAdapter<any>) {
    moment.locale('it');
    this.adapter.setLocale('it');
  }
  ngOnDestroy() {
    this.merchantSubscription.unsubscribe();
    if (this.merchantService.isAdminMode) {
      this.merchantService.currentMerchant = null;
    }
  }

  ngOnInit() {
    this.loaderService.startGeneral();
    this.user = this.authService.user;

    this.route.params.subscribe(params => {
      if (params.id) {
        this.merchantService.getMerchant(params.id).subscribe(
          null,
          error => {
            this.loaderService.stopGeneral();
            this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
          }
        );
      }
    });

    this.merchantSubscription = this.merchantService.currentMerchant$.subscribe(
      value => this.merchant = value
    )

    if (!this.merchantService.isAdminMode) {
      this.disabledFields = ['name', 'code', 'fromAddress'];
    }
    this.loaderService.stopGeneral();

  } // FINE ngOnInit


  modificaMerchant(data: any) {
    this.loaderService.startGeneral();
    this.merchantService.update(this.merchant._id, data)
      .subscribe(
        responseMerchant => {
          this.refreshMerchant();
        },
        error => {
          console.log(error);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
  }

  saveReferent(event: UntypedFormGroup) {
    this.loaderService.startGeneral();
    const dataReferent: Referent = event.value;

    if (this.merchant.referent) {
      this.updateExistingReferent(dataReferent);
    } else {
      this.createNewReferent(dataReferent);
    }
  }

  creaMerchant(dataAzienda: any) {
    this.loaderService.startGeneral();
    const newMerchant = new Merchant();
    for (const prop in dataAzienda) {
      if (dataAzienda.hasOwnProperty(prop)) {
        newMerchant[prop] = dataAzienda[prop];
      }
    }
    this.getDefaultTemplate(newMerchant).subscribe(
      _ => {
        this.merchantService.create(newMerchant)
          .subscribe(
            responseMerchant => {
              this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
              this.loaderService.stopGeneral();
            },
            error => {
              console.log(error);
              this.loaderService.stopGeneral();
              this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
            }
          );
      }
    );
  }


  pagedatiTecnici() {
    this.router.navigate(['/admin/merchants', this.merchant._id, 'technical-data']);
  }

  pagePendingTemplates() {
    this.router.navigate(['/admin/merchants', this.merchant._id, 'templates']);
  }

  tornaClienti() {
    this.router.navigate(['/admin/merchants']);
  }

  updateExistingReferent(referent: Referent) {
    this.referentService.update(this.merchant.referent._id, referent)
      .subscribe(
        responseReferent => {
          this.refreshMerchant();
        },
        error => {
          console.log(error);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
  }

  createNewReferent(referent: Referent) {
    this.referentService.create(referent)
      .subscribe(
        (responseReferent: any) => {
          const body = {
            referent: responseReferent.item._id
          }
          this.modificaMerchant(body)
        },
        error => {
          console.log(error);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
  }

  getDefaultTemplateId(merchant: Merchant) {
    const promise = new Promise<void>(
      (resolve, reject) => {
        this.templateService.getDefaultTemplate()
          .subscribe(
            templateObj => {
              merchant.templates.push(templateObj['items'][0]['_id']);
              resolve();
            },
            error => {
              console.log(error);
              reject('');
            }
          );
      }
    );
    return promise;
  }

  getDefaultTemplate(merchant: Merchant) {
    return this.templateService.getDefaultTemplate().pipe(
      tap(
        response => {
          merchant.templates.push(response._id)
        },
        error => {
          console.log(error);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      )
    )
  }

  saveChanges(outputForm: UntypedFormGroup) {
    console.log(outputForm);
    if (this.merchant && this.merchant._id) {
      this.modificaMerchant(outputForm.value);
    } else {
      this.creaMerchant(outputForm.value);
    }
  }

  onTemplatesUpdate(event: undefined) {
    this.merchantService.getMerchant(this.merchant._id)
      .subscribe(
        response => {
          this.availableTemplates.getTemplates();
        }
      )
  }


  refreshMerchant() {
    this.merchantService.getMerchant(this.merchant._id)
      .subscribe(
        response => {
          this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
          this.loaderService.stopGeneral();
        },
        error => {
          console.log(error);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }

      )
  }

}
