import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LoaderService } from '../../_services/loader.service';
import { AuthService } from '../../_services/auth.service';
import { DialogService } from '../../_services/dialog.service';
import { MatchValidator, MismatchErrorMatcher, MatchOldErrorMatcher } from '../../_validators/match-validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @Input() userId: string;
  @Input() isProfile?: boolean;
  form: UntypedFormGroup;
  mismatchErrorMatcher = new MismatchErrorMatcher();
  matchOldErrorMatcher = new MatchOldErrorMatcher();

  constructor(
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
   }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.fb.group({
      password: [null, [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?["£$%&/()=?])[a-zA-Z0-9"£$%&/()=?]{8,20}$')]],
      confirm: [null, [Validators.required]],
    }, {
      validators: [MatchValidator]
    })
    if (this.isProfile) {
      this.form.addControl('temporary', this.fb.control(null, [Validators.required]))
    } 
  }

  changePassword() {
    this.loaderService.startGeneral();
    const ob$ = this.isProfile 
    ? this.authService.updatePassword(this.userId, this.form.value.temporary, this.form.value.password)
    : this.authService.change(this.userId, this.form.value.password, this.form.value.confirm)
    ob$.subscribe(
      responsePassword => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.loaderService.stopGeneral();
      },
      error => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

}
