
import { LoginComponent } from './login/login.component';
import { environment } from '../environments/environment';
import { Routes, RouterModule } from '@angular/router';
import { ImportGuard } from './_guard/import.guard';
import { MerchantProfileComponent } from './admin/home/merchantManagement/merchantProfile/merchantProfile.component';
import { NewUserComponent } from './admin/home/userManagement/newUser/newUser.component';
import { UserProfileComponent } from './admin/home/userManagement/userProfile/userProfile.component';
import { NewClientsComponent } from './admin/home/clientsManagement/newClients/newClients.component';
import { OperationsComponent } from './admin/home/paymentManagement/operations/operations.component';
import { RequestPaymentEasyComponent } from './admin/home/paymentManagement/requestPaymentEasy/requestPaymentEasy.component';
import { MerchantTechnicalDataComponent } from './admin/home/merchantManagement/merchantTechnicalData/merchantTechnicalData.component';
import { ImportRequestComponent } from './admin/home/paymentManagement/import-request/import-request.component';
import { ImportOperationsComponent } from './admin/home/paymentManagement/import-operations/import-operations.component';
import { ImportOperationsPaymentsComponent } from './admin/home/paymentManagement/import-operations/import-operations-payments/import-operations-payments.component';
import { ProfilesComponent } from './admin/home/profilesManagement/profiles/profiles.component';
import { ProfileComponent } from './admin/home/profilesManagement/profile/profile.component';
import { TemplatesComponent } from './admin/home/merchantManagement/templates/templates.component';
import { SupplierGuard } from './_guard/supplier.guard';
import { ClientsComponent } from './admin/home/clientsManagement/clients/clients.component';
import { MerchantsComponent } from './admin/home/merchantManagement/merchants/merchants.component';
import { AuthGuard } from './_guard/auth.guard';
import { MerchantGuard } from './_guard/merchant.guard';
import { UsersComponent } from './admin/home/userManagement/users/users.component';
import { PaymentRequestComponent } from './admin/home/paymentManagement/payment-request/payment-request.component';
import { RecurrentGuard } from './_guard/recurrent.guard';
import { NgModule } from '@angular/core';
import { RequestManagerComponent } from './admin/home/paymentManagement/request-manager/request-manager.component';
import { MainViewComponent } from './admin/main-view/main-view.component';
import { NavigationConsoleComponent } from './_components/navigation-console/navigation-console.component';
import { HoldingHomeComponent } from './admin/holding-home/holding-home.component';
import { HoldingGuard } from './_guard/holding.guard';
import { UtilsComponent } from './admin/utils/utils.component';


const appRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "activate/:id", component: LoginComponent },

  {
    path: "",
    component: MainViewComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "merchant", pathMatch: "full" },
      {
        path: "merchant",
        canActivate: [MerchantGuard],
        children: [
          { path: "", redirectTo: "home", pathMatch: "full" },
          {
            path: "home",
            component: NavigationConsoleComponent,
            data: { breadcrumbs: [{ label: "home", url: "/home" }] },
          },

          {
            path: "requests",
            component: RequestManagerComponent,
            children: [
              { path: "", redirectTo: "single", pathMatch: "full" },
              {
                path: "single",
                component: OperationsComponent,
                data: {
                  breadcrumbs: [
                    { label: "home", url: "/merchant/home" },
                    { label: "operazioni", url: "requests/single" },
                  ],
                },
              },
              {
                path: "recurrent",
                component: OperationsComponent,
                canActivate: [RecurrentGuard],
                data: {
                  breadcrumbs: [
                    { label: "home", url: "/merchant/home" },
                    { label: "ricorrenti", url: "requests/recurrent" },
                  ],
                },
              },
              {
                path: "deferred",
                component: OperationsComponent,
                canActivate: [RecurrentGuard],
                data: {
                  breadcrumbs: [
                    { label: "home", url: "/merchant/home" },
                    { label: "dilazioni", url: "requests/deferred" },
                  ],
                },
              },
              {
                path: "import/:id",
                component: ImportOperationsPaymentsComponent,
                canActivate: [ImportGuard],
                data: {
                  breadcrumbs: [
                    { label: "home", url: "/merchant/home" },
                    {
                      label: "importOperations",
                      url: "/merchant/requests/import",
                    },
                    { label: "importOperationsPayments" },
                  ],
                },
              },
              {
                path: "import",
                component: ImportOperationsComponent,
                canActivate: [ImportGuard],
                data: {
                  breadcrumbs: [
                    { label: "home", url: "/merchant/home" },
                    { label: "importOperations" },
                  ],
                },
              },
            ],
          },

          {
            path: "request/easy",
            component: RequestPaymentEasyComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "richiediPagamentoEasy" },
              ],
            },
          },
          {
            path: "request/new",
            component: PaymentRequestComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "richiedi-pagamento" },
              ],
            },
          },
          {
            path: "request/import",
            component: ImportRequestComponent,
            canActivate: [ImportGuard],
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "import" },
              ],
            },
          },
          {
            path: "request/:id/:paymentId",
            component: PaymentRequestComponent,
            canActivate: [ImportGuard],
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "importOperations", url: "/merchant/requests/import" },
                {
                  label: "importOperationsPayments",
                  url: "/merchant/requests/import/:id",
                },
                { label: "modificaPagamento" },
              ],
            },
          },
          {
            path: "request/:id",
            component: PaymentRequestComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "operazioni", url: "/merchant/requests/single" },
                { label: "modificaPagamento" },
              ],
            },
          },

          {
            path: "my-company",
            component: NavigationConsoleComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "my-company/company-profile",
            canActivate: [MerchantGuard],
            component: MerchantProfileComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                {
                  label: "profiloAzienda",
                  url: "/merchant/my-company/company-profile",
                },
              ],
            },
          },
          {
            path: "my-company/user-profile",
            component: UserProfileComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                {
                  label: "profiloUtente",
                  url: "/merchant/my-company/user-profile",
                },
              ],
            },
          },
          {
            path: "my-company/users",
            component: UsersComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                { label: "pannelloUtente", url: "/merchant/my-company/users" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "my-company/user/new",
            component: NewUserComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                { label: "pannelloUtente", url: "/merchant/my-company/users" },
                { label: "nuovoUtente" },
              ],
            },
          },
          {
            path: "my-company/user/:id",
            component: NewUserComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                { label: "pannelloUtente", url: "/merchant/my-company/users" },
                { label: "modificaUtente" },
              ],
            },
          },
          {
            path: "my-company/profiles",
            component: ProfilesComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                {
                  label: "pannelloProfili",
                  url: "/merchant/my-company/profiles",
                },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "my-company/profiles/new",
            component: ProfileComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                {
                  label: "pannelloProfili",
                  url: "/merchant/my-company/profiles",
                },
                {
                  label: "nuovoProfilo",
                  url: "/merchant/my-company/profiles/new",
                },
              ],
            },
          },
          {
            path: "my-company/profiles/:id",
            component: ProfileComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                {
                  label: "pannelloProfili",
                  url: "/merchant/my-company/profiles",
                },
                {
                  label: "modificaProfilo",
                  url: "/merchant/my-company/profiles/:id",
                },
              ],
            },
          },
          {
            path: "my-company/users",
            component: UsersComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                { label: "pannelloUtente", url: "/merchant/my-company/users" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "my-company/users/new",
            component: NewUserComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                { label: "pannelloUtente", url: "/merchant/my-company/users" },
                { label: "nuovoUtente" },
              ],
            },
          },
          {
            path: "my-company/users/:id",
            component: NewUserComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "azienda", url: "/merchant/my-company" },
                { label: "pannelloUtente", url: "/merchant/my-company/users" },
                { label: "modificaUtente" },
              ],
            },
          },
          {
            path: "clients",
            component: ClientsComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "clienti", url: "/merchant/clients" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "clients/new",
            component: NewClientsComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "clienti", url: "/merchant/clients" },
                { label: "nuovoCliente", url: "/merchant/clients/new" },
              ],
            },
          },
          {
            path: "clients/:id",
            component: NewClientsComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "clienti", url: "/merchant/clients" },
                { label: "modificaCliente", url: "/merchant/clients/:id" },
              ],
            },
          },
        ],
      },
      {
        path: "admin",
        canActivate: [SupplierGuard],
        children: [
          { path: "", redirectTo: "home", pathMatch: "full" },
          {
            path: "home",
            component: NavigationConsoleComponent,
            data: { breadcrumbs: [{ label: "home", url: "/admin/home" }] },
          },
          {
            path: "merchants",
            component: MerchantsComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "aziende", url: "/admin/merchants" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "merchants/new",
            component: MerchantProfileComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "aziende", url: "/admin/merchants" },
                { label: "profiloAzienda", url: "/admin/merchants/new" },
              ],
            },
          },
          {
            path: "merchants/:id",
            component: MerchantProfileComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "aziende", url: "/admin/merchants" },
                { label: "profiloAzienda", url: "/admin/merchants/:id" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "merchants/:id/technical-data",
            component: MerchantTechnicalDataComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "aziende", url: "/admin/merchants" },
                { label: "profiloAzienda", url: "/admin/merchants/:id" },
                {
                  label: "datiTecnici",
                  url: "/admin/merchants/:id/datiTecnici",
                },
              ],
            },
          },
          {
            path: "merchants/:id/templates",
            component: TemplatesComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "aziende", url: "/admin/merchants" },
                { label: "profiloAzienda", url: "/admin/merchants/:id" },
                { label: "template", url: "/admin/merchants/:id/template" },
              ],
            },
          },
          {
            path: "axepta",
            component: NavigationConsoleComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "profilo", url: "admin/axepta" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "axepta/users",
            component: UsersComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "profilo", url: "admin/axepta" },
                { label: "pannelloUtente", url: "/admin/axepta/users" },
              ],
            },
            pathMatch: "full",
          },
          {
            path: "axepta/users/new",
            component: NewUserComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "profilo", url: "admin/axepta" },
                { label: "pannelloUtente", url: "/admin/axepta/users" },
                { label: "nuovoUtente" },
              ],
            },
          },
          {
            path: "axepta/users/:id",
            component: NewUserComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/admin/home" },
                { label: "profilo", url: "admin/axepta" },
                { label: "pannelloUtente", url: "/admin/axepta/users" },
                { label: "modificaUtente" },
              ],
            },
          },
          {
            path: "axepta/user-profile",
            component: UserProfileComponent,
            data: {
              breadcrumbs: [
                { label: "home", url: "/merchant/home" },
                { label: "profilo", url: "admin/axepta" },
                {
                  label: "profiloUtente",
                  url: "/merchant/axepta/user-profile",
                },
              ],
            },
          },
        ],
      },
      {
        path: "holding",
        canActivate: [HoldingGuard],
        children: [
          { path: "", redirectTo: "home", pathMatch: "full" },
          {
            path: "home",
            component: HoldingHomeComponent,
            data: { breadcrumbs: [{ label: "home" }] },
          },
        ],
      },
      {
        path: "user-profile",
        component: UserProfileComponent,
        data: {
          breadcrumbs: [
            { label: "home", url: "/home" },
            { label: "profiloUtente", url: "/user-profile" },
          ],
        },
      },
      { 
        path: 'utils', 
        canActivate: [SupplierGuard], 
        component: UtilsComponent, 
        data: { breadcrumbs: [{ label: 'utils' }] } 
       }
    ],
  },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: environment.TRACING,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

//export const Routing = RouterModule.forRoot(appRoutes, { enableTracing: environment.TRACING });
