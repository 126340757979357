<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 20px !important; margin-top: 20px !important">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{'CONTROLLA.FILTRI' | translate}}
          <img *ngIf="isFilterActive" alt="filter on icon" [src]="ASSETS_BASE + 'svg/filter-on.png'"
            matTooltip="{{'CONTROLLA.FILTRIATTIVI' | translate}}" matTooltipPosition="right" class="iconSocial"
            [matTooltipClass]="'tooltip'">
          <img *ngIf="!isFilterActive" alt="filter off icon" [src]="ASSETS_BASE + 'svg/filter-off.png'"
            matTooltip="{{'CONTROLLA.FILTRINONATTIVI' | translate}}" matTooltipPosition="right" class="iconSocial"
            [matTooltipClass]="'tooltip'">
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="filterForm">
        <!-- 1A RIGA  -  CAMPO "DATA SCADENZA" E CAMPO "STATO LINK" -->
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field apperance="fill" class="leftInputField datePicker">
              <mat-label>{{'CONTROLLA.FILTRODATERANGE' | translate}}</mat-label>
              <mat-date-range-input [formGroup]="filterForm" [rangePicker]="picker">
                <input matStartDate formControlName="expiration_date_start" placeholder="DD/MM/YYYY" readonly>
                <input matEndDate formControlName="expiration_date_end" placeholder="DD/MM/YYYY" readonly>
              </mat-date-range-input>
              <mat-datepicker-toggle class="mySatToggle" matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <mat-select placeholder="{{ 'CONTROLLA.FILTROSTATO' | translate}}" formControlName="state">
                <mat-option *ngFor="let stato of statoLink" [value]="stato.value">
                  <span style="text-transform: uppercase;">{{ stato.viewValue | translate }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
        <!-- 2A RIGA  -  CAMPO "NOME" E CAMPO "IMPORTO" -->
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <input matInput placeholder="{{'CONTROLLA.FILTRONOME' | translate}}" formControlName="name">
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField">
              <input currencyMask [options]="{ prefix: '', decimal: ',', thousands: '.', align: 'left'}" type="tel"
                matInput placeholder="{{'CONTROLLA.FILTROIMPORTO' | translate}}" formControlName="amount">
              <mat-icon matSuffix>euro_symbol</mat-icon>
            </mat-form-field>
          </span>
        </div>
        <!-- 3A RIGA  -  CAMPO "STATO PAGAMENTO" E CAMPO "DATA RANGE PAGAMENTO EFFETTIVO" -->
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field class="leftInputField">
              <mat-select placeholder="{{ 'CONTROLLA.FILTROESITOPAGAMENTO' | translate}}"
                formControlName="payment_state">
                <mat-option *ngFor="let stato of statoPagamento" [value]="stato.value">
                  {{ stato.viewValue | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field class="rightInputField datePicker">
              <mat-label>{{'CONTROLLA.FILTRORANGEEFFETTIVO' | translate}}</mat-label>
              <mat-date-range-input [formGroup]="filterForm" [rangePicker]="payment_picker">
                <input matStartDate formControlName="payment_date_start" placeholder="DD/MM/YYYY" readonly>
                <input matEndDate formControlName="payment_date_end" placeholder="DD/MM/YYYY" readonly>
              </mat-date-range-input>
              <mat-datepicker-toggle class="mySatToggle" matIconSuffix [for]="payment_picker"></mat-datepicker-toggle>
              <mat-date-range-picker #payment_picker></mat-date-range-picker>
            </mat-form-field>
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end">
          <!-- BOTTONE VERDE -->
          <button mat-flat-button color="primary" style="margin-right: 15px;" class="buttonAzzera"
            [disabled]="filterForm.pristine" (click)=reset()>{{'CONTROLLA.RESETFILTRO' | translate}}</button>
          <!-- BOTTONE VERDE -->
          <button mat-flat-button color="primary" [disabled]="filterForm.invalid"
            (click)=filter()>{{'CONTROLLA.FILTROBUTTON' | translate}}</button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
