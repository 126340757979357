import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { AuthService } from "../../_services/auth.service";
import { MerchantService } from "../../_services/merchant.service";
import { LoaderService } from "../../_services/loader.service";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "../../_services/dialog.service";
import { SupportService } from "../../_services/support.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  private aziendaSel: UntypedFormControl;
  isHolding = false;
  isAdmin = false;
  private accrediti: Array<any> = []; //merchants a disposizione dell'utente holding
  nomeAziendaHeader$: Observable<string>;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  loggedUser: string;

  constructor(
    private router: Router,
    public authService: AuthService,
    private merchantService: MerchantService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private supportService: SupportService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    moment.locale(this.translate.currentLang);

    this.iconRegistry.addSvgIcon(
      "azienda",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/azienda-new2.svg"
      )
    );
  }

  ngOnInit() {
    if (!this.authService.user) {
      return;
    }
    // this.loggedUser = this.authService.user ? this.authService.user.first_name + ' ' + this.authService.user.last_name : '';
    if (this.authService.user.type === "CUSTOMER") {
      return;
    }
    this.nomeAziendaHeader$ = this.merchantService.nomeAziendaHeader$;
    if (
      this.authService.user.type === "SUPPLIER" ||
      this.authService.user.type === "HOLDING"
    ) {
      this.isAdmin = true;
      return;
    }
    this.getHoldingData();
  }

  getHoldingData() {
    this.aziendaSel = new UntypedFormControl(null);
    this.isHolding = true;
    for (let entry of this.authService.user.merchants) {
      this.accrediti.push({
        value: entry.merchant._id,
        description: entry.merchant.name,
      });
    }
    this.nomeAziendaHeader$.subscribe((value) => {
      if (!value) {
        return;
      }
      const aziendaSelected = this.accrediti.find(
        (el) => el.description === value
      );
      this.aziendaSel.setValue(aziendaSelected);
    });
  }

  logout() {
    this.loaderService.startGeneral();
    this.merchantService.exitImpersonifica();
    this.authService.logout().subscribe();
  }

  exitImpersonifica() {
    this.loaderService.startGeneral();
    //this.merchantService.isAdminMode = true;
    this.merchantService.exitImpersonifica();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    let redirectUrl =
      this.authService.user.type === "SUPPLIER" ? "/admin" : "/holding";
    this.router.navigate([redirectUrl]);
    this.loaderService.stopGeneral();
  }

  showChangeMerchantDialog(selection: any) {
    const dialogRef = this.dialogService.openDialogDELETE(
      this.translate.instant("DIALOG.GENERIC_ALLERT_TITLE"),
      this.translate.instant("DIALOG.CHANGE_MERCHANT_1") +
        selection.description +
        this.translate.instant("DIALOG.CHANGE_MERCHANT_2")
    );
    dialogRef.afterClosed().subscribe((data) => {
      if (!data) {
        return this.merchantService.resetHeader();
      }
      this.impersonificaAzienda(selection.value);
    });
  }

  impersonificaAzienda(id: string) {
    this.merchantService
      .impersonifica(id)
      .subscribe((_) => this.router.navigate(["/merchant"]));
  }

  createSupportRequest(event: { description: string; email: null | string }) {
    let postObj = {
      description: event.description,
      email: this.authService.user.email,
      merchant: this.merchantService.currentMerchant._id,
    };

    this.supportService.createLogged(postObj).subscribe(
      (response) => {
        this.dialogService.openDialogPRIMARY(
          "DIALOG.SUCCESS_TITLE",
          "DIALOG.GENERIC_SUCCESS_MESSAGE"
        );
      },
      (error) => {
        console.log(
          "Errore nella creazione della richiesta di supporto",
          error
        );
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }
}
