import { OverlayService } from "./../../_services/overlay.service";
import { SelfEnrollmentComponent } from "./../self-enrollment/self-enrollment.component";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../_services/auth.service";

interface AnchorCard {
  label: string;
  image: string;
  link: string;
}

@Component({
  selector: "app-navigation-console",
  templateUrl: "./navigation-console.component.html",
  styleUrls: ["./navigation-console.component.scss"],
})
export class NavigationConsoleComponent implements OnInit {
  url: string;

  adminHomeCards: AnchorCard[] = [
    { label: "CONSOLE.AZIENDABNL", image: "azienda.svg", link: "../axepta" },
    { label: "CONSOLE.CLIENTIBNL", image: "clienti.svg", link: "../merchants" },
  ];

  axeptaCards: AnchorCard[] = [
    {
      label: "CONSOLE.PROFILO",
      image: "profilo-icona.svg",
      link: "./user-profile",
    },
    {
      label: "AZIENDA.AGGIUNGIUTENTE",
      image: "richiedi-pagamento-icona.svg",
      link: "./users",
    },
  ];

  merchantHomeCards: AnchorCard[] = [
    {
      label: "CONSOLE.RICHIEDIPAGAMENTO",
      image: "richiedi-pagamento-icona.svg",
      link: "../request/new",
    },
    {
      label: "CONSOLE.CONTROLLA",
      image: "controlla-icona.svg",
      link: "../requests",
    },
    {
      label: "CONSOLE.AZIENDA",
      image: "azienda-icona.svg",
      link: "../my-company",
    },
    {
      label: "CONSOLE.CLIENTI",
      image: "clienti-icona.svg",
      link: "../clients",
    },
  ];

  companyCards: AnchorCard[] = [
    {
      label: "AZIENDA.PROFILOUTENTE",
      image: "profilo-icona.svg",
      link: "./user-profile",
    },
    {
      label: "AZIENDA.AGGIUNGIUTENTE",
      image: "richiedi-pagamento-icona.svg",
      link: "./users",
    },
    {
      label: "AZIENDA.PROFILOAZIENDA",
      image: "azienda-icona.svg",
      link: "./company-profile",
    },
    {
      label: "AZIENDA.AGGIUNGIPROFILO",
      image: "aggiungi-profilo-icona.svg",
      link: "./profiles",
    },
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private overlayService: OverlayService
  ) {}

  ngOnInit() {
    this.url = this.router.url;
    if (!this.authService.user) {
      return;
    }
    if (
      this.authService.user.type === "CUSTOMER" &&
      this.authService.user.role === "USER"
    ) {
      this.merchantHomeCards[2] = {
        label: "AZIENDA.PROFILOUTENTE",
        image: "profilo-icona.svg",
        link: "../../user-profile",
      };
    }
    if (!this.authService.user.isCreatePaymentRequestActive) {
      this.merchantHomeCards.shift();
    }

    if (this.authService.user.type === "SUPPLIER") {
      this.companyCards.shift();
    }

    if (
      this.authService.user.type === "CUSTOMER" &&
      this.authService.user.role === "ADMIN" &&
      this.authService.user.merchant.isSelfEnrollmentActive
    ) {
      const selfEnrollmentHasBeenVisited = sessionStorage.getItem(
        "selfEnrollmentHasBeenVisited"
      );
      if (selfEnrollmentHasBeenVisited) {
        return;
      }
      this.overlayService.open(SelfEnrollmentComponent);
    }
  }

  get cards(): AnchorCard[] {
    let cards: AnchorCard[];
    switch (this.url) {
      case "/admin/home":
        cards = this.adminHomeCards;
        break;
      case "/admin/axepta":
        cards = this.axeptaCards;
        break;
      case "/merchant/home":
        cards = this.merchantHomeCards;
        break;
      case "/merchant/my-company":
        cards = this.companyCards;
        break;
      default:
        cards = [];
    }
    return cards;
  }
}
