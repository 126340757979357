<div style="padding: 20px">
  <mat-table [dataSource]="_merchants" multiTemplateDataRows matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
    <!--NOME-->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="textTableFont" mat-sort-header>
        <span>{{'CLIENTI.TABELLACLIENTE' | translate}} </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <!--INSEGNA-->
    <ng-container matColumnDef="insegna">
      <mat-header-cell *matHeaderCellDef class="textTableFont" mat-sort-header>
        <span>{{'CLIENTI.TABELLAINSEGNA' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.insegna}} </mat-cell>
    </ng-container>
    <!--PROVINCIA-->
    <ng-container matColumnDef="province">
      <mat-header-cell *matHeaderCellDef class="textTableFont mat-column-province" mat-sort-header>
        <span>{{'PROFILO.PROVINCIA' | translate | uppercase}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.province}} </mat-cell>
    </ng-container>
    <!--EMAIL-->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef class="textTableFont" mat-sort-header>
        <span> {{'CLIENTI.TABBELLAEMAIL' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
    </ng-container>
    <!--PARTITA IVA-->
    <ng-container matColumnDef="piva">
      <mat-header-cell *matHeaderCellDef class="textTableFont">
        <span>{{'PROFILO.PIVA' | translate | uppercase }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.piva}} </mat-cell>
    </ng-container>
    <!--REFERENTE-->
    <ng-container matColumnDef="referent">
      <mat-header-cell *matHeaderCellDef class="textTableFont" mat-sort-header>
        <span>{{'CLIENTI.TABPERSON' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element?.referent}}
      </mat-cell>
    </ng-container>
    <!--TID-->
    <ng-container matColumnDef="terminal">
      <mat-header-cell *matHeaderCellDef class="textTableFont">
        <span>{{'CLIENTI.TABELLATID' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.terminal?.length > 0">{{element?.terminal}}</span>
      </mat-cell>
    </ng-container>
    <!--ACTIONS-->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="textTableFont"> {{'CLIENTI.TABELLAACTION' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" style="justify-content: flex-end;">
        <div fxLayout="row" fxLayoutAlign="end" (click)="$event.stopPropagation()">
          <!--EXPORTEXCEL-->
          <button mat-icon-button fxHide.lt-sm (click)="downloadExcel(element)">
            <mat-icon style="margin-right: 0; opacity: 0.7;" svgIcon="downloadIcon"
              matTooltip="{{'HOLDING.EXPORTEXCEL' | translate}}"></mat-icon>
          </button>
          <!--IMPERSONIFICA-->
          <button mat-icon-button (click)="impersonificaAzienda(element)">
            <mat-icon style="margin-right: 0" class="inperson icon-show"
              matTooltip="{{'HOLDING.ENTERSTORE' | translate}}"></mat-icon>
          </button>
          <div fxLayout fxHide.lt-sm>
            <!--MODIFICA-->
            <button mat-icon-button (click)="modificaAzienda(element)">
              <mat-icon style="margin-right: 0" class="edit icon-modifica"
                matTooltip="{{'HOLDING.EDITSHOP' | translate}}"></mat-icon>
            </button>
            <!--INFO-->
            <button mat-icon-button (click)="toggleRow(element)">
              <mat-icon style="margin-right: 0; opacity:0.7" class="icon-mostra-dettaglio"
                matTooltip="{{'HOLDING.PAYMENTSUMMARY' | translate}}"></mat-icon>
            </button>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <!-- The row expands to show the payment details-->
        <div fxLayout="row" fxFlexFill fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.sm="8px"
          fxLayoutGap.xs="8px" style="background-color: #f9f9f9; width: 100%;"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div fxFlex>
            <p class='box-info' fxLayoutAlign="space-between center">
              {{'CONTROLLA.TOTPAGAMENTIRICEVUTI' | translate}}
              <span *ngIf="!(holdingService.isStatsPending$ | async)"
                class="amount-style"> {{element?.paymentsReceived?.amount | number: '1.0':'it'}} €</span>
              <mat-progress-spinner *ngIf="(holdingService.isStatsPending$ | async)" style="margin-right: 50px;"
                mode="indeterminate" diameter="20" class="black-spinner">
              </mat-progress-spinner>
            </p>
          </div>
          <div fxFlex>
            <p class='box-info' fxLayoutAlign="space-between center">
              {{'CONTROLLA.TOTPAGAMENTIATTESA' | translate}}
              <span *ngIf="!(holdingService.isStatsPending$ | async)"
                class="amount-style">{{element?.paymentsPending?.amount | number: '1.0':'it'}} €</span>
              <mat-progress-spinner *ngIf="(holdingService.isStatsPending$ | async)" style="margin-right: 50px;"
                mode="indeterminate" diameter="20" class="black-spinner">
              </mat-progress-spinner>
            </p>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" style="text-transform: uppercase;"></mat-header-row>
    <mat-row *matRowDef="let element; columns: displayedColumns;" class="operation-element-row"
      [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)">
    </mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="operation-detail-row"></mat-row>
  </mat-table>
</div>

<!--PAGINATOR-->
<mat-paginator style="margin-bottom: 20px;" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
  class="matPaginator"></mat-paginator>
