import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TemplateService } from '../../../../../_services/template.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MerchantService } from '../../../../../_services/merchant.service';
import { Merchant } from '../../../../../_models/merchant';
import{ DialogService } from '../,,/../../../../../_services/dialog.service';
import { forkJoin, Subscription } from 'rxjs';
import { Template } from '../../../../../_models/template';
import { LoaderService } from '../../../../../_services/loader.service';

@Component({
  selector: 'app-mail-template',
  templateUrl: './mail-template.component.html',
  styleUrls: ['./mail-template.component.scss']
})
export class MailTemplateComponent implements OnInit, OnDestroy {

  merchant: Merchant;
  merchantSubscription: Subscription;

  todo: Array<Template> = [];
  done: Array<Template> = [];
  backupA: Array<Template> = [];

  isSaveDisabled: boolean = true;

  constructor(
    private templateService: TemplateService,
    private merchantService: MerchantService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
  ) { }

  ngOnDestroy() {
    this.merchantSubscription.unsubscribe();
  }


  ngOnInit() {
    this.merchantSubscription = this.merchantService.currentMerchant$.subscribe(
      value => this.setMerchant(value)
    )
  }

  setMerchant(value: Merchant) {
    if (!value) {return; }
    this.merchant = value;
    this.getTemplates();
  }

  getTemplates() {
    if (!this.merchant) {return; }
    this.loaderService.startGeneral();
    const obs = [this.templateService.getStandard()];
    if (this.merchant.isCustomTemplateActive) {
      obs.push(this.templateService.getCustomByMerchant(this.merchant._id))
    }
    forkJoin(obs).subscribe(
      (myTemplates: Array<Array<Template>>) => {
        this.loaderService.stopGeneral();
        this.todo = myTemplates[0];
        if (myTemplates[1]) {
          this.todo = this.todo.concat(myTemplates[1])
        }
        this.done = this.merchant.templates.filter((el: Template) => el.active === true);
        this.resetBackup();
        this.todo = this.todo.filter(o => !this.done.some(v => v._id === o._id));
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.container.id == "attivi" && this.done.length >= 5) {
          return false;
      } else {
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
      }
    }
    this.isSaveDisabled = this.checkDiff();
  }


  checkDiff(){
    return (JSON.stringify(this.done) == JSON.stringify(this.backupA) || this.done.length == 0);
  }

  aggiungiTemplate(){
    if (!this.merchant) {return; }
    //this.merchant.templates = this.done;
    const body = {templates: this.done}
    this.loaderService.startGeneral();
    this.merchantService.update(this.merchant._id, body)
    .subscribe(
    response => {
      this.loaderService.stopGeneral();
      this.resetBackup();
      this.isSaveDisabled = this.checkDiff();
      this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
    }, error => {
      this.loaderService.stopGeneral();
      this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
    });
  }

  resetBackup(){
    this.backupA = Object.assign([], this.done);
  }

}
