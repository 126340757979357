<div [formGroup]="socialForm">
  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.FACEBOOK' | translate}}" formControlName="facebook">
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.LINKEDIN' | translate}}" formControlName="linkedin">
      </mat-form-field>
    </span>
  </div>


  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.TWITTER' | translate}}" formControlName="twitter">
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.SITO' | translate}}" formControlName="website">
      </mat-form-field>
    </span>
  </div>
  <div *ngIf="!isSelfEnrollment" class="buttonRow">
    <button [disabled]="!merchant || !merchant?._id" mat-flat-button color="primary"
      (click)="notifyChanges()">{{'PROFILO.FILTRO2BUTTON' | translate}}</button>
  </div>
</div>
