// eslint-disable-next-line no-restricted-imports
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Recurrent } from './../_models/recurrent';
import { AuthService } from './auth.service'
import { map } from 'rxjs/operators';

@Injectable()
export class RecurrentService {
  private readonly RECURRENT_URL: string = environment.BASE_URL + 'recurrent';

  constructor(private http: HttpClient, private authService: AuthService) { }


  create(recurrent: Recurrent): Observable<Recurrent> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.post<any>(this.RECURRENT_URL, recurrent, { headers }).pipe(
      map(response => response.item)
    )
  }

  searchId(_id: string): Observable <{} | Recurrent[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.get<{} | Recurrent[]>(this.RECURRENT_URL + '/' + _id, { headers });
  }


  update(_id: string, recurrent: Recurrent): Observable <{} | Recurrent[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.put(this.RECURRENT_URL + '/' + _id, recurrent, { headers });
  }
}
