import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { MerchantService } from '../_services/merchant.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantGuard implements CanActivate {
  constructor(private authService: AuthService, private merchantService: MerchantService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.merchantService.currentMerchant && !this.merchantService.isAdminMode) {
      return true; 
    }
    else {
      let redirectUrl = this.authService.user.type === 'SUPPLIER' ? 'admin' : 'holding';
      return this.router.createUrlTree(['/', redirectUrl]); 
    }
  }
  
}
