import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  Bread: any[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const myRoute = this.getFirstRoute(this.activatedRoute);
    this.Bread = this.getCrumbs(myRoute);
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .pipe(map(() => this.activatedRoute))
    .pipe(
      map((route) => {
      while (route.firstChild) { 
        route = route.firstChild; 
      }
      return route;
    }))
    .pipe(filter(route => route.outlet === 'primary'))
    .subscribe(route => {
      this.Bread = this.getCrumbs(route);
    });
  }

  getFirstRoute(route: ActivatedRoute){
    while (route.firstChild) { 
      route = route.firstChild; 
    }
    return route;
  }

  getCrumbs(route: ActivatedRoute) {
    const breadCrumbs = [];
    if (!route.snapshot.data.hasOwnProperty('breadcrumbs')) { return breadCrumbs; }
    route.snapshot.data.breadcrumbs.map((crumb: any) => {
      const breadCrumb = {
        label: 'URL.' + crumb.label,
        url: route.snapshot.params.id && crumb.url ? crumb.url.replace(':id', route.snapshot.params.id) : crumb.url
      }
      breadCrumbs.push(breadCrumb);
    })
    return breadCrumbs;
  }

}
