<form [formGroup]="filterFormHolding" style="margin-bottom: 15px;">

  <div fxLayout="row" fxFlexFill fxLayout.lt-lg="column" fxLayoutGap="30px" style="padding: 20px">

    <div fxFlex='45' fxLayout="row" fxFlexFill fxLayout.lt-sm="column" fxLayoutGap="35px">
      <mat-form-field class="filter-input" fxFlex>
        <mat-select #nobackgiorni placeholder="{{'HOLDING.DAYS' | translate}}" formControlName="from_date">
          <mat-option class="no-background" *ngFor="let item of days" [value]="item.value">
            {{ item.viewValue | translate }}
          </mat-option>
        </mat-select>
        <mat-icon style="font-size: 24px; opacity: 0.6;" matSuffix>today</mat-icon>
      </mat-form-field>

      <mat-form-field class="filter-input" fxFlex>
        <input matInput placeholder="{{'HOLDING.PROVINCESELECTED' | translate}}" formControlName='province'
          [matAutocomplete]="auto">
        <mat-icon matSuffix style="cursor: pointer; font-size: 24px; opacity: 0.6;">arrow_drop_down</mat-icon>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onProvinceSelected($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.valueView">
            {{option.valueView}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div fxFlex='45' fxLayout="row" fxFlexFill fxLayout.lt-sm="column" fxLayoutGap="35px">
      <mat-form-field class="filter-input" fxFlex>
        <input matInput placeholder="{{'HOLDING.FINDNAME' | translate}}" formControlName='name'>
      </mat-form-field>

      <mat-form-field class="filter-input" fxFlex>
        <input matInput placeholder="{{'HOLDING.FINDPAYMENTID' | translate}}" formControlName='paymentId'>
        <mat-error *ngIf="filterFormHolding.controls.paymentId?.hasError('minlength')">{{'HOLDING.INVALIDPAYMENTID' |
          translate}}</mat-error>
        <mat-error *ngIf="filterFormHolding.controls.paymentId?.hasError('invalid_payment_id')">{{
          'HOLDING.INVALIDPAYMENTID' | translate}}</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex fxLayout="row" fxFlexFill fxLayoutAlign="end center" fxLayoutGap="35px">
      <button type='button' mat-flat-button color="primary" class='rounded-button' fxFlex.lg='70' mat-flat-button
        color="primary" (click)="resetFilters()">{{'HOLDING.RESETFILTER' | translate}}</button>
      <button type="button" fxFlex='46px' style="padding: 2px; height: 46px" mat-icon-button class="btn-download"
        (click)='exportReportMerchants()'>
        <mat-icon style="transform: scale(2);" svgIcon='downloadIcon'
          matTooltip="{{'HOLDING.EXPORTEXCEL' | translate}}"></mat-icon>
      </button>
    </div>
  </div>

</form>
