import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PaymentService } from '../../_services/payment.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MerchantService } from '../../_services/merchant.service';
@Component({
  selector: 'app-operations-detail-second',
  templateUrl: './operations-detail-second.component.html',
  styleUrls: ['./operations-detail-second.component.scss']
})
export class OperationsDetailSecondComponent implements OnInit, OnDestroy {
  @Input() element: any;
  @Input() transactionList?: Array<any>;

  dataSource: MatTableDataSource<any>;

  URL = environment.BASE_URL + 'payment/qrcode/';
  author = {
    'name': 'n.d.',
    'profile': 'n.d.',
    'role': 'n.d.'
  };
  request = {
    'state': 'n.d.',
    'recipient': 'n.d.',
    'error': 'n.d.',
    'subject': 'n.d.',
    'sentDate': 'n.d.',
    'receivedDate': 'n.d.'
  };

  whatsappLink: SafeUrl;
  whatsappTarget = '_blank';

  displayedColumns = ['rata', 'data', 'amount', 'esito'];
  show: boolean = false;

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  private isMobile: boolean;
  private watcher: Subscription;


  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private paymentService: PaymentService,
    private breakpointObserver: BreakpointObserver,
    private merchantService: MerchantService
  ) { }

  ngOnInit() {

    this.dataSource = new MatTableDataSource(this.element.recurrents);

    moment(this.element.createdAt).format('DD-MM-YYYY')

    if (!this.element) { return; }

    this.boldCompletedElements();

    this.watcher = this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.getAuthor();
    if (this.element.mail_status) { this.calcEmailStatus(); }
    if (this.element.sms_status) { this.calcSmsStatus(); }
    if (this.element.operation_type === 'WHATSAPP') {
      const whatsAppInfo = this.paymentService.generateWhatsAppUrl(this.element, this.isMobile);
      this.whatsappLink = this.sanitizer.bypassSecurityTrustUrl(whatsAppInfo.link);
      this.whatsappTarget = whatsAppInfo.target;
    }
  }

  ngOnDestroy() {
    if (!this.watcher) { return; }
    this.watcher.unsubscribe();
  }

  getState(state) {
    switch (state) {
      case 'sent':
        return 'OPERAZIONI.REQUEST_DELIVERED';
      case 'bounced':
        return 'OPERAZIONI.REQUEST_BOUNCED';
      case 'rejected':
        return 'OPERAZIONI.REQUEST_REJECTED'
      default:
        return 'n.d.';
    }
  }

  calcEmailStatus() {
    const mailStatus = Array.isArray(this.element.mail_status) ? this.element.mail_status[0] : this.element.mail_status;
    this.request.subject = mailStatus?.subject || 'n.d.';
    this.request.recipient = mailStatus?.email || 'n.d.';
    this.request.state = this.translate.instant(this.getState(mailStatus?.state || mailStatus?.status));
  }

  calcSmsStatus() {
    const codes = [8, 12, 52];
    let state: string;
    let statName: string;
    let errorId: number;
    let error: string;
    let sentDate: string;
    let receivedDate: string;

    // codice per vecchi pagamenti
    if (this.element.sms_status.messages) {
      this.request.recipient = this.element.sms_status.messages[0].to ? this.element.sms_status.messages[0].to : 'n.d';
      state = this.element.sms_status.messages[0].status ? this.element.sms_status.messages[0].status.groupName : undefined;
      statName = this.element.sms_status.messages[0].status ? this.element.sms_status.messages[0].status.name : undefined;
      errorId = this.element.sms_status.messages[0].status ? this.element.sms_status.messages[0].status.id : undefined;
      error = this.element.sms_status.messages[0].error && this.element.sms_status.messages[0].error.id !== 0
        ? this.element.sms_status.messages[0].error.groupName
        : undefined;
    } else { // codice per nuovi pagamenti
      this.request.recipient = this.element.sms_status.to ? this.element.sms_status.to : 'n.d';
      state = this.element.sms_status.status ? this.element.sms_status.status.groupName : undefined;
      statName = this.element.sms_status.status ? this.element.sms_status.status.name : undefined;
      errorId = this.element.sms_status.status ? this.element.sms_status.status.id : undefined;
      error = this.element.sms_status.error && this.element.sms_status.error.id !== 0 ? this.element.sms_status.error.groupName : undefined;
      sentDate = this.element.sms_status.sentAt ? this.element.sms_status.sentAt : undefined;
      receivedDate = this.element.sms_status.doneAt ? this.element.sms_status.doneAt : undefined;
    }

    if (state) {
      const stateString = 'OPERAZIONI.REQUEST_' + state;
      this.request.state = this.translate.instant(stateString);
    }
    if (sentDate) {
      const sDate = new Date(sentDate);
      this.request.sentDate = sDate.toLocaleString();
    }
    if (receivedDate) {
      const rDate = new Date(receivedDate);
      this.request.receivedDate = rDate.toLocaleString();
    }

    if (codes.indexOf(errorId) !== -1) {
      this.request.error = this.translate.instant('OPERAZIONI.' + statName);
    }

    if (error) {
      this.request.error = this.translate.instant('OPERAZIONI.' + error);
    }

  }

  getAuthor() {
    if (this.element.user) {
      const name = this.element.user.first_name && this.element.user.last_name
        ? this.element.user.first_name + ' ' + this.element.user.last_name : 'n.d.';
      let role = this.element.user.role ? this.element.user.role : 'n.d.';
      let profile = null;

      if (this.element.user.type === 'HOLDING' && this.element.user.merchants && this.element.user.merchants.length) {
        let currentMerchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : undefined;
        let _merchant = this.element.user.merchants.find(el => el.merchant == currentMerchantId)
        if (_merchant && _merchant.role) {
          role = _merchant.role;
        }
      }

      if (this.element.user.profile) {
        profile = this.element.user.profile

        if (typeof this.element.user.profile == 'object') {
          profile = profile.name;
        }
      } else {
        profile = 'n.d.';
      }

      this.author = {
        'name': name.toLowerCase(),
        'profile': profile.toLowerCase(),
        'role': role.toLowerCase()
      };
    }
  }

  formattedPaymentData(element) {
    return moment(element.execution_date).format('DD-MM-YYYY')
  }

  boldCompletedElements() {
    const filterRecurrentWithStatus = this.element.recurrents.filter(el => el.state !== 'ACTIVE');
    const lastWithStatus = filterRecurrentWithStatus[filterRecurrentWithStatus.length - 1]
    const recurrents = this.element.recurrents.map(el => {
      el.isBold = el._id === lastWithStatus?._id
      return el;
    })
    this.element.recurrents = recurrents;
  }

  showMore() {
    this.show = !this.show;
  }


}
