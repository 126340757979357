import { catchError, delay, map, tap } from 'rxjs/operators';
import { IAsyncFiltersHolding } from './../_models/filters-holding';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LoaderService } from './loader.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HoldingMerchantInfo, HoldingTableMerchant } from '../_models/holding-table-merchant';

@Injectable({
  providedIn: 'root'
})
export class HoldingService {

  private readonly HOLDING_URL: string = `${environment.BASE_URL}holding`;
  private idPaymentErrorEvent = new Subject();
  public idPaymentErrorEvent$ = this.idPaymentErrorEvent.asObservable();

  private isStatsPending = new BehaviorSubject(false);
  public isStatsPending$ = this.isStatsPending.asObservable();

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private loaderService: LoaderService
  ) { }

  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    return headers;
  }

  setParams(fromdata?: string, idPayment?: string, province?: string) {
    let params = new HttpParams();
    let currentDay = moment().format('YYYY-MM-DD');
    params = fromdata ? params.set('from_date', moment.utc(fromdata).toISOString()) : params;
    params = fromdata ? params.set('to_date', moment.utc(currentDay).endOf('day').toISOString()) : params;
    params = idPayment ? params.set('paymentId', idPayment) : params;
    params = province ? params.set('province', province) : params;
    return params;
  }

  getStatsHolding(filter: IAsyncFiltersHolding) {
    this.isStatsPending.next(true);
    const headers = this.getHeaders();
    const params = filter ? this.setParams(filter.from_date, filter.paymentId, filter.province) : new HttpParams();
    return this.http.get<any>(`${this.HOLDING_URL}/stats`, { headers, params }).pipe(
      //delay(5000),
      map(response => {
        this.isStatsPending.next(false);
        return {
          total: response.total,
          //result: response.result.map(el => Object.assign(el.merchant, { paymentsPending: el.paymentsPending }, { paymentsReceived: el.paymentsReceived }))
          result: response.result.map(el => {
            let newMerchant = new HoldingTableMerchant(el.merchant);
            newMerchant.assignStatsData(el);
            return newMerchant;
          })
        }
      }),
      catchError(err => {
        this.isStatsPending.next(false);
        this.loaderService.stopGeneral();
        if (err && err.error.code === 400) {
          this.idPaymentErrorEvent.next(Math.random());
        }
        return of({ total: null, result: [] });
      })
    )
  }

  getMerchantsHolding(): Observable<HoldingTableMerchant[]> {
    const headers = this.getHeaders();
    return this.http.get<any>(this.HOLDING_URL, { headers }).pipe(
      map(response => response.merchants.map((merchant: HoldingMerchantInfo) => new HoldingTableMerchant(merchant)))
    )
  }

  getExcelMerchants() {
    const headers = this.getHeaders();
    return this.http.get(`${this.HOLDING_URL}/export`, { headers, responseType: 'blob' })
  }

  getExcelSingleMerchant(id: string) {
    const headers = this.getHeaders();
    return this.http.get(`${this.HOLDING_URL}/${id}/export`, { headers, responseType: 'blob' })
  }
}
