import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormGroup, ValidationErrors, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';


export function MatchValidator(control: UntypedFormGroup): ValidationErrors | null {
    const errorObj = {
        matchOld: false,
        mismatch: false,
    }
    let hasError = false;
    const old = control.get('temporary');
    const password = control.get('password');
    const confirm = control.get('confirm');
    if (!old) {
        return password && confirm && password.value === confirm.value ? null : {mismatch: true};
    } 
    if (password && old.value === password.value) {
        hasError = true;
        errorObj.matchOld = true;
    }
    if (password && confirm && confirm.value !== password.value) {
        hasError = true;
        errorObj.mismatch = true;
    }
    return hasError ? errorObj : null;

}



export class MismatchErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.touched && control.errors || control.dirty && form.errors && form.errors.mismatch;
    }
}

export class MatchOldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.touched && control.errors || control.dirty && form.errors && form.errors.matchOld;
    }
}