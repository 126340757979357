<div style="padding: 0 20px 20px 20px">
  <mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
    matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
    <!-- ICON -->
    <ng-container matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef style="text-align: center;">
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        <mat-icon *ngIf="payment.isPaymentOpen" class="iconOpenPayment" svgIcon="openPayment"></mat-icon>
        <div *ngIf="!payment.isPaymentOpen" class="icon-mostra-dettaglio">
        </div>
      </mat-cell>
    </ng-container>

    <!-- DATE -->

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          {{'CONTROLLA.DATAINVIOLINK' | translate}}
         </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        <span *ngIf="payment.state !== 'EXPIRED' && payment.payment_type !== 'SINGLE' ||  payment.payment_type === 'SINGLE'"> {{payment.createdAt | date:'dd/MM/yyyy' }} </span>
        <span *ngIf="payment.state === 'EXPIRED' && payment.payment_type !== 'SINGLE'" matTooltip="{{'CONTROLLA.LINKEXPIRED' | translate}}" class="expired-link">
          {{payment.createdAt | date:'dd/MM/yyyy' }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstPayment">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span> {{'CONTROLLA.DATAPRIMOPAGAMENTO' | translate}} </span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        {{ payment.firstRecurrentDate | momentUtcFormat: 'DD-MM-YYYY' }}
      </mat-cell>
    </ng-container>

    <!-- STATE RECURRENT-->
    <ng-container matColumnDef="stateRecurrent">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont" style="text-align: center;">

        <div fxLayout="row" fxLayoutAlign="center center">
          <div [ngSwitch]="payment_type">
            <span *ngSwitchCase="'SINGLE'"> {{'CONTROLLA.STATOSINGOLO' | translate}} </span>
            <span *ngSwitchCase="'RECURRENT'"> {{'CONTROLLA.STATORICORRENZE' | translate}} </span>
            <span *ngSwitchCase="'DEFERRED'"> {{'CONTROLLA.STATODILAZIONI' | translate}} </span>
          </div>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment">
        <div *ngIf="payment.isRecurrentPlanActive" style="display: flex; align-items: center;">
          <img alt="error icon" *ngIf="payment.recurrents_status === 'ERROR'" class="triangle-alert"
            [src]="ASSETS_BASE + 'svg/iconsvg/triangleError.svg'">
          <span>
            {{getRecurrentPayed(payment)}} {{'CONTROLLA.DI' | translate}} {{showRepeatedPayments(payment)}}
          </span>
        </div>
        <div *ngIf="!payment.isRecurrentPlanActive" style="align-items: center; display: flex;">
          <img matTooltip="{{('OPERAZIONI.PIANODISATTIVATO' | translate).toUpperCase()}}" id="DEACTIVATED" alt="payment status icon" class="payment-status-icon" [src]="ASSETS_BASE + 'svg/iconsvg/pause.svg'"> <span> {{'OPERAZIONI.DISATTIVATO' | translate}}</span>
        </div>

      </mat-cell>
    </ng-container>

    <!-- STATE SINGLE-->
    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span> {{'CONTROLLA.TABELLASTATO' | translate}} </span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="space-around center" class="statoControl">
          <div [ngSwitch]="payment.state">
            <img alt="waiting icon" *ngSwitchCase="'WAIT'" style="height: 20px;"
              [src]="ASSETS_BASE + 'svg/iconsvg/in-attesa.svg'">
            <img alt="expired icon" *ngSwitchCase="'EXPIRED'" style="height: 20px;"
              [src]="ASSETS_BASE + 'svg/iconsvg/scaduto.svg'">
            <img alt="done icon" *ngSwitchCase="'COMPLETED'" style="height: 20px;"
              [src]="ASSETS_BASE + 'svg/iconsvg/completato.svg'">
            <img alt="active icon" *ngSwitchCase="'ACTIVE'" style="height: 20px;"
              [src]="ASSETS_BASE + 'svg/iconsvg/attivo.svg'">
          </div>
          <span style="font-size: 12px; text-transform: uppercase;"> {{ 'CONTROLLA.' + payment.state | translate }} </span>
        </div>
      </mat-cell>
    </ng-container>


    <!-- AMOUNT -->
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span style="text-transform: uppercase;">{{ 'CONTROLLA.TOTALIMPORT' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        {{getAmount(payment)}}
      </mat-cell>
    </ng-container>

    <!-- NAME -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{'CONTROLLA.TABELLANOME' | translate}}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;"> {{payment.name}}
        <mat-icon *ngIf="payment.isCloned === true" id="clone-icon">backup</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- ID OPERAZIONE -->
    <ng-container matColumnDef="operation_id">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{'CONTROLLA.IDOPERAZIONE' | translate}}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        {{payment.recurrent_plan_id}}
      </mat-cell>
    </ng-container>

    <!-- NOTIFICATION TYPE -->
    <ng-container matColumnDef="operation_type">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{'CONTROLLA.TABELLAMODALITAINVIO' | translate}}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        <img alt="operation type icon" style="height: 30px;"
          [src]="ASSETS_BASE + 'svg/iconsvg/dettaglio-'+ payment.operation_type + '.svg'">
      </mat-cell>
    </ng-container>

    <!-- PAYMENT PROVIDER -->
    <ng-container matColumnDef="brand">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{'CONTROLLA.TABELLAMODALITADIPAGAMENTO' | translate}}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        <img alt="payment provider logo" *ngIf="payment.outcomes
          && payment.outcomes[payment.outcomes.length - 1]
          && (payment.outcomes[payment.outcomes.length - 1].outcome_response.brand || payment.outcomes[payment.outcomes.length - 1].outcome_response.payInstr)" style="width: 50px;"
          [src]="payment.outcomes[payment.outcomes.length - 1].outcome_response.payInstr === 'APAY' || payment.outcomes[payment.outcomes.length - 1].outcome_response.payInstr === 'PPAL' || payment.outcomes[payment.outcomes.length - 1].outcome_response.payInstr === 'MYBK' ? ASSETS_BASE + 'svg/circuiti/'+ payment.outcomes[payment.outcomes.length - 1].outcome_response.payInstr + '.png' : ASSETS_BASE + 'svg/circuiti/'+ payment.outcomes[payment.outcomes.length - 1].outcome_response.brand + '.png'">
      </mat-cell>
    </ng-container>

    <!-- OUTCOME -->
    <ng-container matColumnDef="outcomeRecurrent">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span style="text-transform: uppercase;">{{'CONTROLLA.FILTROULTIMOSTATO' | translate}}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="align-items: center">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <div class="payment-status-container">
            <span *ngIf="payment.firstPaymentAttempted">
              <ng-container [ngSwitch]="payment.recurrents_status">
                <ng-container *ngSwitchCase="'OPEN'">
                  <img matTooltip="{{'CONTROLLA.EXECUTEDSUCESSFULLY' | translate}}" id="OPEN" alt="payment status icon" class="payment-status-icon" [src]="ASSETS_BASE + 'svg/iconsvg/ok.svg'">
                </ng-container>
                <ng-container *ngSwitchCase="'ERROR'">
                  <img matTooltip="{{'CONTROLLA.EXECUTEDFAILED' | translate}}" id="ERROR" alt="payment status icon" class="payment-status-icon" [src]="ASSETS_BASE + 'svg/iconsvg/x.svg'">
                </ng-container>
                <ng-container *ngSwitchCase="'COMPLETED'">
                  <img matTooltip="{{'CONTROLLA.EXECUTEDSUCESSFULLY' | translate}}" id="COMPLETED" alt="payment status icon" class="payment-status-icon" [src]="ASSETS_BASE + 'svg/iconsvg/ok.svg'">
                </ng-container>
              </ng-container>
            </span>
            <span *ngIf="!payment.firstPaymentAttempted">
              <img matTooltip="{{('CONTROLLA.NOTEXECUTEDYET' | translate).toUpperCase()}}" id="NOT-EXECUTED-YET" alt="payment status icon" class="payment-status-icon" [src]="ASSETS_BASE + 'svg/iconsvg/wait.svg'">
            </span>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- PAYMENT ID -->

    <ng-container matColumnDef="payment_id">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{'CONTROLLA.TABELLAIDPAGAMENTO' | translate}}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let payment" style="text-align: center;">
        <span *ngIf="payment.outcomes
          && payment.outcomes[payment.outcomes.length - 1]">
          {{ payment.outcomes[payment.outcomes.length - 1].outcome_response.paymentID }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- ACTION ICONS -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span></span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let operation" style="text-align: center;" (click)="$event.stopPropagation()">
        <div *ngIf="!isFullScreen" style="display: flex">

          <button *ngIf="!operation.isQueryString"
            [disabled]="operation.payment_type !=='SINGLE' || !user.isCreatePaymentRequestActive" class="iconButton"
            (click)="modificaPagamento(operation)">
            <mat-icon *ngIf="operation.state=='WAIT' || (operation.state=='ACTIVE' && isAdditionalInfoEditable)"
              [ngClass]="operation.payment_type !=='SINGLE' || !user.isCreatePaymentRequestActive ? 'editDisable' : 'edit'"
              class="icon-modifica" matTooltip="{{'PANNELLOUTENTE.TOOLTIPMODIFICA' | translate}}">
            </mat-icon>
          </button>
          <button *ngIf="operation.payment_type === 'SINGLE'"
            [disabled]="operation.state !== 'ACTIVE' || !isReminderActive || (operation.operation_type !== 'SMS' && operation.operation_type !== 'EMAIL')"
            class="iconButton" (click)="sollecito(operation)">
            <mat-icon
              [ngClass]="operation.state === 'ACTIVE' && isReminderActive && (operation.operation_type === 'SMS' || operation.operation_type === 'EMAIL') ? 'edit' : 'editDisable' "
              class="icon-sollecito" matTooltip="{{'CONTROLLA.SOLLECITA' | translate}}">
            </mat-icon>
          </button>
          <button *ngIf="operation.payment_type !== 'SINGLE'" [disabled]="!operation.firstRecurrentIDFailed"
            class="iconButton" (click)="notification(operation.firstRecurrentIDFailed)">
            <mat-icon [ngClass]="operation.firstRecurrentIDFailed ? 'edit' : 'editDisable' " class="icon-sollecito"
              matTooltip="{{'CONTROLLA.SOLLECITA' | translate}}">
            </mat-icon>
          </button>
          <button *ngIf="operation.payment_type !== 'SINGLE'" class="iconButton" (click)="ricorrenze(operation)">
            <mat-icon [ngClass]="operation.payment_type !=='SINGLE' ? 'edit' : 'editDisable' "
              class="icon-ricorrenza-popup" matTooltip="{{'CONTROLLA.RICRAT' | translate}}">
            </mat-icon>
          </button>
          <button class="iconButton" (click)="completaPagamento(operation)"
            *ngIf="operation.isPaymentOpen && operation.state !== 'COMPLETED'">
            <mat-icon class="edit my-icon-close" matTooltip="{{ 'OPERAZIONI.COMPLETA' | translate }}">
              close
            </mat-icon>
          </button>
          <button class="iconButton"
            *ngIf="operation.payment_type !== 'SINGLE' && operation.recurrents_status !== 'COMPLETED'"
            (click)="deactivateRecurrentPlan(operation)">
            <div [ngSwitch]="operation.isRecurrentPlanActive">
              <mat-icon *ngSwitchCase="true" class="edit my-icon-close"
                matTooltip="{{ 'OPERAZIONI.DISATTIVA_PIANO' | translate }}">
                close
              </mat-icon>
              <mat-icon *ngSwitchCase="false" class="edit my-icon-close"
                matTooltip="{{ 'OPERAZIONI.ATTIVA_PIANO' | translate }}">
                replay
              </mat-icon>
            </div>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let payment" [attr.colspan]="displayedColumns.length">
        <!--STANDARD PAYMENTS and open payments with no transactions-->
        <div *ngIf="!payment?.isPaymentOpen || (payment?.isPaymentOpen && payment?.outcomes.length < 1)"
          style="width: 100%">

          <div *ngIf="(expandedElement && payment === expandedElement) || isFullScreen"
            style="background-color: #e6e6e6!important; width: 100%;"
            [@detailExpand]="payment === expandedElement || isFullScreen ? 'expanded' : 'collapsed'">
            <!-- The row expands to show the payment details-->
            <div fxLayout="row" fxLayoutAlign="center" style="background-color: #f9f9f9;">
              <div fxFlex="33%">
                <app-operations-detail-first [isFullScreen]="isFullScreen" [element]='payment'>
                </app-operations-detail-first>
              </div>
              <div fxFlex="33%">
                <app-operations-detail-second [element]='payment'></app-operations-detail-second>
              </div>
              <div fxFlex="33%">
                <app-operations-detail-static *ngIf="!isFullScreen" [element]='payment'
                  (refreshOutcomes)="refreshTransactions()"></app-operations-detail-static>
                <app-operations-detail-third *ngIf="isFullScreen" [element]='payment'
                  (refreshOutcomes)="refreshTransactions()">
                </app-operations-detail-third>
              </div>
            </div>
            <div *ngIf="!isFullScreen && payment.payment_type === 'SINGLE'" fxLayout="row" fxLayoutAlign="end center"
              style="background-color:#e6e6e6; width: 100%;">
              <button mat-flat-button color="primary" class="rounded-button" style="margin: 10px 25px; height: 40px;"
                (click)="openDetail(payment)" [disabled]="payment?.outcomes.length < 1
                  || payment?.outcomes[payment?.outcomes.length - 1].outcome_type === 'MYBANK'
                  ">
                {{'CONTROLLA.EXPAND' | translate}}
              </button>
            </div>
          </div>
        </div> <!-- end of expanded standard payment-->

        <!-- OPEN PAYMENTS WITH AT LEAST ONE TRANSACTIONS-->
        <div *ngIf="payment?.isPaymentOpen && payment?.outcomes.length > 0"
          style=" background-color: #FFFFFF!important; width: 100%;"
          [@detailExpand]="payment === expandedElement || isFullScreen ? 'expanded' : 'collapsed'">
          <app-grid-open *ngIf="expandedElement && payment === expandedElement || isFullScreen"
            [isSmallScreen]="isSmallScreen" [isFullScreen]="isFullScreen" [payment]="payment">
          </app-grid-open>
        </div>

      </mat-cell>
    </ng-container>

    <!--defining header row-->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <!--defining row case "close"-->
    <mat-row *matRowDef="let payment; columns: displayedColumns;" class="operation-element-row"
      [class.operation-expanded-row]="expandedElement === payment" (click)="viewDetail(payment)">
    </mat-row>

    <!--defining row case "expanded"-->
    <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="operation-detail-row"
      [ngClass]="{'extraBorder': isFullScreen}"></mat-row>
  </mat-table>
</div>
