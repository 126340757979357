<span [ngClass]="theme">
  <mat-dialog-content class="dettaglio">
    <div fxHide.xs fxHide.sm fxLayout="column" fxLayoutAlign="space-around stretch">
      <div class="import-ops-container">
        <table mat-table [dataSource]="dataSource" matSort aria-describedby="Declined payments">
          <ng-container matColumnDef="info">
            <th scope="col" mat-sort-header="info" mat-header-cell *matHeaderCellDef class="headerTableOp"></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon class="icon-mostra-dettaglio" [matTooltip]="element?.error"></mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="data">
            <th scope="col" mat-sort-header="data" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLADATA' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.to_date | date:'dd/MM/yyyy' }} </td>
          </ng-container>

          <ng-container matColumnDef="importo">
            <th scope="col" mat-sort-header="importo" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLIMPORTO' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
          </ng-container>

          <ng-container matColumnDef="nome">
            <th scope="col" mat-sort-header="nome" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLANOME' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="valuta">
            <th scope="col" mat-sort-header="valuta" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLAVALUTA' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th scope="col" mat-sort-header="email" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLAEMAIL' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="telefono">
            <th scope="col" mat-sort-header="telefono" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLATELEFONO' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.smartphone}} </td>
          </ng-container>

          <ng-container matColumnDef="lingua">
            <th scope="col" mat-sort-header="lingua" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLALINGUA' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.language}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>
    </div>

    <div fxHide.md fxHide.lg fxHide.xl fxLayout="column" fxLayoutAlign="space-around stretch">
      <div class="import-ops-container">
        <table mat-table [dataSource]="dataSource" matSort aria-describedby="Declined payments">
          <ng-container matColumnDef="info">
            <th scope="col" mat-sort-header="info" mat-header-cell *matHeaderCellDef class="headerTableOp"></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon class="icon-mostra-dettaglio" [matTooltip]="element?.error"></mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="data">
            <th scope="col" mat-sort-header="data" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLADATA' | translate }}</th>
            <td mat-cell *matCellDef="let element" style='padding-right: 10px; padding-left: 10px;'> {{element.to_date |
              date:'dd/MM/yyyy' }} </td>
          </ng-container>

          <ng-container matColumnDef="nome">
            <th scope="col" mat-sort-header="nome" mat-header-cell *matHeaderCellDef class="headerTableOp">{{
              'CONTROLLA.TABELLANOME' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <p class="trunkate">{{element.name}}</p>
            </td>
          </ng-container>

          <ng-container matColumnDef="importo">
            <th scope="col" mat-sort-header="importo" mat-header-cell style='padding-left: 15px;' *matHeaderCellDef
              class="headerTableOp">{{ 'CONTROLLA.TABELLIMPORTO' | translate }}</th>
            <td mat-cell *matCellDef="let element" style='padding-left: 15px;'> {{element.amount}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsXs"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsXs;"></tr>

        </table>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="buttonContainer">
    <button mat-flat-button color="{{color}}" [mat-dialog-close]="true"
      class="rounded-button buttonDialog">{{'DIALOG.CLOSE' | translate}}</button>
  </mat-dialog-actions>
</span>
