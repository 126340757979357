<div fxLayout="row" fxLayoutAlign="space-between stretch">
  <div class="filtroCampoADue">
    <div class="template-picker-container" style="width: 100%">
      <div class="template-picker-label">{{ 'TEMPLATE.LISTA' | translate }}</div>
      <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" [cdkDropListConnectedTo]="[doneList]"
        class="template-picker-list" (cdkDropListDropped)="drop($event)">
        <div class="template-picker-box" *ngFor="let item of todo" cdkDrag><span
            style="font-weight: 600; margin-right: 5px;">{{item?.short_description}}</span><span
            style="font-weight: 300" *ngIf="item?.description"> - {{item?.description}}</span></div>
      </div>
    </div>
  </div>

  <div class="filtroCampoADue" style="margin-left: 30px;">
    <div class="template-picker-container" style="width: 100%">
      <div class="template-picker-label">{{'TEMPLATE.SCELTA' | translate}}</div>
      <div id='attivi' cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
        [cdkDropListConnectedTo]="[todoList]" class="template-picker-list" (cdkDropListDropped)="drop($event)">
        <div class="template-picker-box" *ngFor="let item of done" cdkDrag><span
            style="font-weight: 600; margin-right: 5px;">{{item?.short_description}}</span><span
            style="font-weight: 300" *ngIf="item?.description"> - {{item?.description}}</span></div>
      </div>
    </div>
  </div>

</div>
<div class="buttonBar">
  <button mat-flat-button color="primary" (click)="aggiungiTemplate()"
    [disabled]="isSaveDisabled">{{'PROFILO.FILTRO1BUTTON' | translate}}</button>
</div>
