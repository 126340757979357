import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { MatDialog } from "@angular/material/dialog";
import { LoaderService } from '../_services/loader.service';
import { DialogService } from '../_services/dialog.service';
import { throwError, TimeoutError } from 'rxjs';
import { backendException } from '../_models/backendException';
import { AuthService } from '../_services/auth.service';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private router: Router, public dialogService: DialogService, public dialog: MatDialog, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if ((event.body.status  === 'error' || event.body.status === 'unauthorized') && event.body.code !== undefined) {
              throw new backendException(
                event.body.name,
                event.body.message,
                event.body.code,
              );
            }
          }
          if (event instanceof TimeoutError) {
            throw new Error('timeout error');
          }
        },
        error => {
          if (error.status === 401) {
            localStorage.removeItem('clicpay-token');
            localStorage.removeItem('clicpay-merchant');
            return location.reload();
          }
          return throwError(error);
        }
      )
    );

  }



}
