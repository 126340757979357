import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogCaricamentiComponent } from '../../../../_components/dialog-caricamenti/dialog-caricamenti.component';
import { BulkService } from '../../../../_services/bulk.service';
import { DialogService } from '../../../../_services/dialog.service';
import { LoaderService } from '../../../../_services/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { merge, of, Subscription } from 'rxjs';
import { startWith, switchMap, map, catchError, filter } from 'rxjs/operators';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Bulk } from '../../../../_models/bulk';
import { AuthService } from '../../../../_services/auth.service';


@Component({
  selector: 'app-import-operations',
  templateUrl: './import-operations.component.html',
  styleUrls: ['./import-operations.component.scss']
})
export class ImportOperationsComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['createdAt', 'state', 'description', 'notification_type', 'auth_type', 'popolate', 'inviate', 'caricamenti', 'actions'];
  dataSource = new MatTableDataSource<any>();

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  results: number;
  sendEnabled: boolean = true;

  private watcher: Subscription;
  private activeMediaQuery: string;


  constructor(
    private route: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private bulkService: BulkService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private mediaObserver: MediaObserver,
    private authService: AuthService,
  ) {
    this.iconRegistry.addSvgIcon(
      'suspend', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/aborted.svg')
    );
    this.iconRegistry.addSvgIcon(
      'inviaSubito', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/inviaSubito.svg')
    );

    this.iconRegistry.addSvgIcon(
      'elimina', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/delete-icon.svg')
    );

    this.iconRegistry.addSvgIcon(
      'bulk-arrow', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/bulk-arrow.svg')
    );
  }

  ngOnInit() {
    this.bulkService.sendEnabled().subscribe((res:any)=>{
      if(res) {
        this.sendEnabled = res['enabled'];
      }
    })
    this.observeLayoutChanges();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.paginator.hidePageSize = true;
    this.onLoadImported();
  }

  ngOnDestroy() {
    if (!this.watcher) {return; }
    this.watcher.unsubscribe();
  }

  onLoadImported() {
    this.loaderService.startGeneral();
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        const sort = this.sort.direction === 'desc' ? '-' + this.sort.active : this.sort.active;
        return this.bulkService.getBulks(this.paginator.pageIndex, this.paginator.pageSize, sort);
      }),
      map((data: any) => {
        this.results = data.results.items;
        return data.items;
      }),
      catchError(() => {
        return of([]);
      })
    ).subscribe(data => {
      this.loaderService.stopGeneral();
      this.dataSource.data = data;
    });
  }



  navigateImportOperationPayments(element: Bulk) {
    if (element.payments.length > 0) {
      this.route.navigate(['/merchant/requests/import', element._id]); 
    } else {
      this.dialogService.openDialogWARN('DIALOG.GENERIC_ALLERT_TITLE', 'DIALOG.NO_IMPORT_PAYMENTS_MESSAGE');
    }
  }

  openDialogCaricamenti(element: Bulk) {
    if (!element.refuses || element.refuses.length === 0) {
      this.dialogService.openDialogWARN('DIALOG.GENERIC_ALLERT_TITLE', 'DIALOG.NO_REFUSES_MESSAGE');
      return;
    }

    this.dialog.open(DialogCaricamentiComponent, {
      autoFocus: false,
      disableClose: true,
      width: '900px',
      data: { refuses: element.refuses }
    });
  }

  sospendiImport(element: Bulk) {
    this.bulkService.suspend(element)
    .subscribe(
      () => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.onLoadImported();
      },
      (error) => {
        console.log(error);
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }


  eliminaImport(element: Bulk) {
    this.bulkService.elimina(element)
    .subscribe(
      () => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.onLoadImported();
      },
      (error) => {
        console.log(error);
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  populateImport(element: Bulk) {
    this.bulkService.populate(element).subscribe(
      (response: any) => {
        console.log(response)
        this.sendImport(response.item);
      },
      (error) => {
        console.log(error);
        this.loaderService.stopGeneral();
      }
    );
  }

  sendImport(element: Bulk) {
    this.bulkService.send(element)
    .subscribe(
      (res) => {
        console.log(res);
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      },
      (error) => {
        this.loaderService.stopGeneral();
        console.log(error);
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      },
      () => {
        this.onLoadImported();
      }
    );
  }

  onSend(element: Bulk) {
    this.dialogService.openDialogDELETE('', this.translate.instant('DIALOG.CONFIRM_SEND_IMPORT'))
    .afterClosed().subscribe(data => {
      if (!data) {return; }
      this.loaderService.startGeneral();
      if (element.state === 'ACTIVE') {
        this.populateImport(element);
      } else {
        this.sendImport(element);
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  observeLayoutChanges() {
    this.watcher = this.mediaObserver.asObservable().pipe(filter((change: MediaChange[]) => {
      this.activeMediaQuery = change[0] ? `'${change[0].mqAlias}' = (${change[0].mediaQuery})` : '';
      if ( change[0].mqAlias === 'xs') {
        this.displayedColumns = ['createdAt', 'state', 'caricamenti'];
      }
      if ( change[0].mqAlias === 'sm') {
        this.displayedColumns = ['createdAt', 'state', 'caricamenti'];
      }
      if ( change[0].mqAlias === 'md') {
        this.displayedColumns = ['createdAt', 'state', 'description', 'notification_type', 'auth_type', 'popolate', 'inviate', 'caricamenti', 'actions'];
      }
      if ( change[0].mqAlias === 'lg') {
        this.displayedColumns = ['createdAt', 'state', 'description', 'notification_type', 'auth_type', 'popolate', 'inviate', 'caricamenti', 'actions'];
      }
      if ( change[0].mqAlias === 'xl') {
        this.displayedColumns = ['createdAt', 'state', 'description', 'notification_type', 'auth_type', 'popolate', 'inviate', 'caricamenti', 'actions'];
      }
      return true;
    })).subscribe();
  }
}
