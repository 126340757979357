import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, } from 'rxjs';
import { MerchantService } from '../_services/merchant.service';

import { AuthService } from '../_services/auth.service';
import { map, catchError, concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private merchantService: MerchantService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const storageToken = localStorage.getItem( 'clicpay-token' );
    const storageMerchant = localStorage.getItem( 'clicpay-merchant' );

    if (this.authService.token) { 
      return this.getMerchant(storageMerchant)
      .pipe(
        map(() => true),
        catchError(error => {
          console.log(error);
          return of(this.router.createUrlTree(['/login']))
        })
      )
    }
    if (!storageToken) { return this.router.createUrlTree(['/login']); }
    this.authService.token = storageToken;
    
    return this.authService.getMe().pipe(
      concatMap(user => {
        if (storageMerchant) {
          return this.merchantService.impersonifica(storageMerchant)
        }  else {
          return this.merchantService.retrieveMerchant(user)
        }
      })
    ).pipe(
      map(() => true),
      catchError(error => {
        console.log(error);
        return of(this.router.createUrlTree(['/login']))
      })
    )
  }


  getMerchant(storageMerchant: string) {
    if (storageMerchant) {
      return this.merchantService.impersonifica(storageMerchant)
    }  else {
      return this.merchantService.retrieveMerchant(this.authService.user)
    }
  }
}
