import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MerchantService } from '../_services/merchant.service';

@Injectable({
  providedIn: 'root'
})
export class RecurrentGuard implements CanActivate {
  constructor(
    private merchantService: MerchantService,
    private router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    if (this.merchantService.currentMerchant && this.merchantService.currentMerchant.isRecurrentActive) {
      return true;
    }
    /* const path = next.url[1].path;
    if (path === 'record') {
      return this.router.createUrlTree(['/merchant/requests/single']);
    } else {
      return this.router.createUrlTree(['/merchant/request/new']);
    } */
    else {
      return this.router.createUrlTree(['/merchant/requests/single']);
    }
  }
  
}
