import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Profile } from '../../../../_models/profile';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../../../_services/profile.service';
import { AuthService } from '../../../../_services/auth.service';
import { DialogService } from '../../../../_services/dialog.service';
import { LoaderService } from '../../../../_services/loader.service';
import { MerchantService } from '../../../../_services/merchant.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  form: UntypedFormGroup;
  profile: Profile;

  constructor(
      private fb: UntypedFormBuilder,
      private route: ActivatedRoute,
      private profileService: ProfileService,
    private merchantService: MerchantService,
    private authenticationService: AuthService,
      private dialogService: DialogService,
      private loaderService: LoaderService,
  )
  {
    this.form = this.buildForm(<Profile>{});
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.profileService.getProfile(params.id).subscribe(response => {
          this.profile = <Profile>response.item;
          this.form = this.buildForm(this.profile);
        });
      } else {
        this.form = this.buildForm();
      }
    });
  }


  buildForm(formValues?: Profile): UntypedFormGroup {
    const merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : undefined;
    return this.fb.group({
      _id: [(formValues ? formValues._id : null)],
      name:  [(formValues ? formValues.name : ''), [Validators.required]],
      description: [(formValues ? formValues.description : ''), [Validators.required]],
      merchant: [(formValues ? formValues.merchant : merchantId), [Validators.required]],

      isCreatePaymentRequestActive: [(formValues ? formValues.isCreatePaymentRequestActive : false)],
      isConfirmActive: [(formValues ? formValues.isConfirmActive : false)],
      isBulkActive: [(formValues ? formValues.isBulkActive : false)],
      isEmailActive: [(formValues ? formValues.isEmailActive : false)],
      isSmsActive: [(formValues ? formValues.isSmsActive : false)],
      isQrcodeActive: [(formValues ? formValues.isQrcodeActive : false)],
      isLinkActive: [(formValues ? formValues.isLinkActive : false)],
      isWhatsAppActive: [(formValues ? formValues.isWhatsAppActive : false)],
      isSupervisor: [(formValues ? formValues.isSupervisor : false)],

      createdAt: [(formValues ? formValues.createdAt : null)],
      updatedAt: [(formValues ? formValues.createdAt : null)],
      __v: [(formValues ? formValues.__v : null)],
    });
  }

  saveOrUpdate(){
    this.loaderService.startGeneral();
    if (this.profile) {
      // AGGIORNA
      this.profileService.update(this.profile._id, this.form.value).subscribe(
        result => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        },
        error => {
          this.loaderService.stopGeneral();
          console.log(error)
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
    } else {
      // CREA NUOVO
      this.profileService.create(this.form.value).subscribe(
        result => {
          this.profile = <Profile>result.item;
          this.form = this.buildForm(this.profile);
          this.loaderService.stopGeneral();
          this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        },
        error => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
    }
  }

}
