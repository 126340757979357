<form [formGroup]="merchantForm">
  <div class="flex-row">
    <span *ngIf="!isSelfEnrollment" class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.MERCHANT' | translate}}" formControlName="name" required>
        <mat-error *ngIf="merchantForm.controls['name'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </span>

    <span *ngIf="!isSelfEnrollment" class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.CODICEAZIENDA' | translate}}" formControlName="code" required>
        <mat-error *ngIf="merchantForm.controls['code'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </span>

  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.DESCRIZIONE' | translate}}" formControlName="description">
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.COMUNE' | translate}}" formControlName="district">
      </mat-form-field>
    </span>
  </div>


  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.INDIRIZZO' | translate}}" formControlName="address">
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.PROVINCIA' | translate}}" formControlName="province">
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.INSEGNA' | translate}}" formControlName="insegna">
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.REGIONE' | translate}}" formControlName="region">
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <mat-select placeholder="{{'PROFILO.VALUTA' | translate}}" formControlName="currency" multiple>
          <mat-select-trigger>
            {{merchantForm.controls.currency.value ? merchantForm.controls.currency.value[0] : ''}}
            <span *ngIf="merchantForm.controls.currency.value?.length > 1" class="example-additional-selection">
              (+{{merchantForm.controls.currency.value.length - 1}} Valute)
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let type of valutaList" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.STATO' | translate}}" formControlName="state">
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.PIVA' | translate}}" formControlName="piva">
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.TELEFONO' | translate}}" formControlName="phone">
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">

    <span class="flex-col">
      <div class="leftInputField">
        <mat-form-field style="width: 20%; float: left;">
          <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code">
            <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
              <img alt="flag country" class="flagCountry"
                src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
              <span>{{ type.CODE }}</span>
              <span>{{ type.DESC }}</span>
              <span> - {{ type.COUNTRY | uppercase }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 75%; float: right;">
          <input type="tel" matInput placeholder="{{'PROFILO.SMARTPHONE' | translate}}" formControlName="smartphone">
          <mat-error *ngIf="merchantForm.controls['smartphone'].hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
        </mat-form-field>
      </div>
    </span>

    <span *ngIf="!isSelfEnrollment" class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{'PROFILO.EMAIL' | translate}}" formControlName="email" required>
        <mat-error
          *ngIf="merchantForm.controls['email'].hasError('pattern') && !merchantForm.controls['email'].hasError('required')"
          [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
        <mat-error *ngIf="merchantForm.controls['email'].hasError('required')"
          [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
        <mat-error *ngIf="merchantForm.controls['email'].hasError('email_not_available')"
          [innerHTML]="'MESSAGE.EMAIL_NOT_AVAILABLE' | translate"></mat-error>
        <mat-error *ngIf="merchantForm.controls['email'].hasError('email_not_available')"
          [innerHTML]="'MESSAGE.EMAIL_NOT_AVAILABLE' | translate"></mat-error>
      </mat-form-field>
    </span>
  </div>

  <div class="flex-row">

    <span *ngIf="!isSelfEnrollment" class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{'PROFILO.FROMADDRESS' | translate}}" formControlName="fromAddress" [readOnly]="!isAdmin">
        <mat-error
          *ngIf="merchantForm.controls['fromAddress'].hasError('pattern') && !merchantForm.controls['fromAddress'].hasError('required')"
          [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
        <mat-error *ngIf="merchantForm.controls['fromAddress'].hasError('required')"
          [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
        <mat-error *ngIf="merchantForm.controls['fromAddress'].hasError('email_not_available')"
          [innerHTML]="'MESSAGE.EMAIL_NOT_AVAILABLE' | translate"></mat-error>
        <mat-error *ngIf="merchantForm.controls['fromAddress'].hasError('email_not_available')"
          [innerHTML]="'MESSAGE.EMAIL_NOT_AVAILABLE' | translate"></mat-error>
      </mat-form-field>
    </span>
  </div>

  <div *ngIf="!isSelfEnrollment" class="buttonRow">
    <button mat-flat-button color="primary" class="rounded-button" [disabled]="!merchantForm.valid"
      (click)="notifyChanges()">{{'PROFILO.FILTRO2BUTTON' | translate}}</button>
  </div>



</form>
