export const DEFAULT_TEMPLATE_EMAIL = `
  
<style type="text/css">
		body,h1,h2,h3,h4,h5,h6,p,div,section,span{
			font-family:'BNPP Sans',sans-serif !important;
			color:#3c3c3b;
		}
		body{
			background-color:white;
		}
		h1{
			font-weight:600;
		}
		h2{
			font-weight:400;
		}
		h3{
			font-size:21px;
			font-weight:200;
			margin:10px 0;
			font-family:'BNPP Sans',sans-serif !important;
		}
		.button-pay{
			width:100%;
			height:auto;
			margin-top:30px;
		}
		.box-button{
			margin:0 auto;
		}
        .button-send{
			height:auto;
			margin:0 20px;
		}
		.button-send a{
			width:200px;
			background-color:#00965e;
			padding:20px 0;
			color:#fff;
			font-size:18px;
			border-radius:60px;
			margin:20px auto;
			display:block;
			text-align:center;
			text-decoration:none;
		}
        .container{
			padding:10px 0 10px 10px;
			width:auto !important;
		}
        .section{
			max-width:940px;
			margin:auto;
		}
</style>

        
        
<div class="button-pay">
    <div class="section">
        <div class="container">
        <div class="box-button">
            <div class="button-send">
            <a href="{{payment_link}}" class="send-link" target="_blank">PAGA</a>
            </div>
        </div>
        </div>
    </div>
</div>
        
`