import { Template } from "./../../_models/template";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";

import { AuthService } from "../../_services/auth.service";
import { MerchantService } from "../../_services/merchant.service";
import { Merchant } from "../../_models/merchant";
import { User } from "../../_models/user";
import { TemplateService } from "../../_services/template.service";
import { DialogService } from "../../_services/dialog.service";
import { tap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { trigger, style, animate, transition } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { RadioInfo } from "../../_models/radio-info";
import { Payment } from "../../_models/payment";
import { Subject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-payment-wizard",
  templateUrl: "./payment-wizard.component.html",
  styleUrls: ["./payment-wizard.component.scss"],
  animations: [
    trigger("openClose", [
      transition(":enter", [
        style({ opacity: 0, width: "0px" }),
        animate("0.5s", style({ opacity: 1, width: "*" })),
      ]),
      transition(":leave", [
        animate("0.5s", style({ opacity: 0, width: "0px" })),
      ]),
    ]),
  ],
})
export class PaymentWizardComponent implements OnInit {
  @Input() isImport?: boolean;

  @Input() set modPayment(value: Payment | undefined) {
    if (!value) {
    } else {
      this.disabledForm(value.state);
      this.getModPaymentData(value);
    }
  }

  @Input() set saveEvent(onParentSave: Subject<any>) {
    if (onParentSave) {
      onParentSave.subscribe((_) => this.setDefaultSettings());
    }
  }

  @Output() wizardValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() enableForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  //@Output() isImportEvent: EventEmitter<boolean> = new EventEmitter();

  wizardForm: UntypedFormGroup;
  featureForm: UntypedFormGroup;
  featureArray: string[];
  merchant: Merchant;
  user: User;
  availableRequestTypes: RadioInfo[] = [];
  availableTransType: RadioInfo[] = [];
  availablePaymentTypes: RadioInfo[] = [this.genRadioObj("SINGLE")];
  _availableSendOpt: RadioInfo[] = [];
  templateOpts: RadioInfo[];
  notifOpts: RadioInfo[] = [];
  isEditMode = false;
  recurrentSelected = false;
  showNotificationOption = true;

  get availableSendOpt() {
    const myOptions = this._availableSendOpt.map((el) => el);
    let toBeRemoved = [];
    if (this.features.isPaymentOpen) {
      toBeRemoved = ["SMS", "EMAIL"];
    }
    if (this.features.isFastCheckout) {
      toBeRemoved = ["LINK", "QRCODE"];
    }
    toBeRemoved.forEach((el) => this.findOpt(el, myOptions));
    return myOptions;
  }

  wizardState = [true, false, false, false, false, false, false];

  icons = [
    "template-standard",
    "template-custom",
    "template-custom",
    "template-custom",
    "template-custom",
  ];

  showRememberButton: boolean;
  showResetButton: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private merchantService: MerchantService,
    public authService: AuthService,
    private templateService: TemplateService,
    private dialogService: DialogService,
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.getIcons();
    this.buildWizardForm();
    this.buildFeatureForm();
    //ESEGUO NEL CONSTRUCTOR PERCHÉ INFORMAZIONI NECESSARIE NEL SETTER CHE SCATTA PRIMA DELL'ONINIT
    if (!this.merchantService.currentMerchant) {
      return;
    }
    this.merchant = this.merchantService.currentMerchant;
    if (!this.merchant.templates || this.merchant.templates.length === 0) {
      this.fallbackTemplate();
    }
    this.user = this.authService.user;
    this.getRequestType();
    this.getTransOptions();
    this.getTemplateOptions();
    this.getNotifOptions();
    this.subscribeToChanges();
  }

  ngOnInit() {
    // this.startDefalult()
    this.getSendOptions();
    this.getPaymentTypeOptions();
    this.activatedRoute.params.subscribe(
      (params) => params && params.paymentId && (this.isImport = true)
    );
    this.getDefaultSettings();
    this.getTemplateOptions();
  }

  buildWizardForm() {
    this.wizardForm = this.fb.group({
      isDonation: [null, [Validators.required]],
      payment_type: [null, [Validators.required]],
      operation_type: [null, [Validators.required]],
      template: [null, Validators.required],
      transaction_type: [null, [Validators.required]],
      isNotificationRequired: [null, Validators.required],
    });
  }

  buildFeatureForm() {
    this.featureForm = this.fb.group({
      isAmountFree: [false],
      isPaymentOpen: [false],
      isFastCheckout: [false],
      isQueryString: [false],
    });
    this.featureArray = Object.keys(this.featureForm.value);
  }

  disabledForm(paymentState: string) {
    if (paymentState && paymentState === "ACTIVE") {
      this.wizardForm.disable();
    }
  }

  toggleWizard(ix: number) {
    this.wizardState[ix] = !this.wizardState[ix];
    if (!this.wizardState[ix]) {
      return;
    }
    for (let i = 0; i < this.wizardState.length; i++) {
      if (i === ix) {
        continue;
      } else {
        this.wizardState[i] = false;
      }
    }
  }

  stepForward(i: number) {
    this.wizardState[i] = false;
    const wizardKeys = Object.keys(this.value);
    const nextCtrl = wizardKeys[i + 1];
    if (this.value[nextCtrl] === null) {
      this.wizardState[i + 1] = true;
    }
    const onlyOpt = this.checkAvailableOpts(nextCtrl);
    if (onlyOpt.check) {
      this.wizardForm.get(nextCtrl).setValue(onlyOpt.value);
    }
  }

  checkAvailableOpts(ctrl: string) {
    let onlyOneOpt: any = {
      check: false,
      value: null,
    };
    switch (ctrl) {
      case "payment_type":
        if (this.availablePaymentTypes.length === 1) {
          onlyOneOpt.check = onlyOneOpt.value =
            this.availablePaymentTypes[0].value;
        }
        break;
      case "operation_type":
        if (this._availableSendOpt.length === 1) {
          onlyOneOpt.check = onlyOneOpt.value = this._availableSendOpt[0].value;
        }
        break;
      case "template":
        if (this.templateOpts.length === 1) {
          onlyOneOpt.check = onlyOneOpt.value = this.templateOpts[0].value;
        }
        break;
      case "transaction_type":
        if (this.availableTransType.length === 1) {
          onlyOneOpt.check = onlyOneOpt.value =
            this.availableTransType[0].value;
        }
        break;
      case "isNotificationRequired":
        if (this.notifOpts.length === 1) {
          onlyOneOpt.check = true;
          onlyOneOpt.value = this.notifOpts[0].value;
        }
        break;
      default:
        onlyOneOpt = {
          check: false,
          value: null,
        };
    }
    return onlyOneOpt;
  }

  subscribeToChanges() {
    this.featureForm.controls.isFastCheckout.valueChanges.subscribe((value) => {
      if (value) {
        for (let key in this.featureForm.value) {
          if (key !== "isFastCheckout") {
            this.featureForm.get(key).setValue(false, { emitEvent: false });
          }
        }
        this.wizardState[6] = false;
        if (
          this.value.operation_type === "QRCODE" ||
          this.value.operation_type === "LINK"
        ) {
          this.wizardForm.controls.operation_type.reset();
          this.stepForward(1);
        }
      } else {
        this.wizardState[6] = true;
      }
    });

    this.featureForm.controls.isPaymentOpen.valueChanges.subscribe((value) => {
      if (value) {
        if (/RECURRENT|DEFERRED/.test(this.wizardForm.value.payment_type)) {
          this.wizardForm.controls.payment_type.reset();
        }

        if (
          this.value.operation_type === "EMAIL" ||
          this.value.operation_type === "SMS"
        ) {
          this.wizardState[6] = false;
          this.wizardForm.controls.operation_type.reset();
          this.stepForward(1);
        }
      } else if (!value && this.features.isQueryString) {
        this.featureForm.controls.isQueryString.setValue(false);
      }
    });

    this.featureForm.controls.isAmountFree.valueChanges.subscribe((value) => {
      if (!value && this.features.isQueryString) {
        this.featureForm.controls.isQueryString.setValue(false);
      }

      if (
        value &&
        /RECURRENT|DEFERRED/.test(this.wizardForm.value.payment_type)
      ) {
        this.wizardForm.controls.payment_type.reset();
      }
    });

    this.wizardForm.controls.isDonation.valueChanges.subscribe((value) => {
      // qui come per isNotificationRequired controlli stringenti per differenziare tra quando il valore
      // è NULL (e quindi la scelta non è stata fatta)
      // e quando è FALSE (e quindi è stata scelta l'opzione NO)

      if (value === null) {
        return;
      }
      if (value === true) {
        this.availablePaymentTypes = [this.genRadioObj("SINGLE")];
      }
      if (this.merchant.isRecurrentActive && !this.isImport) {
        this.availablePaymentTypes = [
          this.genRadioObj("SINGLE"),
          this.genRadioObj("RECURRENT"),
        ];
      }
      if (this.merchant.isDeferredActive && !this.isImport) {
        this.availablePaymentTypes = [
          this.genRadioObj("SINGLE"),
          this.genRadioObj("DEFERRED"),
        ];
      }
      if (
        this.merchant.isDeferredActive &&
        this.merchant.isRecurrentActive &&
        !this.isImport
      ) {
        this.availablePaymentTypes = [
          this.genRadioObj("SINGLE"),
          this.genRadioObj("RECURRENT"),
          this.genRadioObj("DEFERRED"),
        ];
      }

      this.stepForward(0);
    });

    this.wizardForm.controls.payment_type.valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }

      if (/DEFERRED|RECURRENT/.test(value)) {
        this.featureForm.controls.isPaymentOpen.reset();
        this.featureForm.controls.isAmountFree.reset();
        this.recurrentSelected = true;
        this.showNotificationOption = false;
      } else {
        this.recurrentSelected = false;
        this.showNotificationOption = true;
      }

      const filter = /DEFERRED|RECURRENT/.test(value) ? "PURCHASE" : null;
      this.getTransOptions(filter);

      const savedOpt = this.availableTransType.find((el) => {
        this.wizardForm.controls.transaction_type.value === el;
      });
      if (value === "SINGLE" && !savedOpt && !this.isEditMode) {
        this.wizardForm.controls.transaction_type.reset();
      }

      this.wizardForm.controls.template.reset();
      this.getTemplateOptions();
      this.getNotifOptions();
      this.stepForward(1);
    });

    this.wizardForm.controls.operation_type.valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }
      this.stepForward(2);
    });

    this.wizardForm.controls.template.valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }
      this.stepForward(3);
    });

    this.wizardForm.controls.transaction_type.valueChanges.subscribe(
      (value) => {
        if (!value) {
          return;
        }

        this.stepForward(4);
      }
    );

    this.wizardForm.controls.isNotificationRequired.valueChanges.subscribe(
      (value) => {
        if (
          value === null &&
          !this.wizardForm.controls.payment_type.value.test("SINGLE")
        ) {
          return;
        }
        //this.wizardState[5] = false;
        this.stepForward(5);
        this.enableForm.emit(false);
      }
    );

    this.wizardForm.valueChanges.subscribe((value) => {
      this.wizardValue.emit(value);
    });

    this.featureForm.valueChanges.subscribe((value) => {
      this.wizardValue.emit(this.featureForm.getRawValue());
      this.showRememberButton = true;
    });
  }

  getModPaymentData(myPayment: Payment) {
    this.isEditMode = true;
    let isValid = this.checkSettingsCompatible(myPayment, true);
    if (isValid) {
      this.wizardForm.patchValue(myPayment);
      this.featureForm.patchValue(myPayment);
    }
    const templateId =
      typeof myPayment.template === "string"
        ? myPayment.template
        : myPayment.template._id;
    const index = this.merchant.templates.findIndex(
      (el) => el._id === templateId
    );
    if (index !== -1 && isValid) {
      this.wizardForm.controls.template.setValue(templateId);
    } else {
      this.wizardForm.controls.template.setValue(null);
    }
    this.featureForm.removeControl("isQueryString");
    if (isValid) {
      this.wizardState = [false, false, false, false, false, false];
    }
  }

  getDefaultSettings() {
    if (!this.merchant) {
      return;
    }
    let featuresString = "";
    let settingsString = "";
    if (!this.isImport) {
      featuresString = localStorage.getItem(
        "clicpay_payment_features_" + this.merchant._id
      );
      settingsString = localStorage.getItem(
        "clicpay_payment_settings_" + this.merchant._id
      );
    } else {
      featuresString = localStorage.getItem(
        "clicpay_import_features_" + this.merchant._id
      );
      settingsString = localStorage.getItem(
        "clicpay_import_settings_" + this.merchant._id
      );
    }
    if (!featuresString || !settingsString) {
      if (!this.merchant.isDonationActive) {
        this.wizardForm.controls.isDonation.setValue(false);
      }
      return;
    }
    const features = JSON.parse(featuresString);
    const settings = JSON.parse(settingsString);
    let preferences = Object.assign({}, features, settings);
    const isValid = this.checkSettingsCompatible(preferences);
    if (!isValid) {
      return;
    }
    this.featureForm.patchValue(features);
    this.wizardForm.patchValue(settings);
    this.wizardState = [false, false, false, false, false, false];
    this.showResetButton = true;
  }

  checkSettingsCompatible(settings: any, isEditMode = false): boolean {
    const opIx = this._availableSendOpt.findIndex(
      (el) => el.value === settings.operation_type
    );
    const transIx = this.availableTransType.findIndex(
      (el) => el.value === settings.transaction_type
    );
    const tempIx = this.merchant.templates.findIndex(
      (el) => el._id === settings.template
    );

    const paymentsIx = this.availablePaymentTypes.findIndex(
      (el) => el.value === settings.payment_type
    );

    let err = false;
    if (settings.isDonation && !this.merchant.isDonationActive) {
      err = true;
    }
    if (settings.isFastCheckout && !this.merchant.isFastCheckoutActive) {
      err = true;
    }
    if (settings.isPaymentOpen && !this.merchant.isPaymentOpenActive) {
      err = true;
    }
    if (
      settings.isQueryString &&
      (!this.merchant.isPaymentOpenActive || !this.merchant.isQueryStringActive)
    ) {
      err = true;
    }

    if (
      paymentsIx === -1 ||
      opIx === -1 ||
      transIx === -1 ||
      (tempIx === -1 && !isEditMode) ||
      err
    ) {
      this.resetDefaultSettings(isEditMode);
      this.dialogService.openDialogWARN(
        "DIALOG.GENERIC_ALLERT_TITLE",
        "DIALOG.CUSTOM_SETTINGS_NOT_AVAILABLE"
      );
      return false;
    }
    return true;
  }

  setDefaultSettings() {
    const features = JSON.stringify(this.features);
    const settings = JSON.stringify(this.value);
    if (!this.isImport) {
      localStorage.setItem(
        "clicpay_payment_features_" + this.merchant._id,
        features
      );
      localStorage.setItem(
        "clicpay_payment_settings_" + this.merchant._id,
        settings
      );
    } else {
      localStorage.setItem(
        "clicpay_import_features_" + this.merchant._id,
        features
      );
      localStorage.setItem(
        "clicpay_import_settings_" + this.merchant._id,
        settings
      );
    }
    this.showResetButton = true;
  }

  resetDefaultSettings(isEditMode?: boolean) {
    if (!isEditMode) {
      if (!this.isImport) {
        localStorage.removeItem(
          "clicpay_payment_features_" + this.merchant._id
        );
        localStorage.removeItem(
          "clicpay_payment_settings_" + this.merchant._id
        );
      } else {
        localStorage.removeItem("clicpay_import_features_" + this.merchant._id);
        localStorage.removeItem("clicpay_import_settings_" + this.merchant._id);
      }
    }
    this.featureForm.reset();
    this.wizardForm.reset();
    this.wizardState = [true, false, false, false, false, false];
    this.showResetButton = false;
    this.enableForm.emit(!isEditMode);
  }

  startDefalult() {
    localStorage.removeItem("clicpay_payment_features_" + this.merchant._id);
    localStorage.removeItem("clicpay_payment_settings_" + this.merchant._id);
  }

  getSendOptions() {
    if (!this.merchant) {
      return;
    }

    this.setOperationType();
    this.wizardForm.controls.payment_type.valueChanges.subscribe((value) => {
      if (value) {
        this._availableSendOpt = [];
        const savedOpt = this._availableSendOpt.find((el) => {
          this.wizardForm.controls.operation_type.value === el;
        });
        if (value === "SINGLE" && !savedOpt && !this.isEditMode) {
          this.wizardForm.controls.operation_type.reset();
        }

        this.setOperationType();
      }
    });
  }

  setOperationType() {
    for (const op of this.merchant.operation_type) {
      if (op === "EMAIL" && this.user.isEmailActive) {
        this._availableSendOpt.push(this.genRadioObj(op));
      }
      if (op === "SMS" && this.user.isSmsActive) {
        this._availableSendOpt.push(this.genRadioObj(op));
      }
      if (
        this.wizardForm.controls.payment_type.value !== "RECURRENT" &&
        this.wizardForm.controls.payment_type.value !== "DEFERRED"
      ) {
        if (op === "QRCODE" && this.user.isQrcodeActive && !this.isImport) {
          this._availableSendOpt.push(this.genRadioObj(op));
        }
        if (op === "LINK" && this.user.isLinkActive && !this.isImport) {
          this._availableSendOpt.push(this.genRadioObj(op));
        }
        if (op === "WHATSAPP" && this.user.isWhatsAppActive && !this.isImport) {
          this._availableSendOpt.push(this.genRadioObj(op));
        }
      }
    }
  }

  getPaymentTypeOptions() {
    if (!this.merchant) {
      return;
    }
    if (
      this.merchant.isRecurrentActive &&
      !this.isImport &&
      !this.wizardForm.controls.isDonation.value
    ) {
      this.availablePaymentTypes.push(...[this.genRadioObj("RECURRENT")]);
    }
    if (
      this.merchant.isDeferredActive &&
      !this.isImport &&
      !this.wizardForm.controls.isDonation.value
    ) {
      this.availablePaymentTypes.push(...[this.genRadioObj("DEFERRED")]);
    }
  }

  getTransOptions(filter?: string) {
    if (filter === "PURCHASE") {
      this.availableTransType = [this.genRadioObj("PURCHASE")];
    } else {
      this.availableTransType = this.merchant.transaction_type.map((el) =>
        this.genRadioObj(el)
      );
      const purchaseIx = this.availableTransType.findIndex(
        (el) => el.value === "PURCHASE"
      );
      if (purchaseIx !== -1) {
        this.availableTransType.splice(purchaseIx, 1);
        this.availableTransType.unshift(this.genRadioObj("PURCHASE"));
      }
    }
  }

  getTemplateOptions() {
    this.templateOpts = this.merchant.templates.map(
      (el: Template, i: number) => {
        const length = el.short_description.length;
        let label = el.short_description.substring(0, 8);
        if (length > 8) {
          label = label + "...";
        }
        return {
          value: el._id,
          label: label,
          icon: label === "STANDARD" ? "template-standard" : "template-custom",
          tooltip: el.short_description.toUpperCase(),
        };
      }
    );

    if (/DEFERRED|RECURRENT/.test(this.wizardForm.value.payment_type)) {
      this.templateOpts = this.templateOpts.filter(
        (el) => el.label === "STANDARD"
      );
    }

    const index = this.templateOpts.findIndex((el) => el.label === "STANDARD");
    if (index !== -1) {
      this.templateOpts.unshift(this.templateOpts[index]);
      this.templateOpts.splice(index + 1, 1);
    }
  }

  getNotifOptions() {
    if (/DEFERRED|RECURRENT/.test(this.wizardForm.value.payment_type)) {
      this.notifOpts = [
        this.genRadioObj(false, "NO", "notif-no", "PAYMENT_WIZARD.NO_TOOLTIP"),
      ];
    } else {
      this.notifOpts = [
        this.genRadioObj(true, "YES", "notif-yes", "PAYMENT_WIZARD.SI_TOOLTIP"),
        this.genRadioObj(false, "NO", "notif-no", "PAYMENT_WIZARD.NO_TOOLTIP"),
      ];
    }
  }

  getRequestType() {
    this.availableRequestTypes.push(
      this.genRadioObj(
        false,
        "ISSTANDARD",
        "isStandard",
        "PAYMENT_WIZARD.ISSTANDARD_TOOLTIP"
      )
    );
    if (this.merchant.isDonationActive) {
      this.availableRequestTypes.push(
        this.genRadioObj(
          true,
          "ISDONATION",
          "isDonation",
          "PAYMENT_WIZARD.ISDONATION_TOOLTIP"
        )
      );
    }
  }

  findOpt(key: string, arr: any[]) {
    const i = arr.findIndex((el) => el.value === key);
    if (i !== -1) {
      arr.splice(i, 1);
    }
  }

  genRadioObj(
    value: string | boolean,
    label?: string,
    icon?: string,
    tooltip?: string
  ) {
    tooltip =
      value === "AUTH" || value === "VERIFY"
        ? `PAYMENT_WIZARD.${value}_TOOLTIP_BTN`
        : tooltip;
    return {
      value: value,
      label: label
        ? this.translate.instant("PAYMENT_WIZARD." + label)
        : this.translate.instant("PAYMENT_WIZARD." + value),
      icon:
        !icon && typeof value === "string"
          ? value.toLowerCase()
          : icon.toLowerCase(),
      tooltip: tooltip ? this.translate.instant(tooltip) : null,
    };
  }

  resetFeatures() {
    this.featureForm.reset({
      isAmountFree: false,
      isPaymentOpen: false,
      isFastCheckout: false,
      isQueryString: false,
    });
  }

  get value() {
    return this.wizardForm.value;
  }

  get features() {
    return this.featureForm.value;
  }

  fallbackTemplate() {
    // SE NON E' STATO AGGIUNTO
    this.getDefaultTemplate().subscribe((response_template) => {
      this.merchantService
        .update(this.merchant._id, { templates: [response_template._id] })
        .subscribe(
          (response) => {
            this.merchant = response;
          },
          (error) => {
            this.dialogService.openDialogWARN(
              "DIALOG.TEMPLATEERRORTITLE",
              "DIALOG.TEMPLATEERRORMESSAGE"
            );
            this.router.navigate(["/merchant/"]);
          }
        );
    });
  }

  getDefaultTemplate() {
    return this.templateService.getDefaultTemplate().pipe(
      tap(null, (error) => {
        this.dialogService.openDialogWARN(
          "DIALOG.TEMPLATEERRORTITLE",
          "DIALOG.TEMPLATEERRORMESSAGE"
        );
        this.router.navigate(["/merchant/"]);
      })
    );
  }

  getIcons() {
    this.iconRegistry.addSvgIcon(
      "isdonation",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/dona.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "isPaymentOpen",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/pagamento-aperto.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "isAmountFree",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/importo-libero.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "isFastCheckout",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/fast-checkout.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "isstandard",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/pagamento-standard.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "isQueryString",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/query-string.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "single",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/SINGLE.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "recurrent",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/RECURRENT.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "deferred",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/DEFERRED.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "sms",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/sms-icon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "email",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/email-icon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "qrcode",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/qrcode-icon.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "link",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/link.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "whatsapp",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/whatsapp.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "template-standard",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/template-standard.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "template-custom",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/template-custom-2.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "purchase",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/purchase.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "auth",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/auth.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "verify",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/verifica.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "notif-yes",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/notifica-si.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "notif-no",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/notifica-no.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "checked",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/check.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "right_arrow",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/freccia-apertura.svg"
      )
    );
  }
}
