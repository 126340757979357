import { Referent } from "./referent";
import { Terminal } from "./terminal"

export class HoldingTableMerchant {
    email: string;
    name: string;
    piva: string;
    province: string;
    terminal: Terminal;
    insegna: string;
    _id: string;
    paymentsPending: IHoldingStats;
    paymentsReceived: IHoldingStats;
    referent: string;

    constructor(merchantData: HoldingMerchantInfo) {
        this.email = merchantData.email;
        this.name = merchantData.name,
        this.piva = merchantData.piva,
        this.province = merchantData.province;
        this.insegna = merchantData.insegna;
        this.referent = merchantData.referent ? merchantData.referent.first_name + ' ' + merchantData.referent.last_name : '';
        this.terminal = merchantData.terminal && merchantData.terminal[0] ? merchantData.terminal[0].tid : '';
        this._id = merchantData._id;
        this.paymentsPending = {
            count: 0, amount: 0,
        }
        this.paymentsReceived = {
            count: 0, amount: 0,
        }
    }

    assignStatsData(statsData: HoldingTableMerchant) {
        if (statsData) {
            this.paymentsPending = statsData.paymentsPending;
            this.paymentsReceived = statsData.paymentsReceived;
        }
    }
}



export interface IHoldingStats {
    count: number, amount: number,
}

export interface HoldingMerchantInfo {
    email: string;
    name: string;
    province: string;
    piva: string;
    terminal: Terminal;
    insegna: string;
    referent: Referent;
    _id: string;
}

export class HoldingStatsInfo implements IHoldingStatsInfo {
    pending: IHoldingStats;
    received: IHoldingStats;
    constructor(newData?: IHoldingStatsInfo) {
        this.pending = {
            count: 0, amount: 0,
        }
        this.received = {
            count: 0, amount: 0,
        }
        if (newData) {
            this.update(newData)
        }
    }
    
    private update(newData: IHoldingStatsInfo) {
        this.pending.count = newData.pending.count;
        this.pending.amount = newData.pending.amount;
        this.received.count = newData.received.count;
        this.received.amount = newData.received.amount;
    }
    static calcTotal(merchants: HoldingTableMerchant[]) {
        //this.resetCounters()
        let totStats = new HoldingStatsInfo();
        merchants.forEach(el => {
            if (el.paymentsPending) {
                totStats.pending.count += el.paymentsPending.count;
                totStats.pending.amount += el.paymentsPending.amount;
            }
            if (el.paymentsReceived) {
                totStats.received.count += el.paymentsReceived.count;
                totStats.received.amount += el.paymentsReceived.amount;
            }
        })
        return totStats;
    }
}

interface IHoldingStatsInfo {
    pending: IHoldingStats;
    received: IHoldingStats;
}