import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-anchor-card",
  templateUrl: "./anchor-card.component.html",
  styleUrls: ["./anchor-card.component.scss"],
})
export class AnchorCardComponent implements OnInit {
  @Input() image: string;
  @Input() label: string;
  @Input() link: string;

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH + "svg/console-icons/";

  constructor() {}

  ngOnInit() {}
}
