import { tap } from 'rxjs/operators';
import { Template } from './../../../../_models/template';
import { User } from './../../../../_models/user';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from './../../../../_components/dialog/dialog.component';
import { Merchant } from './../../../../_models/merchant';
import { Reminder } from './../../../../_models/reminder';
import { Dynamicfield } from './../../../../_models/dynamicfield';
import { MerchantService } from '../../../../_services/merchant.service';
import { DialogService } from '../../../../_services/dialog.service';
import { Router } from '@angular/router';
import { TemplateService } from '../../../../_services/template.service';
import { LoaderService } from '../../../../_services/loader.service';
import { BulkService } from '../../../../_services/bulk.service';
import { AuthService } from '../../../../_services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { saveFile } from '../../../../_services/file-download-helper';
import { environment } from '../../../../../environments/environment';
import { FileUploader, FileUploaderOptions, FileItem, ParsedResponseHeaders, FileLikeObject } from 'ng2-file-upload';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-import-request',
  templateUrl: './import-request.component.html',
  styleUrls: ['./import-request.component.scss']
})
export class ImportRequestComponent implements OnInit, OnDestroy {
  // FORM
  payForm: UntypedFormGroup;
  // MERCHANT
  merchant: Merchant;
  // USER
  user: User;
  // PATH
  private readonly UPLOAD_URL: string = environment.BASE_URL + 'bulk/upload';
  // FILEUPLOADER
  fileUploaderOptions: FileUploaderOptions;
  uploader: FileUploader;
  item = null;
  fileCtrl: UntypedFormControl;
  uploadResponseAllegato: any;
  // SCHEDULATORE NOTIFICHE
  reminders: Reminder[] = [];
  showNotifyscheduler: boolean;
  pickerChanged: boolean;
  // CAMPI DINAMICI
  dynamicfields: Array<Dynamicfield>;

  dialogRef: MatDialogRef<DialogComponent>;
  boolPrivacy = false;

  bulkForm: UntypedFormGroup;
  @ViewChild('inputfile', { static: true }) inputfile: ElementRef;

  minDate = new Date();
  isLocked = true;
  private watcher: Subscription;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private bulkService: BulkService,
    private loaderService: LoaderService,
    private templateService: TemplateService,
    private router: Router,
    private dialogService: DialogService,
    private merchantService: MerchantService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.setupUploader();
  }

  ngOnInit() {
    this.user = this.authService.user;
    this.watcher = this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      const isSmallScreen = result.matches;
      // REDIRECT SU PAGAMENTO EASY SE DEVICE MOBILE O USER HA EASY NELLE IMPOSTAZIONI
      if (isSmallScreen || this.user.mode_easy) {
        this.router.navigate(['/merchant/request/easy']);
      }
    });
    this.buildBulkForm();
    this.merchant = this.merchantService.currentMerchant;
  }

  ngOnDestroy() {
    if (!this.watcher) {return; }
    this.watcher.unsubscribe();
  }

  getWizardData(event: any) {
    this.bulkForm.patchValue(event);
  }

  enableAccordion(event: boolean) {
    this.isLocked = false;
  }

  buildBulkForm() {
    this.bulkForm = this.fb.group(
      {
        description: [null, [Validators.required]],
        scheduledStartDate: [this.minDate, Validators.required],
        scheduledStartDate_offset: [moment(this.minDate).utcOffset(), Validators.required],
        payment_type: ['SINGLE'],
        operation_type: ['', [Validators.required]],
        transaction_type: ['', [Validators.required]],
        isStandard: [false],
        isDonation: [false],
        isAmountFree: [false],
        isPaymentOpen: [false],
        isNotificationRequired: [false],
        template: [null],
      }
    );
  }

  onFileUploadClick() {
    this.inputfile.nativeElement.click();
  }

  clearInputUpload() {
    this.inputfile.nativeElement.value='';
    this.uploader.clearQueue();
    this.uploader.cancelAll();
  }

  dialogCheckPrivacy() {
    this.dialogRef = this.dialogService.openDialogDELETE('', 'DIALOG.PRIVACY');
    this.dialogRef.afterClosed().subscribe(
      data => {
        this.quequeUpload(data);
      }
    );
  }

  quequeUpload(privacyAccepted: boolean) {
    if (privacyAccepted) {
      this.loaderService.startGeneral();
      this.uploader.queue[0].upload();
    } else {
      this.clearInputUpload();
    }
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  setupUploader() {
    this.uploader = new FileUploader({});

    // DEFINISCO LE OPZIONI DI UPLOAD
    this.fileUploaderOptions = {
      url: this.UPLOAD_URL,
      headers: [{ name: 'Authorization', value: this.authService.token }],
      method: 'POST',
      queueLimit: 1,
      removeAfterUpload: false,
    };

    this.uploader.setOptions(this.fileUploaderOptions);

    // HOOK IN CASO DI AGGIUNTA FILE IN ERRORE
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any): any => {
      this.dialogService.openDialogWARN('DIALOG.FORMATCSV_ERROR_TITLE', 'DIALOG.FORMATCSV_ERROR_MESSAGE');
      this.clearInputUpload();
    };

    // HOOK IN CASO DI AGGIUNTA FILE A BUON FINE
    this.uploader.onAfterAddingFile = (fileItem: FileItem): any => {
      if (this.uploader.queue[0]) {
        this.dialogCheckPrivacy();
      }
    };

    // HOOK IN CASO DI UPLOAD A BUON FINE (FACCIO PARTIRE LA RICHIESTA DI IMPORT)
    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      const data = JSON.parse(response); // success server response
      if(data && data.status && data.status !== 'error'){
        this.bulkService.caricaBulk(this.merchantService.currentMerchant._id, this.bulkForm.value, data.item, this.merchantService.currentMerchant.terminal[0], this.bulkForm.controls.template.value)
        .subscribe(
          res => {
            this.loaderService.stopGeneral();
            this.router.navigate(['/merchant/home/']);
            this.dialogService.openDialogPRIMARY('DIALOG.IMPORTSUCCESSTITLE', 'DIALOG.IMPORTSUCCESSMESSAGE');
          },
          error => {
            this.loaderService.stopGeneral();
            console.log(error);
            this.dialogService.openDialogWARN('DIALOG.IMPORTERRORTITLE', 'DIALOG.IMPORTERRORMESSAGE', error.status);
            this.clearInputUpload();
          }
        );
      } else {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.IMPORTERRORTITLE', data.message ? data.message : JSON.stringify(data), data.code ? data.code : undefined);
        this.clearInputUpload();
      }
      
    };

    // HOOK IN CASO DI ERRORE NELL'UPLOAD...
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
        // let error = JSON.parse(response); //error server response
        this.loaderService.stopGeneral();
        let error: any;
        if (this.isJson(response)) {
          error = JSON.parse(response);
          let errorMsg;
          if (typeof error == 'string') {
            errorMsg = error;
          } else {
            errorMsg = error.message != undefined ? error.message : '';
          }
          this.dialogService.openDialogWARN('DIALOG.UPLOADERROR', errorMsg);
        } else {
          error = response;
          this.dialogService.openDialogWARN('DIALOG.UPLOADERROR', error);
        }
        console.log(error);
    };
  }

  downloadTemplate() {
    this.http.get('assets/template/template.xlsx', {responseType: 'blob'}).subscribe(
      (response) => {
        saveFile(response, 'template.xlsx');
      }
    );
  }

}
