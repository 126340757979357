import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MerchantService } from '../../_services/merchant.service';
import { Merchant } from '../../_models/merchant';
import { environment } from '../../../environments/environment';
import { CustomerService } from '../../_services/customer.service';
import { DialogService } from '../../_services/dialog.service';
import { LoaderService } from '../../_services/loader.service';
import { Customer } from '../../_models/customer';
import { startWith, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatExpansionPanel } from '@angular/material/expansion';
import { rubricaValidator } from '../../_validators/rubrica-validator';
import { PaymentService } from '../../_services/payment.service';
import { Payment } from '../../_models/payment';
import { getPrefixes } from '../../_helpers/get-prefixes';

@Component({
  selector: 'app-request-customer-data',
  templateUrl: './request-customer-data.component.html',
  styleUrls: ['./request-customer-data.component.scss']
})
export class RequestCustomerDataComponent implements OnInit {
  @Input() payForm: UntypedFormGroup;
  /* @Input() set customerFields(fields: string[]) {
    if (!fields) return;
    this.buildForm(fields);
  } */

  @Input() set sendVia(operationType: string) {
    this.toggleFormFields(operationType);
    this._operationType = operationType;
  }

  get sendVia() {
    return this._operationType;
  }

  private _operationType;

  @Input() set modPayment(value: Payment | undefined) {
    if (value) {
      this.paymentState = value.state;
      // this.customerForm.patchValue(value);
      this.payment = value;
    }
  }
  @Output() customerData: EventEmitter<any> = new EventEmitter<any>();

  customerForm = new UntypedFormGroup({})
  merchant: Merchant;
  countryPrefix = [];
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  customers: Customer[];
  filteredCustomers:Observable<Customer[]>;
  selectedCustomer: Customer;
  paymentState: string
  payment: Payment;

  constructor(
    private fb: UntypedFormBuilder,
    private merchantService: MerchantService,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private paymentService: PaymentService,
  ) { 
    this.merchant = this.merchantService.currentMerchant;
    if (!this.merchant) {
      return; 
    }
    this.countryPrefix = getPrefixes(this.merchant.area_code_type);
    this.getCustomers();
    this.buildForm();
   }

  ngOnInit() {
    
  }

  buildForm() {
    this.customerForm = this.fb.group({
      name:  [''],
      email: ['', [Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]],
      area_code: [this.countryPrefix[0] ? this.countryPrefix[0].CODE : ''],
      smartphone: [''],
      piva: [''],
    }, {
      //validators: [rubricaValidator]
    })
    this.customerForm.valueChanges.subscribe(
      value => {
        this.customerData.emit(value);
      }
    ) 
  }

  toggleFormFields(operationType: string) {
    if (operationType === 'EMAIL') {
      this.customerForm.removeControl('smartphone');
      this.customerForm.removeControl('area_code');
      this.customerForm.addControl('email', new UntypedFormControl('', [Validators.required, Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]))
    }
    if (operationType === 'SMS' || operationType === 'WHATSAPP') {
      this.customerForm.removeControl('email');
      this.customerForm.addControl('area_code', new UntypedFormControl(this.countryPrefix[0] ? this.countryPrefix[0].CODE : '', [Validators.required]));
      this.customerForm.addControl('smartphone', new UntypedFormControl(null, [Validators.required]));
    }
    if (operationType === 'QRCODE' || operationType === 'LINK' || !operationType) {
      this.customerForm.removeControl('smartphone');
      this.customerForm.removeControl('area_code');
      this.customerForm.removeControl('email');
    }
    this.customerForm.patchValue(this.payment);
  }

  getCustomers() {
    this.customerService.listMerchant(this.merchant._id).subscribe(
    (results: any) => {
      this.customers = <Customer[]>results['items'];
      this.filteredCustomers = this.customerForm.controls.name.valueChanges
      .pipe(
        startWith(''),
        map(
          value => value ? this.filterCustomers(value.toString()) : this.customers.slice()
        )
      );
    })
  }

  filterCustomers(inputValue: string) {
    return this.customers
    .filter(customer => customer.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0);
  }

  

  clearRegionesociale() {
    if (this.paymentState!=="ACTIVE") {
      this.customerForm.reset({ area_code: this.countryPrefix[0] ? this.countryPrefix[0].CODE : '' });
      this.selectedCustomer = null;
    }
  }

  aggiungiRubrica() {
    this.selectedCustomer = this.customerForm.value;
    this.selectedCustomer.merchant = this.merchant._id;
    this.customerService.create(this.selectedCustomer).subscribe(
      responseCustomer => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.loaderService.stopGeneral();
      },
      error => {
        this.loaderService.stopGeneral();
        this.selectedCustomer = null;
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  ricercaNomeCliente(event: MatAutocompleteSelectedEvent) {
    this.selectedCustomer = this.customers.find(customer => customer._id === event.option.value);
    this.customerForm.patchValue(this.selectedCustomer);
  }


  //GETTERS
  get formValue() {
    if (!this.payForm) {return; }
    return this.payForm.value;
  }

  get emailControl() {
    return this.customerForm.get('email');
  }

  get phoneControl() {
    return this.customerForm.get('smartphone');
  }

  get nameControl() {
    return this.customerForm.get('name');
  }

}
