import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DetailOverlayRef } from '../../_models/detailOverlayRef';
import { ThemeService } from '../../_services/theme.service';
import { Merchant } from '../../_models/merchant';
import { AuthService } from '../../_services/auth.service';
import { MerchantService } from '../../_services/merchant.service';
import { LoaderService } from '../../_services/loader.service';
import { DialogService } from '../../_services/dialog.service';
import { Referent } from '../../_models/referent';
import { ReferentService } from '../../_services/referent.service';
import { UserService } from '../../_services/user.service';
import { User } from '../../_models/user';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';
import { concatMap, catchError } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-self-enrollment',
  templateUrl: './self-enrollment.component.html',
  styleUrls: ['./self-enrollment.component.scss']
})

export class SelfEnrollmentComponent implements OnInit {
  isSmallScreen: boolean;
  theme: string;
  currentMerchant: Merchant;
  user: User;
  merchantData: UntypedFormGroup;
  socialData: UntypedFormGroup;
  referentData: UntypedFormGroup;
  userData: UntypedFormGroup;
  disabledFieldsUser = ['email'];
  disabledFieldsNewUser = ['merchant'];
  hasStarted = false;
  userList: Array<User> = [];
  overlayRef: DetailOverlayRef;

  constructor(
    public detailOverlayRef: DetailOverlayRef,
    private themeService: ThemeService,
    private authService: AuthService,
    private merchantService: MerchantService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private referentService: ReferentService,
    private userService: UserService,
    private translateService: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver
  ) {
    this.overlayRef = detailOverlayRef;
  }

  ngOnInit() {
    
    this.themeService.theme.subscribe(theme => this.theme = theme );
    this.iconRegistry.addSvgIcon('flag', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/flag-self-enrollment.svg'));
    this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 959px)');
    this.currentMerchant = this.merchantService.currentMerchant;
    this.user = this.authService.user;
  }

  saveMerchantData(event: UntypedFormGroup) {
    this.merchantData = event;
  }

  saveSocialData(event: UntypedFormGroup) {
    this.socialData = event;
  }

  saveReferentForm(event: UntypedFormGroup) {
    this.referentData = event;
  }

  saveUserForm(event: UntypedFormGroup) {
    this.userData = event;
  }

  submitStepperData() { //SALVA TUTTO IL FORM. SCATENATA AL CLICK SU COMPLETA, AVVIA TUTTE LE CHIAMATE.
    this.onSave()
    .subscribe(
      (observer: any[]) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      },
    )
  }

  onSave() {
    this.loaderService.startGeneral();
    this.closeOverlay();
    if (this.referentData && this.referentData.status !== 'INVALID') {
      return this.startWithReferentRequest();
    } else {
      return this.chainAllRequests(null);
    }
  }

  startWithReferentRequest() {
    const referentRequest$ = this.currentMerchant.referent 
    ? this.referentService.update(this.currentMerchant.referent._id, this.referentData.value as Referent) 
    : this.referentService.create(this.referentData.value as Referent);
    //ESTRAPOLA RESPONSE PRIMA CHIAMATA DI CREAZIONE REFERENT E AVVIA TUTTE LE ALTRE CHIAMATE PASSANDO ID REFERENT
    return referentRequest$.pipe(
      concatMap((responseReferent: any) => this.chainAllRequests(responseReferent.item._id)),
      catchError(_=> this.chainAllRequests(null)),
    )
  }

  chainAllRequests(idReferent: string) {
    //MAPPA ARRAY NUOVI UTENTI E GENERA UN ARRAY DI OBSERVABLE DERIVATI DALLE POST EFFETTUATE PER LA CREAZIONE DI OGNI UTENTE
    let observables$ = [];
    if (this.userList.length > 0) {
      observables$ = this.userList.map(el => this.userService.create(el)); //CREA NUOVI UTENTI AZIENDA
    } else {
      observables$ = []
    }
    observables$.unshift(
      this.updateMerchant(idReferent), //PASSA ID REFERENT CREATO PER L'UPDATE DEL MERCHANT
      this.updateUser(), //AGGIORNA UTENTE CORRENTE
    )
    return forkJoin(observables$);
  }


  updateMerchant(idReferent: string) {
    const merchant = Object.assign({}, this.merchantData.value, this.socialData.value);
    merchant.isSelfEnrollmentActive = false;
    if (idReferent) { merchant.referent = idReferent; }
    return this.merchantService.update(this.currentMerchant._id, merchant)
  }

  updateUser() {
    return this.authService.updateMe(this.userData.value);
  }

  addUser(event: User) {
    event.merchant = this.currentMerchant._id;
    this.userList.push(event);
  }

  removeUser(i: number) {
    this.userList.splice(i, 1);
  }

  closeOverlay() {
    this.overlayRef.close();
    sessionStorage.setItem('selfEnrollmentHasBeenVisited', 'true');
  }

  
}
