import { LoaderService } from "../../../../_services/loader.service";
import { User } from "../../../../_models/user";
import { UserService } from "../../../../_services/user.service";
import {
  Router,
  ActivatedRoute,
  UrlTree,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  UrlSegment,
} from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, FormBuilder } from "@angular/forms";
import { DialogService } from "../../../../_services/dialog.service";
import { AuthService } from "../../../../_services/auth.service";
import { HoldingMerchant } from "../../../../_models/holding-merchant";
import { MerchantService } from "../../../../_services/merchant.service";
import { Observable } from "rxjs";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-newUser",
  templateUrl: "./newUser.component.html",
  styleUrls: ["./newUser.component.scss"],
})
export class NewUserComponent implements OnInit {
  flagModifica = false;

  userDataForm: UntypedFormGroup;

  user: User;

  disabledFields: Array<string> = [];

  importedMerchantsList: HoldingMerchant[] = [];

  urlTree: UrlTree;
  urlSegmentsGroup: UrlSegmentGroup;
  urlSegments: UrlSegment[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private merchantService: MerchantService,
    private authService: AuthService,
    private userService: UserService,
    private loaderService: LoaderService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      if (!param.id) {
        this.user = new User();
        return this.setForms();
      }
      this.loaderService.startGeneral();
      this.userService.getUser(param.id).subscribe(
        (response) => {
          this.user = response.item;
          this.setForms();
          this.loaderService.stopGeneral();
        },
        (error) => {
          this.user = new User();
          this.setForms();
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN(
            "DIALOG.GENERIC_ERROR_TITLE",
            "DIALOG.GENERIC_ERROR_MESSAGE",
            error.status
          );
        }
      );
    });
  }

  setForms() {
    if (this.user._id) {
      // STO MODIFICANDO UN UTENTE ESISTENTE
      this.flagModifica = true;
    }
    if (this.user.type === "HOLDING" && this.user.merchants) {
      this.importedMerchantsList = this.user.merchants;
    }
    this.disabilitaCampi();
  }

  // SALVA I DATI PROVIENIENTI IN OUTPUT DA USER-DATA
  getUserData(event: UntypedFormGroup) {
    this.userDataForm = event;
  }

  modificaUtente() {
    this.loaderService.startGeneral();
    const userEdit = this.userDataForm.value;
    if (this.user.type === "HOLDING") {
      userEdit.merchants = this.importedMerchantsList;
    }

    this.extractSegmentsUrl();

    let ob$: Observable<any>;
    if (this.user._id === this.authService.user._id) {
      ob$ = this.authService.updateMe(userEdit);
    } else {
      if (this.authService.user.type === "HOLDING") {
        userEdit.merchant = this.merchantService.currentMerchant._id;
      }
      ob$ = this.userService.update(this.user._id, userEdit);
    }
    ob$.subscribe(
      (response: any) => {
        this.dialogService.openDialogPRIMARY(
          "DIALOG.SUCCESS_TITLE",
          "DIALOG.GENERIC_SUCCESS_MESSAGE"
        );
        this.loaderService.stopGeneral();
        // this.router.navigate(['/home/merchant/users']);
        this.router.navigate([
          `/${this.urlSegments[0]}/${this.urlSegments[1]}/users`,
        ]);
      },
      (error) => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }

  creaUtente() {
    this.extractSegmentsUrl();
    this.loaderService.startGeneral();
    this.user = this.userDataForm.value;
    if (this.user.type === "HOLDING") {
      this.user.merchants = this.importedMerchantsList;
    }
    if (this.merchantService.currentMerchant) {
      this.user.merchant = this.merchantService.currentMerchant._id;
      this.user.type = "CUSTOMER";
    }
    this.userService.create(this.user).subscribe(
      (responseUser: any) => {
        this.user._id = responseUser.item._id;
        this.dialogService.openDialogPRIMARY(
          "DIALOG.SUCCESS_TITLE",
          "DIALOG.GENERIC_SUCCESS_MESSAGE"
        );
        this.loaderService.stopGeneral();
        // this.router.navigate(['/home/merchant/users']);
        this.router.navigate([
          `/${this.urlSegments[0]}/${this.urlSegments[1]}/users`,
        ]);
      },
      (error) => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }

  // PRENDE LA LISTA DEI MERCHANT AGGIUNTI EMESSA IN OUTPUT DA HOLDING MERCHANTS E LA CONFRONTA CON LA LISTA ATTUALE
  getMerchantsList(event: HoldingMerchant[]) {
    event.forEach((el: HoldingMerchant) => {
      const index = this.importedMerchantsList.findIndex(
        (imported: HoldingMerchant) => imported.merchant === el.merchant
      );
      if (index === -1) {
        this.importedMerchantsList.push(el);
      }
    });
  }

  // PRENDE LA LISTA DEI MERCHANT RIMOSSI EMESSA IN OUTPUT DA HOLDING MERCHANTS E LA CONFRONTA CON LA LISTA ATTUALE
  getRemovedMerchants(event: HoldingMerchant) {
    const index = this.importedMerchantsList.findIndex(
      (imported: HoldingMerchant) => imported.merchant === event.merchant
    );
    if (index !== -1) {
      this.importedMerchantsList.splice(index, 1);
    }
  }

  disabilitaCampi() {
    if (!this.merchantService.isAdminMode) {
      this.disabledFields = ["active", "merchant", "type"];
      if (this.flagModifica) {
        this.disabledFields.push("password");
      }
    } else {
      this.disabledFields = ["active", "merchant"];
      if (this.flagModifica) {
        this.disabledFields.push("password");
        this.disabledFields.push("type");
        if (this.user.type === "HOLDING") {
          this.disabledFields.push("role");
        }
      }
    }
  }

  extractSegmentsUrl() {
    this.urlTree = this.router.parseUrl(this.router.url);
    this.urlSegmentsGroup = this.urlTree.root.children[PRIMARY_OUTLET];
    this.urlSegments = this.urlSegmentsGroup.segments;
  }
}
