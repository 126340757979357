import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-my-checkbox",
  templateUrl: "./my-checkbox.component.html",
  styleUrls: ["./my-checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyCheckboxComponent),
      multi: true,
    },
  ],
})
export class MyCheckboxComponent implements ControlValueAccessor {
  @Input() name: string;

  @Input()
  set isShowcase(val: boolean) {
    if (!val) {
      return;
    }
    this._isShowcase = val;
    //this.setDisabledState(val);
  }
  @Input()
  set setDisabled(val: boolean) {
    this.disabled = val;
  }
  get isShowcase() {
    return this._isShowcase;
  }

  private _isShowcase: boolean;

  value = false;
  disabled = false;

  describedBy = "";

  placeholder: string;

  controlType = "my-checkbox";

  constructor() {
    console.log("MyCheckboxComponent.constructor()");
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    if (value === undefined || value === null || value === this.value) {
      return;
    }
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  _toggleBox() {
    if (this.disabled) {
      return;
    }
    this.value = !this.value;
    this.onChange(this.value);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
