import { ValidatorFn, UntypedFormControl } from '@angular/forms';



export function MyCustomSmartphoneValidator(element: string, element2: string): ValidatorFn {
  let otherControl: UntypedFormControl;
  let thisControl: UntypedFormControl;
  let thirdControl: UntypedFormControl;

  return function myCustomSmartphoneValidator (control: UntypedFormControl): {[key: string]: any} | null {

    if (!control.parent) {
      return null;
    }

    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.parent.controls[element] as UntypedFormControl;
      thirdControl = control.parent.parent.controls[element2] as UntypedFormControl;
      if (!otherControl || !thirdControl) {
        console.log("ERRORE!!!!");
      }

      otherControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });

      thirdControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });

    }

    if (!otherControl || !thirdControl) {
      return null;
    }

    if (otherControl.value == 'SMS') {
      if (!thisControl.value) {
        return {
          myCustomSmartphoneValidator: true
        };
      }
    }

    if (otherControl.value === 'WHATSAPP' && !thirdControl.value) {
      if (!thisControl.value) {
        return {
          myCustomSmartphoneValidator: true
        };
      }
    }

    return null;
  };
}
