import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import DateHelper from "../../_helpers/date.helper";
import * as numeral from "numeral";
import { FilterValidator } from "../../_validators/filter-validator";

@Component({
  selector: 'app-operations-filter-deferred',
  templateUrl: './operations-filter-deferred.component.html',
  styleUrls: ['./operations-filter-deferred.component.scss']
})
export class OperationsFilterDeferredComponent implements OnInit {
  @Output() filters: EventEmitter<Object> = new EventEmitter<Object>();

  statoLink = [
    { value: "WAIT", viewValue: "CONTROLLA.WAIT" },
    { value: "ACTIVE", viewValue: "CONTROLLA.ACTIVE" },
    { value: "EXPIRED", viewValue: "CONTROLLA.EXPIRED" },
    { value: "COMPLETED", viewValue: "CONTROLLA.PIANO_PAGAMENTI_ADERITO" },
  ];

  statoPagamento = [
    { value: "SUCCESS", viewValue: "CONTROLLA.ABUONFINE" },
    { value: "FAILED", viewValue: "CONTROLLA.NONABUONFINE" },
  ];

  statoPianoDilazione = [
    { value: "OPEN", viewValue: "CONTROLLA.OPEN" },
    { value: "COMPLETED", viewValue: "CONTROLLA.COMPLETED" },
    { value: "DEACTIVATED", viewValue: "CONTROLLA.DEACTIVATED" },
    { value: "ERROR", viewValue: "CONTROLLA.ERROR" },
  ]

  recurrentLastOutcomeState = [
    { value: "COMPLETED", viewValue: "CONTROLLA.ABUONFINE" },
    { value: "ERROR", viewValue: "CONTROLLA.NONABUONFINE" },
    { value: "NESSUNA", viewValue: "CONTROLLA.NOTEXECUTEDYET" }
  ]

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  filterDeferredForm: UntypedFormGroup;
  isFilterActive = false;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.filterDeferredForm = this.fb.group(
      {
        link_state: [],
        name: [],
        total_amount: [],
        recurrents_amount: [],
        recurrent_status: [],
        link_date_start: [],
        link_date_end: [],
        payment_date_start: [],
        payment_date_end: [],
        operationId: [],
        causal: [],
        last_payment_status: [],
      },
      { validators: FilterValidator }
    );
  }

  filter() {
    const value = this.filterDeferredForm.value;
    const filterObj = {
      state: value.link_state,
      recurrents_status: value.recurrent_status,
      last_payment_status: value.last_payment_status,
      name: value.name,
      totalAmount: value.total_amount
        ? numeral(value.total_amount).format("0.00").toString()
        : undefined,
      recurrents_amount: value.recurrents_amount
        ? numeral(value.recurrents_amount).format("0.00").toString()
        : undefined,
      link_date_start: value.link_date_start
        ? moment
          .utc(DateHelper.stripOffset(value.link_date_start))
          .toISOString()
        : undefined,
      link_date_end: value.link_date_end
        ? moment
          .utc(DateHelper.stripOffset(value.link_date_end))
          .endOf("day")
          .toISOString()
        : undefined,
      payment_date_start: value.payment_date_start
        ? moment
          .utc(DateHelper.stripOffset(value.payment_date_start))
          .toISOString()
        : undefined,
      payment_date_end: value.payment_date_end
        ? moment
          .utc(DateHelper.stripOffset(value.payment_date_end))
          .endOf("day")
          .toISOString()
        : undefined,
      description: value.causal,
      recurrent_plan_id: value.operationId,
    };
    this.filters.emit(filterObj);
    this.isFilterActive = true;
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) this.filter();
  }

  reset() {
    this.filterDeferredForm.reset();
    this.isFilterActive = false;
    this.filters.emit(null);
  }
}
