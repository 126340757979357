import * as moment from 'moment';

export default class DateHelper {

  static dateDiff(start: moment.Moment, end: moment.Moment): number {
    return end.diff(start, 'days') + 1;
  }

  static stripOffset(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset).toISOString();
  }

  static stripOffseDate(dateTimeString: string): Date {
    const date = new Date(dateTimeString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  }
}
