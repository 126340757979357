<div class="barraFiltroPrincipale">   
    <div fxLayout="column" fxLayoutAlign="center stretch" style="margin-left:15px; margin-right:15px;">
            <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'CLIENTI.FILTROCERCA' | translate}}">
                    <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
    </div>
</div>

<div style="padding: 0 20px 20px 20px">
    
    <mat-table [dataSource]="dataSource" matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
        <!--NOME-->
        <ng-container matColumnDef="last_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header >
                <span>{{'PANNELLOUTENTE.TABELLAUTENTE' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span fxHide.gt-sm class="tableEmailxssm">{{element?.email}}</span>
                <span>{{element?.first_name}} {{element?.last_name}}</span>
            </mat-cell>
        </ng-container>
        <!--RUOLO-->
        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef>
                <span>{{'PANNELLOUTENTE.TABELLARUOLO' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element?.role}} </mat-cell>
        </ng-container>
        <!--EMAIL-->
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>{{'PANNELLOUTENTE.TABELLAEMAIL' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element?.email}} </mat-cell>
        </ng-container>
        <!--SMARTPHONE-->
        <ng-container matColumnDef="smartphone">
            <mat-header-cell *matHeaderCellDef>
                 {{'PANNELLOUTENTE.TABELLASMATPHONE' | translate}} 
                </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element?.smartphone}} </mat-cell>
        </ng-container>
        <!--PRIMO ACCESSO-->
        <ng-container matColumnDef="first_access">
                <mat-header-cell *matHeaderCellDef> {{'PANNELLOUTENTE.FIRSTACCESS' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.first_access_date | date:'dd/MM/yyyy HH:mm'}} </mat-cell>
        </ng-container>
        <!--ACTIONS-->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="textTableFont">
                {{'PANNELLOUTENTE.TABELLAACTION' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                    <a mat-icon-button [routerLink]="'./' + element?._id">
                            <mat-icon class="edit icon-modifica" matTooltip="{{'PANNELLOUTENTE.TOOLTIPMODIFICA' | translate}}  "></mat-icon>
                    </a>
            </mat-cell>
        </ng-container>
        <!--DEFINIZIONE HEADER E ROW-->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
<!--PAGINATOR-->
<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="matPaginator"></mat-paginator>
<!--FLOATING BUTTON NEW-->
<a *ngIf="authService.user?.role!=='USER'" mat-fab color="primary" class="fab-add-button " 
    routerLink="./new" [matTooltip]=" 'PANNELLOUTENTE.BUTTONADD' | translate ">
    <i class="material-icons" aria-hidden="true">add</i>
</a>





