<div class="barraFiltroPrincipale">
  <div fxLayout="column" fxLayoutAlign="center stretch" style="margin-left:15px; margin-right:15px;">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'CLIENTI.FILTROCERCA' | translate}}">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
</div>

<div style="padding: 0 20px 20px 20px">

  <mat-table [dataSource]="dataSource" matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
    <!--NOME-->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont">
        <span>{{'CLIENTI.TABELLACLIENTE' | translate}} </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>
    <!--INSEGNA-->
    <ng-container matColumnDef="insegna">
      <mat-header-cell *matHeaderCellDef class="textTableFont">
        <span>{{'CLIENTI.TABELLAINSEGNA' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.insegna}} </mat-cell>
    </ng-container>
    <!--PARTITA IVA-->
    <ng-container matColumnDef="piva">
      <mat-header-cell *matHeaderCellDef class="textTableFont">
        <span>{{'CLIENTI.TABELLAPIVA' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.piva}} </mat-cell>
    </ng-container>
    <!--EMAIL-->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont">
        <span> {{'CLIENTI.TABBELLAEMAIL' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
    </ng-container>
    <!--REFERENTE-->
    <ng-container matColumnDef="referent">
      <mat-header-cell *matHeaderCellDef class="textTableFont">
        <span>{{'CLIENTI.TABPERSON' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.referent?.first_name }} {{element.referent?.last_name }}
      </mat-cell>
    </ng-container>
    <!--TID-->
    <ng-container matColumnDef="tid">
      <mat-header-cell *matHeaderCellDef class="textTableFont">
        <span>{{'CLIENTI.TABELLATID' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.terminal.length > 0">{{element.terminal[0].tid}}</span>
      </mat-cell>
    </ng-container>
    <!--ACTIONS-->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="textTableFont"> {{'CLIENTI.TABELLAACTION' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" style="justify-content: flex-end;">
        <div *ngIf="authService.user?.role!=='USER'" fxLayout="row" fxLayoutAlign="end">
          <!--IMPERSONIFICA-->
          <button mat-icon-button (click)="impersonificaAzienda(element)">
            <mat-icon style="margin-right: 0" class="inperson icon-show"
              matTooltip="{{'CLIENTI.IMPERSONIFICA' | translate}}  "></mat-icon>
          </button>
          <div fxLayout fxHide.lt-sm>
            <!--MODIFICA-->
            <button mat-icon-button (click)="modificaAzienda(element)">
              <mat-icon style="margin-right: 0" class="edit icon-modifica"
                matTooltip="{{'CLIENTI.TOOLTIPMODIFICA' | translate}}  "></mat-icon>
            </button>
            <button mat-icon-button *ngIf="authService.user?.role!=='USER'"
              [disabled]="!element?.isCustomTemplateActive" (click)="goToTemplates(element)">
              <mat-icon class="template" svgIcon="template-custom"
                matTooltip="{{'CLIENTI.TOOLTIPTEMPLATECUSTOM' | translate}}"
                [ngStyle]="{'cursor': element?.isCustomTemplateActive ? 'pointer' : 'default'}"></mat-icon>
            </button>
            <!--DISATTIVA-->
            <button mat-icon-button *ngIf="element.active" (click)="toggleActive(element)">
              <mat-icon style="margin-right: 0" class="delete icon-utente-disattivo"
                matTooltip="{{'CLIENTI.TOOLTIPDISATTIVA' | translate}}  "></mat-icon>
            </button>
            <!--ATTIVA-->
            <button mat-icon-button *ngIf="!element.active" (click)="toggleActive(element)">
              <mat-icon style="margin-right: 0" class="abilita icon-completato"
                matTooltip="{{'CLIENTI.TOOLTIPATTIVA' | translate}}  "></mat-icon>
            </button>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <!--DEFINIZIONE HEADER E ROW-->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<!--PAGINATOR-->
<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="matPaginator">
</mat-paginator>
<!--FLOATING BUTTON NEW-->
<button *ngIf="authService.user?.role!=='USER'" mat-fab color="primary" class="fab-add-button "
  (click)="creaNuovaAzienda()" [matTooltip]="'AZIENDA.CREAAZIENDA' | translate">
  <i class="material-icons" aria-hidden="true">add</i>
</button>
