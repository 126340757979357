import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { ThemeService } from "../../_services/theme.service";
import { DomSanitizer } from "@angular/platform-browser";
import { timer } from "rxjs";

@Component({
  selector: "app-dialog-link",
  templateUrl: "./dialog-link.component.html",
  styleUrls: ["./dialog-link.component.scss"],
})
export class DialogLinkComponent implements OnInit {
  displayedLink: string;
  theme: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
    private themeService: ThemeService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.displayedLink = data.displayedLink;
    this.iconRegistry.addSvgIcon(
      "duplica",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/duplica.svg"
      )
    );
  }

  ngOnInit() {
    this.themeService.theme.subscribe((theme) => {
      this.theme = theme;
    });
  }

  copyToClipboard() {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", this.displayedLink);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
}
