<div fxLayout="row" fxLayoutAlign="stretch">
    <div *ngFor='let x of Bread; let i=index'>
        <a *ngIf="i < Bread.length-1" class='breadCrumbsContainer' [routerLink]="x?.url">
            <span [ngClass]='(Bread.length-1)>i ? "breadCrumbs" : "breadCrumbsLast"' >{{x.label | translate}}</span>
        </a>
        <span class='breadCrumbsDivider' *ngIf='Bread.length-1 > i'>></span>
        <div class='breadCrumbsContainer' *ngIf="i === Bread.length-1">
            <span [ngClass]='(Bread.length-1)>i ? "breadCrumbs" : "breadCrumbsLast"'>{{x.label | translate}}</span>
            <span class='breadCrumbsDivider' *ngIf='Bread.length-1 > i'>></span>
        </div>
    </div>
</div>
