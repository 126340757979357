<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important;" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{'NUOVOUTENTE.TITOLO' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column" fxLayoutAlign="space-around stretch">
        <!-- USER DATA FORM -->
        <app-user-data [isProfile]='flagModifica ? "true" : "false"' [userData]='user' [disabledFields]='disabledFields'
          (outputFormValues)="getUserData($event)">
        </app-user-data>

        <!-- SELECT MERCHANTS -->
        <app-holding-merchants *ngIf="userDataForm?.value.type === 'HOLDING' || user?.type === 'HOLDING'" [user]="user"
          (outputPushed)="getMerchantsList($event)" (outputPulled)="getRemovedMerchants($event)">
        </app-holding-merchants>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-around stretch" style="margin-top: 30px;">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- MODIFICA -->
          <button mat-flat-button color="primary" *ngIf="flagModifica" [disabled]="!userDataForm?.valid
                            || (user.type === 'HOLDING' && importedMerchantsList.length === 0)"
            (click)="modificaUtente()">{{'NUOVOUTENTE.BUTTONMOD' | translate}}</button>

          <!-- SALVA -->
          <button mat-flat-button color="primary" *ngIf="!flagModifica" [disabled]="!userDataForm?.valid
                            || (userDataForm?.value.type === 'HOLDING' && importedMerchantsList.length === 0)"
            (click)="creaUtente()">{{'NUOVOUTENTE.BUTTONSAVE' | translate}}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>

<app-change-password *ngIf="user?._id" [userId]="user?._id"></app-change-password>
