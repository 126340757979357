<!--    *
        *
        1° INSERIMENTO BARRA FILTRO DI RICERCA VALIDA PER TUTTE LE DIMENSIONI
        *
        *  -->





<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltroPrincipale"
  ngClass.sm="barraFiltroPrincipale" ngClass.md="barraFiltroPrincipale" ngClass.lg="barraFiltroPrincipale"
  ngClass.xl="barraFiltroPrincipale">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{ 'NUOVOCLIENTE.TITOLOBARRA1' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>



      <!--    *
                *
                DISPOSITIVI DIMENSIONE MD LG XL (LANDSCAPE)
                STRUTTURA FILTRO DUE CAMPI PER RIGA
                *
                *  -->

      <form [formGroup]="customerForm" novalidate (window:resize)="onResizeCustomer()">
        <!-- 1A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{'NUOVOCLIENTE.BARRA1AZIENDA' | translate}}" formControlName="merchant"
                required>
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1EMAILPAGAMENTO' | translate}}" formControlName="email"
                [errorStateMatcher]="matcher" required>
              <mat-error
                *ngIf="customerForm.controls['email'].hasError('pattern') && !customerForm.controls['email'].hasError('required')"
                [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
              <mat-error *ngIf="customerForm.controls['email'].hasError('required')"
                [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- 2A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1PARTITAIVA' | translate}}" formControlName="piva"
                [errorStateMatcher]="matcher">
              <mat-error *ngIf="customerForm.controls['piva'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="center center" style="width:49%">
              <mat-form-field style="width: 20%; margin-right: 20px;">
                <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}"
                  formControlName="area_code" required>
                  <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">

                    <img alt="flag icon" class="flagCountry"
                      src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                    <span>{{ type.CODE }}</span>
                    <span>{{ type.DESC }}</span>
                    <span> - {{ type.COUNTRY | uppercase }}</span>


                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;">
                <input type="number" matInput placeholder="{{'NUOVOCLIENTE.BARRA1MOBILEPAG' | translate}}"
                  formControlName="smartphone" required>
                <mat-error *ngIf="referentForm.controls['smartphone'].hasError('required')"
                  [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>

              </mat-form-field>
            </div>

          </div>
        </div>
        <!-- 3A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1NOMEREGIONE' | translate}}" formControlName="name"
                [errorStateMatcher]="matcher" required>
              <mat-error *ngIf="customerForm.controls['name'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1DESCRIZIONE' | translate}}"
                formControlName="description">
            </mat-form-field>
          </div>
        </div>



        <!--    *
                *
                DISPOSITIVI DIMENSIONE XS SM (PORTAIT)
                STRUTTURA FILTRO UN CAMPO PER RIGA
                *
                *  -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{'NUOVOCLIENTE.BARRA1AZIENDA' | translate}}" formControlName="merchant"
              required>
          </mat-form-field>

        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1PARTITAIVA' | translate}}" formControlName="piva"
              [errorStateMatcher]="matcher">
            <mat-error *ngIf="customerForm.controls['piva'].hasError('required')"
              [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1NOMEREGIONE' | translate}}" formControlName="name"
              [errorStateMatcher]="matcher" required>
            <mat-error *ngIf="customerForm.controls['name'].hasError('required')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1EMAILPAGAMENTO' | translate}}" formControlName="email"
              [errorStateMatcher]="matcher" required>
            <mat-error
              *ngIf="customerForm.controls['email'].hasError('pattern') && !customerForm.controls['email'].hasError('required')"
              [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
            <mat-error *ngIf="customerForm.controls['email'].hasError('required')"
              [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field style="width: 20%; margin-right: 20px;">
              <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code"
                required>
                <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
                  <img alt="flag icon" class="flagCountry"
                    src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                  <span>{{ type.CODE }}</span>
                  <span>{{ type.DESC }}</span>
                  <span> - {{ type.COUNTRY | uppercase }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 100%;">
              <input type="number" matInput placeholder="{{'NUOVOCLIENTE.BARRA1MOBILEPAG' | translate}}"
                formControlName="smartphone" required>
              <mat-error *ngIf="customerForm.controls['smartphone'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>

            </mat-form-field>
          </div>



        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA1DESCRIZIONE' | translate}}"
              formControlName="description">
          </mat-form-field>
        </div>

      </form>
      <div fxLayout="column" fxLayoutAlign="space-evenly end" fxHide.xs fxHide.sm>
        <!-- BOTTONE VERDE -->

        <button *ngIf="!modCustomer && !flagAggiungiDatiAnagrafici" mat-flat-button color="primary"
          (click)="aggiungiDatiAnagrafici()" [disabled]=" !customerForm.valid ">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' |
          translate}}</button>
        <button *ngIf="!modCustomer && flagAggiungiDatiAnagrafici" mat-flat-button color="primary"
          (click)="modificaDatiAnagrafici()" [disabled]=" !customerForm.valid ">{{'NUOVOCLIENTE.BOTTONEMODIFICA' |
          translate}}</button>
        <button *ngIf="modCustomer" mat-flat-button color="primary" (click)="modificaDatiAnagrafici()"
          [disabled]=" !customerForm.valid && flagAggiungiDatiAnagrafici">{{'NUOVOCLIENTE.BOTTONEMODIFICA' |
          translate}}</button>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxHide.md fxHide.lg fxHide.xl>
        <!-- BOTTONE VERDE -->
        <button *ngIf="!modCustomer" mat-flat-button color="primary" (click)="aggiungiDatiAnagrafici()"
          [disabled]=" !customerForm.valid || flagAggiungiDatiAnagrafici">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' |
          translate}}</button>
        <button *ngIf="modCustomer" mat-flat-button color="primary" (click)="modificaDatiAnagrafici()"
          [disabled]=" !customerForm.valid || flagAggiungiDatiAnagrafici">{{'NUOVOCLIENTE.BOTTONEMODIFICA' |
          translate}}</button>


      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>



<!--    *
        *
        2° INSERIMENTO BARRA FILTRO DI RICERCA VALIDA PER TUTTE LE DIMENSIONI
        *
        *  -->


<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{ 'NUOVOCLIENTE.TITOLOBARRA2' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>



      <!--    *
                        *
                        DISPOSITIVI DIMENSIONE MD LG XL (LANDSCAPE)
                        STRUTTURA FILTRO DUE CAMPI PER RIGA
                        *
                        *  -->

      <form [formGroup]="customerForm" novalidate>
        <!-- 1A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2POSIZIONE' | translate}}">
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2REGIONE' | translate}}" formControlName="region">
            </mat-form-field>
          </div>
        </div>
        <!-- 2A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2INDIRIZZO' | translate}}" formControlName="address">
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2PROVINCIA' | translate}}" formControlName="province">
            </mat-form-field>
          </div>
        </div>
        <!-- 3A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2NUMERO' | translate}}" formControlName="number">
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2COMUNE' | translate}}" formControlName="district">
            </mat-form-field>
          </div>
        </div>
        <!-- 4A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2STATO' | translate}}" formControlName="state">
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2CAP' | translate}}" formControlName="cap">
            </mat-form-field>
          </div>
        </div>


        <!--    *
                        *
                        DISPOSITIVI DIMENSIONE XS SM (PORTAIT)
                        STRUTTURA FILTRO UN CAMPO PER RIGA
                        *
                        *  -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2POSIZIONE' | translate}}">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2INDIRIZZO' | translate}}" formControlName="address">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2NUMERO' | translate}}" formControlName="number">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2STATO' | translate}}" formControlName="state">
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2REGIONE' | translate}}" formControlName="region">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2PROVINCIA' | translate}}" formControlName="province">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2COMUNE' | translate}}" formControlName="district">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA2CAP' | translate}}" formControlName="cap">
          </mat-form-field>
        </div>

      </form>
      <div fxLayout="column" fxLayoutAlign="space-evenly end" fxHide.xs fxHide.sm>
        <!-- BOTTONE VERDE -->
        <button *ngIf="!modCustomer&& !flagAggiungiPosizione" mat-flat-button color="primary"
          (click)="aggiungiModificaResidenzaPosizione()"
          [disabled]="!flagAggiungiDatiAnagrafici">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' | translate}}</button>
        <button *ngIf="!modCustomer&& flagAggiungiPosizione" mat-flat-button color="primary"
          (click)="aggiungiModificaResidenzaPosizione()">{{'NUOVOCLIENTE.BOTTONEMODIFICA' | translate}}</button>
        <button *ngIf="modCustomer" mat-flat-button color="primary" (click)="aggiungiModificaResidenzaPosizione()"
          [disabled]="flagAggiungiDatiAnagrafici ">{{'NUOVOCLIENTE.BOTTONEMODIFICA' | translate}}</button>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxHide.md fxHide.lg fxHide.xl>
        <!-- BOTTONE VERDE -->
        <button *ngIf="!modCustomer&& !flagAggiungiPosizione" mat-flat-button color="primary"
          (click)="aggiungiModificaResidenzaPosizione()"
          [disabled]="!flagAggiungiDatiAnagrafici">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' | translate}}</button>
        <button *ngIf="!modCustomer&& flagAggiungiPosizione" mat-flat-button color="primary"
          (click)="aggiungiModificaResidenzaPosizione()">{{'NUOVOCLIENTE.BOTTONEMODIFICA' | translate}}</button>
        <button *ngIf="modCustomer" mat-flat-button color="primary" (click)="aggiungiModificaResidenzaPosizione()"
          [disabled]="flagAggiungiDatiAnagrafici">{{'NUOVOCLIENTE.BOTTONEMODIFICA' | translate}}</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</div>






<!--    *
        *
        3° INSERIMENTO BARRA FILTRO DI RICERCA VALIDA PER TUTTE LE DIMENSIONI
        *
        *  -->


<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{ 'NUOVOCLIENTE.TITOLOBARRA3' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>



      <!--    *
                        *
                        DISPOSITIVI DIMENSIONE MD LG XL (LANDSCAPE)
                        STRUTTURA FILTRO DUE CAMPI PER RIGA
                        *
                        *  -->
      <form [formGroup]="referentForm" novalidate (window:resize)="onResizeReferent()">

        <!-- 1A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3NOME' | translate}}" formControlName="first_name"
                [errorStateMatcher]="matcher" required>
              <mat-error *ngIf="referentForm.controls['first_name'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3TELEFONO' | translate}}" formControlName="phone">
            </mat-form-field>
          </div>
        </div>
        <!-- 2A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3COGNOME' | translate}}" formControlName="last_name"
                [errorStateMatcher]="matcher" required>
              <mat-error *ngIf="referentForm.controls['last_name'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
            </mat-form-field>


            <div fxLayout="row" fxLayoutAlign="center center" style="width:49%">
              <mat-form-field style="width: 20%; margin-right: 20px;">
                <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}"
                  formControlName="area_code">
                  <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
                    <img alt="flag icon" class="flagCountry"
                      src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                    <span>{{ type.CODE }}</span>
                    <span>{{ type.DESC }}</span>
                    <span> - {{ type.COUNTRY | uppercase }}</span>

                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%;">
                <input type="number" matInput placeholder="{{'NUOVOCLIENTE.BARRA1MOBILEPAG' | translate}}"
                  formControlName="smartphone" required>
                <mat-error *ngIf="referentForm.controls['smartphone'].hasError('required')"
                  [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>

              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- 3A RIGA  -  CAMPO "DATA" E CAMPO "STATO" -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.xs fxHide.sm>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3EMAIL' | translate}}" formControlName="email"
                [errorStateMatcher]="matcher" required>
              <mat-error
                *ngIf="referentForm.controls['email'].hasError('pattern') && !referentForm.controls['email'].hasError('required')"
                [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
              <mat-error *ngIf="referentForm.controls['email'].hasError('required')"
                [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
            </mat-form-field>

            <mat-form-field ngClass.md="filtroCampoADueMd" ngClass.lg="filtroCampoADueLg"
              ngClass.xl="filtroCampoADueXl">
              <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3FAX' | translate}}" formControlName="fax">
            </mat-form-field>
          </div>
        </div>

        <!--    *
                        *
                        DISPOSITIVI DIMENSIONE XS SM (PORTAIT)
                        STRUTTURA FILTRO UN CAMPO PER RIGA
                        *
                        *  -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3NOME' | translate}}" formControlName="first_name"
              [errorStateMatcher]="matcher" required>
            <mat-error *ngIf="referentForm.controls['first_name'].hasError('required')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3COGNOME' | translate}}" formControlName="last_name"
              [errorStateMatcher]="matcher" required>
            <mat-error *ngIf="referentForm.controls['last_name'].hasError('required')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3EMAIL' | translate}}" formControlName="email"
              [errorStateMatcher]="matcher" required>

            <mat-error
              *ngIf="referentForm.controls['email'].hasError('pattern') && !referentForm.controls['email'].hasError('required')"
              [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
            <mat-error *ngIf="referentForm.controls['email'].hasError('required')"
              [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3TELEFONO' | translate}}" formControlName="phone">
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>

          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field style="width: 20%; margin-right: 20px;">
              <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}"
                formControlName="area_code">
                <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">

                  <img alt="flag icon" class="flagCountry"
                    src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                  <span>{{ type.CODE }}</span>
                  <span>{{ type.DESC }}</span>
                  <span> - {{ type.COUNTRY | uppercase }}</span>


                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 100%;">
              <input type="number" matInput placeholder="{{'NUOVOCLIENTE.BARRA1MOBILEPAG' | translate}}"
                formControlName="smartphone" required>
              <mat-error *ngIf="referentForm.controls['smartphone'].hasError('required')"
                [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>

            </mat-form-field>
          </div>


        </div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxHide.md fxHide.lg fxHide.xl>
          <mat-form-field>
            <input matInput placeholder="{{ 'NUOVOCLIENTE.BARRA3FAX' | translate}}" formControlName="fax">
          </mat-form-field>
        </div>



      </form>

      <div fxLayout="column" fxLayoutAlign="space-evenly end" fxHide.xs fxHide.sm>
        <!-- BOTTONE VERDE -->
        <button *ngIf="!modCustomer&& !flagAggiungiReferent" mat-flat-button color="primary"
          (click)="aggiungiReferente()"
          [disabled]="!flagAggiungiDatiAnagrafici|| !referentForm.valid ">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' |
          translate}}</button>
        <button *ngIf="!modCustomer&& flagAggiungiReferent" mat-flat-button color="primary"
          (click)="aggiungiReferente()">{{'NUOVOCLIENTE.BOTTONEMODIFICA' | translate}}</button>
        <button *ngIf="modCustomer && codiceIdReferent === ''" mat-flat-button color="primary"
          (click)="aggiungiReferente()" [disabled]="!referentForm.valid ">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' |
          translate}}</button>
        <button *ngIf="modCustomer && codiceIdReferent != ''" mat-flat-button color="primary"
          (click)="modificaReferent()" [disabled]="!referentForm.valid ">{{'NUOVOCLIENTE.BOTTONEMODIFICA' |
          translate}}</button>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxHide.md fxHide.lg fxHide.xl>
        <!-- BOTTONE VERDE -->
        <button *ngIf="!modCustomer && !flagAggiungiReferent" mat-flat-button color="primary"
          (click)="aggiungiReferente()"
          [disabled]="!flagAggiungiPosizione || !referentForm.valid">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' |
          translate}}</button>
        <button *ngIf="!modCustomer&& flagAggiungiReferent" mat-flat-button color="primary"
          (click)="aggiungiReferente()">{{'NUOVOCLIENTE.BOTTONEMODIFICA' | translate}}</button>
        <button *ngIf="modCustomer && codiceIdReferent === ''" mat-flat-button color="primary"
          (click)="aggiungiReferente()" [disabled]="!referentForm.valid ">{{'NUOVOCLIENTE.BOTTONEAGGIUNGI' |
          translate}}</button>
        <button *ngIf="modCustomer && codiceIdReferent != ''" mat-flat-button color="primary"
          (click)="modificaReferent()" [disabled]="!referentForm.valid ">{{'NUOVOCLIENTE.BOTTONEMODIFICA' |
          translate}}</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>


</div>
