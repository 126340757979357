import { saveAs } from "file-saver";
import { HoldingService } from "./../../_services/holding.service";
import { MerchantService } from "./../../_services/merchant.service";
import {
  Breakpoints,
  BreakpointState,
  BreakpointObserver,
} from "@angular/cdk/layout";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import {
  trigger,
  state,
  transition,
  animate,
  style,
} from "@angular/animations";
import { Router } from "@angular/router";
import { Merchant } from "./../../_models/merchant";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { HoldingTableMerchant } from "../../_models/holding-table-merchant";

@Component({
  selector: "app-holding-merchants-table",
  templateUrl: "./holding-merchants-table.component.html",
  styleUrls: ["./holding-merchants-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class HoldingMerchantsTableComponent implements OnInit {
  @Input() set merchants(value: MatTableDataSource<HoldingTableMerchant[]>) {
    if (value) {
      this._merchants = value;
    }
  }

  _merchants: MatTableDataSource<HoldingTableMerchant[]>;
  get merchants() {
    return this._merchants;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[];
  expandedElement: HoldingTableMerchant | null;
  activeMediaQuery: string;

  constructor(
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public breakpointObserver: BreakpointObserver,
    private merchantService: MerchantService,
    private holdingService: HoldingService
  ) {
    this.iconRegistry.addSvgIcon(
      "openPayment",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/open-payment.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "downloadIcon",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/download.svg"
      )
    );
  }

  ngOnInit() {
    this.resizeTable();
  }

  ngAfterViewInit() {
    if (this._merchants) {
      this._merchants.paginator = this.paginator;
      this._merchants.sort = this.sort;
      if (!this.sort) {
        return;
      }
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    }
  }

  impersonificaAzienda(row: Merchant) {
    this.merchantService
      .impersonifica(row._id)
      .subscribe((_) => this.router.navigate(["/merchant"]));
  }

  modificaAzienda(row: any) {
    this.merchantService
      .impersonifica(row._id)
      .subscribe((_) =>
        this.router.navigate(["/merchant/my-company/company-profile"])
      );
  }

  downloadExcel(row: Merchant) {
    this.holdingService.getExcelSingleMerchant(row._id).subscribe((blob) => {
      saveAs(blob, `${row.name}.xlsx`);
    });
  }

  resizeTable() {
    let myBreakPoints = [
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ];
    this.breakpointObserver
      .observe(myBreakPoints)
      .subscribe((bpState: BreakpointState) => {
        if (bpState.breakpoints[Breakpoints.XSmall]) {
          this.activeMediaQuery = Breakpoints.XSmall;
          this.displayedColumns = ["name", "actions"];
        }
        if (bpState.breakpoints[Breakpoints.Small]) {
          this.activeMediaQuery = Breakpoints.Small;
          this.displayedColumns = ["name", "terminal", "actions"];
        }
        if (bpState.breakpoints[Breakpoints.Medium]) {
          this.activeMediaQuery = Breakpoints.Medium;
          this.displayedColumns = [
            "name",
            "province",
            "piva",
            "terminal",
            "actions",
          ];
        }
        if (bpState.breakpoints[Breakpoints.Large]) {
          this.activeMediaQuery = Breakpoints.Large;
          this.displayedColumns = [
            "name",
            "insegna",
            "province",
            "email",
            "piva",
            "referent",
            "terminal",
            "actions",
          ];
        }
        if (bpState.breakpoints[Breakpoints.XLarge]) {
          this.activeMediaQuery = Breakpoints.XLarge;
          this.displayedColumns = [
            "name",
            "insegna",
            "province",
            "email",
            "piva",
            "referent",
            "terminal",
            "actions",
          ];
        }
      });
  }

  toggleRow(element: HoldingTableMerchant) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }
}
