import { PreviewRicComponent } from './../../../../_components/previewRic/previewRic.component';
import { counties } from "../../../../_models/country";
import { QrCodeDialogComponent } from "../../../../_components/qrCodeDialog/qrCodeDialog.component";
import { DialogLinkComponent } from "../../../../_components/dialog-link/dialog-link.component";
import { TemplateService } from "../../../../_services/template.service";
import { PaymentService } from "../../../../_services/payment.service";
import { Payment } from "../../../../_models/payment";
import { CustomerService } from "../../../../_services/customer.service";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import * as moment from "moment";
import { DateAdapter } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { User } from "../../../../_models/user";
import { LoaderService } from "../../../../_services/loader.service";
import { MerchantService } from "../../../../_services/merchant.service";
import { Merchant } from "../../../../_models/merchant";
// eslint-disable-next-line no-restricted-imports
import { Observable, Subscription } from "rxjs";
import { startWith, map, tap } from "rxjs/operators";
import { DialogService } from "../../../../_services/dialog.service";
import { Customer } from "../../../../_models/customer";
import {
  FileUploader,
  FileUploaderOptions,
  FileLikeObject,
  FileItem,
  ParsedResponseHeaders,
} from "ng2-file-upload";
import * as numeral from "numeral";
import { AuthService } from "../../../../_services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { MyCustomEmailValidator } from "../../../../_validators/my-custom-email-validator";
import { MyCustomSmartphoneValidator } from "../../../../_validators/my-custom-smartphone-validator";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-requestPaymentEasy",
  templateUrl: "./requestPaymentEasy.component.html",
  styleUrls: ["./requestPaymentEasy.component.scss"],
})
export class RequestPaymentEasyComponent implements OnInit, OnDestroy {
  listCurrency: any;
  flagQRCODE: boolean;
  flagVERIFY: boolean;
  flagEMAIL: boolean;
  flagSMS: boolean;
  flagLINK: boolean;
  flagWHATSAPP: boolean;
  onlyQRCODELINK = false;

  isPaymentOpenActive: boolean;
  merchant: Merchant;
  uploadResponseAllegato: any;
  fifteenDays: string;
  currentDay: string;
  public readonly UPLOAD_URL: string =
    environment.BASE_URL + "payment/attachment";
  flagFormato: boolean;
  customerSelect: Customer;
  user: User;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  filteredCustomer: Observable<any[]>;
  customers: Customer[];
  template: any;
  pagamentoAvanzatoForm: UntypedFormGroup;
  payment: Payment = new Payment();
  paymentType: string;


  donationActive = false;
  isRecurrentActive: boolean = false;
  isDeferredActive: boolean = false;

  flagImporto: boolean = true;
  flagNomeCognome = false;
  flagDescrizione = false;
  flagAllegato = false;
  flagSmsEmail = false;
  flagGenerateRecurrentPlan: boolean = false;

  language = [{ value: "IT", viewValue: "Italiano" }];

  countryPrefix: any = [];

  private watcher: Subscription;
  fileUploaderOptions: FileUploaderOptions;
  uploader: FileUploader;
  item = null;
  file = new UntypedFormControl("");
  @ViewChild("inputfile") inputfile: ElementRef;

  public nominativoAnimationConfiguration: Object;
  private nominativoAnimation: any;
  private reverseNominativo = -1;

  public importoAnimationConfiguration: Object;
  private importoAnimation: any;

  public descrizioneAnimationConfiguration: Object;
  private descrizioneAnimation: any;

  public allegatoAnimationConfiguration: Object;
  private allegatoAnimation: any;
  private reverseAllegato = -1;

  public emailAnimationConfiguration: Object;
  private emailAnimation: any;

  // Manage search form panel
  customerFormSearch: string;
  showFormSearchPanel: boolean;
  isSmallScreen: boolean;


  private recurrentList = [
    { value: 2, viewValue: '2' },
    { value: 3, viewValue: '3' },
    { value: 4, viewValue: '4' },
    { value: 5, viewValue: '5' },
    { value: 6, viewValue: '6' },
    { value: 7, viewValue: '7' },
    { value: 8, viewValue: '8' },
    { value: 9, viewValue: '9' },
    { value: 10, viewValue: '10' },
    { value: 11, viewValue: '11' },
    { value: 12, viewValue: '12' },
    { value: 0, viewValue: 'Senza termine' }
  ];

  private deferredList = [
    { value: 3, viewValue: '3' },
    { value: 4, viewValue: '4' }
  ];

  private frequencyList = [];

  constructor(
    private router: Router,
    private adapter: DateAdapter<any>,
    private authService: AuthService,
    private loaderService: LoaderService,
    private merchantService: MerchantService,
    private customerService: CustomerService,
    private templateService: TemplateService,
    private paymentService: PaymentService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.customerFormSearch = "";
    this.showFormSearchPanel = false;
    this.uploader = new FileUploader({});
    this.adapter.setLocale("it");

    this.fileUploaderOptions = {
      url: this.UPLOAD_URL,
      headers: [{ name: "Authorization", value: this.authService.token }],
      method: "POST",
      queueLimit: 1,
      removeAfterUpload: true,
      allowedMimeType: ["application/pdf"],
    };
    this.uploader.setOptions(this.fileUploaderOptions);
    this.uploader.onWhenAddingFileFailed = (
      item: FileLikeObject,
      filter: any,
      options: any
    ): any => {
      this.dialogService.openDialogWARN(
        "DIALOG.FORMATCSV_ERROR_TITLE",
        "DIALOG.FORMATIMAGE_ERROR_MESSAGE_PDF"
      );
      this.file = new UntypedFormControl("");
      this.flagFormato = false;
    };

    this.uploader.onCompleteItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ): any => {
      if (status === 200) {
        this.uploadResponseAllegato = JSON.parse(response);
      } else {
        let parsedError;
        if (JSON.parse(response)) {
          parsedError = JSON.parse(response);
        }
        if (parsedError && parsedError.message) {
          this.dialogService.openDialogWARN(
            "DIALOG.GENERIC_ERROR_TITLE",
            parsedError.message,
            status
          );
        } else {
          this.dialogService.openDialogWARN(
            "DIALOG.GENERIC_ERROR_TITLE",
            parsedError.message,
            status
          );
        }
      }
    };
    this.uploader.onAfterAddingFile = (fileItem: FileItem): any => {
      this.file = new UntypedFormControl(fileItem._file.name);

      this.flagFormato = true;
    };
    moment.locale("it");
    this.currentDay = moment().format("YYYY-MM-DD");
    this.fifteenDays = moment().add(15, "days").format("YYYY-MM-DD");

    this.importoAnimationConfiguration = {
      path: this.ASSETS_BASE + "json/importo.json",
      autoplay: true,
      loop: true,
      speed: 2,
    };
    this.nominativoAnimationConfiguration = {
      path: this.ASSETS_BASE + "json/nome-cognome.json",
      autoplay: true,
      loop: true,
      speed: 2,
    };

    this.descrizioneAnimationConfiguration = {
      path: this.ASSETS_BASE + "json/descrizione.json",
      autoplay: true,
      loop: true,
      speed: 2,
    };

    this.allegatoAnimationConfiguration = {
      path: this.ASSETS_BASE + "json/allegato.json",
      autoplay: true,
      loop: true,
      speed: 2,
    };

    this.emailAnimationConfiguration = {
      path: this.ASSETS_BASE + "json/mail.json",
      autoplay: true,
      loop: true,
      speed: 2,
    };

    this.buidDatiPagamentoAvanzatoFrom();
    this.getIcons();
  }

  handleImportoAnimation(anim: any) {
    this.importoAnimation = anim;
  }

  handleNominativoAnimation(anim: any) {
    this.nominativoAnimation = anim;
  }

  handleDescrizioneAnimation(anim: any) {
    this.descrizioneAnimation = anim;
  }

  handleAllegatoAnimation(anim: any) {
    this.allegatoAnimation = anim;
  }

  handleEmailAnimation(anim: any) {
    this.emailAnimation = anim;
  }

  handleTransactionType(type) {
    if (this.merchant) {
      return this.merchant.transaction_type.includes(type);
    } else {
      return true;
    }
  }

  ngOnInit() {
    if (!this.merchantService.currentMerchant) {
      return;
    }
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });

    this.loaderService.startGeneral();

    this.uploadResponseAllegato = null;
    this.user = this.authService.user;
    this.fileUploaderOptions.url = this.UPLOAD_URL;
    this.uploader.setOptions(this.fileUploaderOptions);

    this.merchant = this.merchantService.currentMerchant;
    this.isPaymentOpenActive =
      this.merchantService.currentMerchant.isPaymentOpenActive;

    if (this.merchant["templates"].length == 0) {
      this.fallbackTemplate();
    }

    if (this.merchant.templates) {
      if (typeof this.merchant.templates[0] == "object") {
        this.template = this.merchant.templates[0]["_id"];
      } else {
        this.template = this.merchant.templates[0];
      }
    } else {
      this.dialogService.openDialogWARN(
        "DIALOG.GENERIC_ERROR_TITLE",
        "DIALOG.TEMPLATEERRORMESSAGE"
      );
    }

    this.getData();
  }

  ngOnDestroy() {
    if (!this.watcher) {
      return;
    }
    this.watcher.unsubscribe();
  }

  filterStates(name: string) {
    if (name.length < 3) {
      return;
    }
    return this.customers.filter(
      (customers) =>
        customers.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  getData() {
    this.customerService.listMerchant(this.merchant._id).subscribe(
      (results) => {
        this.customers = <Customer[]>results["items"];

        this.flagEMAIL = false;
        this.flagQRCODE = false;
        this.flagSMS = false;
        this.flagWHATSAPP = false;

        for (let i = 0; i < this.merchant.operation_type.length; i++) {
          if (this.merchant.operation_type[i] === "EMAIL") {
            this.flagEMAIL = true;
          }
          if (this.merchant.operation_type[i] === "SMS") {
            this.flagSMS = true;
          }
          if (this.merchant.operation_type[i] === "QRCODE") {
            this.flagQRCODE = true;
          }
          if (this.merchant.operation_type[i] === "LINK") {
            this.flagLINK = true;
          }
          if (this.merchant.operation_type[i] === "WHATSAPP") {
            this.flagWHATSAPP = true;
          }
        }

        for (let i = 0; i < this.merchant.area_code_type.length; i++) {
          for (let j = 0; j < counties.length; j++) {
            if (this.merchant.area_code_type[i] === counties[j].CODE) {
              this.countryPrefix.push(counties[j]);
            }
          }
        }
        this.listCurrency = this.merchant.currency;

        this.donationActive = this.merchant.isDonationActive;
        this.isDeferredActive = this.merchant.isDeferredActive;
        this.isRecurrentActive = this.merchant.isRecurrentActive;

        this.filteredCustomer =
          this.pagamentoAvanzatoForm.controls.customerForm[
            "controls"
          ].name.valueChanges.pipe(
            startWith(""),
            map((customers: string) =>
              customers ? this.filterStates(customers) : this.customers.slice()
            )
          );

        this.pagamentoAvanzatoForm.controls.language.setValue("IT");

        if (this.merchant.defaultToDateElapsed) {
          const defaultDate = moment()
            .add(parseInt(this.merchant.defaultToDateElapsed), "days")
            .format("YYYY-MM-DD");
          this.pagamentoAvanzatoForm.controls.to_date.setValue(defaultDate);
        } else {
          this.pagamentoAvanzatoForm.controls.to_date.setValue(
            this.fifteenDays
          );
        }
        this.pagamentoAvanzatoForm.controls.currency.setValue("EUR");
        this.pagamentoAvanzatoForm.controls.operation_type.setValue("SMS");
        if (this.countryPrefix.length > 0) {
          this.pagamentoAvanzatoForm.controls.customerForm[
            "controls"
          ].area_code.setValue(this.countryPrefix[0].CODE);
        }
        this.loaderService.stopGeneral();
      },
      (error) => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }

  clickInizio() {
    this.flagImporto = true;
    this.flagNomeCognome = false;
    this.flagDescrizione = false;
    this.flagAllegato = false;
    this.flagSmsEmail = false;
    this.flagGenerateRecurrentPlan = false;
  }

  clickImporto() {
    this.flagImporto = false;
    this.flagNomeCognome = true;
    this.flagDescrizione = false;
    this.flagAllegato = false;
    this.flagSmsEmail = false;

    if (this.pagamentoAvanzatoForm.value.isPaymentDeferred || this.pagamentoAvanzatoForm.value.isPaymentRecurrent) {
      this.flagQRCODE = false;
      this.flagLINK = false;
      this.flagWHATSAPP = false;
      this.pagamentoAvanzatoForm.controls.recurrentForm['controls'].rounds.setValidators([Validators.required]);
      this.pagamentoAvanzatoForm.controls.recurrentForm['controls'].frequency.setValidators([Validators.required]);
      if (this.pagamentoAvanzatoForm.value.isPaymentRecurrent) {
        this.pagamentoAvanzatoForm.controls.recurrentForm['controls'].rounds.setValue(2);
      } else if (this.pagamentoAvanzatoForm.value.isPaymentDeferred) {
        this.pagamentoAvanzatoForm.controls.recurrentForm['controls'].rounds.setValue(3);
      }
      this.pagamentoAvanzatoForm.controls.recurrentForm['controls'].frequency.setValue(1);
    }
  }

  clickToogleImporto() {
    this.pagamentoAvanzatoForm.controls.amount.setValue(undefined);
    if (!this.pagamentoAvanzatoForm.controls.isAmountFree.value) {
      this.pagamentoAvanzatoForm.controls.amount.disable();
    } else {
      this.pagamentoAvanzatoForm.controls.amount.enable();
    }
  }

  clickNomeCognome() {
    this.flagImporto = false;
    this.flagNomeCognome = false;
    this.flagAllegato = false;
    this.flagSmsEmail = false;
    this.flagDescrizione = false;
    this.flagGenerateRecurrentPlan = false;

    if (this.pagamentoAvanzatoForm.value.isPaymentDeferred || this.pagamentoAvanzatoForm.value.isPaymentRecurrent) {
      this.flagGenerateRecurrentPlan = true;
      this.paymentType = this.pagamentoAvanzatoForm.value.isPaymentDeferred ? 'DEFERRED' : this.pagamentoAvanzatoForm.value.isPaymentRecurrent ? 'RECURRENT' : 'SINGLE';
    } else {
      this.flagDescrizione = true;
    }
  }

  clickGenerateRecurrentPlan() {
    this.flagImporto = false;
    this.flagNomeCognome = false;
    this.flagAllegato = false;
    this.flagSmsEmail = false;
    this.flagDescrizione = true;
    this.flagGenerateRecurrentPlan = false;
  }

  clickDescrizione() {
    this.flagImporto = false;
    this.flagNomeCognome = false;
    this.flagDescrizione = false;
    this.flagAllegato = true;
    this.flagSmsEmail = false;
    this.uploadResponseAllegato = null;
  }

  clickAllegato() {
    // this.allegatoAnimation.pause();
    this.flagImporto = false;
    this.flagNomeCognome = false;
    this.flagDescrizione = false;
    this.flagAllegato = false;
    this.flagSmsEmail = true;

    if (this.uploader.queue[0]) {
      this.uploader.queue[0].upload();
      this.uploadResponseAllegato = this.uploader.response["item"];
    }
  }

  buidDatiPagamentoAvanzatoFrom() {
    this.pagamentoAvanzatoForm = this.fb.group({
      amount: [
        null,
        [
          Validators.required,
          Validators.min(0.01),
          Validators.max(99999999999999),
        ],
      ],
      to_date: [null, [Validators.required]],
      language: [null, [Validators.required]],
      currency: [null, [Validators.required]],
      operation_type: [null],
      description: [
        null,
        [
          Validators.required,
          Validators.pattern("^[A-Za-z0-9/\\-?:().,'+\\s]+$"),
        ],
      ],

      customerForm: this.fb.group({
        name: ["", [Validators.required]],
        email: [
          "",
          [
            Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/),
            MyCustomEmailValidator("operation_type"),
          ],
        ],
        area_code: [
          "",
          [MyCustomSmartphoneValidator("operation_type", "isPaymentOpen")],
        ],
        smartphone: [
          "",
          [MyCustomSmartphoneValidator("operation_type", "isPaymentOpen")],
        ],
      }),
      toggleAuth: [false],
      isAmountFree: [false],
      isDonation: [false],
      isPaymentDeferred: [false],
      isPaymentRecurrent: [false],
      isPaymentOpen: [false],
      recurrentForm: this.fb.group({
        frequency: [1],
      rounds: this.paymentType === 'RECURRENT' ? [2, [Validators.required]] : [3, [Validators.required]]
      })
    });

    this.pagamentoAvanzatoForm.controls.customerForm["controls"].name.valueChanges.subscribe((change: any) => {
      this.searchStatus(change);
    });
  }

  onToggle(event: any, payment_type: string) {
    if (payment_type === 'SINGLE') {
      this.pagamentoAvanzatoForm.controls.isPaymentRecurrent.setValue(false);
      this.pagamentoAvanzatoForm.controls.isPaymentDeferred.setValue(false);
    } else {
      this.pagamentoAvanzatoForm.controls.isAmountFree.setValue(false);
      this.pagamentoAvanzatoForm.controls.isDonation.setValue(false);
      this.pagamentoAvanzatoForm.controls.isPaymentOpen.setValue(false);
      this.pagamentoAvanzatoForm.controls.toggleAuth.setValue(false);

      if (payment_type === 'RECURRENT') {
        this.frequencyList = [
          { value: 2, viewValue: 'Ogni 2 settimane', type: 'week', interval: 2 },
          { value: 1, viewValue: 'Mensile', type: 'month', interval: 1 },
          { value: 3, viewValue: 'Trimestrale', type: 'month', interval: 3 },
          { value: 6, viewValue: 'Semestrale', type: 'month', interval: 6 },
          { value: 12, viewValue: 'Annuale', type: 'month', interval: 12 }
        ];
        this.pagamentoAvanzatoForm.controls.isPaymentDeferred.setValue(false);
      } else {
        this.frequencyList = [
          { value: 2, viewValue: 'Ogni 2 settimane', type: 'week', interval: 2 },
          { value: 1, viewValue: 'Mensile', type: 'month', interval: 1 },
          { value: 3, viewValue: 'Trimestrale', type: 'month', interval: 3 },
          { value: 6, viewValue: 'Semestrale', type: 'month', interval: 6 },
        ];
        this.pagamentoAvanzatoForm.controls.isPaymentRecurrent.setValue(false);
      }
    }
  }

  ricercaNomeCliente(id: any) {
    this.loaderService.startGeneral();

    this.customerService.list(id.option.value).subscribe(
      (results) => {
        this.customerSelect = <Customer>results["items"][0];

        this.pagamentoAvanzatoForm.controls.customerForm[
          "controls"
        ].name.setValue(this.customerSelect.name);
        this.pagamentoAvanzatoForm.controls.customerForm[
          "controls"
        ].email.setValue(this.customerSelect.email);
        this.pagamentoAvanzatoForm.controls.customerForm[
          "controls"
        ].smartphone.setValue(this.customerSelect.smartphone);
        this.pagamentoAvanzatoForm.controls.customerForm[
          "controls"
        ].area_code.setValue(this.customerSelect.area_code);

        this.loaderService.stopGeneral();
      },
      (error) => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }

  clearRegionesociale() {
    this.pagamentoAvanzatoForm.controls.customerForm.reset();
  }

  onFileUploadClick() {
    this.inputfile.nativeElement.click();
  }

  clickToggleOpenPayment() {
    if (!this.pagamentoAvanzatoForm.controls.isPaymentOpen.value) {
      this.pagamentoAvanzatoForm.controls.customerForm[
        "controls"
      ].email.reset();
      this.pagamentoAvanzatoForm.controls.customerForm[
        "controls"
      ].smartphone.reset();
      this.pagamentoAvanzatoForm.controls.customerForm[
        "controls"
      ].area_code.reset();
      this.pagamentoAvanzatoForm.controls.operation_type.setValue("QRCODE");
      this.onlyQRCODELINK = true;
      this.payment.isPayInstrToken = false;
    } else {
      this.onlyQRCODELINK = false;
    }
  }

  createPaymentObject() {
    const dataPayment = this.pagamentoAvanzatoForm.value;

    for (const prop in dataPayment) {
      if (dataPayment.hasOwnProperty(prop)) {
        if (prop === 'recurrentForm') {
          continue;
        } else {
          this.payment[prop] = dataPayment[prop];
        }
      }
    }

    let importoForm: any;
    if (this.pagamentoAvanzatoForm.controls.amount.value !== "") {
      importoForm = numeral(
        this.pagamentoAvanzatoForm.controls.amount.value
      ).format("0.00");
    }
    this.payment.name =
      this.pagamentoAvanzatoForm.controls.customerForm["controls"].name.value;
    this.payment.email =
      this.pagamentoAvanzatoForm.controls.customerForm["controls"].email.value;
    this.payment.smartphone =
      this.pagamentoAvanzatoForm.controls.customerForm[
        "controls"
      ].smartphone.value;
    this.payment.area_code =
      this.pagamentoAvanzatoForm.controls.customerForm[
        "controls"
      ].area_code.value;

    this.payment.template = this.template;
    this.payment.transaction_type = this.pagamentoAvanzatoForm.controls
      .toggleAuth.value
      ? "AUTH"
      : "PURCHASE";

    this.payment.payment_type = this.pagamentoAvanzatoForm.value.isPaymentRecurrent
      ? 'RECURRENT' : this.pagamentoAvanzatoForm.value.isPaymentDeferred ? 'DEFERRED' : 'SINGLE';

    if (this.pagamentoAvanzatoForm.controls.isAmountFree.value == false) {
      this.payment.amount = importoForm;
    }

    this.payment.merchant = this.merchant._id;
    this.payment.terminal = this.merchant.terminal[0];

    this.payment.notify_url = "https://www.worldlineitalia.it";
    this.payment.error_url = "https://www.worldlineitalia.it";

    if (this.merchant.isPayInstrToken === true) {
      if (!this.pagamentoAvanzatoForm.controls.isPaymentOpen.value) {
        this.payment.isPayInstrToken = true;
      } else {
        this.payment.isPayInstrToken = false;
      }
    } else {
      this.payment.isPayInstrToken = false;
    }

    if (this.uploadResponseAllegato) {
      this.payment.attachment = this.uploadResponseAllegato;
    }

    if (/RECURRENT|DEFERRED/.test(this.payment.payment_type)) {

      if (/RECURRENT/.test(this.payment.payment_type)) {
        if (dataPayment.recurrentForm.rounds === 0) {
          this.payment.amount = numeral().format('0.00');
        }  else {
          this.payment.amount = numeral(dataPayment.amount * dataPayment.recurrentForm.rounds).format('0.00');
        }
      } else {
        this.payment.amount = numeral(dataPayment.amount).format('0.00');
      }

      if (dataPayment.recurrentForm.frequency) {
        const enumFrequency = /RECURRENT/.test(this.payment.payment_type) ? [
          { value: 2, viewValue: 'Ogni 2 settimane', type: 'week', interval: 2 },
          { value: 1, viewValue: 'Mensile', type: 'month', interval: 1 },
          { value: 3, viewValue: 'Trimestrale', type: 'month', interval: 3 },
          { value: 6, viewValue: 'Semestrale', type: 'month', interval: 6 },
          { value: 12, viewValue: 'Annuale', type: 'month', interval: 12 }
        ] : [
          { value: 2, viewValue: 'Ogni 2 settimane', type: 'week', interval: 2 },
          { value: 1, viewValue: 'Mensile', type: 'month', interval: 1 },
          { value: 3, viewValue: 'Trimestrale', type: 'month', interval: 3 },
          { value: 6, viewValue: 'Semestrale', type: 'month', interval: 6 },
        ];

        let frequency = enumFrequency.find(el => el.value === dataPayment.recurrentForm.frequency);
        this.payment.recurrents_frequency = frequency.type;
        this.payment.recurrents_frequency_interval = frequency.interval;
      }

      if (!isNaN(dataPayment.recurrentForm.rounds)) {
        this.payment.recurrents_rounds = dataPayment.recurrentForm.rounds;

        if (this.payment.payment_type !== 'DEFERRED') {
          if (dataPayment.recurrentForm.rounds !== 0) {
            this.payment.recurrents_type = 'DEFAULT';
          } else {
            this.payment.recurrents_type = 'REPEATED';
          }
        }
      }

      if (/RECURRENT/.test(this.payment.payment_type)) {
        this.payment.recurrents_status = 'OPEN';
        this.payment.recurrents_amount = numeral(dataPayment.amount).format('0.00')
      }

      if (/DEFERRED/.test(this.payment.payment_type)) {
        this.payment.recurrents_status = 'OPEN';
        this.payment.recurrents_amount = numeral(dataPayment.amount / dataPayment.recurrentForm.rounds).format('0.00')
      }
    }
  }

  salvaPagamentoEasy() {
    this.loaderService.startGeneral();
    this.createPaymentObject();

    this.paymentService.create(this.payment).subscribe(
      (responsePayment) => {
        this.paymentService.send(responsePayment["item"]["_id"]).subscribe(
          (responsePaymentSend) => {
            this.loaderService.stopGeneral();
            if (
              this.pagamentoAvanzatoForm.controls.operation_type.value ===
              "SMS" ||
              this.pagamentoAvanzatoForm.controls.operation_type.value ===
              "EMAIL"
            ) {
              this.dialogService.openDialogPRIMARY(
                "DIALOG.SUCCESS_TITLE",
                "DIALOG.GENERIC_SUCCESS_MESSAGE"
              );
              return this.router.navigate(["/merchant/home"]);
            }
            this.displayLinkOrQrCode(responsePaymentSend["item"]);
          },
          (error) => {
            console.log(error);
            this.loaderService.stopGeneral();
            this.dialogService.openDialogWARN(
              "DIALOG.GENERIC_ERROR_TITLE",
              "DIALOG.GENERIC_ERROR_MESSAGE",
              error.status
            );
          }
        );
      },
      (error) => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE",
          error.status
        );
      }
    );
  }

  caricaAllegato() {
    if (this.uploader.queue[0]) {
      this.uploader.queue[0].upload();
      this.uploadResponseAllegato = this.uploader.response["item"];
    }
  }

  apriQrcode(id: string, payment_id: string) {
    const isSmallScreen =
      this.breakpointObserver.isMatched("(max-width: 959px)");
    const dialogRef = this.dialog.open(QrCodeDialogComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: isSmallScreen ? 350 : 600,
      data: { item: id, payment_id: payment_id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/merchant/home"]);
    });
  }

  apriDialogLink(link: string) {
    const dialogRef = this.dialog.open(DialogLinkComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: 350,
      data: { displayedLink: link },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/merchant/home"]);
    });
  }

  displayLinkOrQrCode(payment: Payment) {
    const sendMode = this.pagamentoAvanzatoForm.controls.operation_type.value;
    if (sendMode === "QRCODE") {
      this.apriQrcode(payment.qrcode._id, payment._id);
    }
    if (sendMode === "LINK") {
      this.apriDialogLink(payment.payment_link);
    }
    if (sendMode === "WHATSAPP") {
      const whatsAppInfo = this.paymentService.generateWhatsAppUrl(
        payment,
        this.isSmallScreen
      );
      window.open(whatsAppInfo.link, whatsAppInfo.target);
      this.router.navigate(["/merchant/home"]);
    }
  }

  searchStatus($event) {
    if ($event) {
      this.showFormSearchPanel = $event.length >= 3;
    } else {
      this.showFormSearchPanel = false;
    }
  }

  fallbackTemplate() {
    // SE NON E' STATO AGGIUNTO
    this.getDefaultTemplate().subscribe((response_template) => {
      this.merchantService
        .update(this.merchant._id, { templates: [response_template._id] })
        .subscribe(
          (response) => {
            this.merchant = response;
            if (typeof this.merchant.templates[0] == "object") {
              this.template = this.merchant.templates[0]["_id"];
            } else {
              this.template = this.merchant.templates[0];
            }
          },
          (error) => {
            this.dialogService.openDialogWARN(
              "DIALOG.TEMPLATEERRORTITLE",
              "DIALOG.TEMPLATEERRORMESSAGE"
            );
            this.router.navigate(["/merchant/home"]);
          }
        );
    });
  }

  getDefaultTemplate() {
    return this.templateService.getDefaultTemplate().pipe(
      tap(null, (error) => {
        this.dialogService.openDialogWARN(
          "DIALOG.TEMPLATEERRORTITLE",
          "DIALOG.TEMPLATEERRORMESSAGE"
        );
        this.router.navigate(["/merchant/home"]);
      })
    );
  }

  recurrentLooped(count: number) {
    let recurrentForm = this.pagamentoAvanzatoForm.controls.recurrentForm;
    if (recurrentForm.value.frequency === 2) {
      return 'Dopo ' + (++count * 2) + ' Settimane'
    } else if (recurrentForm.value.frequency === 1) {
      return 'Dopo ' + (count + 1) + (count + 1 === 1 ? ' Mese' : ' Mesi')
    } else if (recurrentForm.value.frequency === 3) {
      return 'Dopo ' + (++count * 3) + ' Mesi'
    } else if (recurrentForm.value.frequency === 6) {
      return 'Dopo ' + (++count * 6) + ' Mesi'
    } else if (recurrentForm.value.frequency === 12) {
      return 'Dopo ' + (++count * 12) + ' Mesi'
    }
  }

  recurrentNoExpiration() {
    let recurrentForm = this.pagamentoAvanzatoForm.controls.recurrentForm;
    if (recurrentForm.value.frequency === 2) {
      return 'Ogni due settimane fino a sospensione piano ricorrenze'
    } else if (recurrentForm.value.frequency === 1) {
      return 'Ogni mese fino a sospensione piano riccorenze'
    } else if (recurrentForm.value.frequency === 3) {
      return 'Ogni 3 mesi fino a sospensione piano riccorenze'
    } else if (recurrentForm.value.frequency === 6) {
      return 'Ogni 6 mesi fino a sospensione piano riccorenze'
    } else if (recurrentForm.value.frequency === 12) {
      return 'Ogni 12 mesi fino a sospensione piano riccorenze'
    }
  }

  amountPerRound(amount: number, rounds: number): string {
    const roundedValuePerRound: number = Math.round((amount / rounds) * 100) / 100;
    const roundedValueTotal: number = roundedValuePerRound * rounds;
    const roundedAmount: number = amount - roundedValueTotal;
    const roundedValueFinal: number = roundedValuePerRound + Math.round(roundedAmount * 100) / 100;
    return roundedValueFinal.toFixed(2);
  }

  recurrentPreview() {
    let data: any = moment(this.pagamentoAvanzatoForm.value.to_date);
    let recurrentForm = this.pagamentoAvanzatoForm.controls.recurrentForm;
    const paymentRounds = [];
    const preTable = {
      'rata': 1,
      'data': 'Entro il ' + moment(data).format('DD-MM-YYYY'),
      'azione': this.paymentType === 'RECURRENT'
        ? 'Adesione piano ricorrenze'
        : 'Adesione piano dilazionato',
      'importo': this.paymentType === 'RECURRENT'
        ? numeral(this.pagamentoAvanzatoForm.value.amount).format('0.00')
        : this.amountPerRound(this.pagamentoAvanzatoForm.value.amount, recurrentForm.value.rounds),
      'noLimit': false
    };
    const postTable = {
      'rata': 2,
      'data': this.recurrentNoExpiration(),
      'azione': 'Addebito automatico',
      'importo': this.paymentType === 'RECURRENT'
        ? numeral(this.pagamentoAvanzatoForm.value.amount).format('0.00')
        : numeral(this.pagamentoAvanzatoForm.value.amount / recurrentForm.value.rounds).format('0.00'),
      'noLimit': true
    }


    paymentRounds.push(preTable);

    if (recurrentForm.value.rounds === 0) {
      paymentRounds.push(postTable)
    }
    let totalImportRecurrent = numeral(this.pagamentoAvanzatoForm.value.amount * recurrentForm.value.rounds).format('0.00');

    let mobileImporto = this.paymentType === 'RECURRENT'
      ? numeral(this.pagamentoAvanzatoForm.value.amount).format('0.00')
      : numeral(this.pagamentoAvanzatoForm.value.amount / recurrentForm.value.rounds).format('0.00')


    let step = "months"
    if (recurrentForm.value.frequency == 2) {
      step = "weeks";
    }

    for (let i = 0; i < recurrentForm.value.rounds; i++) {
      if (i === 0) continue;

      //AD OGNI GIRO AGGIUNGO IL NUMERO DI MESI INDICATO NELLA RICORRENZA
      data = moment(data).add(recurrentForm.value.frequency, step).format('YYYY-MM-DD');

      paymentRounds.push({
        'data': moment(data).format('DD-MM-YYYY'),
        'azione': 'Addebito automatico',
        'importo': (i + 1) === 1 ?
          this.amountPerRound(this.pagamentoAvanzatoForm.value.amount, recurrentForm.value.rounds)
          : mobileImporto,
        'nome': '',
        'stato': 'WAIT',
        'rata': i + 1,
        'recurrentTotal': this.paymentType === 'RECURRENT' ? totalImportRecurrent : '',
        'frequency': this.recurrentLooped(i - 1)
      });
    }

    console.log("paymentRounds", paymentRounds);
    this.dialog.open(PreviewRicComponent, {
      autoFocus: false,
      disableClose: true,
      data: { paymentRounds: paymentRounds }
    });
  }

  private getIcons() {
    this.iconRegistry.addSvgIcon(
      "arrow-right",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/right-arrow-icon.svg"
      )
    );

    this.iconRegistry.addSvgIcon(
      "arrow-left",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/left-arrow-icon.svg"
      )
    );
  }
}
