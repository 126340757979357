<!-- input to filter operations-->
<div fxLayout="column" fxLayoutAlign="space-around stretch">
  <mat-form-field style='margin-left: 20px; margin-right: 20px; margin-bottom: -25px'>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'CONTROLLA.FILTROCERCA' | translate}}"
      autocomplete="off">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<!--TABELLA PAGAMENTI IMPORTATI (BULK)-->
<div class="import-ops-container">
  <mat-table id="importedTable" mat-table [dataSource]="dataSource" matSort matSortActive="createdAt"
    matSortDisableClear matSortDirection="desc" aria-describedby="imported operations">
    <!--DATA CREAZIONE-->
    <ng-container matColumnDef="createdAt">
      <mat-header-cell scope="createdAt" mat-sort-header *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONTROLLA.TABELLADATA' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.createdAt | date:'dd/MM/yyyy' }} </mat-cell>
    </ng-container>
    <!--STATO PAGAMENTO-->
    <ng-container matColumnDef="state">
      <mat-header-cell scope="state" *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONTROLLA.TABELLASTATO' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">

        <div fxLayout="row" fxLayoutAlign="center">

          <div fxLayout="row" fxLayoutAlign="space-around center" class="statoControl" *ngIf="element.state=='ACTIVE'"
            matTooltip="{{'PANNELLOUTENTE.TOOLTIPIMPORTLAVORAZIONE' | translate}}  ">
            <img alt="active payment icon" style="height: 20px;" [src]="ASSETS_BASE + 'svg/iconsvg/attivo.svg'">
            <span> {{'CONTROLLA.ACTIVE_MASSIVO' | translate}} </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around center" class="statoControl" *ngIf="element.state=='WAIT'"
            matTooltip="{{'PANNELLOUTENTE.TOOLTIPIMPORTATTESA' | translate}}  ">
            <img alt="pending payment icon" style="height: 20px;" [src]="ASSETS_BASE + 'svg/iconsvg/in-attesa.svg'">
            <span> {{'CONTROLLA.WAIT' | translate}} </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around center" class="statoControl" *ngIf="element.state=='SUSPEND'">
            <img alt="expired payment icon" style="height: 20px;" [src]="ASSETS_BASE + 'svg/iconsvg/scaduto.svg'">
            <span> {{'CONTROLLA.SUSPEND' | translate}} </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around center" class="statoControl" *ngIf="element.state=='ERROR'">
            <img alt="error payment icon" style="height: 20px;" [src]="ASSETS_BASE + 'svg/iconsvg/scaduto.svg'">
            <span> {{'CONTROLLA.ERROR' | translate}} </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" class="statoControl" *ngIf="element.state=='COMPLETED'">
            <img alt="completed payment icon" style="height: 20px;" [src]="ASSETS_BASE + 'svg/iconsvg/completato.svg'">
            <span> {{'CONTROLLA.COMPLETED' | translate}} </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" class="statoControl" *ngIf="element.state=='ABORTED'">
            <img alt="completed payment icon" style="height: 20px;" [src]="ASSETS_BASE + 'svg/iconsvg/scaduto.svg'">
            <span> {{'CONTROLLA.ABORTED' | translate}} </span>
          </div>
        </div>
        </mat-cell>
        <mat-cell *matCellDef="let element">
          <img alt="transaction type icon" style="height: 30px;" [src]="ASSETS_BASE + 'svg/iconsvg/dettaglio-'+ element.transaction_type + '.svg'">
        </mat-cell>
      </ng-container>

      <!--populate-->
      <ng-container matColumnDef="popolate">
        <mat-header-cell *matHeaderCellDef>
          <div fxLayout="row" fxLayoutAlign="center center">
            <span>{{ 'CONTROLLA.TABELLAPOPULATE' | translate }}</span>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button>{{element.number_populated}}</button>
        </mat-cell>
      </ng-container>

      <!--INVIATE-->
      <ng-container matColumnDef="inviate">
        <mat-header-cell *matHeaderCellDef>
          <div fxLayout="row" fxLayoutAlign="center center">
            <span>{{ 'CONTROLLA.TABELLASENT' | translate }}</span>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button>{{element.number_sent}}</button>
        </mat-cell>
      </ng-container>

    <!--DESCRIZIONE-->
    <ng-container matColumnDef="description">
      <mat-header-cell scope="description" *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONTROLLA.TABELLADESCRIZIONE' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
    </ng-container>

    <!--INVIATO COME-->
    <ng-container matColumnDef="notification_type">
      <mat-header-cell scope="notification type" *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONTROLLA.TABELLAMODALITAINVIO' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <img alt="operation type icon" style="height: 30px;"
          [src]="ASSETS_BASE + 'svg/iconsvg/dettaglio-'+ element.operation_type + '.svg'">
      </mat-cell>
    </ng-container>

    <!--TIPO PAGAMENTO-->
    <ng-container matColumnDef="auth_type">
      <mat-header-cell scope="auth type" *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONTROLLA.TABELLAAUTORIZZAZIONE' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <img alt="transaction type icon" style="height: 30px;"
          [src]="ASSETS_BASE + 'svg/iconsvg/dettaglio-'+ element.transaction_type + '.svg'">
      </mat-cell>
    </ng-container>

    <!--CARICAMENTI-->
    <ng-container matColumnDef="caricamenti">
      <mat-header-cell scope="loadings" *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONTROLLA.TABELLACARICAMENTI' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div
          *ngIf='element.state == "WAIT" || element.state == "ACTIVE" || element.state == "ERROR" || element.state == "SUSPEND"'>
          <button mat-flat-button color="primary" class="bottoneG">-</button>
          <button mat-flat-button color="warn" class="bottoneR"
            (click)='openDialogCaricamenti(element);$event.stopPropagation()'>-</button>
        </div>
        <div
          *ngIf='element.state != "WAIT" && element.state != "ACTIVE" && element.state != "ERROR" && element.state != "SUSPEND"'>
          <button mat-flat-button color="primary" class="bottoneG">{{element.payments.length}}</button>
          <button mat-flat-button color="warn" class="bottoneR"
            (click)='openDialogCaricamenti(element);$event.stopPropagation()'>{{element.refuses.length}}</button>
        </div>

      </mat-cell>
    </ng-container>

    <!--AZIONI-->
    <ng-container matColumnDef='actions'>
      <mat-header-cell scope="actions" *matHeaderCellDef>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONTROLLA.TABELLAAZIONI' | translate }}</span>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <!--SOSPENDI-->
        <button mat-icon-button [disabled]=' element.state !== "WAIT" && element.state !== "ACTIVE" '
          (click)="sospendiImport(element);">
          <mat-icon svgIcon="suspend" matTooltip="{{'PANNELLOUTENTE.SOSPENDIIMPORT' | translate}}">
          </mat-icon>
        </button>
          <!--INVIA-->
          <div *ngIf='sendEnabled'>
            <button mat-icon-button [disabled]=' (element.number_populated > 0 || element.number_sent > 0) || element.state !== "WAIT" && element.state !== "ACTIVE" ' (click)="onSend(element);">
              <mat-icon svgIcon="inviaSubito" matTooltip="{{'PANNELLOUTENTE.INVIASUBITOIMPORT' | translate}}"></mat-icon>
            </button>
          </div>
          <!--ELIMINA-->
          <button *ngIf="authService.user?.type === 'SUPPLIER'" mat-icon-button [disabled]=' element.state !== "ABORTED" ' (click)="eliminaImport(element);">
            <mat-icon svgIcon="elimina" matTooltip="{{'PANNELLOUTENTE.ELIMINAIMPORT' | translate}}"></mat-icon>
          </button>
          <!--APRI DETTAGLIO-->
          <button mat-icon-button [disabled]='element?.state !== "COMPLETED" || !element?.payments || (element?.payments && element?.payments.length === 0)' (click)="navigateImportOperationPayments(element);">
            <mat-icon svgIcon="bulk-arrow" matTooltip="{{'PANNELLOUTENTE.DETAILS' | translate}}" style="height: 20px;"></mat-icon>
          </button>

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns; let element" (click)='navigateImportOperationPayments(element)'></mat-row>

    </mat-table>
  </div>


<mat-paginator #paginator pageSize="5" [length]="results" [showFirstLastButtons]="true" class="matPaginator importPaginator">
</mat-paginator>
