<div style="width: 100%; display: flex; flex-direction: row; margin-top: 20px; justify-content: space-between;">

  <div *ngIf="isAdmin && maxAmount" class="pendingAmount">{{ maxAmount.replace('.', ',') + "€" }}</div>

  <div class="flex-row">

    <div>
      <div class="insertImportControlla">
        <input currencyMask [options]="{ prefix: '', decimal: ',', thousands: '.', align: 'left'}" type="tel"
          class="inputImporto" matInput placeholder="{{ 'OPERAZIONI.INSERISCIIMPORTO' | translate }}"
          [formControl]="importoInput">
        <span class="inputImporto">€</span>
      </div>
    </div>

    <div class="flex-col" [ngClass]="{'userCol': !isAdmin}">
      <button *ngIf="type === 'AUTH' " class="overlayButton " (click)="showDialog('CONFERMA')">{{
        'OPERAZIONI.CONFERMA_BUTTON' | translate }}</button>
      <button *ngIf="type === 'PURCHASE' || type === 'CONFIRM' " class="overlayButton " (click)="showDialog('STORNA')">{{
        'OPERAZIONI.STORNA_BUTTON' | translate }}</button>
      <button *ngIf="type === 'AUTH' " class="overlayButton " (click)="showDialog('STORNAPRE')">{{
        'OPERAZIONI.STORNA_BUTTON' | translate }}</button>
    </div>
  </div>

</div>