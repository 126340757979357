import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../_components/dialog/dialog.component';
import { AlertType } from '../_enum/alert-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  openDialogWARN(title: string, message: string, code?) {
    if (code == 401) {
      title = "DIALOG.LOGIN_ERROR_TITLE";
      message = "DIALOG.LOGIN_ERROR_MESSAGE";
    } 
    if (code!= undefined) code = 'Cod. ' + code.toString();
    this.dialog.open(DialogComponent, {
        autoFocus: false,
        disableClose: true,
        maxWidth: 350,
        data: { type: AlertType.WARN, title: title, message: message, code: code }
    });
  }

  openDialogPRIMARY(title: string, message: string) {
    return this.dialog.open(DialogComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: 350,
      data: { type: AlertType.PRIMARY, title: title, message: message }
    });
  }

  openDialogNewVersion(title: string, message: string) {
    return this.dialog.open(DialogComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: 350,
      data: { type: AlertType.PRIMARY, title: title, message: message, confirm: true }
    });
  }

  openDialogStopRecurrent(title: string, message: string, paymentType: string) {
    return this.dialog.open(DialogComponent, {
      autoFocus: false,
      disableClose: true,
      minWidth: 520,
      minHeight: 140,
      data: { type: AlertType.DELETE, title: title, message: message, paymentType }
    });
  }

  openDialogDELETE(title: string, message: string) {
    return this.dialog.open(DialogComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: 350,
      data: { type: AlertType.DELETE, title: title, message: message }
    });
  }

}
