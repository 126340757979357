import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DatepickerModalComponent } from '../datepicker-modal/datepicker-modal.component';
import { PaymentService } from '../../_services/payment.service';
import { Payment } from '../../_models/payment';
import { DialogService } from '../../_services/dialog.service';
import { OutcomeResponse } from '../../_models/outcome_response';
import {Operation} from '../../_models/operation';
import * as moment from 'moment';


@Component({
  selector: 'app-operations-detail-first',
  templateUrl: './operations-detail-first.component.html',
  styleUrls: ['./operations-detail-first.component.scss']
})
export class OperationsDetailFirstComponent implements OnInit {

  @Input() element: any;
  @Input() transactionList?: Array<any>;
  @Input() isFullScreen?: boolean;

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  transaction: OutcomeResponse;
  shieldColor: string;

  constructor(
    public dialog: MatDialog,
    public dialogService: DialogService,
    public paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.getTransactionData();
    this.shieldColor = this.calcShieldColor();
  }

  changeExpirationDate() {
    const dialogRef = this.dialog.open(DatepickerModalComponent, {
      maxWidth: 350,
      data: {expiration: this.element.to_date, isMobile: false}
    });

    dialogRef.afterClosed()
    .subscribe(result => {
      if (result) {
        if(!result.to_date.isUTC()){ //Se viene cambiato il giorno sul DatePicker, ritorna una data invalida. 
          let momentInvalidDate = moment(result.to_date).format('DD-MM-YYYY'); //Riformatto la data.
          result.to_date = moment(String(momentInvalidDate) +' '+String(result.expiration_time), 'DD-MM-YYYY HH:mm').utc(); // I valori to_date ed expiration vengono inviati separatamente.
        }
        const payment: Payment =  new Payment();
        let expiration = <moment.Moment>result.to_date;
        let time = result.expiration_time.split(':');
        expiration.hours(time[0]);
        expiration.minutes(time[1]);

        payment.to_date = expiration.toISOString();

        this.paymentService.update(this.element._id, payment)
        .subscribe(
          obj => {
            this.element.to_date = obj['item']['to_date'];
            this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
          },
          error => {
            console.log('Errore: ', error);
            this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
          }
        );
      }
    });
  }

  disableLink() {
    if (this.element.state !== 'ACTIVE') { return; }
    const dialogRef = this.dialogService.openDialogDELETE('', 'DIALOG.DISABLE_LINK');
    dialogRef.afterClosed()
    .subscribe(
      data => {
        if (!data) { return; }
        const payment: Payment = new Payment();
        payment.state = 'EXPIRED';

        this.paymentService.update(this.element._id, payment)
        .subscribe(
          obj => {
            this.element.state = obj['item']['state'];
            this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
          },
          error => {
            console.log('Error: ', error);
            this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
          }
        );
      }
    );
  }

  calcShieldColor() {
    if (!this.transaction || !this.transaction.enrStatus) {
      return 'grigio';
    }
    if (this.transaction.enrStatus === 'N') {
      return 'rosso';
    }
    if (this.transaction.enrStatus === 'Y') {
      if (this.transaction.authStatus === 'Y') { return 'verde'; }
      if (this.transaction.authStatus === 'N') { return 'rosso'; }
      if (this.transaction.authStatus === 'A') { return 'giallo'; }
      if (this.transaction.authStatus === 'E') { return 'rosa'; }
    }
    if (this.transaction.enrStatus === 'E' || this.transaction.enrStatus === 'U') {
      return 'giallo';
    }
  }

  getTransactionData() {
    if (!this.element) { return; }
    if (!this.element.outcomes || this.element.outcomes.length === 0) { return; }
    if (this.element.isPaymentOpen  && this.transactionList) {
      if (this.transactionList.length < 1) { return; }
      this.transaction = this.transactionList[this.transactionList.length - 1]['outcome_response'];
      this.transaction.notes = this.element.outcomes[this.transactionList.length - 1]['outcome_notes'];
      return;
    }

    let okTrx = this.element.outcomes.find((el: any) => el.outcome_response.error === false);
    const n = this.element.outcomes.length - 1;
    this.transaction = okTrx ? okTrx['outcome_response'] : this.element.outcomes[n]['outcome_response'];
    this.transaction.notes = okTrx ? okTrx['outcome_notes'] : this.element.outcomes[n]['outcome_notes'];
  }

}
