<!--COLONNA PRIMO COMPONENTE-->
<div class="mainCol">

  <!--PRIMO QUADRO-->
  <!--RIGA 1-->
  <div class="mobileColumn">
    <!--TIPO TRANSAZIONE-->
    <div class="mobileBlock">
      <div style="margin-top: 20px;">
        <strong>{{'CONTROLLA.' + element?.transaction_type | translate}} </strong>
      </div>
      <div>
        <img alt="transaction icon" style="height: 60px;"
          [src]="ASSETS_BASE + 'svg/iconsvg/dettaglio-' + element?.transaction_type + '.svg'">
      </div>
    </div>
    <!--DATI CARTA-->
    <div class="mobileBlock">
      <div>
          <img alt="card icon" *ngIf="transaction?.brand || transaction?.payInstr || paymentService?.lastOutcomePurchase?.brand" style="width: 50px; height: auto; margin-bottom: 10px;"
          [src]="transaction?.payInstr === 'APAY' || transaction?.payInstr === 'PPAL' || transaction?.payInstr === 'MYBK' ? ASSETS_BASE + 'svg/circuiti/'+ transaction?.payInstr + '.png' : ASSETS_BASE + 'svg/circuiti/'+ (transaction?.brand || paymentService?.lastOutcomePurchase?.brand) + '.png'">

          <img alt="unknown card icon" *ngIf="!transaction?.brand && !paymentService?.lastOutcomePurchase?.brand && !paymentService?.lastOutcomePurchase && !transaction?.payInstr" style="width: 50px; height: auto;"
          [src]="ASSETS_BASE + 'svg/circuiti/credit-card-empty-icon.png'">

      </div>
      <div *ngIf='transaction?.brand !== "MYBANK"'>
        <strong>{{ 'OPERAZIONI.NUMEROCARTA' | translate }}:</strong>&nbsp;
        <span>{{transaction?.maskedPan || paymentService?.lastOutcomePurchase?.maskedPan}}</span>

      </div>
      <div *ngIf='transaction?.brand === "MYBANK"'>
        <strong>{{ 'OPERAZIONI.IBAN' | translate }}:</strong>&nbsp;
        <span>{{transaction?.maskedPan}}</span>
      </div>
      <div *ngIf='transaction?.brand !== "MYBANK"'>
        <strong>{{'OPERAZIONI.DATASCADENZA' | translate }}:</strong>&nbsp;
        <span *ngIf="transaction?.maskedPan || paymentService?.lastOutcomePurchase?.maskedPan">
          <div *ngIf="element?.expireMonth; then useElement else useTransaction"></div>
          <ng-template #useElement>{{element?.expireMonth}}/{{element?.expireYear}}</ng-template>
          <ng-template #useTransaction>{{transaction?.expireMonth}}/{{transaction?.expireYear}}</ng-template>
        </span>

      </div>
    </div>

  </div>

  <!--RIGA 2-->
  <div class="mobileColumn" style="padding:20px" *ngIf="element.payment_type === 'SINGLE'">
    <!--SCUDO-->
    <div class="mobileBlock">
      <div>
        <strong>{{ 'CONTROLLA.ESITO3DSECURE' | translate }}</strong>
      </div>
      <div class="imgDiv">
        <img alt="shield icon" *ngIf="shieldColor" style="height: 60px;"
          [src]="ASSETS_BASE + 'svg/iconsvg/scudo-'+ shieldColor +'.svg'">
      </div>
    </div>
    <!--ESITO TRANSAZIONE-->
    <div class="mobileBlock">
      <div>
        <strong>{{ 'OPERAZIONI.ESITO' | translate }}</strong>
      </div>
      <div class="esitoTransazione">
        <img alt="unknown outcome icon" *ngIf="!transaction?.rc" class="transactionStateIcon"
          [src]="ASSETS_BASE + 'svg/iconsvg/riepilogo-unknown.svg'">
        <img alt="transaction ok icon" *ngIf="transaction?.rc==='IGFS_000'" class="transactionStateIcon"
          [src]="ASSETS_BASE + 'svg/iconsvg/riepilogo-ok.svg'">
        <img alt="transaction ko icon" *ngIf="transaction?.rc && transaction?.rc!=='IGFS_000'"
          class="transactionStateIcon" [src]="ASSETS_BASE + 'svg/iconsvg/riepilogo-ko.svg'">
        <span *ngIf="transaction?.rc" class="capitalize">{{'CONTROLLASTATOTRANSA.'+transaction?.rc |
          translate}}</span>
      </div>
    </div>
  </div>
  <!--FINE PRIMO QUADRO-->

  <!--SECONDO QUADRO: SCADENZA, LINK, TOKEN, ECC-->
  <div class="infoBox">
    <div style="margin-bottom: 10px; width: 100%; text-align: left; font-weight: 400; white-space: nowrap;">{{ 'INFORMAZIONI RICHIESTA DI PAGAMENTO' | translate }}</div>
    <div class="paymentInfo"><strong>{{'OPERAZIONI.CAUSALE' | translate }}: </strong>{{element?.description}}</div>

    <div *ngIf="element.payment_type === 'SINGLE'">
      <div class="paymentInfo"><strong>Shop ID: </strong>{{ transaction?.shopID ? transaction.shopID : element?.shop_id }}</div>
      <div class="paymentInfo"><strong>Auth Code: </strong>{{transaction?.authCode}}</div>
      <div class="paymentInfo"><strong>Token: </strong>{{element ? element?.payInstrToken : ''}}</div>
    </div>
    <div *ngIf="element.payment_type !== 'SINGLE'">
      <div class="paymentInfo"><strong>Shop ID: </strong>{{paymentService?.lastOutcomePurchaseWithAuthCode?.shopID}}</div>
      <div class="paymentInfo"><strong>Auth Code: </strong>{{paymentService?.lastOutcomePurchaseWithAuthCode?.authCode}}</div>
      <div class="paymentInfo"><strong>Token: </strong>{{element ? element?.payRecurrentToken : ''}}</div>
    </div>
    <div class="causale_box">
      <strong>Link: </strong> {{element?.payment_link}}
      <button mat-icon-button *ngIf="!isFullScreen" [disabled]="element?.state !== 'ACTIVE'" class="actionButton disableLink">
        <mat-icon svgIcon="disable" matTooltip="{{'PANNELLOUTENTE.TOOLTIPDISATTIVA' | translate}}"
          (click)="disableLink()"></mat-icon>
      </button>
    </div>

    <div class="causale_box">
      <strong>{{'OPERAZIONI.SCADENZA' | translate }}: </strong> {{(element?.to_date) | momentUtcFormat:
      'DD-MM-YYYY, HH:mm' }}
      <button mat-icon-button *ngIf="element?.state === 'ACTIVE' && !isFullScreen" class="actionButton"
        style="bottom: 2px;">
        <mat-icon class="icon-modifica" matTooltip="{{'PANNELLOUTENTE.TOOLTIPMODIFICA' | translate}}"
          (click)="changeExpirationDate()"></mat-icon>
      </button>
    </div>
    <div *ngIf="element?.operation_type === 'SMS'" class="causale_box"><strong>{{'OPERAZIONI.RICHIESTAINVIATA' |
        translate }}: </strong>{{ element?.smartphone }}</div>
    <div *ngIf="element?.operation_type === 'EMAIL'" class="causale_box"><strong>{{'OPERAZIONI.RICHIESTAINVIATA' |
        translate }}: </strong>{{ element?.email }}</div>

  </div>
  <!--FINE SECONDO QUADRO-->


  <!--TERZO QUADRO: INFO GENERICHE E NOTE PAGATORE-->


  <div *ngIf="!transaction; else elseBlock" class="infoBox extraMargin">
    <div class="paymentInfo">
      <strong>{{ 'OPERAZIONI.INFO1' | translate }}: </strong>
      {{ element?.informations[0] ? element?.informations[0] : "n.d"}}
    </div>
    <div class="paymentInfo">
      <strong>{{ 'OPERAZIONI.INFO2' | translate }}: </strong>
      {{ element?.informations[1] ? element?.informations[1] : "n.d"}}
    </div>
    <div class="paymentInfo">
      <strong>{{ 'OPERAZIONI.INFO3' | translate }}: </strong>
      {{ element?.informations[2] ? element?.informations[2] : "n.d"}}
    </div>
    <div class="paymentInfo">
      <strong>{{ 'OPERAZIONI.INFO4' | translate }}: </strong>
      {{ element?.informations[3] ? element?.informations[3] : "n.d"}}
    </div>
    <div class="paymentInfo">
      <strong>{{ 'OPERAZIONI.INFO5' | translate }}: </strong>
      {{ element?.informations[4] ? element?.informations[4] : "n.d"}}
    </div>
    <div *ngIf="element?.notes" class="paymentInfo">
      <strong>{{ 'OPERAZIONI.PAYERNOTES' | translate }}: </strong>
      {{element?.notes}}
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="infoBox extraMargin">
      <div class="paymentInfo">
        <strong>{{ 'OPERAZIONI.INFO1' | translate }}: </strong>
        {{ transaction?.addInfo1 ? transaction?.addInfo1 : "n.d"}}
      </div>
      <div class="paymentInfo">
        <strong>{{ 'OPERAZIONI.INFO2' | translate }}: </strong>
        {{ transaction?.addInfo2 ? transaction?.addInfo2 : "n.d"}}
      </div>
      <div class="paymentInfo">
        <strong>{{ 'OPERAZIONI.INFO3' | translate }}: </strong>
        {{ transaction?.addInfo3 ? transaction?.addInfo3 : "n.d"}}
      </div>
      <div class="paymentInfo">
        <strong>{{ 'OPERAZIONI.INFO4' | translate }}: </strong>
        {{ transaction?.addInfo4 ? transaction?.addInfo4 : "n.d"}}
      </div>
      <div class="paymentInfo">
        <strong>{{ 'OPERAZIONI.INFO5' | translate }}: </strong>
        {{ transaction?.addInfo5 ? transaction?.addInfo5 : "n.d"}}
      </div>
      <div *ngIf="element?.notes" class="paymentInfo">
        <strong>{{ 'OPERAZIONI.PAYERNOTES' | translate }}: </strong>
        {{element?.notes}}
      </div>
    </div>
  </ng-template>


</div>
