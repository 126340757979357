import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../_services/theme.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-reject-dialog',
  templateUrl: './template-reject-dialog.component.html',
  styleUrls: ['./template-reject-dialog.component.scss']
})
export class TemplateRejectDialogComponent implements OnInit {

  theme: string;
  reason: string;

  constructor(
    private themeService: ThemeService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
  }

}
