import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { MerchantService } from '../_services/merchant.service';
import { Observable } from 'rxjs';

@Injectable()
export class ImportGuard implements CanActivate {
  constructor(
    private merchantService: MerchantService,
    private authService: AuthService, 
    private router: Router
    )
  { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.merchantService.currentMerchant 
      && this.merchantService.currentMerchant.isImportMassivelyActive
      &&  this.authService.user && this.authService.user.isBulkActive) {
      return true;
    } 
    const path = next.url[0] && next.url[0].path;
    if (path === 'request') {
      return this.router.createUrlTree(['/merchant/request/new']);
    } else {
      return this.router.createUrlTree(['/merchant/requests/single']);
    }
  }

}
