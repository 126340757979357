<mat-table multiTemplateDataRows [dataSource]="transactionsOpen" matSort>
  <!-- ICON -->
  <ng-container matColumnDef="iconOpen">
    <mat-header-cell *matHeaderCellDef style="text-align: center;">
    </mat-header-cell>
    <mat-cell *matCellDef="let operation" style="text-align: center;">
      <mat-icon class="iconOpenPayment" svgIcon="openPayment"></mat-icon>
    </mat-cell>
  </ng-container>

  <!-- NUMERO CARTA -->
  <ng-container matColumnDef="pan">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont" style="text-align: center;">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span>{{'CONTROLLA.TABELLANUMEROCARTA' | translate}}</span>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let operation" style="text-align: center;"> {{operation.pan}} </mat-cell>
  </ng-container>

  <!-- DATA -->
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="textTableFont" style="text-align: center;">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span> {{'CONTROLLA.TABELLADATA' | translate}} </span>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let operation" style="text-align: center;">
      {{!operation.date && isSmallScreen ? "N/D" : (operation.date | date:'dd/MM/yyyy') }}
    </mat-cell>
  </ng-container>

  <!-- IMPORTO -->
  <ng-container matColumnDef="amountOpen">
    <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span>{{'CONTROLLA.TABELLIMPORTO' | translate}}</span>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let operation" style="text-align: center;"> {{operation.amount?.replace('.', ',')}}
    </mat-cell>
  </ng-container>

  <!-- DETTAGLIO EXPANDED -->
  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let operation" [attr.colspan]="openColumns.length">
      <div *ngIf="operation === expandedElement" style="width: 100%"
        [@detailExpand]="operation === expandedElement  ? 'expanded' : 'collapsed'">
        <div fxLayout="row" fxLayoutAlign="center" style="background-color: #f9f9f9;">
          <div fxFlex="33%">
            <app-operations-detail-first [element]='_payment' [transactionList]="operation.transactions">
            </app-operations-detail-first>
          </div>
          <div fxFlex="33%">
            <app-operations-detail-second [element]='_payment' [transactionList]="operation.transactions">
            </app-operations-detail-second>
          </div>
          <div fxFlex="33%">
            <app-operations-detail-third *ngIf="isFullScreen" [element]='_payment'
              [transactionList]="operation.transactions">
            </app-operations-detail-third>
          </div>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="openColumns"></mat-header-row>
  <mat-row *matRowDef="let operation; columns: openColumns;" class="operation-element-row"
    [class.operation-expanded-row]="expandedElement === operation" (click)="isSmallScreen
           ? openSlider(_payment, operation.transactions)
           : isFullScreen
             ? expandedElement === operation? expandedElement = null : expandedElement = operation
             : null">
  </mat-row>
  <!--defining row case "expanded"-->
  <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="operation-detail-row"></mat-row>

</mat-table>

<div *ngIf="!isSmallScreen && !isFullScreen" fxLayout="row" fxLayoutAlign="end center"
  style="background-color:#e6e6e6; width: 100%; margin-top: 10px;">
  <button class="Normal" style="margin: 10px 25px; height: 40px; padding: 5px 15px 0; width: unset;"
    (click)="exportTransactions()"
    [disabled]="_payment?.outcomes.length < 1 || _payment?.outcomes[_payment?.outcomes.length - 1].outcome_type === 'MYBANK'">
    {{'OPERAZIONI.EXPORTAPERTO' | translate}}
  </button>
  <button mat-flat-button color="primary" class="rounded-button" style="margin: 10px 25px; height: 40px;"
    (click)="openDetail(_payment)"
    [disabled]="_payment?.outcomes.length < 1 || _payment?.outcomes[_payment?.outcomes.length - 1].outcome_type === 'MYBANK'">
    {{'CONTROLLA.EXPAND' | translate}}
  </button>
</div>
