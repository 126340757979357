// eslint-disable-next-line no-restricted-imports
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../_models/user';
import { Merchants } from '../_models/merchants';
import { AuthService } from './auth.service';
import { map, catchError } from 'rxjs/operators';
import { MerchantService } from './merchant.service';

@Injectable()
export class UserService {
  private readonly USER_URL: string = environment.BASE_URL + 'user';
  private readonly USERHOLDING_URL: string = environment.BASE_URL + 'holding';

  private _currentUser: User;
  public modUser: boolean;

  get currentUser() { // NON E' L'UTENTE AUTENTICATO MA L'UTENTE IN ESAME NELLA VIEW CORRENTE (es: modifica utente, crea utente, modifica profilo, ecc)
    return this._currentUser;
  }

  set currentUser(user: User) { //METODO PER SETTARE ALL'INTERNO DEL SERVICE L'UTENTE IN ESAME NELLA VIEW CORRENTE
    this._currentUser = user;
  }

  constructor(private http: HttpClient, private authService: AuthService, private merchantService: MerchantService) { }

  /**
   * Metodo per recuperare la lista dei User
   * @param user (string) - nome del User per effetture la ricerca
   */
  list(merchant?: string, type?: string): Observable <User[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let params = new HttpParams();
    if (merchant) { params = params.set('merchant', merchant); }
    if (type) { params = params.set('type', type); }

    return this.http.get<any>(this.USER_URL, { headers, params }).pipe(
      map(response => response.items)
    )
  }

  searchId(id: string): Observable <{} | User[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');



    let params = new HttpParams();
    if (id != null && id !== undefined && id !== '') {
      params = params.set('_id', id);
    }

    return this.http.get<{} | User[]>(this.USER_URL, { headers, params });
  }

  getUser(id: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let params = new HttpParams();
    if (this.authService.user.type === 'HOLDING' && this.merchantService.currentMerchant) {
      params = params.set('merchant', this.merchantService.currentMerchant._id);
    }

    return this.http.get<any>(this.USER_URL + '/' + id, { headers, params });
  }

  listOrder(merchant: string, type: string, value: string): Observable <{} | User[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');



    let params = new HttpParams();
    if (merchant != null && merchant !== undefined && merchant !== '') {
      params = params.set('merchant', merchant);
    }
    if (type != null && type !== undefined && type !== '') {
      params = params.set('type', type);
    }
    params = params.set('sort', value );
    return this.http.get<{} | User[]>(this.USER_URL, { headers, params });
  }

  /**
   * Metodo per creare un nuovo User
   * @param user Model User del User
   */
  create(user: User): Observable <{} | User> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');


    return this.http.post(this.USER_URL, user, { headers });
  }

  /**
   * Metodo per aggiornare le informazioni di un User (User)
   * @param _id identificativo univoco dell'oggetto User (User)
   * @param user oggetto User da aggiornare
   */
  update(_id: string, user: User): Observable <{} | User[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');


    return this.http.put(this.USER_URL + '/' + _id, user, { headers });
  }

  
  pushHolding(_id: string, merchants: Merchants): Observable <{} | User[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');


    return this.http.put(this.USERHOLDING_URL + '/' + _id + '/push', merchants, { headers });
  }
  pullHolding(_id: string, merchants: Merchants): Observable <{} | User[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');


    return this.http.put(this.USERHOLDING_URL + '/' + _id + '/pull', merchants, { headers });
  }

}
