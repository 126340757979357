import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Merchant } from '../../_models/merchant';
import { AvailableEmailValidator } from '../../_validators/available-email.directive';
import { counties } from '../../_models/country';
import { environment } from '../../../environments/environment';
import { MerchantService } from '../../_services/merchant.service';
import { AuthService } from '../../_services/auth.service';


@Component({
  selector: 'app-merchant-data',
  templateUrl: './merchant-data.component.html',
  styleUrls: ['./merchant-data.component.scss']
})
export class MerchantDataComponent implements OnInit {
  //@Input() merchant?: Merchant;
  @Input() isSelfEnrollment?: boolean;
  @Input() disabledFields?: string[] = [];
  @Output() outputFormValues: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();


  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  merchantForm: UntypedFormGroup;
  valutaList = [
    { value: 'AED', viewValue: 'AED' },
    { value: 'AUD', viewValue: 'AUD' },
    { value: 'BRL', viewValue: 'BRL' },
    { value: 'EUR', viewValue: 'EUR' },
    { value: 'GBP', viewValue: 'GBP' },
    { value: 'HKD', viewValue: 'HKD' },
    { value: 'JPY', viewValue: 'JPY' },
    { value: 'KWD', viewValue: 'KWD' },
    { value: 'MXN', viewValue: 'MXN' },
    { value: 'MYR', viewValue: 'MYR' },
    { value: 'NOK', viewValue: 'NOK' },
    { value: 'RUB', viewValue: 'RUB' },
    { value: 'SAR', viewValue: 'SAR' },
    { value: 'SEK', viewValue: 'SEK' },
    { value: 'SGD', viewValue: 'SGD' },
    { value: 'THB', viewValue: 'THB' },
    { value: 'TWD', viewValue: 'TWD' },
    { value: 'USD', viewValue: 'USD' },
  ];
  countryPrefix = counties;
  isAdmin: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private availableValidator: AvailableEmailValidator,
    private merchantService: MerchantService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isAdmin = this.authService.user.role == "ADMIN";
    this.merchantService.currentMerchant$.subscribe(
      value => {
        this.buildForm(value);
        if(!value) {return; }
        if (this.isSelfEnrollment) {
          this.onChanges();
          this.notifyChanges();
        }
      }
    )

  }



  buildForm(merchantData?: Merchant) {
    this.merchantForm = this.fb.group({
      name: [ { value: merchantData ? merchantData.name : null, disabled: this.disabledFields.includes('name')} , [Validators.required] ],
      description: [ merchantData ? merchantData.description : null],
      code: [ {value: merchantData ? merchantData.code : null, disabled: this.disabledFields.includes('code')}, [Validators.required] ],
      insegna: [ merchantData ? merchantData.insegna : null],
      piva: [ merchantData ? merchantData.piva : null],
      email: [ merchantData ? merchantData.email : null, [Validators.required, Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)], /* [this.availableValidator.validate.bind(this.availableValidator)] */],
      fromAddress: [ merchantData ? merchantData.fromAddress : null, [Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]],
      address: [ merchantData ? merchantData.address : null ],
      district: [ merchantData ? merchantData.district : null],
      province: [ merchantData ? merchantData.province : null ],
      region: [ merchantData ? merchantData.region : null ],
      currency:  [merchantData ? merchantData.currency : [] ],
      state: [ merchantData ? merchantData.state : null ],
      phone: [ merchantData ? merchantData.phone : null ],
      area_code: [ merchantData && merchantData.area_code ? merchantData.area_code : '+39' ],
      smartphone: [ merchantData ? merchantData.smartphone : null ]
    });
  }

  notifyChanges() {
    this.outputFormValues.emit(this.merchantForm);
  }
  onChanges(): void {
    this.merchantForm.statusChanges.subscribe(() => this.notifyChanges());
  }

}
