import { Component, OnInit, Inject } from '@angular/core';
import { AlertType } from '../../_enum/alert-type.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../_services/theme.service';
import { AuthService } from '../../_services/auth.service';


export interface ImportedPaymentRequests {
  data: Date;
  importo: string;
  nome: string;
  valuta: string;
  email: string;
  telefono: string;
  lingua: string;
}

@Component({
  selector: 'app-dialog-caricamenti',
  templateUrl: './dialog-caricamenti.component.html',
  styleUrls: ['./dialog-caricamenti.component.scss']
})
export class DialogCaricamentiComponent implements OnInit {
  theme: string;
  color = AlertType.PRIMARY;

  displayedColumns: string[] = ['data', 'importo', 'nome', 'valuta', 'email', 'telefono', 'lingua'];
  displayedColumnsXs: string[] = ['data', 'nome', 'importo'];
  dataSource = new MatTableDataSource();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
    private themeService: ThemeService,
    private authService: AuthService,
    ) {
      this.dataSource = data.refuses;
  }

  ngOnInit() {
    if (this.authService.user) { // && (this.authService.user.type === 'SUPPLIER' || this.authService.user.type === 'HOLDING' )) {
      this.displayedColumns.unshift('info');
      this.displayedColumnsXs.unshift('info');
    }

    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
  }

}
