import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AuthService } from '../../../../_services/auth.service';
import { CustomerService } from '../../../../_services/customer.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Merchant } from '../../../../_models/merchant';
import { Customer } from '../../../../_models/customer';
import { LoaderService } from '../../../../_services/loader.service';
import { DialogService } from '../../../../_services/dialog.service';
import { merge, of, Subscription } from 'rxjs';
import { startWith, switchMap, map, catchError, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { FileUploader, FileItem, FileLikeObject, ParsedResponseHeaders } from 'ng2-file-upload';
import { UntypedFormControl, Validators } from '@angular/forms';
import { saveFile } from '../../../../_services/file-download-helper';
import { MerchantService } from '../../../../_services/merchant.service';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, OnDestroy {
  @ViewChild('inputfile') inputfile: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  displayedColumns = ['name', 'description', 'email', 'createdAt', 'province', 'piva', 'tid', 'actions'];
  dataSource: MatTableDataSource<Customer> = new MatTableDataSource();
  private watcher: Subscription;  

  private uploader: FileUploader;
  private readonly UPLOAD_URL = environment.BASE_URL + 'customer/csv/';
  private importFile: UntypedFormControl;
  spinnerdisplay = 'none';

  constructor(
    private merchantService: MerchantService,
    public authService: AuthService,
    private customerService: CustomerService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private router: Router,
    private mediaObserver: MediaObserver,
  ) {
    this.importFile = new UntypedFormControl('', [Validators.required]);
   }

  ngOnInit() {
    this.observeLayoutChanges();
    this.setUploader();
  }

  ngOnDestroy() {
    if (!this.watcher) {return; }
    this.watcher.unsubscribe();
  }

  ngAfterViewInit() {
    this.onLoadItems();
    if (!this.sort) {return; }
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  }

  onLoadItems() {
    const id = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    if (!this.sort) {return; }
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loaderService.startGeneral();
          const sort = this.sort.direction === 'desc' ? '-' + this.sort.active : this.sort.active;
          return  this.customerService.get(id, this.paginator.pageSize, this.paginator.pageIndex, sort);
        }),
        map((data: any) => {
          this.paginator.length = data.results.items;
          return data.items;
        }),
        catchError((error) => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
          return of([]);
        })
    ).subscribe(data => {
      this.dataSource.data = data;
      this.loaderService.stopGeneral();
    });
  }


  toggleActive(row: Customer|Merchant) {
    this.loaderService.startGeneral();
    const data = {
      active: !row.active
    }
    this.customerService.update(row._id, data)
    .subscribe(
      response => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.onLoadItems();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }
  
  modificaCliente(row: Customer) {
    /* this.customerService.customer = row;
    this.customerService.modCustomer = true */
    this.router.navigate(['/merchant/clients/', row._id]);
  }

  creaNuovoCliente() {
    this.router.navigate(['/merchant/clients/new']);
  }

  observeLayoutChanges() {
    this.watcher = this.mediaObserver.asObservable().pipe(filter((change: MediaChange[]) => {
      switch(change[0].mqAlias) {
        case 'xs': this.displayedColumns = ['name', 'piva']
        break;
        case 'sm': this.displayedColumns = ['name','email', 'piva']
        break;
        case 'md': this.displayedColumns = ['name', 'email', 'createdAt', 'piva', 'actions']
        break;
        case 'lg': this.displayedColumns = ['name', 'description', 'email', 'createdAt', 'province', 'piva', 'tid', 'actions']
        break;
        case 'xl': this.displayedColumns = ['name', 'description', 'email', 'createdAt', 'province', 'piva', 'tid', 'actions']
        break;
      }
      return true;
    })).subscribe();
  }

  setUploader() {
    const id = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : '';
    this.uploader = new FileUploader({
      url: this.UPLOAD_URL + id,
      headers: [{ name: 'Authorization', value: this.authService.token }],
      method: 'POST',
      queueLimit: 1,
      removeAfterUpload: true,
      //allowedMimeType: ['text/csv'],
      allowedMimeType: ['application/vnd.ms-excel', 'text/csv'],
    });

    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any): any => {
      this.spinnerdisplay = 'none';
      this.dialogService.openDialogWARN('DIALOG.FORMATCSV_ERROR_TITLE', 'DIALOG.FORMATCSV_ERROR_MESSAGE');
      this.importFile.reset();
    };

    this.uploader.onAfterAddingFile = (fileItem: FileItem): any => {
      this.importFile.setValue(fileItem._file.name);
    };
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.spinnerdisplay = 'none';
      this.importFile.reset();
      this.dialogService.openDialogWARN('DIALOG.FORMATCSV_ERROR_TITLE', 'DIALOG.FORMATCSV_ERROR_MESSAGE', status);
    }
    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.spinnerdisplay = 'none';
      const resJ = JSON.parse(response);
      if (resJ.status === 'error' && resJ.code === -314) {
        this.dialogService.openDialogWARN('DIALOG.FORMATCSV_ERROR_TITLE', 'DIALOG.FORMATCSV_ERROR_MESSAGE', resJ.code);
      } else if (resJ.status === 'error') {
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', resJ.code);
      } else {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.onLoadItems();
      }
      this.importFile.reset();
    }
  }

  onFileUploadClick() {
    this.uploader.cancelAll();
    this.uploader.clearQueue();
    this.inputfile.nativeElement.click();
  }

  importCustomer() {
    this.uploader.queue[0].upload();
    this.spinnerdisplay = 'block';
  }

  clearFile() {
    this.uploader.cancelAll();
    this.uploader.clearQueue();
    this.importFile.reset();
  }

  downloadTemplate(){
    this.customerService.download().subscribe( res =>{
      saveFile(res, "customer_import_template.csv");
    })
  }

}
