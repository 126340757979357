import { RecurrentService } from '../../../../_services/recurrent.service';
import { Recurrent } from '../../../../_models/recurrent';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MerchantService } from '../../../../_services/merchant.service';
import { AuthService } from '../../../../_services/auth.service';
import { PaymentService } from '../../../../_services/payment.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import * as moment from 'moment';
import { Merchant } from '../../../../_models/merchant';
import { DialogService } from '../../../../_services/dialog.service';
import { Payment } from '../../../../_models/payment';
import { LoaderService } from '../../../../_services/loader.service';
import { Reminder } from '../../../../_models/reminder';
import { ReminderService } from '../../../../_services/reminder.service';
import { QrCodeDialogComponent } from '../../../../_components/qrCodeDialog/qrCodeDialog.component';
import { DialogLinkComponent } from '../../../../_components/dialog-link/dialog-link.component';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { customerDataValidator } from '../../../../_validators/customer-data-validator';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { tap, concatMap } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { DynamicFieldsComponent } from '../../../../_components/dynamic-fields/dynamic-fields.component';
import { RequestExtraFieldsComponent } from '../../../../_components/request-extra-fields/request-extra-fields.component';

@Component({
  selector: 'app-payment-request',
  templateUrl: './payment-request.component.html',
  styleUrls: ['./payment-request.component.scss']
})
export class PaymentRequestComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('requestDetailPanel', { static: true }) requestDetailPanel: MatExpansionPanel;
  @ViewChild('recurrentPanel') recurrentPanel: MatExpansionPanel;
  @ViewChild('deferredPanel') deferredPanel: MatExpansionPanel;
  @ViewChild('infoPanel') infoPanel: MatExpansionPanel;
  @ViewChild('dynamicPanel') dynamicPanel: MatExpansionPanel;
  @ViewChild('schedulePanel') schedulePanel: MatExpansionPanel;
  @ViewChild('dynamicFields') dynamicFields: DynamicFieldsComponent;

  @ViewChild(RequestExtraFieldsComponent, { static: true }) requestExtraFieldsComponent: RequestExtraFieldsComponent

  // FORM
  payForm: UntypedFormGroup;
  // MERCHANT
  merchant: Merchant;
  // PAGAMENTO CREATO O IN CORSO DI MODIFICA
  payment: Payment;

  // SCHEDULATORE NOTIFICHE
  reminders: Reminder[] = [];
  pickerChanged: boolean;

  // BOOLEANO CHE CONTROLLA L'ABILITAZIONE DEGLI EXPANSIONPANEL
  isLocked = true;

  //SUBSCRIPTION AI CAMBIAMENTI DIMENSIONI SCHERMO
  watcher: Subscription;

  //EVENTO SALVATAGGIO PAGAMENTO PER TRIGGERARE IL SALVATAGGIO DELLE IMPOSTAZIONI NEL CHILD WIZARD COMPONENT
  saveEvent = new Subject();

  constructor(
    private merchantService: MerchantService,
    private authService: AuthService,
    private paymentService: PaymentService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private reminderService: ReminderService,
    private recurrentService: RecurrentService,
    private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.watcher = this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        const isSmallScreen = result.matches;
        // REDIRECT SU PAGAMENTO EASY SE DEVICE MOBILE O USER HA EASY NELLE IMPOSTAZIONI
        if (isSmallScreen || (this.authService.user.mode_easy && !this.activatedRoute.snapshot.params.id)) {
          this.router.navigate(['/merchant/request/easy']);
        }
      });
    //COSTRUZIONE FORM
    this.buildPayForm();

    if (!this.merchantService.currentMerchant) { return; }
    //INIZIALIZZO IL MERCHANT
    this.merchant = this.merchantService.currentMerchant;

    //CONTROLLO SE LA ROTTA CONTIENE L'ID DEL PAGAMENTO DA MODIFICARE E NEL CASO GET DEL PAGAMENTO IN QUESTIONE
    this.checkParams();
  }

  ngAfterViewInit() {
    this.dynamicPanel && this.subscribeToDynamicInfoPanelToggle();
    this.infoPanel && this.subscribeToAdditionalInfoPanelToggle();
  }

  ngOnDestroy() {
    if (!this.watcher) { return; }
    this.watcher.unsubscribe();
  }

  getChildrenData(event: any) {
    this.payForm.patchValue(event);
  }


  buildPayForm() {
    this.payForm = this.fb.group({
      amount: [null, [Validators.required, Validators.min(0.01), Validators.max(99999999999999)]],
      notes: [null],
      language: ['IT', [Validators.required]],
      currency: ['EUR', [Validators.required]],
      payment_type: ['', [Validators.required]],
      operation_type: ['', [Validators.required]],
      description: [null, [Validators.required, Validators.pattern("^[A-Za-z0-9/\\-?:().,'+\\s]+$")]],
      transaction_type: ['PURCHASE', [Validators.required]],
      recurrentForm: this.fb.group({}),
      isAmountFree: [false],
      isDonation: [false, Validators.required],
      isPaymentOpen: [false],
      isFastCheckout: [false],
      isNotificationRequired: [false, Validators.required],
      isQueryString: [false, []],
      template: [null, Validators.required],
      dynamic_informations: this.fb.array([]),
      informations: this.fb.array([]),
      name: [''],
      email: ['', [Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]],
      area_code: [''],
      smartphone: [''],
      piva: [''],
      recurrents_merchant_email_error: [null, [Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]]
    }, {
      validators: [customerDataValidator]
    });
    this.subscribeToAmountFreeFlag();

    this.subscribeToPaymentTypeChanges();
  }

  checkParams() {
    this.activatedRoute.params.subscribe(param => {
      if (param.id || param.paymentId) {
        this.getPayment(param)
      } else {
        if (!this.merchant.isDynamicInformationsActive) {
          for (let i = 0; i < this.merchant.addInfoCount; i++) {
            this.addField(null);
          }
        }
      }
    })
  }

  getPayment(params: Params) {
    let myId = params.paymentId ? params.paymentId : params.id;
    this.loaderService.startGeneral();
    this.paymentService.searchID(myId).subscribe(
      response => {
        this.getModifiedPaymentData(response);
        this.loaderService.stopGeneral();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    )
  }

  getModifiedPaymentData(myPayment: Payment) {
    this.payment = myPayment;
    this.reminders = this.payment.reminders;
    this.payForm.patchValue(this.payment);

    /* let momentDate = moment(this.payment.to_date);
    this.payForm.controls.to_date.setValue(momentDate); */


    const index = this.merchant.templates.findIndex(
      el => el._id === this.payment.template._id
    );
    if (index !== -1) {
      this.payForm.controls.template.setValue(this.payment.template._id);
    } else {
      this.payForm.controls.template.setValue(null);
    }

    for (let i = 0; i < this.payment.informations.length; i++) {
      this.addField(this.payment.informations[i]);
    }
    if ((this.payment.informations.length < this.merchant.addInfoCount) && !this.merchant.isDynamicInformationsActive) {
      for (let i = this.payment.informations.length; i < this.merchant.addInfoCount; i++) {
        this.addField(null)
      }
    }

    if (this.merchant.isDynamicInformationsActive) {
      this.dynamicFields.dynamicValues = this.payment.dynamic_informations;
    }
  }

  createPayment(send: boolean) {
    this.loaderService.startGeneral();

    this.saveEvent.next(Math.random());
    const payment = new Payment(this.payForm, this.requestExtraFieldsComponent.extraDataForm.value, this.merchant, this.requestExtraFieldsComponent.uploadResponseAllegato);
    this.paymentService.create(payment).subscribe(
      response => {
        this.payment = response.item;
        this.createReminders();

        if (send) {
          return this.sendPayment();
        }
        this.loaderService.stopGeneral();
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      },
      error => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  updatePayment(send: boolean) {
    this.saveEvent.next(Math.random());
    const paymentData = new Payment();
    paymentData.generateUpdateObj(
      this.payForm, this.requestExtraFieldsComponent.extraDataForm.value, this.requestExtraFieldsComponent.fileCtrl.value, this.requestExtraFieldsComponent.uploadResponseAllegato, this.merchant);
    this.managePickerState(paymentData)
      .subscribe(
        (res) => {
          this.paymentService.update(this.payment._id, paymentData).subscribe(
            (response) => {
              this.payment = response.item;
              if (send) { return this.sendPayment(); }
              this.loaderService.stopGeneral();
              this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
            }
          );
        }
      );
  }

  sendPayment() {
    this.loaderService.startGeneral();
    this.paymentService.send(this.payment._id).subscribe(
      (responsePayment) => {
        this.loaderService.stopGeneral();
        this.displayResponse(responsePayment.item);
      },
      (error) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }

    );
  }

  displayResponse(payment: Payment) {
    if (payment.operation_type === 'QRCODE') {
      return this.apriQrcode(payment.qrcode._id, payment._id);
    }
    if (payment.operation_type === 'LINK') {
      return this.apriDialogLink(payment.payment_link);
    }
    if (payment.operation_type === 'WHATSAPP') {
      const whatsAppInfo = this.paymentService.generateWhatsAppUrl(payment, false);
      window.open(whatsAppInfo.link, whatsAppInfo.target);
      return this.router.navigate(['/merchant/home']);
    }
    this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
    this.router.navigate(['/merchant/home']);
  }

  apriQrcode(id: string, payment_id: string) {
    const dialogRef = this.dialog.open(QrCodeDialogComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: 600,
      data: { item: id, payment_id: payment_id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/merchant/home']);
    });
  }

  apriDialogLink(link: string) {
    const dialogRef = this.dialog.open(DialogLinkComponent, {
      autoFocus: false,
      disableClose: true,
      maxWidth: 350,
      data: { displayedLink: link }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/merchant/home']);
    });
  }

  subscribeToAmountFreeFlag() {
    this.payForm.controls.isAmountFree.valueChanges.subscribe(value => {
      if (!value || value && this.formValue.isQueryString) {
        this.payForm.controls.amount.enable();
      } else {
        this.payForm.controls.amount.setValue(null);
        this.payForm.controls.amount.disable();
      }
    });
    this.payForm.controls.isQueryString.valueChanges.subscribe(value => {
      if (value) {
        this.payForm.controls.amount.enable();
      } else if (!value && this.formValue.isAmountFree) {
        this.payForm.controls.amount.setValue(null);
        this.payForm.controls.amount.disable();
      }
    });
  }

  subscribeToPaymentTypeChanges() {
    this.payForm.controls.payment_type.valueChanges.subscribe(
      value => {
        if (value === 'SINGLE') {
          this.payForm.removeControl('recurrentForm')
        }
      }
    )
  }

  enablePanelExpansion(event: boolean) {
    this.isLocked = event;
  }

  // INFORMAZIONI AGGIUNTIVE
  addField(value: string | null): void {
    const array = this.payForm.get('informations') as UntypedFormArray;
    array.push(new UntypedFormControl(value, [Validators.required]));
  }

  removeField(i: number): void {
    const array = this.payForm.get('informations') as UntypedFormArray;
    array.removeAt(i);
  }

  // SCHEDULATORE NOTIFICHE
  updateSchedulerData(reminders: any) {
    this.reminders = reminders;
  }

  createReminders() {
    if (this.reminders.length === 0) { return; }
    this.reminderService.create(this.reminders).subscribe((data: any) => {
      const list = data.items.map((reminder) => reminder._id);
      this.reminderService.push(this.payment._id, list).subscribe(() => {
        this.reminders = data.items;
      });
    });
  }

  updatePickerStatus(changed: boolean) {
    this.pickerChanged = changed;
  }

  managePickerState(payment: Payment) {
    // Managing picker state
    if (!this.pickerChanged) {
      //payment.reminders = this.reminders.length > 0 ? [...this.reminders.map(r => r._id)] : [];
      return of(null);
    }
    return this.reminderService.deleteAll(this.payment._id).pipe(
      concatMap(_ => this.updateReminders(payment)),
    );
  }

  updateReminders(payment: Payment) {
    return this.reminderService.create(this.reminders).pipe(
      tap(
        (data: any) => {
          this.reminders = data.items;
          const list = data.items.map((reminder) => reminder._id);
          // Managing reminders
          payment.reminders = this.reminders.length > 0 ? list : [];
        },
        (error) => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      )
    );
  }

  getRecurrentsData(event: UntypedFormGroup) {
    this.payForm.setControl('recurrentForm', event);
    if (this.payForm.value.payment_type !== 'SINGLE') {
      this.payForm.controls.amount.setValue(this.formValue.recurrentForm.totalAmount);
      this.payForm.controls.description.setValue(this.formValue.recurrentForm.causale);
      this.requestExtraFieldsComponent.extraDataForm.controls.to_date.setValue(this.formValue.recurrentForm.to_date);
      this.requestExtraFieldsComponent.extraDataForm.controls.expiration_time.setValue(this.formValue.recurrentForm.expiration_time);
    }
  }

  // GETTERS
  get formValue() {
    return this.payForm.value;
  }


  //CONTROLLA SE QUANDO NEL FORMARRAY C'È SOLO UN CONTROL QUESTO È STATO VALORIZZATO IN ALMENO UNO DEI SUOI CAMPI
  checkDynamics() {
    const ctrls = this.dynamicFields.dynamicfields.controls[0].value;
    let isValorized = false;
    for (let key in ctrls) {
      if (ctrls[key] && key !== 'name') {
        isValorized = true;
        break;
      }
    }
    return isValorized;
  }

  subscribeToDynamicInfoPanelToggle() {
    this.payForm.setControl('dynamic_informations', this.dynamicFields.dynamicfields);
    this.dynamicPanel.opened.subscribe(
      () => {
        if (this.dynamicFields.dynamicfields.length === 0) {
          this.dynamicFields.addItem();
        }
      }
    );
    this.dynamicPanel.closed.subscribe(
      () => {
        if (this.dynamicFields.dynamicfields.length !== 1) { return; }
        if (!this.checkDynamics()) {
          this.dynamicFields.removeItem(0);
        }
      }
    )
  }

  subscribeToAdditionalInfoPanelToggle() {
    this.infoPanel.opened.subscribe(
      () => {
        if (this.formValue.informations.length === 0) {
          this.addField(null);
        }
      }
    );
    this.infoPanel.closed.subscribe(
      () => {
        if (this.formValue.informations.length === 0) { return; }
        if (!this.formValue.informations[this.formValue.informations.length - 1]
          && this.formValue.informations.length > this.merchant.addInfoCount) {
          this.removeField(this.formValue.informations.length - 1);
        }
      }
    )
  }




}
