import { Component, OnInit, Inject } from "@angular/core";
import { Operation } from "../../_models/operation";
import { DETAIL_OVERLAY_DATA } from "../../_cdk/overlayData";
import { ThemeService } from "../../_services/theme.service";
import { DetailOverlayRef } from "../../_models/detailOverlayRef";
import { environment } from "../../../environments/environment";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-detail-overlay",
  templateUrl: "./detail-overlay.component.html",
  styleUrls: ["./detail-overlay.component.scss"],
})
export class DetailOverlayComponent implements OnInit {
  ref;
  element: Operation;
  theme: string;
  dataSource: Operation[];
  displayedColumns: string[] = [
    "icon",
    "createdAt",
    "state",
    "amount",
    "name",
    "operation_type",
    "brand",
    "payment_id",
  ];
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  constructor(
    @Inject(DETAIL_OVERLAY_DATA) public data: Operation,
    public detailOverlayRef: DetailOverlayRef,
    private themeService: ThemeService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.element = data;
    this.ref = detailOverlayRef;
    this.iconRegistry.addSvgIcon(
      "close",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/iconsvg/closeIcon.svg"
      )
    );
  }

  ngOnInit() {
    this.themeService.theme.subscribe((theme) => {
      this.theme = theme;
    });
    this.dataSource = [this.element];
  }

  closeDetail() {
    this.ref.close();
  }
}
