<div [formGroup]="customerForm">
  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field [ngClass]="{
    'required':formValue?.operation_type && !nameControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.name,
    'error':formValue?.operation_type && nameControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.name
    }" class="leftInputField">
        <input [readonly]="paymentState==='ACTIVE' ? true : false" matInput formControlName="name" [matAutocomplete]="auto"
          placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.NOME' | translate}}">
        <mat-icon matSuffix (click)="clearRegionesociale()">clear</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="ricercaNomeCliente($event)">
          <mat-option [ngStyle]="{'display':
                    nameControl?.value && nameControl?.value.length >=3 ? 'block' : 'none'}"
            *ngFor="let customer of filteredCustomers | async" [value]="customer._id">
            <span>{{ customer.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </span>
    <!-- <span class="flex-col">
            <mat-form-field class="rightInputField">
                <input matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.PIVACODFISC' | translate}}"
                    formControlName="piva">
            </mat-form-field>
        </span> -->
    <span class="flex-col" *ngIf="emailControl">
      <mat-form-field [ngClass]="{
      'required':!emailControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.email,
      'error':emailControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.email
      }" class="rightInputField">
        <input [readonly]="paymentState==='ACTIVE' ? true : false" matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.EMAIL' | translate}}" formControlName="email">
        <mat-error *ngIf="emailControl?.hasError('email')" [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
      </mat-form-field>
    </span>

    <span class="flex-col" *ngIf="phoneControl">
      <span class="rightInputField">
        <mat-form-field style="width: 20%;">
          <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code">
            <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
              <img alt="flag icon" class="flagCountry"
                src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
              <span>{{ type.CODE }}</span>
              <span>{{ type.DESC }}</span>
              <span> - {{ type.COUNTRY | uppercase }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field [ngClass]="{
        'required': !phoneControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.phone,
        'error': phoneControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.phone
        }" class="telephone-with-prefix">
          <input [readonly]="paymentState==='ACTIVE' ? true : false" formControlName="smartphone" type="tel" matInput
            placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.TELEFONO' | translate}}">
        </mat-form-field>
      </span>
    </span>
  </div>

  <!-- <div class="flex-row">
        <span class="flex-col" *ngIf="emailControl">
            <mat-form-field [ngClass]="{
      'required':!emailControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.email,
      'error':emailControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.email
      }" class="leftInputField">
                <input matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.EMAIL' | translate}}"
                    formControlName="email">
                <mat-error *ngIf="emailControl?.hasError('email')"
                    [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
            </mat-form-field>
        </span>

        <span class="flex-col" *ngIf="phoneControl">
            <span class="rightInputField">
                <mat-form-field style="width: 20%;">
                    <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}"
                        formControlName="area_code">
                        <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
                            <img alt="flag icon" class="flagCountry"
                                src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                            <span>{{ type.CODE }}</span>
                            <span>{{ type.DESC }}</span>
                            <span> - {{ type.COUNTRY | uppercase }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field [ngClass]="{
        'required': !phoneControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.phone,
        'error': phoneControl?.touched && payForm?.errors?.missingField && payForm?.errors?.missingField.phone
        }" class="telephone-with-prefix">
                    <input formControlName="smartphone" type="tel" matInput
                        placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.TELEFONO' | translate}}">
                </mat-form-field>
            </span>
        </span>
    </div> -->

  <div fxLayout="column" fxLayoutAlign="space-around stretch">
    <div fxLayout="row" fxLayoutAlign="end center">
      <!-- BOTTONE VERDE -->
      <button mat-flat-button color="primary"
        [disabled]="!customerForm.valid || selectedCustomer || (!emailControl && !phoneControl) "
        (click)="aggiungiRubrica()">{{ 'RICHIEDIPAGAMENTOAVANZATO.BOTTAGGIUNGIRUBRICA' | translate }}</button>
    </div>
  </div>
</div>
