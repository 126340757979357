import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { PaymentService } from '../../_services/payment.service';
import { saveFile } from '../../_services/file-download-helper';
import { ThemeService } from '../../_services/theme.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-qrCodeDialog',
  templateUrl: './qrCodeDialog.component.html',
  styleUrls: ['./qrCodeDialog.component.scss']
})
export class QrCodeDialogComponent implements OnInit {
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  URL = '';
  theme: string;
  @ViewChild('qrcode', { static: true }) qrcode: ElementRef;
  constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translateService: TranslateService,
        public paymentService: PaymentService,
        private themeService: ThemeService
            )
  {
    this.URL = environment.BASE_URL + 'payment/qrcode/' + data.item;
  }

  ngOnInit() {
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
  }

  downloadPDF(){
    this.paymentService.downloadQRCodePDF(this.data.payment_id).subscribe(response => {
      saveFile(response, 'qrcode.pdf');
    }, error => {
      console.error(error);
    });
  }

  downloadImg(){
    // saveFile(this.URL, 'qrcode.png');
    this.paymentService.downloadQRCodeImage(this.URL).subscribe(response => {
      saveFile(response, 'qrcode.png');
    }, error => {
      console.error(error);
    });
  }

}
