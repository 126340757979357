<!--ACCORDION DATI CLIENTE-->
<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltroPrincipale">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{'PROFILO.TITOLO2FILTROCLIENTE' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-merchant-data [disabledFields]="disabledFields" (outputFormValues)="saveChanges($event)"></app-merchant-data>

    </mat-expansion-panel>
  </mat-accordion>
</div>


<!--ACCORDION LOGO MERCHANT-->
<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro">

  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{'PROFILO.TITOLO1FILTRO' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-logo-form></app-logo-form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!--ACCORDION SOCIAL -->

<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{'PROFILO.TITOLO4FILTRO' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-social-form [merchant]="merchant" (outputFormValues)="saveChanges($event)"></app-social-form>



    </mat-expansion-panel>
  </mat-accordion>
</div>
<!--FINE ACCORDION SOCIAL-->

<!--ACCORDION TEMPLATE DISPONIBILI -->
<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro" *ngIf="merchant?.isCustomTemplateActive">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>

        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{ 'PROFILO.AVAILABLETEMPLATE' | translate}}
        </mat-panel-title>

      </mat-expansion-panel-header>

      <app-mail-template #availableTemplates></app-mail-template>

    </mat-expansion-panel>
  </mat-accordion>
</div>

<!--ACCORDION EDITOR TEMPLATE CUSTOM -->

<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro" *ngIf="merchant?.isCustomTemplateActive">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{'PROFILO.TITOLO5FILTRO' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-template-form [merchant]="merchant" (updateTemplates)="onTemplatesUpdate($event)">
      </app-template-form>

    </mat-expansion-panel>
  </mat-accordion>
</div>
<!--FINE ACCORDION EDITOR TEMPLATE CUSTOM-->

<!--ACCORDION DATI REFERENTE-->
<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{ 'PROFILO.TITOLOBARRA3' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-referent-data (outputFormValues)="saveReferent($event)"></app-referent-data>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<!--FINE ACCORDION DATI REFERENTE -->


<!--BOTTONI TORNA AI CLIENTI/DATI TECNICI-->
<div *ngIf="user.type==='SUPPLIER' && merchantService.isAdminMode" fxLayout="row" fxLayoutAlign="end end">
  <button color="primary" mat-flat-button (click)="tornaClienti()" style="margin-right: 10px;">
    <div fxLayout="row" fxLayoutAlign="space-evenly  center">
      <span>{{'CLIENTI.TORNAACLIENTI' | translate }}</span>
    </div>
  </button>

  <button [disabled]="!merchant?._id" color="primary" mat-flat-button (click)="pagedatiTecnici()"
    style="margin-right: 10px;">
    <div xLayout="row" fxLayoutAlign="space-evenly center">
      <i class=" material-icons" aria-hidden="true">add</i>
      <span>{{'CLIENTI.DATITECNICI' | translate }}</span>
    </div>
  </button>
  <button *ngIf="merchant?.isCustomTemplateActive" [disabled]="!merchant?._id" color="primary" mat-flat-button
    (click)="pagePendingTemplates()" style="margin-right: 10px;">
    <div xLayout="row" fxLayoutAlign="space-evenly center">
      <span>{{'CLIENTI.TEMPLATE' | translate }}</span>
    </div>
  </button>
</div>
