import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Merchant } from '../../_models/merchant';
import { FileUploader, FileLikeObject, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { AuthService } from '../../_services/auth.service';
import { DialogService } from '../../_services/dialog.service';
import { environment } from '../../../environments/environment';
import { MerchantService } from '../../_services/merchant.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-logo-form',
  templateUrl: './logo-form.component.html',
  styleUrls: ['./logo-form.component.scss']
})
export class LogoFormComponent implements OnInit, OnDestroy {
  @Input() isSelfEnrollment?: boolean;

  @ViewChild('inputfile', { static: true }) inputfile: ElementRef;
  spinnerdisplay = 'none';
  logoFile: UntypedFormControl;
  idLogo = null;
  imageURL: any;
  uploader: FileUploader;
  flagFormato: boolean;
  merchant: Merchant;
  readonly ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  private readonly UPLOAD_URL: string = environment.BASE_URL + 'merchant/';
  private merchantSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private merchantService: MerchantService,
    private dialogService: DialogService,
  ) {
    this.uploader = new FileUploader({});
    this.logoFile = new UntypedFormControl('');
   }

  ngOnInit() {
    this.merchantSubscription = this.merchantService.currentMerchant$.subscribe(
      value => this.setMerchant(value)
    )
  }

  ngOnDestroy() {
    this.merchantSubscription.unsubscribe();
  }

  setMerchant(value: Merchant) {
    if (!value) {return; }
    this.merchant = value;
    this.uploaderConfig();
    if (this.merchant.logo) {
      this.idLogo = this.merchant.logo._id;
      this.imageURL = this.UPLOAD_URL + 'logo/' + this.idLogo;
    }
  }

  uploaderConfig() {
    let fileUploaderOptions = {
      url: this.UPLOAD_URL + this.merchant._id + '/upload',
      headers: [{ name: 'Authorization', value: this.authService.token }],
      method: 'PUT',
      queueLimit: 1,
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png']
    };

    this.uploader.setOptions(fileUploaderOptions);

    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any): any => {
      this.dialogService.openDialogWARN('DIALOG.FORMATCSV_ERROR_TITLE', 'DIALOG.FORMATIMAGE_ERROR_MESSAGE');
      this.logoFile = new UntypedFormControl('');
      this.flagFormato = false;
    };

    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      if (status === 200) {
        this.spinnerdisplay = "none"
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        const uploadResponse = JSON.parse(response);
        this.merchantService.getMerchant(uploadResponse.item._id).subscribe();

      } else {
        this.spinnerdisplay = "none"
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE', status.toString());
      }
    };

    this.uploader.onAfterAddingFile = (fileItem: FileItem): any => {
      this.logoFile = new UntypedFormControl(fileItem._file.name);
      this.flagFormato = true;
    };
  }

  onFileUploadClick() {
    this.inputfile.nativeElement.click();

  }

  invia() {
    this.uploader.queue[0].upload();
    this.spinnerdisplay = 'inline-block';
  }

}
