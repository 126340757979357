import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AbstractControl, ValidationErrors} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../_services/auth.service';
import { MerchantService } from '../_services/merchant.service';



@Injectable({ providedIn: 'root' })
export class AvailableEmailValidator {
  private readonly URL: string = environment.BASE_URL;
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private merchantService: MerchantService,
  ) {}

  validateEmail(currentUserEmail: string) {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (control.value === currentUserEmail) {return of(null); }
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Authorization', this.authService.token);
        headers = headers.set('Accept-Language', 'it');
        let params = new HttpParams();
        if (this.authService.user.type != 'SUPPLIER') {
          params = params.set('merchant', this.merchantService.currentMerchant._id);
        }
        params = params.set('populate', 'true');
        params = params.set('email', control.value);
        return this.http.get<any>(`${this.URL}user`, { headers, params })
      .pipe(
        map(
          response => {
            if (!response) {return null; }
            if (response.results.items != 0 || response.items && response.items[0]) {
              return {'email_not_available': true};
            }
            return null;
          },
          catchError(_ => of(null))
        ),
      );
    };
  }
}
