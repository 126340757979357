export class Referent {
    _id: string = null;
    first_name: string = null;
    last_name: string = null;
    smartphone: number = null;
    email: string = null;
    phone: string = null;
    fax: string = null;
    area_code: string = null;

    constructor(referentObj?){
        this._id = referentObj?._id;
        this.first_name = referentObj?.first_name;
        this.last_name = referentObj?.last_name;
        this.smartphone = referentObj?.smartphone;
        this.email = referentObj?.email;
        this.phone = referentObj?.phone;
        this.fax = referentObj?.fax;
        this.area_code = referentObj?.area_code;
    }
}
