export class Terminal {

    _id: string;
    tid: string;
    ksig: string;
    tid_moto: string;
    ksig_moto: string;
    description: string;
    brand: Array<string>;
    is3dsecure: boolean;
    default: boolean;
    is3d2secure: boolean;
    mid: string;
}
