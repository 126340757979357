<form [formGroup]="referentForm">
  <div class="flex-row">
    <div class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{ 'PROFILO.BARRA3NOME' | translate}}" formControlName="first_name" required>
        <mat-error *ngIf="referentForm.controls['first_name'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </div>

    <div class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{ 'PROFILO.BARRA3TELEFONO' | translate}}" formControlName="phone">
      </mat-form-field>
    </div>
  </div>

  <div class="flex-row">

    <div class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{ 'PROFILO.BARRA3COGNOME' | translate}}" formControlName="last_name" required>
        <mat-error *ngIf="referentForm.controls['last_name'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </div>

    <div class="flex-col">
      <div class="rightInputField">
        <mat-form-field style="width: 20%; float: left;">
          <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code">
            <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
              <img alt="flag icon" class="flagCountry"
                src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
              <span>{{ type.CODE }}</span>
              <span>{{ type.DESC }}</span>
              <span> - {{ type.COUNTRY | uppercase }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 75%; float: right;">
          <input type="tel" matInput placeholder="{{'PROFILO.BARRA3MOBILE' | translate}}" formControlName="smartphone"
            required>
          <mat-error *ngIf="referentForm.controls['smartphone'].hasError('required')"
            [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>


  <div class="flex-row">

    <div class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput placeholder="{{ 'PROFILO.BARRA3EMAIL' | translate}}" formControlName="email" required>
        <mat-error
          *ngIf="referentForm.controls['email'].hasError('pattern') && !referentForm.controls['email'].hasError('required')"
          [innerHTML]="'MESSAGE.VALID_EMAIL' | translate"></mat-error>
        <mat-error *ngIf="referentForm.controls['email'].hasError('required')"
          [innerHTML]="'MESSAGE.EMAIL_REQUIRED' | translate"></mat-error>
        <mat-error *ngIf="referentForm.controls['email'].hasError('email_not_available')"
          [innerHTML]="'MESSAGE.EMAIL_NOT_AVAILABLE' | translate"></mat-error>
      </mat-form-field>
    </div>

    <div class="flex-col">
      <mat-form-field class="rightInputField">
        <input matInput placeholder="{{ 'PROFILO.BARRA3FAX' | translate}}" formControlName="fax">
      </mat-form-field>
    </div>

  </div>

  <div *ngIf="!isSelfEnrollment" class="buttonRow">
    <button mat-flat-button color="primary" [disabled]="!merchantService.currentMerchant || !referentForm.valid"
      (click)="notifyChanges()">{{'PROFILO.FILTRO2BUTTON' | translate}}</button>
  </div>

</form>
