import { Operation } from './../../_models/operation';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { ThemeService } from '../../_services/theme.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-recurrentPrev',
  templateUrl: './recurrentPrev.component.html',
  styleUrls: ['./recurrentPrev.component.scss']
})
export class RecurrentPrevComponent implements OnInit {
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  dataSource = new MatTableDataSource();
  theme: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public readonly UPLOAD_URL: string = environment.BASE_URL + 'merchant/';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public translateService: TranslateService, private themeService: ThemeService) {

  }
  displayedColumns = ['rata', 'data', 'stato', 'importo', 'idPag'];
  displayedxs = ['data', 'stato', 'importo'];
  ngOnInit() {
    this.dataSource = this.data.item.ricRat;
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
  }
}
