import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Merchant } from '../../_models/merchant';
import { AvailableEmailValidator } from '../../_validators/available-email.directive';
import { counties } from '../../_models/country';
import { environment } from '../../../environments/environment';
import { Referent } from '../../_models/referent';
import { MerchantService } from '../../_services/merchant.service';
import { ReferentService } from '../../_services/referent.service';
import { LoaderService } from '../../_services/loader.service';
import { DialogService } from '../../_services/dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-referent-data',
  templateUrl: './referent-data.component.html',
  styleUrls: ['./referent-data.component.scss']
})
export class ReferentDataComponent implements OnInit, OnDestroy {
  @Input() isSelfEnrollment?: boolean;
  @Output() outputFormValues: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  countryPrefix = counties;

  referentForm: UntypedFormGroup;

  private merchantSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    public merchantService: MerchantService,
  ) {
    this.buildReferentForm();
  }

  ngOnInit() {
    if (this.isSelfEnrollment) {
      this.onChanges();
      this.notifyChanges();
    }
    this.merchantSubscription = this.merchantService.currentMerchant$.subscribe(
      value => this.getReferent(value)
    )
  }


  ngOnDestroy() {
    this.merchantSubscription.unsubscribe();
  }

  getReferent(value: Merchant) {
    if (!value || !value.referent) {return; }
    this.referentForm.patchValue(value.referent);
  }

  buildReferentForm() {
    this.referentForm = this.fb.group({
      first_name: [ null, [Validators.required]],
      last_name: [null, [Validators.required]],
      smartphone: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]],
      phone: [null],
      fax: [null],
      area_code: [null],
    });
  }

  notifyChanges() {
    this.outputFormValues.emit(this.referentForm);
  }

  onChanges(): void {
    this.referentForm.statusChanges.subscribe(() => this.notifyChanges());
  }


}
