import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {OperationsDataSource} from '../../_cdk/operationsDataSource';
import {PaymentService} from '../../_services/payment.service';
import {AuthService} from '../../_services/auth.service';
import {Operation} from '../../_models/operation';
import {Observable} from 'rxjs/internal/Observable';
import {MatPaginator} from '@angular/material/paginator';
import {tap} from 'rxjs/operators';
import {Sort} from '@angular/material/sort';
import { saveFile } from '../../_services/file-download-helper';
import { MerchantService } from '../../_services/merchant.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-operations-dashboard',
  templateUrl: './operations-dashboard.component.html',
  styleUrls: ['./operations-dashboard.component.scss']
})
export class OperationsDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: OperationsDataSource;
  sort = '-createdAt';
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  private cardDataSource: Observable<Operation[]>;
  filters: Object;
  isAsc = false;
  merchantId: string;
  paymentType: string;

  constructor(
    private paymentService: PaymentService,
    private merchantService: MerchantService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    
    if (this.activatedRoute.snapshot.url && this.activatedRoute.snapshot.url[0]) {
      this.checkRoute(this.activatedRoute.snapshot.url[0].path);
    }
    this.merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : undefined;
    this.dataSource = new OperationsDataSource(this.paymentService);
    this.loadOperations();
    this.cardDataSource = this.dataSource.connect();
  }
  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadOperations() )
      )
      .subscribe();
  }
  loadOperations() {
    this.dataSource.loadOperations(
      this.merchantId,
      this.filters,
      this.sort,
      this.paginator ? this.paginator.pageIndex : 0,
      this.paginator ? this.paginator.pageSize : this.pageSize,
      this.paymentType
    );
  }
  sortData(sort: Sort) {
    this.isAsc = sort.direction === 'asc';
    this.sort = this.isAsc ? sort.active : `-${sort.active}`;
    this.loadOperations();
  }

  filtraDataSource(event: any) {
    this.filters = event;
    this.loadOperations();
  }

  downloadReport() {
    if (this.paymentType) {
      if (!this.filters) {
        this.filters = {}
      }
      Object.assign(this.filters, { payment_type: this.paymentType})
    }
    this.paymentService.export(this.merchantId, this.filters, false)
    .subscribe(
      response => saveFile(response, 'operations_report.xlsx')
    );
  }

  checkRoute(route: string) {
    this.paymentType = route.toUpperCase();
    
  }

}
