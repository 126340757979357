import { Injectable, ViewContainerRef, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DETAIL_OVERLAY_DATA } from '../_cdk/overlayData';
import { DetailOverlayRef } from '../_models/detailOverlayRef';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  reference: any;

  constructor(public overlay: Overlay, private injector: Injector) { }

  private createInjector(data, overlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(DetailOverlayRef, overlayRef);

    injectionTokens.set(DETAIL_OVERLAY_DATA, data);


    return new PortalInjector(this.injector, injectionTokens);
  }

  private configure(configObj: any) {
    const overlayConfiguration = new OverlayConfig({
      // backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'overlay-panel',
      hasBackdrop: true,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically()
    });
    if (configObj) { overlayConfiguration.width = configObj.width; }
    return overlayConfiguration;
  }

  private create(configObj: any) {
    const config = this.configure(configObj);
    return this.overlay.create(config);
  }

  open(component, data?: any,  ref?: ViewContainerRef, config?: any ) {

    const overlayRef = this.create(config);

    const detailOverlayRef = new DetailOverlayRef(overlayRef);

    const injectedTokenData = this.createInjector(data, detailOverlayRef);

    const portal = new ComponentPortal(component, ref, injectedTokenData);


    this.reference = overlayRef.attach(portal);

    return detailOverlayRef;
  }
}
