export const provinceArray = [
  { value: 'AG', valueView: 'Agrigento'},
  { value: 'AL', valueView: 'Alessandria'},
  { value: 'AN', valueView: 'Ancona'},
  { value: 'AO', valueView: 'Aosta'},
  { value: 'AR', valueView: 'Arezzo'},
  { value: 'AP', valueView: 'Ascoli Piceno'},
  { value: 'AT', valueView: 'Asti'},
  { value: 'AV', valueView: 'Avellino'},
  { value: 'BA', valueView: 'Bari'},
  { value: 'BT', valueView: 'Barletta-Andria-Trani'},
  { value: 'BL', valueView: 'Belluno'},
  { value: 'BN', valueView: 'Benevento'},
  { value: 'BG', valueView: 'Bergamo'},
  { value: 'BI', valueView: 'Biella'},
  { value: 'BO', valueView: 'Bologna'},
  { value: 'BZ', valueView: 'Bolzano'},
  { value: 'BS', valueView: 'Brescia'},
  { value: 'BR', valueView: 'Brindisi'},
  { value: 'CA', valueView: 'Cagliari'},
  { value: 'CL', valueView: 'Caltanissetta'},
  { value: 'CB', valueView: 'Campobasso'},
  { value: 'CI', valueView: 'Carbonia-Iglesias'},
  { value: 'CE', valueView: 'Caserta'},
  { value: 'CT', valueView: 'Catania'},
  { value: 'CZ', valueView: 'Catanzaro'},
  { value: 'CH', valueView: 'Chieti'},
  { value: 'CO', valueView: 'Como'},
  { value: 'CS', valueView: 'Cosenza'},
  { value: 'CR', valueView: 'Cremona'},
  { value: 'KR', valueView: 'Crotone'},
  { value: 'CN', valueView: 'Cuneo'},
  { value: 'EN', valueView: 'Enna'},
  { value: 'FM', valueView: 'Fermo'},
  { value: 'FE', valueView: 'Ferrara'},
  { value: 'FI', valueView: 'Firenze'},
  { value: 'FG', valueView: 'Foggia'},
  { value: 'FC', valueView: 'Forlì-Cesena'},
  { value: 'FR', valueView: 'Frosinone'},
  { value: 'GE', valueView: 'Genova'},
  { value: 'GO', valueView: 'Gorizia'},
  { value: 'GR', valueView: 'Grosseto'},
  { value: 'IM', valueView: 'Imperia'},
  { value: 'IS', valueView: 'Isernia'},
  { value: 'SP', valueView: 'La Spezia'},
  { value: 'AQ', valueView: 'L\'Aquila'},
  { value: 'LT', valueView: 'Latina'},
  { value: 'LE', valueView: 'Lecce'},
  { value: 'LC', valueView: 'Lecco'},
  { value: 'LI', valueView: 'Livorno'},
  { value: 'LO', valueView: 'Lodi'},
  { value: 'LU', valueView: 'Lucca'},
  { value: 'MC', valueView: 'Macerata'},
  { value: 'MN', valueView: 'Mantova'},
  { value: 'MS', valueView: 'Massa-Carrara'},
  { value: 'MT', valueView: 'Matera'},
  { value: 'ME', valueView: 'Messina'},
  { value: 'MI', valueView: 'Milano'},
  { value: 'MO', valueView: 'Modena'},
  { value: 'MB', valueView: 'Monza e della Brianza'},
  { value: 'NA', valueView: 'Napoli'},
  { value: 'NO', valueView: 'Novara'},
  { value: 'NU', valueView: 'Nuoro'},
  { value: 'OT', valueView: 'Olbia-Tempio'},
  { value: 'OR', valueView: 'Oristano'},
  { value: 'PD', valueView: 'Padova'},
  { value: 'PA', valueView: 'Palermo'},
  { value: 'PR', valueView: 'Parma'},
  { value: 'PV', valueView: 'Pavia'},
  { value: 'PG', valueView: 'Perugia'},
  { value: 'PU', valueView: 'Pesaro e Urbino'},
  { value: 'PE', valueView: 'Pescara'},
  { value: 'PC', valueView: 'Piacenza'},
  { value: 'PI', valueView: 'Pisa'},
  { value: 'PT', valueView: 'Pistoia'},
  { value: 'PN', valueView: 'Pordenone'},
  { value: 'PZ', valueView: 'Potenza'},
  { value: 'PO', valueView: 'Prato'},
  { value: 'RG', valueView: 'Ragusa'},
  { value: 'RA', valueView: 'Ravenna'},
  { value: 'RC', valueView: 'Reggio Calabria'},
  { value: 'RE', valueView: 'Reggio Emilia'},
  { value: 'RI', valueView: 'Rieti'},
  { value: 'RN', valueView: 'Rimini'},
  { value: 'RM', valueView: 'Roma'},
  { value: 'RO', valueView: 'Rovigo'},
  { value: 'SA', valueView: 'Salerno'},
  { value: 'VS', valueView: 'Medio Campidano'},
  { value: 'SS', valueView: 'Sassari'},
  { value: 'SV', valueView: 'Savona'},
  { value: 'SI', valueView: 'Siena'},
  { value: 'SR', valueView: 'Siracusa'},
  { value: 'SO', valueView: 'Sondrio'},
  { value: 'TA', valueView: 'Taranto'},
  { value: 'TE', valueView: 'Teramo'},
  { value: 'TR', valueView: 'Terni'},
  { value: 'TO', valueView: 'Torino'},
  { value: 'OG', valueView: 'Ogliastra'},
  { value: 'TP', valueView: 'Trapani'},
  { value: 'TN', valueView: 'Trento'},
  { value: 'TV', valueView: 'Treviso'},
  { value: 'TS', valueView: 'Trieste'},
  { value: 'UD', valueView: 'Udine'},
  { value: 'VA', valueView: 'Varese'},
  { value: 'VE', valueView: 'Venezia'},
  { value: 'VB', valueView: 'Verbano-Cusio-Ossola'},
  { value: 'VC', valueView: 'Vercelli'},
  { value: 'VR', valueView: 'Verona'},
  { value: 'VV', valueView: 'Vibo Valentia'},
  { value: 'VI', valueView: 'Vicenza'},
  { value: 'VT', valueView: 'Viterbo'}
];
