import { Component, OnInit, Input, ElementRef, ViewChild, ViewChildren, QueryList, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { saveFile } from '../../_services/file-download-helper';
import { PaymentService } from '../../_services/payment.service';
import { DialogService } from "../../_services/dialog.service";
import { LoaderService } from "../../_services/loader.service";

@Component({
  selector: 'app-operations-detail-static',
  templateUrl: './operations-detail-static.component.html',
  styleUrls: ['./operations-detail-static.component.scss']
})
export class OperationsDetailStaticComponent implements OnInit {

  @Input() element: any;
  @Input() transactionList?: Array<any>;
  @Output() refreshOutcomes?: EventEmitter<any> = new EventEmitter<any>();

  dates = [];
  transactions = [];
  operations = [];
  success: any;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  adminName: string;
  selectedTabIndex: number = 0;

  constructor(
    private paymentService: PaymentService,
    private loaderService: LoaderService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.getAuthor();
    this.getTransactions();
    this.getDates();
  }

  getAuthor() {
    if (!this.element.user) { return; }
    if (this.element.user.first_name && this.element.user.last_name) {
      const name = this.element.user.first_name + ' ' + this.element.user.last_name;
      this.adminName = name.toLowerCase();
    }
  }

  getDates() {
    if (this.transactions.length < 1) { return; }
    let date;
    this.transactions.forEach(el => {
      if (el.date) {
        date = el.date.slice(0, 10);
      }
      const dateExists = this.dates.find(y => y === date);
      if (!dateExists) {
        this.dates.push(date);
      }
    });
  }

  calcShieldColor(trx) {
    if (!trx || !trx.enrStatus) {
      return 'grigio';
    }
    if (trx.enrStatus === 'N') {
      return 'rosso';
    }
    if (trx.enrStatus === 'Y') {
      if (trx.authStatus === 'Y') { return 'verde'; }
      if (trx.authStatus === 'N') { return 'rosso'; }
      if (trx.authStatus === 'A') { return 'giallo'; }
      if (trx.authStatus === 'E') { return 'rosa'; }
    }
    if (trx.enrStatus === 'E' || trx.enrStatus === 'U') {
      return 'giallo';
    }
  }

  refreshTransactions() {
    this.refreshOutcomes.emit();
  }

  async getTransactions(event?: any) {
    // Set tab index
    this.selectedTabIndex = event && this.selectedTabIndex !== event.index ? event.index : this.selectedTabIndex;

    //Controllo se pagamento ricorrente
    if (/RECURRENT|DEFERRED/.test(this.element.payment_type)) {
      this.transactions = this.paymentService.transactions;
      this.operations = this.paymentService.operations;

      // ORDINA LE TRANSAZIONI CRONOLOGICAMENTE
      this.sortTransactions(this.transactions, 'date');
    } else {
      if (!this.element.outcomes || this.element.outcomes.length < 1) { return; }

      // SE IL PAGAMENTO E APERTO LE TRANSAZIONI VENGONO PASSATE IN INPUT GIA RAGGRUPPATE PER DATA
      if (this.element.isPaymentOpen && this.transactionList) {
        this.transactionList.forEach(el => {
          const transaction = {
            response: el.outcome_response,
            receipt: el.outcome_receipt,
            date: el.outcome_update,
          };
          this.transactions.unshift(transaction);
        });
      } else {
        this.element.outcomes.forEach(el => {
          const transaction = {
            response: el.outcome_response,
            receipt: el.outcome_receipt,
            date: el.outcome_update,
          };
          this.transactions.unshift(transaction);
        });
      }
      this.transactions.forEach(el => {
        const nameString = el.response.accountName;
        if (!nameString) { return; }
        const nameArray = nameString.split(',');
        if (!nameArray[1]) {
          el.response.accountName = nameArray[0];
          return;
        }
        el.response.accountName = nameArray[1] + ' ' + nameArray[0];
      });

      // ORDINA LE TRANSAZIONI CRONOLOGICAMENTE
      this.sortTransactions(this.transactions);
    }

    // CONTROLLA SE ESISTE ALMENO UNA TRANSAZIONE ANDATA A BUON FINE
    this.findSuccess();
  }

  getTypeOperation(type) {
    switch (type) {
      case 'PURCHASE':
      case 'AUTH':
      case 'VERIFY':
        return "TRANSACTION";

      case 'CREDIT':
      case 'VOID':
      case 'CONFIRM':
        return "OPERATION"

      default:
        return "TRANSACTION"
    }
  }

  notification(recurr_id) {
    this.loaderService.startGeneral();
    this.paymentService.recurrentNotify(recurr_id).subscribe((res) => {
      this.loaderService.stopGeneral();
      if (res) {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      } else {
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', "Generic Notification Error");
      }
    });
  }

  findSuccess() {
    this.success = this.transactions.find(el => el.response.rc === 'IGFS_000');
  }

  downloadReceipt(transaction) {
    this.paymentService.downloadReceipt(transaction.receipt._id)
      .subscribe(
        response => saveFile(response, 'receipt.pdf')
      );
  }

  sortTransactions(array: any[], field = null) {
    if (!field) {
      array = array.sort((a, b) => a.date - b.date);
    } else {
      array = array.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        } else if (a[field] < b[field]) {
          return -1;
        }

        return 0;
      });
    }
  }

}
