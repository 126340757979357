import { Component, OnInit, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-mobile-preview',
  templateUrl: './mobile-preview.component.html',
  styleUrls: ['./mobile-preview.component.scss']
})
export class MobilePreviewComponent implements OnInit {

  @Input() smsText: string;
  @Input() emailHTML?: SafeHtml;

  currentData: string;
  constructor() {
    this.currentData = this.formatAMPM(new Date());
  }

  ngOnInit() {
    setInterval(() => {
      this.currentData = this.formatAMPM(new Date());
    }, 1000);
  }

  formatAMPM(date: any): string {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return hours + ':' + minutes + ' ' + ampm.toUpperCase();
  }

}
