import { Merchant } from "../_models/merchant";

// eslint-disable-next-line no-restricted-imports
import { Observable, forkJoin, of, Subscription, BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Referent } from "../_models/referent";
import { AuthService } from "./auth.service";
import { map, catchError } from "rxjs/operators";
import { User } from "../_models/user";

@Injectable()
export class MerchantService {
  public isAdminMode = false; // SEMPRE FALSE ECCETTO CHE CON UTENTE SUPPLIER CHE NON IMPERSONIFICA
  private readonly MERCHANT_URL: string = environment.BASE_URL + "merchant";
  private merchantSubj: BehaviorSubject<Merchant>;
  currentMerchant$: Observable<Merchant>;

  private nomeAziendaHeaderSubj: BehaviorSubject<string>;
  nomeAziendaHeader$: Observable<string>;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.merchantSubj = new BehaviorSubject(null);
    this.currentMerchant$ = this.merchantSubj.asObservable();

    this.nomeAziendaHeaderSubj = new BehaviorSubject<string>("");
    this.nomeAziendaHeader$ = this.nomeAziendaHeaderSubj.asObservable();
  }

  public get currentMerchant() {
    return this.merchantSubj.value;
  }

  public set currentMerchant(value: Merchant) {
    if (!value) {
      this.exitImpersonifica();
    } else {
      this.merchantSubj.next(value);
    }
  }

  public resetHeader() {
    this.nomeAziendaHeaderSubj.next(this.currentMerchant.name);
  }

  public retrieveMerchant(user: User) {
    if (user.type === "SUPPLIER" || user.type === "HOLDING") {
      this.isAdminMode = true;
      return of(null);
    }
    let id: string;
    if (user.type === "CUSTOMER") {
      id = user.merchant._id;
    }
    /* if (user.type === 'HOLDING') {
      if (!user.merchants[0]) {return; }
      id = user.merchants[0].merchant._id;
    } */
    return this.impersonifica(id);
  }

  public exitImpersonifica() {
    this.merchantSubj.next(null);
    this.isAdminMode = true;
    this.nomeAziendaHeaderSubj.next("");
    localStorage.removeItem("clicpay-merchant");
  }

  public impersonifica(id: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", this.authService.token);
    headers = headers.set("Accept-Language", "it");

    return this.http.get<any>(this.MERCHANT_URL + "/" + id, { headers }).pipe(
      map((value) => {
        this.merchantSubj.next(value.item);
        this.isAdminMode = false;
        localStorage.setItem("clicpay-merchant", this.currentMerchant._id);
        this.nomeAziendaHeaderSubj.next(this.currentMerchant.name);
        return value.item;
      })
    );
  }

  /**
   * Metodo per creare un nuovo merchant
   * @param merchant Model merchant del merchant
   */
  create(merchant: Merchant): Observable<Merchant> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", this.authService.token);
    headers = headers.set("Accept-Language", "it");

    return this.http.post(this.MERCHANT_URL, merchant, { headers }).pipe(
      map((value: any) => {
        this.merchantSubj.next(value.item);
        return value.item;
      })
    );
  }

  update(_id: string, merchant: any): Observable<Merchant> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", this.authService.token);
    headers = headers.set("Accept-Language", "it");

    return this.http
      .put(this.MERCHANT_URL + "/" + _id, merchant, { headers })
      .pipe(
        map((value: any) => {
          this.merchantSubj.next(value.item);
          return value.item;
        })
      );
  }

  getMerchant(id: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", this.authService.token);
    headers = headers.set("Accept-Language", "it");

    return this.http.get<any>(this.MERCHANT_URL + "/" + id, { headers }).pipe(
      map((value) => {
        this.merchantSubj.next(value.item);
        return value.item;
      })
    );
  }

  get(
    sort?: string,
    limit?: number,
    skip?: number,
    populate?: "true" | "false",
    select?: string
  ): Observable<Merchant[]> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", this.authService.token);
    headers = headers.set("Accept-Language", "it");
    let params = new HttpParams();
    params = params.set("skip", limit && skip ? (skip * limit).toString() : "");
    params = params.set("limit", limit ? limit.toString() : "");
    params = params.set("sort", sort ? sort : "-createdAt");
    if (!populate) {
      populate = "true";
    }
    if (populate !== "false") {
      params = params.set("populate", populate);
    }
    if (select) {
      params = params.set("select", select);
    }
    return this.http
      .get<any>(this.MERCHANT_URL, { headers, params })
      .pipe(map((response) => response.items));
  }

  inserisciTerminale(
    _idMerchant: string,
    idterminale: string
  ): Observable<{} | Merchant[]> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", this.authService.token);
    headers = headers.set("Accept-Language", "it");

    return this.http.put(
      this.MERCHANT_URL + "/" + _idMerchant + "/push/" + idterminale,
      null,
      { headers }
    );
  }

  eliminaTerminale(
    _idMerchant: string,
    idterminale: string
  ): Observable<{} | Merchant[]> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Authorization", this.authService.token);
    headers = headers.set("Accept-Language", "it");

    return this.http.put(
      this.MERCHANT_URL + "/" + _idMerchant + "/pull/" + idterminale,
      null,
      { headers }
    );
  }
}
