import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dialog-circuiti',
  templateUrl: './dialog-circuiti.component.html',
  styleUrls: ['./dialog-circuiti.component.css']
})
export class DialogCircuitiComponent implements OnInit {

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  circuiti: any;

  @Input() data: Array<string>;

  @Output() selectChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor()
  {

    this.circuiti = [
      {code: 'AMEX', desc: 'American Express'},
      {code: 'DINERS', desc: 'Diners'},
      {code: 'MAESTRO', desc: 'Maestro'},
      {code: 'MASTERCARD', desc: 'Master Card'},
      {code: 'MASTERCARDSECURE', desc: 'Master Card Secure Code'},
      {code: 'MASTERPASS', desc: 'Master Pass'},
      {code: 'MYBANK', desc: 'My Bank'},
      {code: 'PAYPAL', desc: 'Pay Pal'},
      {code: 'VERIFIEDBYVISA', desc: 'Verified by Visa'},
      {code: 'VISA', desc: 'Visa'},
      {code: 'VPAY', desc: 'VPay'}
    ];

  }

  ngOnInit() {

  }

  selectionChanged(event){
    this.selectChanged.emit(event.value);
  }

}
