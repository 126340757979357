import { Merchant } from '../../../../_models/merchant';
import { MerchantService } from '../../../../_services/merchant.service';
import { Customer } from '../../../../_models/customer';

import { ReferentService } from '../../../../_services/referent.service';

import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';

import * as moment from 'moment';
import { FormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ErrorMatcher } from '../../../../_classes/error-matcher';
import { Referent } from '../../../../_models/referent';
import { CustomerService } from '../../../../_services/customer.service';
import { LoaderService } from '../../../../_services/loader.service';
import { DialogService } from '../../../../_services/dialog.service';
// eslint-disable-next-line no-restricted-imports
import { User } from '../../../../_models/user';
import { counties } from '../../../../_models/country';
import { AuthService } from '../../../../_services/auth.service';



@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-newClients',
  templateUrl: './newClients.component.html',
  styleUrls: ['./newClients.component.scss']
})

export class NewClientsComponent implements OnInit, OnDestroy {


  merch: any;
  modCustomer: boolean;
  user: User;
  currentDayNow: string;
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  customer: Customer = new Customer();

  referent: Referent = new Referent();

  flagAggiungiDatiAnagrafici = false;
  flagAggiungiPosizione = false;
  flagAggiungiReferent = false;

  @Output() close = new EventEmitter();

  @ViewChild(MatExpansionPanel, { static: true }) matexpansion: MatExpansionPanel;

  customerForm: UntypedFormGroup;

  referentForm: UntypedFormGroup;
  matcher = new ErrorMatcher();

  //merchant: Merchant[];
  customers: Customer[];
  codiceIdCustomer = '';
  codiceIdReferent = '';
  countryPrefix: any[] = [];

  idMerchant: string;

  constructor(private router: Router,
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    private referentService: ReferentService,
    private merchantService: MerchantService,
    private authService: AuthService,
    private dialog: MatDialog,
    public dialogService: DialogService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    ) {
    moment.locale('it');
    this.currentDayNow = moment().format('DD/MM/YYYY');
    this.buildCustomerForm();
    this.buildReferentForm();
  }

  ngOnDestroy(): void {

    //this.customerService.customer = new Customer();
  }
  ngOnInit() {
    this.user = this.authService.user;
    if (!this.merchantService.currentMerchant) {return; }
    this.merch = this.merchantService.currentMerchant;
    this.idMerchant = this.merch._id;

    for (let i = 0; i < this.merch.area_code_type.length; i++) {
      for (let j = 0; j < counties.length; j++) {
        if (this.merch.area_code_type[i] === counties[j].CODE) {
          this.countryPrefix.push(counties[j]);
        }
      }
    }

    this.codiceIdCustomer = '';
    this.codiceIdReferent = '';
    this.loaderService.startGeneral();
    this.flagAggiungiDatiAnagrafici = false;
    this.flagAggiungiPosizione = false;
    this.flagAggiungiReferent = false;
    /* if (!!(this.customerService.customer)) {
      this.customer = this.customerService.customer;

    } */

    /* this.modCustomer = this.customerService.modCustomer;
    this.customerService.modCustomer = false; */

    const params = this.activatedRoute.snapshot.params;
    if (params.id) {
      this.customerService.getById(params.id).subscribe(
        response => {
          this.customer = response;
          this.modCustomer = true;
          this.codiceIdCustomer = this.customer._id;
          this.matexpansion.open();
          this.buildCustomerForm();
          //this.customerForm.controls.merchant.disable();
          //this.getReferentData();
          this.referent = this.customer.referent;
          this.loaderService.stopGeneral();
          if (!this.referent) {return; }
          this.codiceIdReferent = this.referent._id;
          this.buildReferentForm();

        }
      )
    } else {
      this.buildCustomerForm();
      this.loaderService.stopGeneral();
    }

    /* if (!!(this.customer._id)) {
      this.codiceIdCustomer = this.customer._id;
      this.matexpansion.open();
    }
    if (this.modCustomer) {
      this.customer = this.customerService.customer ;
      this.codiceIdCustomer = this.customer._id;
    } */

    //this.buildCustomerForm();
    //this.buildReferentForm();

    /* this.customerForm.controls.merchant.disable();

    this.getData(); */


  }

  getReferentData() {
    if (!(this.customer.referent)) return this.loaderService.stopGeneral();
    this.loaderService.startGeneral();
    this.referentService.searchId(this.customer.referent)
    .subscribe(
      (results) => {
        this.loaderService.stopGeneral();
        this.referent = <Referent>results['items'][0];
        this.codiceIdReferent = this.referent._id;
        this.buildReferentForm();
        this.loaderService.stopGeneral();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }

  buildCustomerForm() {
    this.customerForm = this.fb.group({

      informations: [!(this.customer.informations) ? null : this.customer.informations],
      piva: [!(this.customer.piva) ? null : this.customer.piva],
      name: [!(this.customer.name) ? null : this.customer.name, [Validators.required]],
      description: [!(this.customer.description) ? null : this.customer.description],
      address: [!(this.customer.address) ? null : this.customer.address],
      number: [!(this.customer.number) ? null : this.customer.number],
      state: [!(this.customer.state) ? null : this.customer.state],
      district: [!(this.customer.district) ? null : this.customer.district],
      province: [!(this.customer.province) ? null : this.customer.province],
      region: [!(this.customer.region) ? null : this.customer.region],
      cap: [!(this.customer.cap) ? null : this.customer.cap],
      location: [!(this.customer.location) ? null : this.customer.location],
      phone: [!(this.customer.phone) ? null : this.customer.phone],
      smartphone: [!(this.customer.smartphone) ? null : this.customer.smartphone, [Validators.required]],
      fax: [!(this.customer.fax) ? null : this.customer.fax],
      email: [!(this.customer.email) ? null : this.customer.email, [Validators.required, Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]],
      referent: [!(this.customer.referent) ? null : this.customer.referent],
      merchant: [{value: !(this.merch) ? null : this.merch.name, disabled: true}, [Validators.required]],
      createAt: [!(this.customer.createAt) ? null : this.customer.createAt],
      updatAt: [!(this.customer.updatAt) ? null : this.customer.updatAt],
      area_code: [!(this.customer.area_code) ? null : this.customer.area_code],
    });
  }


  buildReferentForm() {
    this.referentForm = this.fb.group({

      first_name: [this.referent && !(this.referent.first_name) ? null : this.referent.first_name, [Validators.required]],
      last_name: [this.referent && !(this.referent.last_name) ? null : this.referent.last_name, [Validators.required]],
      smartphone: [this.referent && !(this.referent.smartphone) ? null : this.referent.smartphone, [Validators.required]],
      email: [this.referent && !(this.referent.email) ? null : this.referent.email, [Validators.required, Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)]],
      phone: [this.referent && !(this.referent.phone) ? null : this.referent.phone],
      fax: [this.referent && !(this.referent.fax) ? null : this.referent.fax],
      area_code: [this.referent && !(this.referent.area_code) ? null : this.referent.area_code],

    });



  }


  aggiungiDatiAnagrafici() {

    this.loaderService.startGeneral();
    const dataCustomer = this.customerForm.value;

    this.codiceIdCustomer = '';
    for (const prop in dataCustomer) {
      if (dataCustomer.hasOwnProperty(prop)) {
        this.customer[prop] = dataCustomer[prop];
      }
    }
    this.customer.merchant = this.idMerchant;
    this.customerService.create(this.customer)
    .subscribe(
      responseCustomer => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.flagAggiungiDatiAnagrafici = true;
        this.codiceIdCustomer = responseCustomer['item']['_id'];
        this.loaderService.stopGeneral();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.flagAggiungiDatiAnagrafici = false;
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }


  modificaDatiAnagrafici() {

    this.loaderService.startGeneral();
    const dataCustomer = this.customerForm.value;

    for (const prop in dataCustomer) {
      if (dataCustomer.hasOwnProperty(prop)) {
        this.customer[prop] = dataCustomer[prop];
      }
    }
    this.customer.merchant = this.idMerchant;
    this.customerService.update(this.codiceIdCustomer, this.customer)
    .subscribe(
      responseCustomer => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.flagAggiungiDatiAnagrafici = true;
        this.loaderService.stopGeneral();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.flagAggiungiDatiAnagrafici = false;
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      });

  }

  aggiungiModificaResidenzaPosizione() {

    this.loaderService.startGeneral();
    const dataCustomer = this.customerForm.value;

    for (const prop in dataCustomer) {
      if (dataCustomer.hasOwnProperty(prop)) {
        this.customer[prop] = dataCustomer[prop];
      }
    }
    this.customerService.update(this.codiceIdCustomer, this.customer)
    .subscribe(
      responseCustomer => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.flagAggiungiPosizione = true;

        this.codiceIdCustomer = responseCustomer['item']['_id'];
        this.loaderService.stopGeneral();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.flagAggiungiDatiAnagrafici = false;
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    );
  }


  aggiungiReferente() {

    this.loaderService.startGeneral();
    const dataReferent = this.referentForm.value;
    this.codiceIdReferent = '';
    this.referent = new Referent(dataReferent)
    this.referentService.create(this.referent)
    .subscribe(
      responseReferent => {
        this.codiceIdReferent = responseReferent['item']['_id'];
        this.customer = <Customer>{};
        this.customer._id = this.codiceIdCustomer;
        this.customer.referent = this.codiceIdReferent;
        this.flagAggiungiReferent = true;
        this.customerService.update(this.codiceIdCustomer, this.customer)
        .subscribe(
          responseCustomer => {
            this.loaderService.stopGeneral();
            this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
          },
           error => { // errore secondo inserimento (customer)
            console.log(error);
            this.loaderService.stopGeneral();
            this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
          }
        );
      },
      error => { // errore primo inserimento (referent)
        console.log(error);
        this.flagAggiungiReferent = false;
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      });

  }

  modificaReferent() {

    this.loaderService.startGeneral();
    const dataReferent = this.referentForm.value;

    for (const prop in dataReferent) {
      if (dataReferent.hasOwnProperty(prop)) {
        this.referent[prop] = dataReferent[prop];
      }
    }
    this.referentService.update(this.codiceIdReferent, this.referent)
    .subscribe(
      responseReferent => {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.loaderService.stopGeneral();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      });

  }

  // conservo i valori cambiando la dimensione dello schermo
  onResizeCustomer() {
    for (const key in this.customerForm.controls) {
      if (this.customerForm.controls.hasOwnProperty(key)) {
        const control = this.customerForm.get(key);
        if (!!(control.value)) {
          control.setValue(control.value);
        }
      }
    }
  }

  onResizeReferent() {
    for (const key in this.referentForm.controls) {
      if (this.referentForm.controls.hasOwnProperty(key)) {
        const control = this.referentForm.get(key);
        if (!!(control.value)) {
          control.setValue(control.value);
        }
      }
    }
  }
}
