import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../_services/auth.service';
import { MerchantService } from '../../../../_services/merchant.service';
import { LoaderService } from '../../../../_services/loader.service';
import { DialogService } from '../../../../_services/dialog.service';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter, Subscription } from 'rxjs';
import { Merchant } from '../../../../_models/merchant';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-merchants',
  templateUrl: './merchants.component.html',
  styleUrls: ['./merchants.component.scss']
})
export class MerchantsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[];
  private readonly supplierCol = ['name', 'insegna', 'email', 'piva', 'referent', 'tid', 'actions'];
  dataSource: MatTableDataSource<Merchant> = new MatTableDataSource();

  private watcher: Subscription;

  constructor(
    public authService: AuthService,
    private merchantService: MerchantService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private router: Router,
    private mediaObserver: MediaObserver,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.displayedColumns = this.supplierCol;
    this.iconRegistry.addSvgIcon('template-custom', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/approvazione-template.svg'))
  }

  ngOnInit() {
    this.merchantService.currentMerchant = null;
    this.observeLayoutChanges(this.supplierCol);
    this.getItems();
  }

  ngOnDestroy() {
    if (!this.watcher) {return; }
    this.watcher.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (!this.sort) {return; }
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  }

  getItems() {
    this.loaderService.startGeneral();
    this.merchantService.get().subscribe(
      (data: Merchant[]) => {
        this.dataSource.data = data;
        this.loaderService.stopGeneral();
      },
      error => {
        this.loaderService.stopGeneral()
        this.dataSource.data = [];
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      },
    );
  }

  impersonificaAzienda(row: Merchant) {
    this.merchantService.impersonifica(row._id).subscribe(
      _ => this.router.navigate(['/merchant'])
    )
  }

  toggleActive(row: Merchant) {
    this.loaderService.startGeneral();
    const data = {
      active: !row.active
    }
    this.merchantService.update(row._id, data)
    .subscribe(
      response => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
        this.getItems();
      },
      error => {
        console.log(error);
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      },
      () => this.merchantService.currentMerchant = null
    );
  }


  modificaAzienda(row: any) {
    this.router.navigate(['/admin/merchants', row._id ])
  }

  creaNuovaAzienda() {
    this.router.navigate(['/admin/merchants/new']);
  }

  goToTemplates(row: Merchant) {
    this.router.navigate(['/admin/merchants', row._id, 'templates']);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  observeLayoutChanges(cols: Array<string>) {
    this.watcher = this.mediaObserver.asObservable().pipe(filter((change: MediaChange[]) => {
      switch(change[0].mqAlias) {
        case 'xs': this.displayedColumns = ['name', 'actions'];
        break;
        case 'sm': this.displayedColumns = ['name', 'piva','actions'];
        break;
        case 'md': this.displayedColumns = ['name', 'insegna', 'email', 'piva', 'tid', 'actions'];
        break;
        case 'lg': this.displayedColumns = ['name', 'insegna', 'email', 'piva', 'referent', 'tid', 'actions'];
        break;
        case 'xl': this.displayedColumns = ['name', 'insegna', 'email', 'piva', 'referent', 'tid', 'actions'];
        break;
      }
      return true;
    })).subscribe();
  }


}
