<div fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="barrEasy">
    <div fxLayout="row" fxLayoutAlign="center center">
      <span Style="margin-right:15px;">{{'PROFILO.MODEASY' | translate}}</span>
      <mat-slide-toggle [formControl]="modEasyControl" color="primary" (click)="modeEasy()"></mat-slide-toggle>
    </div>
  </mat-card>
</div>

<div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="barraFiltro" ngClass.sm="barraFiltro"
  ngClass.md="barraFiltro" ngClass.lg="barraFiltro" ngClass.xl="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important; margin-top: 40px !important">
      <mat-expansion-panel-header>
        <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
          ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
          {{'PROFILO.TITOLO' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-user-data [isProfile]='true' [userData]='user' [disabledFields]='disabledFields'
        (outputFormValues)="getUserData($event)">
      </app-user-data>

      <div fxLayout="column" fxLayoutAlign="space-around stretch">
        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-flat-button color="primary" [disabled]="!userDataForm?.valid"
            (click)="modificaUtente()">{{'PROFILO.BUTTONMOD' | translate}}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<app-change-password [userId]="user?._id" [isProfile]="true"></app-change-password>
