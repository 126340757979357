<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <mat-accordion>
    <mat-expansion-panel style="margin-bottom: 40px !important; margin-top: 40px !important" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          PROFILO
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column" fxLayoutAlign="space-around stretch">

        <form [formGroup]="form">
          <div class="flex-row">
            <span class="flex-col">
              <mat-form-field class="leftInputField">
                <input matInput placeholder="NOME" formControlName="name">
                <mat-error *ngIf="form.controls.name.hasError('required')"
                  [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
              </mat-form-field>
            </span>
            <span class="flex-col">
              <mat-form-field class="rightInputField">
                <input matInput placeholder="DESCRIZIONE" formControlName="description">
                <mat-error *ngIf="form.controls.description.hasError('required')"
                  [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
              </mat-form-field>
            </span>
          </div>

          <div class="flex-row" style="margin-bottom: 40px;">
            <span class="flex-col">
              <div>
                <mat-checkbox color="primary" formControlName="isCreatePaymentRequestActive" class="leftInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.RICHIESTEPAGAMENTO' | translate}}</span>
                </mat-checkbox>
              </div>

              <div>
                <mat-checkbox color="primary" formControlName="isConfirmActive" class="leftInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.STORNAOPERAZIONI' | translate}}</span>
                </mat-checkbox>
              </div>

              <div>
                <mat-checkbox color="primary" formControlName="isBulkActive" class="leftInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.IMPORTMASSIVI' | translate}}</span>
                </mat-checkbox>
              </div>

              <div>
                <mat-checkbox color="primary" formControlName="isSupervisor" class="leftInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.SUPERVISOR' | translate}}</span>
                </mat-checkbox>
              </div>
            </span>

            <span class="flex-col">
              <div>
                <mat-checkbox color="primary" formControlName="isSmsActive" class="rightInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.SMS' | translate}}</span>
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" formControlName="isQrcodeActive" class="rightInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.QRCODE' | translate}}</span>
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" formControlName="isEmailActive" class="rightInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.EMAIL' | translate}}</span>
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" formControlName="isLinkActive" class="rightInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.LINK' | translate}}</span>
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" formControlName="isWhatsAppActive" class="rightInputField">
                  <span class="checkFont">{{'PROFILOUTENTI.WHATSAPP' | translate}}</span>
                </mat-checkbox>
              </div>
            </span>
          </div>

          <div fxLayout="column" fxLayoutAlign="space-evenly end">
            <div fxLayout="row" fxLayoutAlign="center center">
              <!-- BOTTONE VERDE -->
              <button mat-flat-button color="primary" [disabled]="!form.valid"
                (click)="saveOrUpdate()">{{'NUOVOUTENTE.BUTTONSAVE' | translate}}</button>
            </div>
          </div>

        </form>


      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
