import { MatTableDataSource } from '@angular/material/table';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { Operation } from '../../_models/operation';
import { Anteprima } from '../../_models/anteprima';
import { RecurrentPrevComponent } from '../recurrentPrev/recurrentPrev.component';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../_services/payment.service';
import { AuthService } from '../../_services/auth.service';
import { LoaderService } from '../../_services/loader.service';
import { DialogService } from '../../_services/dialog.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { OverlayService } from '../../_services/overlay.service';
import { User } from '../../_models/user';
import { environment } from '../../../environments/environment';
import { OperationsMobileSliderComponent } from '../operations-mobile-slider/operations-mobile-slider.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { DetailOverlayComponent } from '../detail-overlay/detail-overlay.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort, Sort } from '@angular/material/sort';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OperationsDataSource } from '../../_cdk/operationsDataSource';
import { Payment } from '../../_models/payment';
import { MerchantService } from '../../_services/merchant.service';
import { filter } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GridComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) basicSort: MatSort;
  @Input() isFullScreen?: boolean;
  @Input() dataSource: MatTableDataSource<Operation> | MatTableDataSource<Payment> | OperationsDataSource | Array<Operation>;
  @Output() sortEmitter: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Input()
  set _paymentType(value) {
    this.payment_type = value ? value : 'SINGLE';

    if (this.payment_type !== 'SINGLE') {
      this.displayedColumns = this.displayedColumns.filter(e => e !== 'firstPayment')
    }
  }

  payment_type: string;
  private user: User;
  private isReminderActive: boolean;
  private isAdditionalInfoEditable: boolean;
  private watcher: Subscription;
  private activeMediaQuery: string;
  private isSmallScreen: boolean;
  displayedColumns: string[] = ['icon', 'createdAt', 'firstPayment', 'state', 'amount', 'name', 'action'];
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  expandedElement: any = null;
  currentPaymentId: string = null;

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private mediaObserver: MediaObserver,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private merchantService: MerchantService,
    private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit() {
    this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 959px)');
    this.user = this.authService.user;
    this.isReminderActive = this.merchantService.currentMerchant ? this.merchantService.currentMerchant.isReminderActive : undefined;
    this.isAdditionalInfoEditable = this.merchantService.currentMerchant ? this.merchantService.currentMerchant.isAdditionalInfoEditable : undefined;
    this.observeLayoutChanges();
  }

  ngOnDestroy() {
    if (!this.watcher) { return; }
    this.watcher.unsubscribe();
  }

  openDetail(payment: Operation) {
    this.overlayService.open(DetailOverlayComponent, payment);

  }

  modificaPagamento(row: Operation) {
    const paths = this.activatedRoute.routeConfig.path.split('/');
    if (paths[0] === 'import') {
      this.router.navigate(['/merchant/request', this.activatedRoute.snapshot.params.id, row._id]).then(
        value => { }
      )
      return;
    }
    this.router.navigate(['/merchant/request', row._id]);
  }

  completaPagamento(element: Operation) {
    const dialogRef = this.dialogService.openDialogDELETE('', 'DIALOG.OPENPAYMENTCOMPLETED');
    dialogRef.afterClosed().subscribe(
      data => {
        if (!data) { return; }
        element['state'] = 'COMPLETED';
        this.paymentService.updateOperation(element['_id'], element)
          .subscribe(
            response => {
              this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
              this.loaderService.stopGeneral();
              this.ngOnInit();
            },
            error => {
              this.loaderService.stopGeneral();
              this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
            }
          );
      }
    );
  }

  getTypeOperation(type) {
    switch (type) {
      case 'PURCHASE':
      case 'AUTH':
      case 'VERIFY':
        return 'TRANSACTION';
      case 'CREDIT':
      case 'VOID':
      case 'CONFIRM':
        return 'OPERATION';
      default:
        return 'TRANSACTION';
    }
  }

  refreshTransactions(payment_id = null) {
    this.paymentService.lastOutcomePurchase = null;
    this.paymentService.transactions = [];
    this.paymentService.operations = [];

    this.paymentService.getRecurrentOutcomes(<any>{_id: payment_id || this.currentPaymentId}).subscribe((response: any) => {
      let outcomes = response.items;
      let filter = outcomes.filter(el => el.operation_type === 'PURCHASE' && el.outcome_response?.rc === 'IGFS_000');
      this.paymentService.lastOutcomePurchase = filter.length > 0 ? filter[filter.length - 1]?.outcome_response : null;
      this.paymentService.lastOutcomePurchaseWithAuthCode = filter.length > 0 ? filter.sort((a, b) => b.createdAt - a.createdAt) : null;

      for (let outcome of outcomes) {
        const transaction = {
          response: outcome.outcome_response,
          receipt: outcome.outcome_receipt,
          date: outcome.outcome_update,
          recurrent: outcome.recurrent,
          operation_type: outcome.operation_type,
          amount: outcome.outcome_amount
        }

        if (this.getTypeOperation(transaction.operation_type) === 'TRANSACTION') {
          this.paymentService.transactions.push(transaction);
        } else {
          this.paymentService.operations.push(transaction);
        }
      }
    });
  }

  viewDetail(payment: any) {
    const previousPaymentId = `${this.currentPaymentId}`;
    this.currentPaymentId = payment._id;

    if (/RECURRENT|DEFERRED/.test(payment.payment_type)) {
      let action = this.isSmallScreen ? 'SLIDER' : 'EXPANSION';

      if (payment._id !== previousPaymentId) {
        this.paymentService.lastOutcomePurchase = null;
        this.paymentService.lastOutcomePurchaseWithAuthCode = null;
        this.paymentService.transactions = [];
        this.paymentService.operations = [];

        this.paymentService.getRecurrentOutcomes(payment).subscribe((response: any) => {
          let outcomes = response.items;
          let filter = outcomes.filter(el => el.operation_type === 'PURCHASE' && el.outcome_response?.rc === 'IGFS_000');
          this.paymentService.lastOutcomePurchase = filter.length > 0 ? filter[filter.length - 1]?.outcome_response : null;
          let sortByDateReverse = filter.length > 0 ? filter.sort((a, b) => b.createdAt - a.createdAt) : null;
          this.paymentService.lastOutcomePurchaseWithAuthCode = sortByDateReverse?.length > 0 ? sortByDateReverse[0]?.outcome_response : null;

          for (let outcome of outcomes) {
            let _currentRecurrent = outcome.recurrent;
            let _occurrence  = _currentRecurrent ? payment.recurrents.find((recurrent) => recurrent._id == outcome.recurrent).occurence : null;
            const transaction = {
              response: outcome.outcome_response,
              receipt: outcome.outcome_receipt,
              date: outcome.outcome_update,
              recurrent: outcome.recurrent ? outcome.recurrent : null,
              operation_type: outcome.operation_type,
              amount: outcome.outcome_amount,
              maskedPan: outcome.outcome_response.maskedPan,
              occurence: _occurrence
            }

            if (this.getTypeOperation(transaction.operation_type) === 'TRANSACTION') {
              this.paymentService.transactions.push(transaction);
            } else {
              this.paymentService.operations.push(transaction);
            }
          }
        });

        if (action === 'SLIDER') {
          this.openSlider(payment);
        } else {
          this.expandedElement = payment;
        }

      } else {
        this.isSmallScreen && (!payment.isPaymentOpen || (payment.isPaymentOpen && payment?.outcomes.length < 1))
        ? this.openSlider(payment)
        : this.expandedElement === payment ? this.expandedElement = null : this.expandedElement = payment;
      }
    } else {
      this.isSmallScreen && (!payment.isPaymentOpen || (payment.isPaymentOpen && payment?.outcomes.length < 1))
      ? this.openSlider(payment)
      : this.expandedElement === payment ? this.expandedElement = null : this.expandedElement = payment;
    }
  }


  sollecito(element) {
    this.loaderService.startGeneral();
    this.paymentService.send(element['_id'])
      .subscribe(
        response => {
          this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
          this.loaderService.stopGeneral();
          this.ngOnInit();
        },
        error => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      );
  }

  notification(recurrent_id: string) {
    this.loaderService.startGeneral();
    this.paymentService.recurrentNotify(recurrent_id).subscribe((res) => {
      this.loaderService.stopGeneral();
      if (res) {
        this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
      } else {
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', "Generic Notification Error");
      }
    });
  }

  ricorrenze(element: Operation) {

    this.loaderService.startGeneral();
    const recurr = element.recurrents;

    const anteprima = new Anteprima();
    const table = [];
    const filterRecurrentWithStatus = recurr.filter((el: any) => el.state !== 'ACTIVE');
    const lastWithStatus: any = filterRecurrentWithStatus[filterRecurrentWithStatus.length - 1]
    const recurrents = element.recurrents.map((el: any) => {
      let outcomes = el.outcomes.filter(el => /PURCHASE/.test(el.operation_type));
      let last_outcome = outcomes.length > 0 ? outcomes[outcomes.length - 1] : null;
      el.paymentID = last_outcome?.outcome_response ? last_outcome.outcome_response.paymentID : '';
      el.isBold = el._id === lastWithStatus?._id;
      return el;
    })
    element.recurrents = recurrents;

    for (let recurrent of recurrents) {
      table.push({
        'rata': recurrent.occurence,
        'data': recurrent.execution_date,
        'importo': recurrent.amount,
        'stato': recurrent.state,
        'idPag': recurrent.paymentID,
        'isBold': recurrent.isBold
      });
    }

    anteprima.ricRat = new MatTableDataSource<Operation>(table);
    // VISUALIZZAZIONE TABELLA TRAMITE DIALOG
    this.dialog.open(RecurrentPrevComponent, {
      autoFocus: false,
      disableClose: true,
      data: { item: anteprima }
    });

    this.loaderService.stopGeneral();
  }

  openSlider(payment) {
    const data = {
      'element': payment,
      'transactions': undefined
    };
    this.overlayService.open(OperationsMobileSliderComponent, data);
    this.overlayService.reference.instance.notificationEmitter.subscribe((recurrent_id) => {
      this.notification(recurrent_id);
    });
    this.overlayService.reference.instance.planDeactivationEmitter.subscribe((payment) => {
      this.deactivateRecurrentPlan(payment);
    });
    this.overlayService.reference.instance.refreshParentOutcomes.subscribe((payment) => {
      if (payment._id) {
        this.refreshTransactions(payment._id);
      }
    });
  }

  observeLayoutChanges() {

    this.watcher = this.mediaObserver.asObservable().pipe(filter((change: MediaChange[]) => {

      this.activeMediaQuery = change[0] ? `'${change[0].mqAlias}' = (${change[0].mediaQuery})` : '';
      if (change[0].mqAlias === 'xs') {
        if (this.payment_type === 'SINGLE') {
          this.displayedColumns = ['createdAt', 'state', 'amount'];
        } else {
          this.displayedColumns = ['createdAt', 'stateRecurrent', 'amount'];
        }
      }

      if (change[0].mqAlias === 'sm') {
        if (this.payment_type === 'SINGLE') {
          this.displayedColumns = ['icon', 'createdAt', 'state', 'amount', 'name'];
        } else {
          this.displayedColumns = ['icon', 'createdAt', 'stateRecurrent', 'name', 'amount'];
        }
      }

      if (change[0].mqAlias === 'md') {
        if (this.payment_type === 'SINGLE') {
          this.displayedColumns = ['icon', 'createdAt', 'state', 'amount', 'name', 'brand'];
        } else {
          this.displayedColumns = ['icon', 'createdAt', 'stateRecurrent', 'name', 'amount', 'operation_id'];
        }
      }

      if (change[0].mqAlias === 'lg') {
        if (this.payment_type === 'SINGLE') {
          this.displayedColumns = ['icon', 'createdAt', 'state', 'amount', 'name',  'operation_type', 'brand', 'payment_id', 'action'];
        } else {
          this.displayedColumns = ['icon', 'createdAt', 'firstPayment', 'stateRecurrent', 'name', 'amount', 'operation_id', 'outcomeRecurrent', 'action'];
        }

      }
      if (change[0].mqAlias === 'xl') {
        if (this.payment_type === 'SINGLE') {
          this.displayedColumns = ['icon', 'createdAt', 'state', 'amount', 'name', 'operation_type', 'brand', 'payment_id', 'action'];
        } else {
          this.displayedColumns = ['icon', 'createdAt', 'firstPayment', 'stateRecurrent', 'name', 'amount', 'operation_id', 'outcomeRecurrent', 'action'];
        }
      }

      return true;
    })).subscribe();
  }

  getRecurrentPayed(payment) {
    return payment.recurrents.filter(el => el.state !== 'ACTIVE').length;
  }

  showRepeatedPayments(payment) {
    return (payment.recurrents_type === 'REPEATED' ? '∞' : payment.recurrents_rounds);
  }

  getExecutionDate(payment) {
    if (payment.recurrents.length !== 0) {
      return payment.recurrents[0].execution_date
    }
  }

  getAmount(payment) {
    if (payment.recurrents_type === 'REPEATED') {
      return '-'
    }
    if (!payment.recurrents_type || payment.recurrents_type === 'DEFAULT' || payment.payment_type === 'DEFERRED' || payment.payment_type === 'RECURRENT') {
      return payment && payment.amount ? payment.amount.replace('.', ',') : payment.amount || '';
    }
  }

  sortData(event: Sort) {
    if (!event.active || event.direction === '') { return; }
    this.sortEmitter.emit(event);
  }

  deactivateRecurrentPlan(recurrent: Operation) {
    let isRecurrentPlanActive = recurrent.isRecurrentPlanActive ? false : true;
    let message = recurrent.isRecurrentPlanActive ? 'DIALOG.DEACTIVATE_PLAN' : 'DIALOG.ACTIVATE_PLAN';
    const dialogRef = this.dialogService.openDialogStopRecurrent('', message, this.payment_type);
    dialogRef.afterClosed().subscribe(
      data => {
        if (!data) { return; }
        this.paymentService.updateOperation(recurrent['_id'], <any>{ isRecurrentPlanActive })
          .subscribe(
            response => {
              recurrent.isRecurrentPlanActive = isRecurrentPlanActive;
              this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
              this.loaderService.stopGeneral();
              this.ngOnInit();
            },
            error => {
              this.loaderService.stopGeneral();
              this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
            }
          );
      }
    );
  }

}
