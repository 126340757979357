import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { TemplateService } from './../_services/template.service';
import { Observable } from 'rxjs';
import { AbstractControl, AsyncValidator, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidatorFn } from '@angular/forms';

export function themeNameValidatorDirective(templateService: TemplateService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return templateService.search(control.value.toUpperCase()).pipe(
      map(
        (data: any) => {
          if (data.results.items !== 0 || data.results.items && data.results.items[0]) {
            return {'themeName_exists': true};
          }
          return null;
        }
      ),
      catchError(() => null)
    );
  };
}
