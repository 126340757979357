export class Customer {
    _id: string;
    active: boolean;
    informations: any[];
    piva: string;
    name: string;
    description: string;
    address: string;
    number: number;
    state: string;
    district: string;
    province: string;
    region: string;
    cap: string;
    location: any[];
    phone: string;
    smartphone: string;
    fax: string;
    email: string;
    referent: any;
    merchant: string;
    createAt: string;
    updatAt: string;
    area_code: string;
    constructor() {
        this.informations = [];
        this.location = [];
    }
}
