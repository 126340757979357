<div [formGroup]="extraDataForm">

  <!-- LANGUAGE AND CURRENCY -->
  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <mat-select [disabled]="paymentState==='ACTIVE' ? true : false" placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.LANGUAGE' | translate}}" formControlName="language">
          <mat-option *ngFor="let type of languages" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <mat-select [disabled]="paymentState==='ACTIVE' ? true : false" placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.VALUTA' | translate}}" formControlName="currency">
          <mat-option *ngFor="let type of merchant?.currency" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </div>

  <!-- EXPIRATION AND ATTACHMENTS -->
  <div class="flex-row" *ngIf="paymentType === 'SINGLE'">
    <span class="flex-col">
      <div class="flex-row">
        <span class="flex-col" style="margin-right: 35px;">
          <mat-form-field class="leftInputField">
            <input [disabled]="paymentState==='ACTIVE' ? true : false" matInput [min]="minDate" [matDatepicker]="picker"
              placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.SCADENZA' | translate}}" formControlName="to_date" required
              (click)="picker.open()">
            <mat-error *ngIf="extraDataForm?.controls.to_date.errors"
              [innerHTML]="'RICHIEDIPAGAMENTOAVANZATO.EXPIRATION_DAY_ERROR' | translate">
            </mat-error>
            <mat-datepicker-toggle [disabled]="paymentState==='ACTIVE' ? true : false" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [disabled]="paymentState==='ACTIVE' ? true : false" #picker></mat-datepicker>
          </mat-form-field>
        </span>

        <span class="flex-col">
          <div>
            <mat-form-field style="width: 100%;">
              <mat-label>{{'RICHIEDIPAGAMENTOAVANZATO.SCADENZA_ORARIO' | translate}}
              </mat-label>
              <input [disabled]="paymentState==='ACTIVE' ? true : false" matInput formControlName="expiration_time"
                placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.SCADENZA_ORARIO' | translate}}" [ngxTimepicker]="myTimePicker"
                [format]="24" [min]="minTime" readonly>
              <mat-error *ngIf="extraDataForm?.controls.expiration_time.hasError('alreadyExpired')"
                [innerHTML]="'RICHIEDIPAGAMENTOAVANZATO.EXPIRATION_ERROR' | translate">
              </mat-error>
            </mat-form-field>
            <ngx-material-timepicker #myTimePicker></ngx-material-timepicker>
          </div>
        </span>
      </div>
    </span>
    <span class="flex-col">
      <div class="rightInputField" style="display: flex; justify-content: space-between;">
        <mat-form-field class="uploadFile">
          <input [readonly]="paymentState==='ACTIVE' ? true : false" matInput [formControl]="fileCtrl" placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.ALLEGAFILE' | translate}}">
          <mat-icon *ngIf="fileCtrl.value != null" matSuffix (click)="clearFile()">clear
          </mat-icon>
        </mat-form-field>
        <button type="button" mat-button color="primary" class="outline-button"
          (click)="onFileUploadClick()">{{'RICHIEDIPAGAMENTOAVANZATO.BOTTONESFOGLIA' |
          translate}}</button>
        <input [disabled]="paymentState==='ACTIVE' ? true : false" #inputfile type="file" ng2FileSelect [uploader]="uploader" style="display: none;"
          (change)="caricaAllegato()" />
      </div>
    </span>
  </div>

  <!--anagrafica aggiuntiva-->
  <ng-container *ngIf="!isPaymentOpen">
    <div class="flex-row" *ngIf="paymentType === 'RECURRENT' || paymentType === 'DEFERRED'">
      <span class="flex-col">
        <mat-form-field class="leftInputField">
          <input matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.PIVACODFISC' | translate}}" formControlName="piva">
        </mat-form-field>
      </span>

      <span class="flex-col">
        <div class="rightInputField" style="display: flex; justify-content: space-between;">
          <mat-form-field class="uploadFile">
            <input matInput [formControl]="fileCtrl" placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.ALLEGAFILE' | translate}}">
            <mat-icon *ngIf="fileCtrl.value !== null" matSuffix (click)="clearFile()">clear
            </mat-icon>
          </mat-form-field>
          <button type="button" mat-button color="primary" class="outline-button"
            (click)="onFileUploadClick()">{{'RICHIEDIPAGAMENTOAVANZATO.BOTTONESFOGLIA' |
            translate}}</button>
          <input #inputfile type="file" ng2FileSelect [uploader]="uploader" style="display: none;"
            (change)="caricaAllegato()" />
        </div>
      </span>
    </div>

    <div class="flex-row" *ngIf="paymentType === 'SINGLE'">
      <span class="flex-col">
        <mat-form-field class="leftInputField">
          <input [readonly]="paymentState==='ACTIVE' ? true : false" matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.PIVACODFISC' | translate}}" formControlName="piva">
        </mat-form-field>
      </span>

      <span class="flex-col">
        <mat-form-field *ngIf="emailControl" class="rightInputField">
          <input [readonly]="paymentState==='ACTIVE' ? true : false" matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.EMAIL' | translate}}" formControlName="email">
          <mat-error *ngIf="emailControl?.hasError('email')" [innerHTML]="'MESSAGE.VALID_EMAIL' | translate">
          </mat-error>
        </mat-form-field>

        <span *ngIf="phoneControl && !emailControl" class="rightInputField">
          <mat-form-field style="width: 20%;">
            <mat-select [disabled]="paymentState==='ACTIVE' ? true : false" placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code">
              <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
                <img alt="flag icon" class="flagCountry"
                  src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                <span>{{ type.CODE }}</span>
                <span>{{ type.DESC }}</span>
                <span> - {{ type.COUNTRY | uppercase }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="telephone-with-prefix">
            <input [readonly]="paymentState==='ACTIVE' ? true : false" formControlName="smartphone" type="tel" matInput
              placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.TELEFONO' | translate}}">
          </mat-form-field>
        </span>
      </span>
    </div>
    <div class="flex-row">
      <span class="flex-col">
        <div *ngIf="phoneControl && emailControl" class="leftInputField">
          <mat-form-field style="width: 20%;">
            <mat-select [disabled]="paymentState==='ACTIVE' ? true : false" placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.COUNTRY' | translate}}" formControlName="area_code">
              <mat-option *ngFor="let type of countryPrefix" [value]="type.CODE">
                <img alt="flag icon" class="flagCountry"
                  src="{{ ASSETS_BASE + 'svg/flags/4x3/' + type.COUNTRY + '.svg' }}" />
                <span>{{ type.CODE }}</span>
                <span>{{ type.DESC }}</span>
                <span> - {{ type.COUNTRY | uppercase }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="telephone-with-prefix" style="margin-right: 0;">
            <input [readonly]="paymentState==='ACTIVE' ? true : false" formControlName="smartphone" type="tel" matInput
              placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.TELEFONO' | translate}}">
          </mat-form-field>
        </div>
      </span>
    </div>

    <div class="flex-row" *ngIf="paymentType === 'RECURRENT' || paymentType === 'DEFERRED'">
      <span class="flex-col">
        <mat-form-field class="leftInputField">
          <input matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.RECURRENTS_ERROR_EMAIL_NOTIFY' | translate}}" formControlName="recurrents_merchant_email_error">
          <mat-error *ngIf="recurrents_merchant_email_error?.hasError('email')" [innerHTML]="'MESSAGE.VALID_EMAIL' | translate">
          </mat-error>
        </mat-form-field>
      </span>
    </div>
   
  </ng-container>
</div>
