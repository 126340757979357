import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { Dynamicfield } from '../../_models/dynamicfield';


@Component({
  selector: 'app-dynamic-fields',
  templateUrl: './dynamic-fields.component.html',
  styleUrls: ['./dynamic-fields.component.scss']
})
export class DynamicFieldsComponent {

  form: UntypedFormGroup;
  dynamicfields: UntypedFormArray;
  types = ['text', 'date', 'number', 'tel', 'email', 'url', 'hidden'];

  count: number;

  private _dynamicValues: Array<Dynamicfield>;
  set dynamicValues(value: Array<Dynamicfield>){
    this.dynamicfields = this.form.get('dynamicfields') as UntypedFormArray;
    this._dynamicValues = value;
    if (value){
      for (let j = 0; j < value.length; j++ ){
        if (this.dynamicfields.length < value.length) {
          this.dynamicfields.push(this.createGroup(j + 1));
        }
        this.count = this.dynamicfields.length;
        this.dynamicfields.controls[j]['controls'].type.setValue(value[j].type);
        this.dynamicfields.controls[j]['controls'].name.setValue(value[j].name);
        this.dynamicfields.controls[j]['controls'].label.setValue(value[j].label);
        this.dynamicfields.controls[j]['controls'].isRequired.setValue(value[j].isRequired ? value[j].isRequired.toString() : 'false');
        this.dynamicfields.controls[j]['controls'].isEnabled.setValue(value[j].isEnabled ? value[j].isEnabled.toString() : 'false');
        this.dynamicfields.controls[j]['controls'].isVisible.setValue(value[j].isVisible ? value[j].isVisible.toString() : 'false');
        this.dynamicfields.controls[j]['controls'].ricevuta.setValue(value[j].ricevuta ? value[j].ricevuta.toString() : 'false');
        this.dynamicfields.controls[j]['controls'].default.setValue(value[j].default);
      }
    }
  }
  get dynamicValues(): Array<Dynamicfield> {
    return this._dynamicValues;
  }


  get formData() { return <UntypedFormArray>this.form.get('dynamicfields'); }

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      dynamicfields: this.formBuilder.array([])
    });
    this.dynamicfields = this.form.get('dynamicfields') as UntypedFormArray;

  }

  createGroup(index: number): UntypedFormGroup {
    let nameField = 'addInfo' + index;
    const formGroup = this.formBuilder.group({
      type: ['', [Validators.required]],
      name: [nameField, [Validators.required]],
      label: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9/\\-?:().,'+\\s]+$") ]],
      isRequired: [null],
      isEnabled: [null],
      isVisible: [null],
      ricevuta: [null],
      default: [null]
    });
    formGroup.controls.type.valueChanges.subscribe(
      data => {
        if (data === 'hidden') {
          formGroup.controls.isRequired.disable();
          formGroup.controls.isRequired.setValue('false');
          formGroup.controls.isEnabled.disable();
          formGroup.controls.isEnabled.setValue('false');
          formGroup.controls.isVisible.disable();
          formGroup.controls.isVisible.setValue('false');
          formGroup.controls.ricevuta.disable();
          formGroup.controls.ricevuta.setValue('false');
          formGroup.controls.label.setValue('');
          formGroup.controls.label.disable();
          formGroup.controls.default.setValidators([Validators.required, Validators.pattern("^[A-Za-z0-9/\\-@?:().,'+\\s]+$")]);
          formGroup.controls.default.updateValueAndValidity();
          formGroup.controls.default.markAsDirty();
          formGroup.controls.default.markAsTouched();

          this.form.updateValueAndValidity();
        } else {
          formGroup.controls.isRequired.enable();
          formGroup.controls.isRequired.setValue(null);
          formGroup.controls.isEnabled.enable();
          formGroup.controls.isEnabled.setValue(null);
          formGroup.controls.isVisible.enable();
          formGroup.controls.isVisible.setValue(null);
          formGroup.controls.ricevuta.enable();
          formGroup.controls.ricevuta.setValue(null);
          formGroup.controls.label.enable();
          formGroup.controls.default.setValidators([Validators.pattern("^[A-Za-z0-9/\\-@?:().,'+\\s]+$")]);
          formGroup.controls.default.updateValueAndValidity();

        }
      }
    );

    formGroup.controls.isRequired.valueChanges.subscribe(
      data => {
        if (data === 'true') {
          formGroup.controls.isEnabled.disable();
          formGroup.controls.isEnabled.setValue('true');
          formGroup.controls.isVisible.disable();
          formGroup.controls.isVisible.setValue('true');
        } else {
          formGroup.controls.isEnabled.enable();
          formGroup.controls.isEnabled.setValue(null);
          formGroup.controls.isVisible.enable();
          formGroup.controls.isVisible.setValue(null);
        }
      }
    );

    formGroup.controls.isEnabled.valueChanges.subscribe(
      data => {
        if (data === 'true') {
          formGroup.controls.isVisible.disable();
          formGroup.controls.isVisible.setValue('true');
        } else {
          formGroup.controls.isVisible.enable();
          formGroup.controls.isVisible.setValue(null);
        }
      }
    )
    return formGroup;
  }

  addItem(): void {
    this.dynamicfields = this.form.get('dynamicfields') as UntypedFormArray;
    if (this.dynamicfields.length < 5 ) {
      this.dynamicfields.push(this.createGroup(this.dynamicfields.length + 1));
      this.count = this.dynamicfields.length;
    }
  }

  removeItem(i: number): void {
    this.dynamicfields = this.form.get('dynamicfields') as UntypedFormArray;
    this.dynamicfields.removeAt(i);
    this.count = this.dynamicfields.length;
    for (let j = 0; j <= this.dynamicfields.length - 1; j++ ){
      this.dynamicfields.controls[j]['controls'].name.setValue("addInfo" + (j+1));
    }
  }

  getControls(frmGrp: UntypedFormGroup, key: string) {
    return (<UntypedFormArray>frmGrp.controls[key]).controls;
  }

}
