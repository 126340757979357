import { environment } from "../environments/environment";
import { LoginComponent } from "./login/login.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { SupportModule } from "@mobilesoft/support";

import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSortModule } from "@angular/material/sort";
import { MatChipsModule } from "@angular/material/chips";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatGridListModule } from "@angular/material/grid-list";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { FileUploadModule } from "ng2-file-upload";
import { FlexLayoutModule } from "@angular/flex-layout";

import { AuthService } from "./_services/auth.service";
import { ImportGuard } from "./_guard/import.guard";

import { LoaderService } from "./_services/loader.service";
import { DialogComponent } from "./_components/dialog/dialog.component";
import { LoaderComponent } from "./_components/loader/loader.component";
import { CustomerService } from "./_services/customer.service";
import { ReferentService } from "./_services/referent.service";
import { MerchantService } from "./_services/merchant.service";

import { UserService } from "./_services/user.service";
import { TemplateService } from "./_services/template.service";
import { PaymentService } from "./_services/payment.service";

import { RequestInterceptor } from "./_interceptors/request-interceptor.service";

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { PreviewComponent } from "./_components/preview/preview.component";
import { QrCodeDialogComponent } from "./_components/qrCodeDialog/qrCodeDialog.component";
import { TerminalService } from "./_services/terminal.service";
import { HeaderFooterComponent } from "./admin/headerFooter/headerFooter.component";

import { MerchantProfileComponent } from "./admin/home/merchantManagement/merchantProfile/merchantProfile.component";
import { NewUserComponent } from "./admin/home/userManagement/newUser/newUser.component";
import { UserProfileComponent } from "./admin/home/userManagement/userProfile/userProfile.component";
import { ClientsComponent } from "./admin/home/clientsManagement/clients/clients.component";
import { NewClientsComponent } from "./admin/home/clientsManagement/newClients/newClients.component";
import { OperationsComponent } from "./admin/home/paymentManagement/operations/operations.component";
import { RequestPaymentEasyComponent } from "./admin/home/paymentManagement/requestPaymentEasy/requestPaymentEasy.component";
import { MerchantTechnicalDataComponent } from "./admin/home/merchantManagement/merchantTechnicalData/merchantTechnicalData.component";
import { BulkService } from "./_services/bulk.service";
import { PreviewRicComponent } from "./_components/previewRic/previewRic.component";
import { RecurrentService } from "./_services/recurrent.service";
import { RecurrentPrevComponent } from "./_components/recurrentPrev/recurrentPrev.component";
import { MatMomentDateModule } from "@angular/material-moment-adapter";

import { ServiceWorkerModule } from "@angular/service-worker";
import {
  NgxCurrencyModule,
  CurrencyMaskInputMode,
  CURRENCY_MASK_CONFIG
} from "ngx-currency";
import { DialogCircuitiComponent } from "./_components/dialog-circuiti/dialog-circuiti.component";

import { ImportOperationsComponent } from "./admin/home/paymentManagement/import-operations/import-operations.component";
import { MailTemplateComponent } from "./admin/home/merchantManagement/merchantTechnicalData/mail-template/mail-template.component";
import { DialogCaricamentiComponent } from "./_components/dialog-caricamenti/dialog-caricamenti.component";
import { ImportOperationsPaymentsComponent } from "./admin/home/paymentManagement/import-operations/import-operations-payments/import-operations-payments.component";
import { BreadcrumbsComponent } from "./_components/breadcrumbs/breadcrumbs.component";
import { OperationWidgetComponent } from "./_components/operation-widget/operation-widget.component";

import { Angulartics2Module } from "angulartics2";
import { DatepickerModalComponent } from "./_components/datepicker-modal/datepicker-modal.component";
import { UserDataComponent } from "./_components/user-data/user-data.component";
// import { from } from 'rxjs';
import { ProfilesComponent } from "./admin/home/profilesManagement/profiles/profiles.component";
import { ProfileComponent } from "./admin/home/profilesManagement/profile/profile.component";
import { DialogLinkComponent } from "./_components/dialog-link/dialog-link.component";
import { SelfEnrollmentComponent } from "./_components/self-enrollment/self-enrollment.component";
import { MerchantDataComponent } from "./_components/merchant-data/merchant-data.component";
import { SocialFormComponent } from "./_components/social-form/social-form.component";
import { LogoFormComponent } from "./_components/logo-form/logo-form.component";
import { ReferentDataComponent } from "./_components/referent-data/referent-data.component";
import { OperationsDetailFirstComponent } from "./_components/operations-detail-first/operations-detail-first.component";
import { OperationsDetailSecondComponent } from "./_components/operations-detail-second/operations-detail-second.component";
import { OperationsFilterComponent } from "./_components/operations-filter/operations-filter.component";
import { OperationsDetailThirdComponent } from "./_components/operations-detail-third/operations-detail-third.component";
import { OperationsWidgetsComponent } from "./_components/operations-widgets/operations-widgets.component";
import { OperationButtonsComponent } from "./_components/operation-buttons/operation-buttons.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { DetailOverlayComponent } from "./_components/detail-overlay/detail-overlay.component";
import { DETAIL_OVERLAY_DATA } from "./_cdk/overlayData";
import { OperationsDetailStaticComponent } from "./_components/operations-detail-static/operations-detail-static.component";
import {
  OverlayContainer,
  FullscreenOverlayContainer,
} from "@angular/cdk/overlay";
import { OperationsMobileSliderComponent } from "./_components/operations-mobile-slider/operations-mobile-slider.component";
//import { PageSliderModule } from 'ng2-page-slider';
import { MatTabsModule } from "@angular/material/tabs";
import { HoldingMerchantsComponent } from "./_components/holding-merchants/holding-merchants.component";
import { DynamicFieldsComponent } from "./_components/dynamic-fields/dynamic-fields.component";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { ScheduleReminderComponent } from "./_components/schedule-reminder/schedule-reminder.component";
import { CdkTableModule } from "@angular/cdk/table";
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { GridComponent } from "./_components/grid/grid.component";
import { GridOpenComponent } from "./_components/grid-open/grid-open.component";
import { OperationsDashboardComponent } from "./_components/operations-dashboard/operations-dashboard.component";
import { CodemirrorModule } from "@ctrl/ngx-codemirror";
import { DialogCountryCodeComponent } from "./_components/dialog-country-code/dialog-country-code.component";
import { TemplateFormComponent } from "./_components/template-form/template-form/template-form.component";
import { TemplateOverlayComponent } from "./_components/template-overlay/template-overlay/template-overlay.component";
import { TemplateCodeComponent } from "./_components/template-code/template-code/template-code.component";
//import { SafePipeModule } from 'safe-pipe';
import { MobilePreviewComponent } from "./_components/mobile-preview/mobile-preview/mobile-preview.component";
import { CKEditorModule } from "ngx-ckeditor";
import { TemplatesComponent } from "./admin/home/merchantManagement/templates/templates.component";
import { TemplateRejectDialogComponent } from "./_components/template-reject-dialog/template-reject-dialog.component";
import { MerchantsComponent } from "./admin/home/merchantManagement/merchants/merchants.component";
import { UsersComponent } from "./admin/home/userManagement/users/users.component";
import { PaymentRequestComponent } from "./admin/home/paymentManagement/payment-request/payment-request.component";
import { PaymentWizardComponent } from "./_components/payment-wizard/payment-wizard.component";
import { RequestCustomerDataComponent } from "./_components/request-customer-data/request-customer-data.component";
import { TabMenuComponent } from "./_components/tab-menu/tab-menu.component";
import { ImportRequestComponent } from "./admin/home/paymentManagement/import-request/import-request.component";
import { MyCheckboxComponent } from "./_components/my-checkbox/my-checkbox.component";
import { OptHoverDirective } from "./_directives/opt-hover.directive";
import { MyRadioGroupComponent } from "./_components/my-radio-group/my-radio-group.component";
import { ChangePasswordComponent } from "./_components/change-password/change-password.component";
import { FooterComponent } from "./_components/footer/footer.component";
import { RequestRecurrentsComponent } from "./_components/request-recurrents/request-recurrents.component";
import { RouterModule } from "@angular/router";
import { RequestManagerComponent } from "./admin/home/paymentManagement/request-manager/request-manager.component";
import { HeaderComponent } from "./_components/header/header.component";
import { MainViewComponent } from "./admin/main-view/main-view.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MomentPipe } from "./_helpers/moment.pipe";
import { PaymentWizardHeaderComponent } from "./_components/payment-wizard-header/payment-wizard-header.component";
import { RequestExtraFieldsComponent } from "./_components/request-extra-fields/request-extra-fields.component";
import { HoldingMerchantsTableComponent } from "./_components/holding-merchants-table/holding-merchants-table.component";
import { HoldingHomeComponent } from "./admin/holding-home/holding-home.component";
import { HoldingFilterTableComponent } from "./_components/holding-filter-table/holding-filter-table.component";
import { AnchorCardComponent } from "./_components/anchor-card/anchor-card.component";
import { NavigationConsoleComponent } from "./_components/navigation-console/navigation-console.component";
import { HoldingOperationWidgetComponent } from "./_components/holding-operation-widget/holding-operation-widget.component";
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { SafePipe } from "./_helpers/safe.pipe";
import { UtilsComponent } from "./admin/utils/utils.component";
import { OperationsFilterRecurrentComponent } from './_components/operations-filter-recurrent/operations-filter-recurrent.component';
import { OperationsFilterDeferredComponent } from './_components/operations-filter-deferred/operations-filter-deferred.component';
import { DialogBookmarkComponent } from './_components/dialog-bookmark/dialog-bookmark.component';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.TRANSLATE_FILE, ".json");
}

export function playerFactory() {
  return player;
}

registerLocaleData(localeIt, "it");

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LoaderComponent,
        DialogComponent,
        HeaderFooterComponent,
        OperationsComponent,
        MerchantProfileComponent,
        ClientsComponent,
        UserProfileComponent,
        RequestPaymentEasyComponent,
        NewClientsComponent,
        MerchantTechnicalDataComponent,
        NewUserComponent,
        QrCodeDialogComponent,
        PreviewComponent,
        PreviewRicComponent,
        RecurrentPrevComponent,
        DialogCircuitiComponent,
        ImportOperationsComponent,
        MailTemplateComponent,
        DialogCaricamentiComponent,
        ImportOperationsPaymentsComponent,
        BreadcrumbsComponent,
        OperationWidgetComponent,
        DatepickerModalComponent,
        UserDataComponent,
        ProfilesComponent,
        ProfileComponent,
        DialogLinkComponent,
        SelfEnrollmentComponent,
        MerchantDataComponent,
        SocialFormComponent,
        LogoFormComponent,
        ReferentDataComponent,
        OperationsDetailFirstComponent,
        OperationsDetailSecondComponent,
        OperationsFilterComponent,
        OperationsDetailThirdComponent,
        OperationsWidgetsComponent,
        OperationButtonsComponent,
        DetailOverlayComponent,
        OperationsDetailStaticComponent,
        OperationsMobileSliderComponent,
        HoldingMerchantsComponent,
        DynamicFieldsComponent,
        ScheduleReminderComponent,
        GridComponent,
        GridOpenComponent,
        OperationsDashboardComponent,
        DialogCountryCodeComponent,
        TemplateFormComponent,
        TemplateOverlayComponent,
        TemplateCodeComponent,
        MobilePreviewComponent,
        TemplatesComponent,
        TemplateRejectDialogComponent,
        MerchantsComponent,
        UsersComponent,
        ChangePasswordComponent,
        PaymentRequestComponent,
        PaymentWizardComponent,
        RequestCustomerDataComponent,
        RequestRecurrentsComponent,
        TabMenuComponent,
        ImportRequestComponent,
        MyCheckboxComponent,
        OptHoverDirective,
        MyRadioGroupComponent,
        FooterComponent,
        RequestManagerComponent,
        HeaderComponent,
        MainViewComponent,
        MomentPipe,
        PaymentWizardHeaderComponent,
        RequestExtraFieldsComponent,
        HoldingMerchantsTableComponent,
        HoldingHomeComponent,
        HoldingFilterTableComponent,
        AnchorCardComponent,
        NavigationConsoleComponent,
        HoldingOperationWidgetComponent,
        SafePipe,
        UtilsComponent,
        OperationsFilterRecurrentComponent,
        OperationsFilterDeferredComponent,
        DialogBookmarkComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        Angulartics2Module.forRoot(),
        FlexLayoutModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatToolbarModule,
        MatTooltipModule,
        MatIconModule,
        MatSortModule,
        MatExpansionModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatStepperModule,
        MatRadioModule,
        MatCheckboxModule,
        MatGridListModule,
        FileUploadModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatMomentDateModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatDialogModule,
        MatInputModule,
        LottieModule.forRoot({ player: playerFactory }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgxCurrencyModule,
        DragDropModule,
        SupportModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
        OverlayModule,
        //PageSliderModule,
        MatTabsModule,
        CdkTableModule,
        CodemirrorModule,
        CKEditorModule,
        NgxUsefulSwiperModule,
        NgxMaterialTimepickerModule,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
        AuthService,
        ImportGuard,
        LoaderService,
        CustomerService,
        ReferentService,
        RecurrentService,
        MerchantService,
        TemplateService,
        TerminalService,
        UserService,
        PaymentService,
        BulkService,
        { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig },
        { provide: DETAIL_OVERLAY_DATA, useValue: {} },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    ],
    bootstrap: [AppComponent],
    exports: [DragDropModule]
})
export class AppModule {}
