<div style="text-align: center; width: 75px; position: relative; margin-right: 10px;">
    <div id="description" class="checkboxFont">{{'PAYMENT_WIZARD.' + (name | uppercase) | translate }}</div>
    <button color="primary" aria-roledescription="checkbox" aria-labelledby="description" appOptHover [value]="value"
    [disabled]="disabled" class="checkboxButton"
    mat-icon-button (click)="_toggleBox()">
        <mat-icon class="wizardIcon"
        [svgIcon]="name" ></mat-icon>
        <mat-icon color="primary" matTooltip="{{'PAYMENT_WIZARD.' + (name | uppercase) + '_TOOLTIP' | translate }}" 
        [ngStyle]="{ 'display': value ? 'inline-block' : 'none'}" class="checkedIcon wizardIcon" svgIcon="checked"></mat-icon>
    </button>
</div>
