
<div  [formGroup]="form">
  <div formArrayName="dynamicfields" *ngFor="let item of getControls(form, 'dynamicfields'); let i = index;">
      <div class="flex-row" [formGroupName]="i">


          <mat-form-field class="flex-col sm-field">
            <mat-select [placeholder]="'RICHIEDIPAGAMENTOAVANZATO.TYPE' | translate" required formControlName="type">
              <mat-option *ngFor="let type of types" [value]="type">{{ 'DYNAMIC.' + type.toUpperCase() | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="flex-col lg-field">
            <input matInput required formControlName="label" [placeholder]="'RICHIEDIPAGAMENTOAVANZATO.LABEL' | translate">
            <mat-error *ngIf="formData.controls[i].controls['label'].hasError('pattern')" [innerHTML]="'MESSAGE.PATTERN_ERROR' | translate"></mat-error>
          </mat-form-field>

          <mat-form-field class="flex-col sm-field">
            <mat-select [placeholder]="'RICHIEDIPAGAMENTOAVANZATO.REQUIRED' | translate" required formControlName="isRequired">
              <mat-option value="true">true</mat-option>
              <mat-option value="false">false</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field class="flex-col sm-field">
            <mat-select [placeholder]="'RICHIEDIPAGAMENTOAVANZATO.ENABLED' | translate" required formControlName="isEnabled">
              <mat-option value="true">true</mat-option>
              <mat-option value="false">false</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="flex-col sm-field">
            <mat-select [placeholder]="'RICHIEDIPAGAMENTOAVANZATO.VISIBLE' | translate" required formControlName="isVisible">
              <mat-option value="true">true</mat-option>
              <mat-option value="false">false</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="flex-col sm-field">
            <mat-select [placeholder]="'RICHIEDIPAGAMENTOAVANZATO.RECEIPT' | translate" required formControlName="ricevuta" matTooltip="{{'RICHIEDIPAGAMENTOAVANZATO.RICEVUTA' | translate}}" matTooltipPosition="below">
              <mat-option value="true">true</mat-option>
              <mat-option value="false">false</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field  class="flex-col lg-field" style="padding-right: 0;">
            <input matInput type="text" formControlName="default" [placeholder]="'RICHIEDIPAGAMENTOAVANZATO.DEFAULT' | translate">
            <mat-error *ngIf="formData.controls[i].controls['default'].hasError('pattern')" [innerHTML]="'MESSAGE.PATTERN_ERROR' | translate"></mat-error>
          </mat-form-field>

          <div class="flex-col sm-field action-bar">
            <button mat-icon-button color="primary" (click)="removeItem(i)">
              <mat-icon>remove</mat-icon>
            </button>

            <button mat-icon-button color="primary" (click)="addItem()" *ngIf="i == (count - 1) && count < 5 && getControls(form, 'dynamicfields')[i].valid">
              <mat-icon>add</mat-icon>
            </button>
          </div>
      </div>
  </div>
</div>

