
// eslint-disable-next-line no-restricted-imports
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Referent } from '../_models/referent';
import { AuthService } from './auth.service';

@Injectable()
export class ReferentService {
  private readonly REFERENT_URL: string = environment.BASE_URL + 'referent';

  constructor(private http: HttpClient, private authService: AuthService) { }
  /**
   * Metodo per recuperare la lista dei referent
   * @param referent (string) - nome del referent per effetture la ricerca
   */
  list(referent: string): Observable <{} | Referent[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');



    let params = new HttpParams();
    if (referent != null && referent !== undefined && referent !== '') {
      params = params.set('name', referent);
    }
    params = params.set('sort', '-createdAt');
    return this.http.get<{} | Referent[]>(this.REFERENT_URL, { headers, params });
  }

  /**
   * Metodo per creare un nuovo referent
   * @param referent Model referent del cliente
   */
  create(referent: Referent): Observable <{} | Referent> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.post(this.REFERENT_URL, referent, { headers });
  }


  searchId(id: string): Observable <{} | Referent[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');



    let params = new HttpParams();

      params = params.set('_id', id);

    return this.http.get<{} | Referent[]>(this.REFERENT_URL, { headers, params });
  }


  update(_id: string, referent: Referent): Observable <{} | Referent[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.put(this.REFERENT_URL + '/' + _id, referent, { headers });
  }

}
