import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Output, Input, EventEmitter } from '@angular/core';
import { Reminder } from '../../_models/reminder';
import * as moment from 'moment';
import DateHelper from '../../_helpers/date.helper';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ReminderService } from '../../_services/reminder.service';


@Component({
  selector: 'app-schedule-reminder',
  templateUrl: './schedule-reminder.component.html',
  styleUrls: ['./schedule-reminder.component.scss']
})
export class ScheduleReminderComponent implements OnInit, AfterViewInit {
  reminders: Reminder[] = [];
  dataSource: MatTableDataSource<Reminder>;

  pickerForm: UntypedFormGroup

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() paymentId: string;
  @Input() set inputData(data: Reminder[] | any[]) {
    if (data) {
      this.reminders = data;

      if (this.reminders.length > 0) {
        this.dataSource = new MatTableDataSource(this.reminders);
        this.dataSource.paginator = this.paginator;
      }
    }
  }
  @Output() outputData: EventEmitter<any> = new EventEmitter();
  @Output() pickerChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private reminderService: ReminderService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) {
    this.pickerForm = this.fb.group(
      {
        date_start: [],
        date_end: []
      }
    );
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.reminders.length > 0) {
      this.setDatePicker(false);
      this.cdr.detectChanges();
    }
  }

  dateConvert(execution_date) {
    const date = new Date(execution_date);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const day  = date.getDate().toString();
    return `${day}/${month}/${year}`;
  }

  langConvert(state) {
    const type = state === 'ACTIVE' ? 'REMINDER.ACTIVE' : 'REMINDER.COMPLETED';
    return this.translate.instant(type);
  }

  setDatePicker(changed) {
    const begin = this.reminders[0];
    const end = this.reminders[this.reminders.length - 1];
    if (begin && end) {
      this.pickerForm.controls.date_start.setValue(new Date(begin.execution_date).toISOString());
      this.pickerForm.controls.date_end.setValue(new Date(end.execution_date).toISOString());
      this.pickerChanged.emit(changed);
    }
  }

  createSchedulerData(changed) {
    if (this.pickerForm.value.date_start && this.pickerForm.value.date_end) {
      const reminders = [];
      const days = DateHelper.dateDiff(this.pickerForm.value.date_start, this.pickerForm.value.date_end);

      for (let i = 0; i < days; i++) {
        const beginDate = moment(this.pickerForm.value.date_start.toString());
        const date = beginDate.add(i, 'days');
        const reminder = { state: 'ACTIVE', execution_date: DateHelper.stripOffset(date.toString()) };
        reminders.push(<Reminder>reminder);
      }
      this.outputData.emit(reminders);
      this.pickerChanged.emit(changed);
    }
  }

  delete(id: string, i: any) {
    if (id) {
      this.reminders = this.reminders.filter(reminder => reminder._id !== id);
      this.reminderService.delete(id, this.paymentId).subscribe(() => {
        this.dataSource.data = this.reminders;
        this.outputData.emit(this.reminders);
      });
    } else {
      this.reminders = this.reminders.filter((reminder, index) => index !== i);
      this.dataSource.data = this.reminders;
      this.outputData.emit(this.reminders);
    }
    if (this.reminders.length === 0) {
      this.pickerForm.reset();
    }
    this.setDatePicker(false);
  }

}
