import { User } from "./user";
import { Dynamicfield } from "./dynamicfield";
import { Reminder } from './reminder';
import { Merchant } from "./merchant";
import * as numeral from 'numeral';
import { UntypedFormGroup, UntypedFormArray, FormArray } from '@angular/forms';
import * as moment from "moment";

export class Payment {
    _id: string;
    payment_type: string;
    isPaymentOpen: boolean;
    isAmountFree: boolean;
    isDonation: boolean;
    isFastCheckout: boolean;
    isQueryString: boolean;
    isNotificationRequired: boolean;
    pagamenti: any;
    merchant: any;
    recurrent_plan_id: string;
    recurrents: Array<any>;
    name: string;
    operation_type: string;
    email: string;
    smartphone: string;
    transaction_type: string;
    amount: string;
    currency: string;
    template: any;
    piva: string;
    description: string;
    language: string;
    to_date: string;
    terminal: any ;
    notify_url: string;
    error_url: string;
    state: string;
    shop_id: string;
    notes: string;
    payment_link: string;
    createdAt: string;
    area_code: string;
    attachment: any ;
    informations: any [];
    transaction: any [];
    isPayInstrToken: boolean;
    outcome: any [];
    async_outcome: any [];
    mail_status: any [];
    sms_status: any;
    qrcode: any;
    user: User;
    dynamic_informations: Dynamicfield[];
    reminders: any[];
    isCloned: boolean;
    recurrents_type: string;
    recurrents_status: string;
    recurrents_frequency: string;
    recurrents_frequency_interval: number;
    recurrents_rounds: number;
    recurrents_merchant_email_error: string;
    recurrents_amount: string;
    recurrents_first_date: string;

    constructor(form?: UntypedFormGroup, extraData?: any, merchant?: Merchant, allegato?: any) {

        this.informations = [];
        this.transaction = [];
        this.outcome = [];
        this.async_outcome = [];
        this.reminders = [];


        if (!form) {return; }


        let formData = Object.assign({}, form.value, extraData)
        this.name = formData.name;
        this.email = formData.email;
        this.area_code = formData.area_code;
        this.smartphone = formData.smartphone;
        this.piva = formData.piva;
        this.isPaymentOpen = formData.isPaymentOpen;
        this.isAmountFree = formData.isAmountFree;
        this.isDonation = formData.isDonation;
        this.isFastCheckout = formData.isFastCheckout;
        this.isQueryString = formData.isQueryString;
        this.isNotificationRequired = formData.isNotificationRequired;
        this.payment_type = formData.payment_type;
        this.operation_type = formData.operation_type;
        this.transaction_type = formData.transaction_type;
        this.currency = formData.currency;
        this.description = formData.description;
        this.language = formData.language;
        this.terminal = merchant.terminal[0];
        this.notify_url = 'https://worldlineitalia.it';
        this.error_url = 'https://worldlineitalia.it';
        this.notes = formData.notes;
        this.attachment = allegato;
        this.informations = formData.informations;
        this.merchant = merchant._id;
        this.template = formData.template;
        this.recurrents_merchant_email_error = formData.recurrents_merchant_email_error;

        //EXPIRATION DATE AND HOUR
        let expiration = moment(formData.to_date);
        let offset = expiration.utcOffset();
        let time = formData.expiration_time.split(':');
        expiration.hours(time[0]);
        expiration.minutes(time[1]);

        if (formData.recurrentForm) {

            if (formData.recurrentForm.firstRecurrentLinkDate) {
                this.recurrents_first_date = moment(formData.recurrentForm.firstRecurrentLinkDate)
                .endOf("day")
                .toISOString();
            }

            if (formData.recurrentForm.frequency) {
                const enumFrequency = [
                    { value: 2, viewValue: 'Bisettimanale', type: 'week', interval: 2 },
                    { value: 1, viewValue: 'Mensile', type: 'month', interval: 1 },
                    { value: 3, viewValue: 'Trimestrale', type: 'month', interval: 3  },
                    { value: 6, viewValue: 'Semestrale', type: 'month', interval: 6  },
                    { value: 12, viewValue: 'Annuale', type: 'month', interval: 12  }
                ];

                let frequency = enumFrequency.find(el => el.value === formData.recurrentForm.frequency);
                this.recurrents_frequency = frequency.type;
                this.recurrents_frequency_interval = frequency.interval;
            }

            if (!isNaN(formData.recurrentForm.rounds)) {
                this.recurrents_rounds = formData.recurrentForm.rounds;

                if (formData.payment_type !== 'DEFERRED') {
                    if (formData.recurrentForm.rounds !== 0) {
                      this.recurrents_type = 'DEFAULT';
                    } else {
                        this.recurrents_type = 'REPEATED';
                    }
                  }
            }

            if (/RECURRENT/.test(formData.payment_type)) {
                this.recurrents_status = 'OPEN';
                this.recurrents_amount = numeral(formData.recurrentForm.amount).format('0.00')
            }

            if (/DEFERRED/.test(formData.payment_type)) {
                this.recurrents_status = 'OPEN';
                this.recurrents_amount = numeral(formData.recurrentForm.amount / formData.recurrentForm.rounds).format('0.00')
            }
        }

        this.to_date = expiration.add(offset, 'm').toISOString();
        if (this.amount === 'NO_TERM') {
            this.amount = '0.0';
        } else if (!formData.isAmountFree || (formData.isAmountFree && formData.isQueryString)) {
            this.amount = numeral(formData.amount).format('0.00');
        } else {
            this.amount = '0.0';
        }
        if (merchant.isPayInstrToken && !formData.isPaymentOpen) {
            this.isPayInstrToken = true;
        } else {
            this.isPayInstrToken = false;
        }

        for (let key in this) {
          if (this[key] === undefined || this[key] === null){
            delete this[key]
          }
        }

        const dynamic = form.get('dynamic_informations') as FormArray;
        if (dynamic) {
        this.dynamic_informations = dynamic.getRawValue();
        }
    }

    //METODO CHE FUNGE DA "CONSTRUCTOR ALTERNATIVO" PER MANIPOLARE I DATI DA PASSARE ALLA PUT PER LA MODIFICA DEL PAGAMENTO
    public generateUpdateObj(form: UntypedFormGroup, extraData: any, uploadCtrl: string, allegato: any, merchant: Merchant) {
        let formData = Object.assign({}, form.value, extraData);
        //const formData = form.value;
        this.name = formData.name;
        this.email = formData.email;
        this.area_code = formData.area_code;
        this.smartphone = formData.smartphone;
        this.piva = formData.piva;
        this.isPaymentOpen = formData.isPaymentOpen;
        this.isAmountFree = formData.isAmountFree;
        this.isDonation = formData.isDonation;
        this.isFastCheckout = formData.isFastCheckout;
        this.isQueryString = formData.isQueryString;
        this.isNotificationRequired = formData.isNotificationRequired;
        this.payment_type = formData.payment_type;
        this.operation_type = formData.operation_type;
        this.transaction_type = formData.transaction_type;
        this.currency = formData.currency;
        this.description = formData.description;
        this.language = formData.language;
        //this.to_date = formData.to_date;
        this.notes = formData.notes;
        this.informations = formData.informations;
        this.merchant = merchant._id;
        this.template = formData.template;
        
        //EXPIRATION DATE AND HOUR
        let expiration = moment(formData.to_date);
        let offset = expiration.utcOffset();
        let time = formData.expiration_time.split(':');
        expiration.hours(time[0]);
        expiration.minutes(time[1]);
        this.to_date = expiration.add(offset, 'm').toISOString();

        if (!uploadCtrl) {
            this.attachment = {};
        }

        if (allegato) {
            this.attachment = allegato;
        }

        if (!formData.isAmountFree || (formData.isAmountFree && formData.isQueryString)) {
            this.amount = numeral(formData.amount).format('0.00');
        } else {
            this.amount = '0.0';
        }
        if (merchant.isPayInstrToken && !formData.isPaymentOpen) {
            this.isPayInstrToken = true;
        } else {
            this.isPayInstrToken = false;
        }

        const dynamic = form.get('dynamic_informations') as UntypedFormArray;
        if (dynamic) {
        this.dynamic_informations = dynamic.getRawValue();
        }
    }
}
