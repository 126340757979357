import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-wizard-header',
  templateUrl: './payment-wizard-header.component.html',
  styleUrls: ['./payment-wizard-header.component.scss']
})
export class PaymentWizardHeaderComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() step: number;
  @Input() label: string;
  @Input() controlName: string;

  constructor() { }

  ngOnInit() {
  }

}
