import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioInfo } from '../../_models/radio-info';

@Component({
  selector: 'app-my-radio-group',
  templateUrl: './my-radio-group.component.html',
  styleUrls: ['./my-radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyRadioGroupComponent),
      multi: true
    }
  ],
})
export class MyRadioGroupComponent implements ControlValueAccessor {
  _options: RadioInfo[]

  @Input()
  set options(v: RadioInfo[]) {
    this._options = v
    if(!this._options) { return; }
  }

  @Input()
  set isShowcase(val: boolean) {
    if(!val) { return; }
    this._isShowcase = val;
    //this.setDisabledState(val);
  }

  get isShowcase() {return this._isShowcase; }

  private _isShowcase: boolean;

  value: string | null = null;
  disabled: boolean;

  describedBy = '';

  controlType = 'my-radio-button';

  constructor() {  }

  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    if (value === this.value) {return; }
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  _setValue(key: string) {
    if (this.disabled) {return; }
    this.value = key;
    this.onChange(this.value)
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


}
