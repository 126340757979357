import { counties } from "../_models/country";

export const getPrefixes = (merchantCodes: string[]): any[] => {
    let countryPrefix = [];
    if (!merchantCodes) return countryPrefix;
    
    for (let i = 0; i < merchantCodes.length; i++) {
      for (let j = 0; j < counties.length; j++) {
        if (merchantCodes[i] === counties[j].CODE) {
          countryPrefix.push(counties[j]);
        }
      }
    }
    return countryPrefix;
  }