<div [formGroup]="recurrentsForm">

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField"
      [ngClass]="{
        'required': !recurrentsForm.controls.causale.touched && !recurrentsForm.controls.causale.valid,
        'error': recurrentsForm.controls.causale.touched && !recurrentsForm.controls.causale.valid
        }">
        <input matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.CAUSALE' | translate}}" formControlName="causale"
          maxlength='100' required>
          <mat-error *ngIf="recurrentsForm.controls['causale'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          <mat-error *ngIf="recurrentsForm.controls['causale'].hasError('min')"
          [innerHTML]="'MESSAGE.MIN_AMOUNT' | translate"></mat-error>
        <mat-error *ngIf="recurrentsForm.controls['causale'].hasError('max')"
          [innerHTML]="'MESSAGE.MAX_AMOUNT' | translate"></mat-error>
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField"
      [ngClass]="{
          'required': !recurrentsForm.controls.amount.touched && !recurrentsForm.controls.amount.valid,
          'error': recurrentsForm.controls.amount.touched && !recurrentsForm.controls.amount.valid
          }">
        <input currencyMask [options]="{ prefix: '', decimal: ',', thousands: '.', align: 'left'}" type="tel"
          pattern="\d+((\.|,)\d+)?" formnovalidate matInput placeholder="{{ (paymentType === 'RECURRENT' ? 'RICHIEDIPAGAMENTOAVANZATO.RECURRENTS_IMPORT' : 'RICHIEDIPAGAMENTOAVANZATO.DEFERRED_IMPORT') | translate}}" formControlName="amount"
          required>
        <mat-error *ngIf="recurrentsForm.controls['amount'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
        <mat-error *ngIf="recurrentsForm.controls['amount'].hasError('min')"
          [innerHTML]="'MESSAGE.MIN_AMOUNT' | translate"></mat-error>
        <mat-error *ngIf="recurrentsForm.controls['amount'].hasError('max')"
          [innerHTML]="'MESSAGE.MAX_AMOUNT' | translate"></mat-error>
      </mat-form-field>
    </span>

  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <input matInput [min]="minDate" readonly [matDatepicker]="pickerRIC" formControlName="firstRecurrentLinkDate"
          placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.FIRST_RECURRENT_IMPORT' | translate}}" (click)="pickerRIC.open()">
        <mat-datepicker-toggle matSuffix [for]="pickerRIC"></mat-datepicker-toggle>
        <mat-datepicker #pickerRIC></mat-datepicker>
        <mat-error *ngIf="recurrentsForm.controls['firstRecurrentLinkDate'].hasError('required')"
          [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
      </mat-form-field>
    </span>

    <span class="flex-col spaceLeft">
      <div class="flex-row">
        <span class="flex-col">
          <mat-form-field class="leftInputField">
            <input matInput [min]="minDate" readonly [matDatepicker]="picker" formControlName="to_date"
              placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.EXPIRED_LINK_DATE' | translate}}" (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="recurrentsForm.controls['to_date'].hasError('required')"
              [innerHTML]="'MESSAGE.CAMPO_REQUIRED' | translate"></mat-error>
          </mat-form-field>
        </span>
        <span class="flex-col">
          <mat-form-field class="rightInputField">
            <mat-label>{{'RICHIEDIPAGAMENTOAVANZATO.EXPIRED_LINK_HOUR' | translate}}</mat-label>
            <input matInput formControlName="expiration_time" placeholder="orario scadenza link"
              [ngxTimepicker]="myTimePicker" [format]="24" [min]="minTime" readonly>
              <mat-error *ngIf="recurrentsForm?.controls.expiration_time.hasError('alreadyExpired')"
                [innerHTML]="'RICHIEDIPAGAMENTOAVANZATO.EXPIRATION_ERROR' | translate"></mat-error>
          </mat-form-field>
          <ngx-material-timepicker #myTimePicker></ngx-material-timepicker>
        </span>
      </div>
    </span>
  </div>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.RICORRENZA' | translate}}" formControlName="frequency">
          <mat-option *ngFor="let type of RicorrenteList" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <mat-select placeholder="{{ 'RICHIEDIPAGAMENTOAVANZATO.PERIODICITA' | translate}}" formControlName="rounds">
          <mat-option *ngFor="let type of loopedList" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </div>
  <div class="buttonRow">
    <!-- BOTTONE VERDE -->
    <button [disabled]="!recurrentsForm.valid" (click)="recurrentPreview()" mat-flat-button
      color="primary">{{'RICHIEDIPAGAMENTOAVANZATO.BOTTONEANTEPRIMA'| translate}}
    </button>
  </div>
</div>
