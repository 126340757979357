import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export function customPasswordValidator (control: UntypedFormControl): null|ValidationErrors {
    const errorObj: ValidationErrors = {
        noLowerCase: false,
        noCapitalized: false,
        noNumber: false,
        noSymbol: false,
        tooShort: false,
        tooLong: false,
        invalidChar: false,
    }
    let hasError = false;
    if (!control.value || !control.value.match('[a-z]')) {
        errorObj.noLowerCase = true;
        hasError = true;
    }
    if (!control.value || !control.value.match('[A-Z]')) {
        errorObj.noCapitalized = true;
        hasError = true;
    }
    if (!control.value || !control.value.match('[0-9]')) {
        errorObj.noNumber = true;
        hasError = true;
    }
    if (!control.value || !control.value.match('["£$%&/()=?]')) {
        errorObj.noSymbol = true;
        hasError = true;
    }

    if (!control.value || control.value.length < 8) {
        errorObj.tooShort = true;
        hasError = true;
    }

    if (control.value && control.value.length > 20) {
        errorObj.tooLong = true;
        hasError = true;
    }

    if (control.value && control.value.match('[^a-zA-Z0-9"£$%&/()=?]')) {
        errorObj.invalidChar = true;
        hasError = true;
    }

    return hasError ? errorObj : null;

}
