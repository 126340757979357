import { saveAs } from 'file-saver';
import { HoldingService } from './../../_services/holding.service';
import { provinceArray } from './../../_helpers/province-list';
import { Observable } from 'rxjs/index';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { debounceTime, startWith, map, skipWhile } from 'rxjs/operators';
import * as moment from 'moment';
import { IAsyncFiltersHolding } from '../../_models/filters-holding';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-holding-filter-table',
  templateUrl: './holding-filter-table.component.html',
  styleUrls: ['./holding-filter-table.component.scss']
})
export class HoldingFilterTableComponent implements OnInit {

  @Output() syncFilters: EventEmitter<string> = new EventEmitter<string>();
  @Output() asyncFilters: EventEmitter<IAsyncFiltersHolding> = new EventEmitter<IAsyncFiltersHolding>();

  filterFormHolding: UntypedFormGroup;
  filteredOptions: Observable<any[]>;

  toData: any;
  currentDay: string = '';
  sevenDaysAgo: string = '';
  fifteenDaysAgo: string = '';
  oneMonthAgo: string = '';
  provinceArray = provinceArray;

  days = [
    { value: '3', viewValue: 'OPERAZIONI.PERIODO_OGGI' },
    { value: '0', viewValue: 'OPERAZIONI.PERIODO_7GIORNI' },
    { value: '1', viewValue: 'OPERAZIONI.PERIODO_15GIORNI' },
    { value: '2', viewValue: 'OPERAZIONI.PERIODO_MESE' }
  ];

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.provinceArray.filter(
      option => {
        if (option && option.valueView.toLowerCase().indexOf(filterValue) === 0 || option.value.toLowerCase().indexOf(filterValue) === 0) {
          return option;
        }
      });
  }

  constructor(
    private fb: UntypedFormBuilder,
    private holdingService: HoldingService,
    ) {
    this.currentDay = moment().format('YYYY-MM-DD');
    this.sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
    this.fifteenDaysAgo = moment().subtract(15, 'days').format('YYYY-MM-DD');
    this.oneMonthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
  }

  ngOnInit() {
    this.filterFormHolding = this.fb.group({
      from_date: [''],
      province: [''],
      name: [''],
      paymentId: ['', [Validators.minLength(24)]],
    });

    this.filteredOptions = this.filterFormHolding.get('province').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.subscribeToChangeForm();

    this.holdingService.idPaymentErrorEvent$.subscribe(
      _ => {
        this.filterFormHolding.controls.paymentId.setErrors({invalid_payment_id: true})
        this.filterFormHolding.controls.paymentId.markAsDirty()
        this.filterFormHolding.controls.paymentId.markAsTouched()
        this.filterFormHolding.updateValueAndValidity()
    }
    )
  }

  subscribeToChangeForm() {
    this.filterFormHolding.controls.from_date.valueChanges
    .pipe(skipWhile(value => !value))
    .subscribe(value => this.outputAsyncFilters(value, 'from_date'));

    this.filterFormHolding.controls.paymentId.statusChanges.pipe(
      debounceTime(500)
    )
    .subscribe(
      status => status === 'VALID' && this.outputAsyncFilters(this.filterFormHolding.controls.paymentId.value, 'paymentId')
    );

    this.filterFormHolding.controls.province.valueChanges.pipe(
      debounceTime(500),
    )
    .subscribe(value => !value ? this.outputAsyncFilters(value, 'province') : null);

    this.filterFormHolding.controls.name.valueChanges
    .subscribe(value => this.outputSyncFilters(value));

  }

  onProvinceSelected(event: MatAutocompleteSelectedEvent) {
    this.outputAsyncFilters(event.option.value, 'province')
  }

  outputAsyncFilters(fieldValue: string, fieldName: string) {
    let valueObj = {
      from_date: this.getIntervalDate(this.filterFormHolding.value.from_date),
      paymentId: this.filterFormHolding.value.paymentId,
      province: this.filterFormHolding.value.province,
    }
    valueObj[fieldName] = fieldName === 'from_date' ? this.getIntervalDate(fieldValue) : fieldValue;
    this.asyncFilters.emit(valueObj)
  }

  outputSyncFilters(fieldValue: string) {
    this.syncFilters.emit(fieldValue ? fieldValue.trim().toLowerCase() : '');
  }

  getIntervalDate(id: any) {
    switch (id) {
      case '0': return this.toData = this.sevenDaysAgo;
      case '1': return this.toData = this.fifteenDaysAgo;
      case '2': return this.toData = this.oneMonthAgo;
      case '3': return this.toData = this.currentDay;
    }
  }

  resetFilters() {
    this.filterFormHolding.reset({
      from_date: '',
      province: '',
      name: '',
      paymentId: '',
    }, {emitEvent: false});

    this.filterFormHolding.controls.province.setValue('');
    // this.asyncFilters.emit(null);
    this.syncFilters.emit('');
  }

  exportReportMerchants() {
    this.holdingService.getExcelMerchants().subscribe(blob => {
      saveAs(blob, `Export.xlsx`);
    });
  }

}
