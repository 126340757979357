<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro">
  <div fxLayout="column" fxLayoutAlign="space-around none">
    <mat-form-field appearance="fill" class="datePicker">
      <mat-label>{{'CONTROLLA.FILTRODATERANGE' | translate}}</mat-label>
      <mat-date-range-input [formGroup]="pickerForm" [rangePicker]="picker">
        <input matStartDate formControlName="date_start" placeholder="DD/MM/YYYY" (dateInput)="createSchedulerData(true)" readonly>
        <input matEndDate formControlName="date_end" placeholder="DD/MM/YYYY" (dateInput)="createSchedulerData(true)" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle class="mySatToggle" matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>

  <div [hidden]="reminders && dataSource ? reminders?.length === 0 : true" class="table-border">
    <mat-table [dataSource]="dataSource" >
      <ng-container cdkColumnDef="execution_date">
        <mat-header-cell *cdkHeaderCellDef style="margin-left: 20px;"> {{ 'CONTROLLA.TABELLADATA' | translate }} </mat-header-cell>
        <mat-cell *cdkCellDef="let reminder"> {{ dateConvert(reminder?.execution_date) }} </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="state">
        <mat-header-cell *cdkHeaderCellDef style="justify-content: center;"> {{ 'CONTROLLA.TABELLASTATO' | translate }} </mat-header-cell>
        <mat-cell *cdkCellDef="let reminder" style="justify-content: center; padding-left: 50px"> {{ langConvert(reminder?.state) }} </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="action">
        <mat-header-cell *cdkHeaderCellDef style="justify-content: center;"> {{ 'CONTROLLA.TABELLAAZIONI' | translate }} </mat-header-cell>
        <mat-cell *cdkCellDef="let reminder; index as i" style="justify-content: center; padding-left: 30px">
          <button mat-icon-button (click)="delete(reminder?._id, i)">
            <mat-icon class="edit icon-cancella" matTooltip="{{'PROFILO.ELIMINA' | translate}}  "></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="['execution_date', 'state', 'action']"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: ['execution_date', 'state', 'action']"></mat-row>
    </mat-table>

    <mat-paginator [length]="reminders ? reminders?.length : 0" [pageSize]="5"
                    [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
  </div>
</div>
