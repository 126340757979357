<div style="padding: 60px 20px 20px 20px">

  <mat-table [dataSource]="dataSource" matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
    <!--NOME-->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>{{'CLIENTI.TABELLACLIENTE' | translate}} </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>
    <!--DESCRIZIONE-->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        <span>{{'CLIENTI.TABELLADESCRIZIONE' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
    </ng-container>
    <!--EMAIL-->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>{{'CLIENTI.TABELLAPAYEMAIL' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
    </ng-container>
    <!--DATA CREAZIONE-->
    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>{{'CLIENTI.TABELLACREATOIL' | translate}} </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.createdAt | date:'dd/MM/yyyy'}} </mat-cell>
    </ng-container>
    <!--PROVINCIA-->
    <ng-container matColumnDef="province">
      <mat-header-cell *matHeaderCellDef>
        <span>{{'CLIENTI.TABELLACITTA' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.province}} </mat-cell>
    </ng-container>
    <!--PARTITA IVA-->
    <ng-container matColumnDef="piva">
      <mat-header-cell *matHeaderCellDef>
        <span>{{'CLIENTI.TABELLAPIVA' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.piva}} </mat-cell>
    </ng-container>
    <!--TID-->
    <ng-container matColumnDef="tid">
      <mat-header-cell *matHeaderCellDef>
        <span>{{'CLIENTI.TABELLATID' | translate}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngFor='let t of element.terminal'>{{t.tid}}</span>
      </mat-cell>
    </ng-container>
    <!--ACTIONS-->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> {{'CLIENTI.TABELLAACTION' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element" style="justify-content: flex-end;">
        <div *ngIf="authService.user?.role!=='USER'" fxLayout="row" fxLayoutAlign="end">
          <!--MODIFICA-->
          <button mat-icon-button (click)="modificaCliente(element)">
            <mat-icon style="margin-right: 0" class="edit icon-modifica"
              matTooltip="{{'CLIENTI.TOOLTIPMODIFICA' | translate}}  "></mat-icon>
          </button>
          <!--DISATTIVA-->
          <button mat-icon-button *ngIf="element.active" (click)="toggleActive(element)">
            <mat-icon style="margin-right: 0" class="delete icon-utente-disattivo"
              matTooltip="{{'CLIENTI.TOOLTIPDISATTIVA' | translate}}  "></mat-icon>
          </button>
          <!--ATTIVA-->
          <button mat-icon-button *ngIf="!element.active" (click)="toggleActive(element)">
            <mat-icon style="margin-right: 0" class="abilita icon-completato"
              matTooltip="{{'CLIENTI.TOOLTIPATTIVA' | translate}}  "></mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <!--DEFINIZIONE HEADER E ROW-->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<!--PAGINATOR-->
<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="matPaginator">
</mat-paginator>
<!--FLOATING BUTTON NEW-->
<button mat-fab *ngIf="authService.user?.role!=='USER'" color="primary" class="fab-add-button "
  (click)="creaNuovoCliente()" [matTooltip]=" 'CLIENTI.BUTTONADD' | translate ">
  <i class="material-icons" aria-hidden="true">add</i>
</button>

<!--ACCORDION IMPORT CLIENTI-->
<div fxLayout fxHide.lt-md>
  <mat-accordion *ngIf="authService.user?.role !=='USER'" fxFlex="100">
    <mat-expansion-panel
      style="margin-bottom: 40px !important; margin-left: 20px; margin-right: 20px; margin-top:20px;">
      <mat-expansion-panel-header>
        <mat-panel-title class="titoloFiltro">
          {{'CLIENTI.IMPORTADAFILE' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center" style="padding: 0 10px;">
          <mat-form-field style="width: 100%; flex: 1;">
            <input matInput [formControl]="importFile" readonly="true">
            <button type="button" *ngIf="importFile.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="clearFile();">
              <mat-icon style="margin-bottom: 10px;">close</mat-icon>
            </button>
          </mat-form-field>
          <input #inputfile type="file" ng2FileSelect [uploader]="uploader" style="display: none;" />
          <div [style.display]='spinnerdisplay' style="margin: 0 20px 42px">
            <mat-progress-spinner color='primary' mode='indeterminate' diameter='30'>
            </mat-progress-spinner>
          </div>
          <!-- BOTTONE BIANCO SFOGLIA -->
          <button style="margin-top: -45px; margin-left: 45px;" mat-button class="outline-button"
            (click)="onFileUploadClick();">{{'CLIENTI.SFOGLIA' | translate}}</button>
          <!--BOTTONE DOWNLAD-->
          <button style="margin: -45px 0 0 50px" mat-flat-button color="primary"
            (click)="downloadTemplate()">{{'RICHIEDIPAGAMENTOAVANZATO.DOWNLOAD_TEMPLATE' | translate}}</button>

        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <button mat-flat-button color="primary" [disabled]="!importFile.valid || uploader?.queue.length === 0"
            (click)="importCustomer()">{{'CLIENTI.INVIA' | translate}}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
