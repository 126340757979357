import * as moment from 'moment';
import DateHelper from '../_helpers/date.helper';
import { Payment } from './payment';

export class Bulk {
    merchant: string;
    payment_type: string;
    operation_type: string;
    transaction_type: string;
    state: string;
    template: string;
    terminal: string;
    description: string;
    scheduledStartDate: string;
    scheduledStartDate_offset: string;
    file: string;
    payments: Payment[] | string[];
    refuses: any[] | string[];
    isPaymentOpen: boolean;
    isAmountFree: boolean;
    isDonation: boolean;
    privacyAccepted: boolean;
    isNotificationRequired: boolean;
    _id: string;
    constructor(id: string, data: any, terminal: string, template: string, file: any) {
        this.merchant = id;
        this.payment_type = data.payment_type;
        this.operation_type = data.operation_type;
        this.transaction_type = data.transaction_type;
        this.template = template;
        this.terminal = terminal;
        this.description = data.description;
        this.scheduledStartDate = moment(DateHelper.stripOffseDate(data.scheduledStartDate)).format('YYYY-MM-DD');
        this.scheduledStartDate_offset = data.scheduledStartDate_offset;
        this.file = file;
        this.isPaymentOpen = data.isPaymentOpen;
        this.isAmountFree = data.isAmountFree;
        this.isDonation = data.isDonation;
        this.privacyAccepted = true;
        this.isNotificationRequired = data.isNotificationRequired;
    }
}
