import { Terminal } from '../_models/terminal';

// eslint-disable-next-line no-restricted-imports
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Referent } from '../_models/referent';


@Injectable()
export class TerminalService {
  private readonly TERMINAL_URL: string = environment.BASE_URL + 'terminal';


  constructor(private http: HttpClient, private authService: AuthService) { }

  list(terminal: string): Observable <{} | Referent[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');



    let params = new HttpParams();
    if (terminal != null && terminal !== undefined && terminal !== '') {
      params = params.set('_id', terminal);
    }
    params = params.set('sort', '-createdAt');
    params = params.set('populate', 'true' );
    return this.http.get<{} | Referent[]>(this.TERMINAL_URL, { headers, params })
  }

  create(terminal: Terminal): Observable <{} | Terminal> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.post(this.TERMINAL_URL, terminal, { headers })
  }

  update(_id: string, terminal: Terminal): Observable <{} | Terminal[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.put(this.TERMINAL_URL + '/' + _id, terminal, { headers })
  }

  bulkUpdate(body){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    
    return this.http.post(this.TERMINAL_URL + '/bulkUpload', body, { headers })
  }

}
