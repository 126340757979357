<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltroPrincipale">

        <div fxLayout="column" fxLayoutAlign="space-around stretch" ngClass.xs="positionTable" ngClass.sm="positionTable"
                ngClass.md="positionTable" ngClass.lg="positionTable" ngClass.xl="positionTable">
                <div class="example-container ">

                  <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc" matSortDisableClear>

                    <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                            <span>NOME</span>
                                    </div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let profile" style="text-align: center;"> {{profile.name}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;">
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                            <span>DESCRIZIONE</span>
                                    </div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let profile" style="text-align: center;"> {{profile.description}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef class="textTableFont" style="text-align: center;"> {{'PANNELLOUTENTE.TABELLAACTION' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let element" style="text-align: center;">
                                    <button mat-icon-button (click)="openProfile(element._id)">
                                        <mat-icon class="icon-modifica" matTooltip="{{'PANNELLOUTENTE.TOOLTIPMODIFICA' | translate}} "></mat-icon>
                                    </button>
                                    <button *ngIf="user?.type === 'SUPPLIER'" mat-icon-button (click)="eliminaProfilo(element._id)">
                                        <mat-icon class="icon-cancella-import" matTooltip="{{'PANNELLOUTENTE.TOOLTIPCANCELLA' | translate}} "></mat-icon>
                                    </button>
                            </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                </div>
        </div>

        <mat-paginator #paginator [length]="dataSource.count" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" class="matPaginator"></mat-paginator>

</div>
<button mat-fab color="primary" class="fab-add-button " (click)="createProfile()" matTooltip="{{'PANNELLOUTENTE.BUTTONADDPROFILE' | translate }}">
        <i class="material-icons" aria-hidden="true">add</i>
</button>
