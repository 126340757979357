export class backendException {
    name: string;
    message: string;
    status: number;
    constructor(name: string, message: string, status: number) {
        this.name = name;
        this.message = message;
        this.status = status;
    }
}
