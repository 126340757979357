<!-- MENU WIZARD -->
<div style="padding: 0 20px;">
  <app-payment-wizard [modPayment]="payment" [saveEvent]="saveEvent" (enableForm)="enablePanelExpansion($event)"
    (wizardValue)="getChildrenData($event)">
  </app-payment-wizard>

  <mat-card style="position: relative; padding-top: 32px;">



    <!-- FORM -->
    <form [formGroup]="payForm" fxLayout="column" fxLayoutAlign="space-around stretch" style="padding: 0 0;">

      <!-- ANAGRAFICA CLIENTE -->
      <mat-expansion-panel *ngIf="!formValue.isPaymentOpen" [disabled]="isLocked" [expanded]="!isLocked"
        style="margin-bottom: 20px !important;" class="borderStyleAcc">
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{'RICHIEDIPAGAMENTOAVANZATO.RIFERIMENTIRICHIESTADIPAGAMENTO' | translate
              }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-request-customer-data [payForm]="payForm" [modPayment]="payment" [sendVia]="formValue?.operation_type"
          (customerData)="getChildrenData($event)">
        </app-request-customer-data>
      </mat-expansion-panel>

      <!-- DETTAGLI RICHIESTA -->
      <mat-expansion-panel #requestDetailPanel [disabled]="isLocked" [expanded]="!isLocked"
        style="margin-bottom: 20px !important;" class="borderStyleAcc"
        *ngIf="formValue?.payment_type && formValue?.payment_type !== 'RECURRENT' && formValue?.payment_type !== 'DEFERRED'">
        <!-- {{formValue?.payment_type}} -->
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{'RICHIEDIPAGAMENTOAVANZATO.DETTAGLIRICHIESTADIPAGAMENTO' | translate
              }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- AMOUNT AND DESCRIPTION -->
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field [ngClass]="{
                            'required':formValue.operation_type && !payForm.controls.description.touched && !payForm.controls.description.valid,
                            'error':formValue.operation_type && payForm.controls.description.touched && !payForm.controls.description.valid
                            }" class="leftInputField">
              <input [readonly]="payment && payment.state==='ACTIVE' ? true : false" matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.CAUSALE' | translate}}"
                formControlName="description" maxlength='100' required>
              <mat-error *ngIf="payForm.controls['description'].hasError('pattern')"
                [innerHTML]="'MESSAGE.PATTERN_ERROR' | translate"></mat-error>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field [ngClass]="{
                            'required':formValue.operation_type && !payForm.controls.amount.touched && payForm.controls.amount.hasError('required'),
                            'error':formValue.operation_type && payForm.controls.amount.hasError('required') && payForm.controls.amount.touched
                            }" class="rightInputField">
              <input [readonly]="payment && payment.state==='ACTIVE' ? true : false" currencyMask [options]="{ prefix: '', decimal: ',', thousands: '.', align: 'left'}" type="tel"
                pattern="\d+((\.|,)\d+)?" formnovalidate matInput
                placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.IMPORTO' | translate}}" formControlName="amount" required>
              <mat-error *ngIf="payForm.controls['amount'].hasError('min')"
                [innerHTML]="'MESSAGE.MIN_AMOUNT' | translate"></mat-error>
              <mat-error *ngIf="payForm.controls['amount'].hasError('max')"
                [innerHTML]="'MESSAGE.MAX_AMOUNT' | translate"></mat-error>
            </mat-form-field>
          </span>
        </div>

      </mat-expansion-panel>

      <!-- PAGAMENTI RICORRENTI/DILAZIONI -->
      <mat-expansion-panel #recurrentPanel [disabled]="isLocked" [expanded]="!isLocked"
        *ngIf="formValue?.payment_type && formValue?.payment_type !== 'SINGLE'" style="margin-bottom: 20px !important;"
        class="borderStyleAcc">
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{ 'RICHIEDIPAGAMENTOAVANZATO.DETTAGLIRICHIESTADIPAGAMENTO' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-request-recurrents [name]="payForm?.controls.name.value"
          [paymentType]="formValue?.payment_type" (recurrentsData)="getRecurrentsData($event)">
        </app-request-recurrents>
      </mat-expansion-panel>

      <mat-expansion-panel #infoPanel [disabled]="isLocked" [expanded]="!isLocked && formValue?.informations.length > 0"
        *ngIf="!merchant?.isDynamicInformationsActive" style="margin-bottom: 20px !important;" class="borderStyleAcc">
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{'RICHIEDIPAGAMENTOAVANZATO.INFOAGGIUNTIVE' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div formArrayName="informations" style="display: flex; flex-direction: column;">
          <mat-form-field *ngFor="let control of payForm?.controls.informations.controls; index as i">
            <input [readonly]="!payment || (payment && (payment.state==='WAIT' || (payment.state==='ACTIVE' && merchant.isAdditionalInfoEditable))) ? false : true" matInput placeholder="{{'RICHIEDIPAGAMENTOAVANZATO.INFOAGG' | translate}}" [formControlName]="i">
            <button matSuffix mat-icon-button color="warn" (click)="removeField(i)"
              [disabled]="merchant?.addInfoCount >= payForm?.value.informations.length">
              <mat-icon style="font-size: 30px; margin: -10px 0 0 -20px;">clear</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            [disabled]="payForm?.controls.informations.invalid || payForm?.controls.informations.controls.length > 4"
            mat-flat-button color="primary" (click)="addField(null)">{{'RICHIEDIPAGAMENTOAVANZATO.AGGIUNGI' |
            translate}}</button>
        </div>

      </mat-expansion-panel>

      <!-- CAMPI DINAMICI -->
      <mat-expansion-panel #dynamicPanel [disabled]="isLocked"
        [expanded]="!isLocked && formValue?.dynamic_informations.length > 0"
        *ngIf="merchant?.isDynamicInformationsActive" style="margin-bottom: 20px !important;" class="borderStyleAcc">
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{'RICHIEDIPAGAMENTOAVANZATO.INFODYNAMIC' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <app-dynamic-fields #dynamicFields></app-dynamic-fields>
        </div>
      </mat-expansion-panel>

      <!-- SCHEDULATORE NOTIFICHE -->
      <mat-expansion-panel #schedulePanel [disabled]="isLocked" [expanded]="!isLocked && reminders.length > 0"
        *ngIf="merchant?.isNotificationActive && (formValue?.operation_type === 'SMS' || formValue?.operation_type ==='EMAIL')"
        style="margin-bottom: 20px !important;" class="borderStyleAcc">
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{'RICHIEDIPAGAMENTOAVANZATO.SCHEDULATORENOTIFICHE' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="width: 35% !important;">
          <app-schedule-reminder [paymentId]="payment?._id" [inputData]="reminders"
            (outputData)="updateSchedulerData($event)" (pickerChanged)="updatePickerStatus($event)">
          </app-schedule-reminder>
        </div>
      </mat-expansion-panel>

      <!-- CAMPI NON OBBLIGATORI -->
      <mat-expansion-panel [disabled]="isLocked" [expanded]="!isLocked" style="margin-bottom: 20px !important;"
        class="borderStyleAcc">
        <mat-expansion-panel-header style="margin-left: -7px;">
          <mat-panel-title ngClass.xs="titoloFiltro" ngClass.sm="titoloFiltro" ngClass.md="titoloFiltro"
            ngClass.lg="titoloFiltro" ngClass.xl="titoloFiltro">
            <span class="cardTema">{{'RICHIEDIPAGAMENTOAVANZATO.VEDICAMPIEXTRA' | translate
              }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-request-extra-fields [modPayment]="payment" [isPaymentOpen]="formValue?.isPaymentOpen"
          [sendVia]="formValue?.operation_type" [merchant]="merchant" [paymentType]="formValue?.payment_type">
        </app-request-extra-fields>
      </mat-expansion-panel>

      <!--SALVA/INVIA-->
      <div fxLayout fxLayoutAlign="end">
        <button
          *ngIf="(!formValue?.payment_type || formValue?.payment_type === 'SINGLE') && !payForm.get('isQueryString').value"
          mat-flat-button color="primary" style="margin-left: 15px !important"
          (click)="!payment ? createPayment(false) : updatePayment(false)"
          [disabled]="!payForm?.valid || !requestExtraFieldsComponent?.extraDataForm.valid">
          {{'RICHIEDIPAGAMENTOAVANZATO.BOTTONESALVA'| translate}}
        </button>
        <!-- BOTTONE VERDE SALVA E INVIA MODALITA' DESKTOP -->
        <button mat-flat-button color="primary" style="margin-left: 15px !important"
          (click)="!payment ? createPayment(true) : updatePayment(true)"
          [disabled]="!payForm?.valid || !requestExtraFieldsComponent?.extraDataForm.valid">
          {{'RICHIEDIPAGAMENTOAVANZATO.BOTTONESALVAINVIA'| translate}}
        </button>
      </div>

    </form>

  </mat-card>
</div>
