<span [ngClass]="theme">
  <mat-dialog-content>


    <table mat-table [dataSource]="paymentRounds" aria-describedby="Payment scheduling" fxHide.xs fxHide.sm>


      <ng-container class="tbody" matColumnDef="rata">
        <th scope="col" mat-header-cell *matHeaderCellDef class="center">{{ 'CONTROLLA.RATA' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="rata-cell">
          <div class="rata-format">{{element.rata}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="frequency">
        <th scope="col" mat-header-cell *matHeaderCellDef class="center">{{ 'CONTROLLA.DATADIPAGAMENTO' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="center"> {{
          !element.azione
          ? element.frequency
          : element.data
        }}
        </td>
      </ng-container>

      <ng-container matColumnDef="azione">
        <th scope="col" mat-header-cell *matHeaderCellDef class="center">{{ 'CONTROLLA.AZIONE' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="center">{{element.azione || "CONTROLLA.TABELLAAZIONE_MESSAGE" | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="importo">.
        <th scope="col" mat-header-cell *matHeaderCellDef class="center">{{ 'CONTROLLA.TABELLIMPORTO' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="center">{{element.importo}} €</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="total" class="totalImport" fxHide.xs fxHide.sm>
      <span class="totalImportBold">{{'CONTROLLA.TOTALIMPORT' | translate}}:</span> <span> {{total}}</span> €
    </div>



    <!-- TABELLA DIMENSIONI INFERIORI A SCHERMO NORMALE -->

    <table mat-table [dataSource]="paymentRounds" aria-describedby="Payment scheduling" fxHide.md fxHide.lg fxHide.xl style="color: #3c3c3b;">

      <ng-container matColumnDef="rata">
        <th scope="col" mat-header-cell *matHeaderCellDef class="center">{{ 'CONTROLLA.RATE' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="rata-cell">
          <div class="rata-format">{{element.rata}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="azione">
        <th scope="col" mat-header-cell *matHeaderCellDef>{{ 'CONTROLLA.PAGAMENTO' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index;" class="center" [ngStyle]="{'padding-top.px': i === 0 ? '10' : '0'}" style="padding-bottom: 10px; color: #3c3c3b;"> 
          <span class="strong"> {{ i === 0 || element.noLimit ? element.data : element.frequency }} </span> <br> {{ element.azione }} <br>
          {{ element.importo }} €
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedxs"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedxs;"></tr>
    </table>
    <div *ngIf="total" class="totalImportMobile" fxHide.md fxHide.lg fxHide.xl style="color: #3c3c3b;">
      <span class="totalImportBold">{{'CONTROLLA.TOTALIMPORT' | translate}}:</span> 
      <span> {{total}}</span> €
    </div>



    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-flat-button color="primary" class="buttonDialog" style="margin-top: 15px;"
        [mat-dialog-close]="true">{{'DIALOG.CLOSE' | translate}}</button>
    </div>
  </mat-dialog-content>
</span>
