<span [ngClass]="theme">
  <mat-dialog-content>


    <table mat-table matSort aria-describedby="Payment scheduling" matSortActive="rata" matSortDirection="asc"
      [dataSource]="dataSource" fxHide.xs fxHide.sm>


      <ng-container class="tbody" matColumnDef="rata">
        <th scope="col" mat-header-cell *matHeaderCellDef class="center">RATE</th>
        <td mat-cell *matCellDef="let element" class="rata-cell">
          <div
            [ngClass]="{'completed-payment': element.isBold, 'rata-cell-standard': !element.isBold, 'rata-cell-inverted': element.stato === 'ACTIVE'}"
            class="rata-format">{{element.rata}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="data">
        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CONTROLLA.TABELLADATA' | translate }}</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isBold ? 'bold' : 'normal'">{{element.data |
          date:'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="stato">
        <th scope="col" mat-header-cell *matHeaderCellDef>{{ 'CONTROLLA.TABELLASTATO' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="statoControl stato-control-waiting"
            *ngIf="element.stato === 'ACTIVE'">
            <img alt="status waiting icon" style="height: 20px; margin-right: 10px "
              [src]="ASSETS_BASE + 'svg/iconsvg/in-attesa.svg'">
            <span> {{'CONTROLLA.WAIT' | translate}} </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="statoControl stato-control-error"
            *ngIf="element.stato === 'ERROR'">
            <img alt="expired icon" style="height: 15px; margin-right: 10px" [src]="ASSETS_BASE + 'svg/iconsvg/x.svg'">
            <span> {{'CONTROLLA.ERROR_C' | translate}} </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="statoControl stato-control-completed"
            *ngIf="element.stato === 'COMPLETED'">
            <img alt="done icon" style="height: 15px; margin-right: 10px " [src]="ASSETS_BASE + 'svg/iconsvg/ok.svg'">
            <span> {{'CONTROLLA.COMPLETED_C' | translate}} </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="importo">
        <th scope="col" mat-header-cell *matHeaderCellDef>{{ 'CONTROLLA.TABELLIMPORTO' | translate }}</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isBold ? 'bold' : 'normal'">{{element.importo}} €</td>
      </ng-container>

      <ng-container matColumnDef="idPag">
        <th scope="col" mat-header-cell *matHeaderCellDef>{{ 'CONTROLLA.TABELLAIDPAGAMENTO' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.idPag}}</td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <th scope="col" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.stato==='WAIT'" style="color: #e85205; font-size: 26px;" class="edit icon-in-attesa">
          </mat-icon>
          <mat-icon *ngIf="element.stato==='EXPIRED'" style="color: red; font-size: 26px;" class="edit icon-scaduto">
          </mat-icon>
          <mat-icon *ngIf="element.stato==='COMPLETED'" style="color: green; font-size: 26px;"
            class="edit icon-completato"></mat-icon>
          <mat-icon *ngIf="element.stato==='ACTIVE'" style=" font-size: 26px;" class="edit icon-attivo"></mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <table mat-table aria-describedby="Payment scheduling" [dataSource]="data.item.ricRat" fxHide.md fxHide.lg
      fxHide.xl>


      <ng-container matColumnDef="data">
        <th scope="col" mat-header-cell *matHeaderCellDef>{{ 'CONTROLLA.TABELLADATA' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.data}} </td>
      </ng-container>

      <ng-container matColumnDef="stato">
        <th scope="col" mat-header-cell *matHeaderCellDef>{{ 'CONTROLLA.TABELLASTATO' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="row" fxLayoutAlign="space-around center" class="statoControl" *ngIf="element.stato==='WAIT'">
            <img alt="waiting icon" style="height: 20px;" [src]="ASSETS_BASE + 'svg/iconsvg/in-attesa.svg'">
            <span> {{'CONTROLLA.WAIT' | translate}} </span>
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="importo">
        <th scope="col" mat-header-cell *matHeaderCellDef>{{ 'CONTROLLA.TABELLIMPORTO' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.importo}} €</td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedxs"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedxs;"></tr>
    </table>


    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" class="buttonDialog" style="margin-top: 20px;"
        [mat-dialog-close]="true">{{'DIALOG.CLOSE' | translate}}</button>
    </div>
  </mat-dialog-content>
</span>