import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { ThemeService } from '../../_services/theme.service';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-previewRic',
  templateUrl: './previewRic.component.html',
  styleUrls: ['./previewRic.component.scss']
})
export class PreviewRicComponent implements OnInit {
  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;
  public readonly UPLOAD_URL: string = environment.BASE_URL + 'merchant/';
  theme: string;
  paymentRounds: MatTableDataSource<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public translateService: TranslateService, private themeService: ThemeService) {

  }
  displayedColumns = ['rata', 'frequency', 'azione', 'importo'];
  shortColumns = ['data', 'azione', 'importo'];
  displayedxs = ['rata', 'azione'];
  total: number;
  ngOnInit() {
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
    });
    this.paymentRounds = new MatTableDataSource(this.data.paymentRounds);
    this.total = this.data.paymentRounds[1].recurrentTotal;
  }



}
