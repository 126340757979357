import { Merchant } from './merchant';

export class User {

    _id: string;
    role:  string;
    language:  string;
    mode_easy:  boolean;
    first_access:  boolean;
    active: boolean;
    type:  string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    smartphone: string;
    phone: string;
    fax: string;
    merchant: any;
    updatedAt: Date;
    createdAt: Date;
    birthday: Date;
    timezone: string;
    reset_password_expire: Date;
    terminal: any;
    area_code: string;
    merchants: any;

    isCreatePaymentRequestActive: boolean;
    isConfirmActive: boolean;
    isBulkActive: boolean;
    isEmailActive: boolean;
    isSmsActive: boolean;
    isQrcodeActive: boolean;
    isLinkActive: boolean;
    isWhatsAppActive: boolean;
    isSupervisor: boolean;

    profile:  string;
}
