<span [ngClass]="theme">
  <mat-dialog-content class="content">
    <div class="parentContainer">
      <div class="dettQrcode">{{ 'DIALOG.QRCODE_MESSAGE' | translate }}</div>
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <img alt="qrcode icon" class="qrImage" [src]="URL">
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="buttonContainer respectPadding">
    <button mat-flat-button color="primary" (click)="downloadPDF()" class="buttonDialog buttonVertical">{{
      'DIALOG.DOWNLOADPDF' | translate }}</button>
    <button mat-flat-button color="primary" (click)="downloadImg()" class="buttonDialog buttonVertical">{{
      'DIALOG.DONWLOADQRCODE' | translate }}</button>
    <button mat-flat-button color="primary" class="buttonDialog buttonVertical"
      [mat-dialog-close]="true">{{'DIALOG.CLOSE' | translate}}</button>
  </mat-dialog-actions>
</span>
