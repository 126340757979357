import { Payment } from '../_models/payment';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Referent } from '../_models/referent';
import { Report } from '../_models/report';
import { Information } from '../_models/information';
import * as moment from 'moment';
import { AuthService } from './auth.service';
import { Operation } from '../_models/operation';
import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from './merchant.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PaymentService {
  private readonly PAYMENT_URL: string = environment.BASE_URL + 'payment';
  private readonly REPORT_URL: string = environment.BASE_URL + 'report';
  private readonly SEND_URL: string = environment.BASE_URL + 'payment/send';
  private readonly PAYMENTPUSHINFO_URL: string = environment.BASE_URL + 'payment/push/information/';
  private readonly PAYMENTPUllINFO_URL: string = environment.BASE_URL + 'payment/pull/information/';
  private readonly PAYMENTEXPORT_URL: string = environment.BASE_URL + 'export';
  private readonly CONFERMAPAGAMENTO_URL: string = environment.BASE_URL + 'payment/confirm';
  private readonly STORNACREDIT_URL: string = environment.BASE_URL + 'payment/credit';
  private readonly STORNAVOID_URL: string = environment.BASE_URL + 'payment/void';
  private readonly PRINT_URL: string = environment.BASE_URL + 'payment/depliant/';
  private readonly RECEIPT_URL: string = environment.BASE_URL + 'payment/receipt/';
  private readonly HISTORY_URL: string = environment.BASE_URL + 'payment/history';
  private readonly RECURRENT_OUTCOME_URL: string = environment.BASE_URL + 'payment/outcomes';
  private readonly RECURRENT_NOTIFY_URL: string = environment.BASE_URL + 'recurrent/';

  lastOutcomePurchase: any = null;
  lastOutcomePurchaseWithAuthCode: any = null;
  transactions: any[] = [];
  operations: any[] = [];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translate: TranslateService,
    private merchantService: MerchantService,
  ) { }
  /**
   * Metodo per recuperare la lista dei payment
   * @param payment (string) - nome del payment per effetture la ricerca
   */
  list(id: string): Observable<{} | Referent[]> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    if (id) {
      params = params.set('merchant', id);
    }
    params = params.set('sort', '-createdAt');
    params = params.set('populate', 'true');
    params = params.set('isImportMassively', 'false');

    return this.http.get<{} | Referent[]>(this.PAYMENT_URL, { headers, params });
  }

  getOperations(
    merchant_id: string, filters: Object, isImportFilter: boolean, sort: string, skip: number, limit: number, populate: boolean = false, payment_type?: string
  ) {
    const headers = this.getHeaders();
    let params = new HttpParams();
    params = params.set('merchant', merchant_id);
    params = params.set('sort', sort);
    params = params.set('limit', limit.toString());
    params = params.set('populate', populate.toString());
    params = params.set('skip', (skip * limit).toString());
    params = params.set('isImportMassively', isImportFilter.toString());
    for (const key in filters) {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    }
    if (payment_type) {
      params = params.set('payment_type', payment_type);
    }
    return this.http.get<any>(this.PAYMENT_URL, { headers, params });
  }

  getRecurrentOutcomes(element: Payment): Observable<{} | Referent[]> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    if (element._id) {
      params = params.set('payment', element._id);
    }
    if (this.authService.user.type === 'HOLDING' || this.authService.user.type === 'SUPPLIER') {
      params = params.set('merchant', this.merchantService.currentMerchant._id);
    }
    return this.http.get<{} | Referent[]>(this.RECURRENT_OUTCOME_URL, { headers, params });
  }

  recurrentNotify(id: string) {
    const headers = this.getHeaders();
    return this.http.post<{} | Referent[]>(this.RECURRENT_NOTIFY_URL + id + "/notify/failed", {}, { headers });
  }

  listOrder(id: string, value: string): Observable<{} | Referent[]> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    if (id) {
      params = params.set('merchant', id);
    }
    params = params.set('sort', value);
    params = params.set('populate', 'true');
    return this.http.get<{} | Referent[]>(this.PAYMENT_URL, { headers, params });
  }

  /**
   * Metodo per creare un nuovo payment
   * @param payment Model payment del payment
   */
  create(payment: Payment): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(this.PAYMENT_URL, payment, { headers });
  }

  searchID(id: string): Observable<Payment> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    if (id) {
      params = params.set('_id', id);
    }
    params = params.set('populate', 'true');
    if (this.authService.user.type === 'HOLDING' || this.authService.user.type === 'SUPPLIER') {
      params = params.set('merchant', this.merchantService.currentMerchant._id);
    }
    return this.http.get<any>(this.PAYMENT_URL + '/' + id, { headers, params }).pipe(map(response => response.item));
  }


  export(idMerchant: string, filters: any, isImportFilter: boolean): Observable<Blob> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    params = params.set('merchant', idMerchant);
    params = params.set('isImportMassively', isImportFilter.toString());
    for (const key in filters) {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    }
    return this.http.get(this.PAYMENTEXPORT_URL, { headers, params, responseType: 'blob' });
  }

  updateOperation(_id: string, payment: Operation) {
    const headers = this.getHeaders();
    if (this.authService.user.type === 'HOLDING') {
      payment.merchant = this.merchantService.currentMerchant._id;
    }
    return this.http.put(this.PAYMENT_URL + '/' + _id, payment, { headers });
  }

  update(_id: string, payment: Payment): Observable<any> {
    const headers = this.getHeaders();
    if (this.authService.user.type === 'HOLDING') {
      payment.merchant = this.merchantService.currentMerchant._id;
    }
    return this.http.put(this.PAYMENT_URL + '/' + _id, payment, { headers });
  }

  send(_id: string): Observable<any> {
    const headers = this.getHeaders();
    const body = { merchant: this.merchantService.currentMerchant._id };
    return this.http.put(this.SEND_URL + '/' + _id, body, { headers });
  }

  getNotPaid(idMerchant: string, fromdata: string, todata: string, payment_type: string = null): Observable<{} | Report[]> {
    const headers = this.getHeaders();
    const params = this.setParams(idMerchant, fromdata, todata, payment_type);
    return this.http.get<{} | Report[]>(this.REPORT_URL + '/notpayed', { headers, params });
  }

  getPaid(idMerchant: string, fromdata: string, todata: string, payment_type: string = null): Observable<{} | Report[]> {
    const headers = this.getHeaders();
    const params = this.setParams(idMerchant, fromdata, todata, payment_type);
    return this.http.get<{} | Report[]>(this.REPORT_URL + '/payed', { headers, params });
  }

  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    return headers;
  }

  setParams(idMerchant: string, fromdata: string, todata: string, payment_type?: string) {
    let params = new HttpParams();
    params = params.set('merchant', idMerchant);
    params = params.set('from_date', moment.utc(fromdata).toISOString());
    params = params.set('to_date', moment.utc(todata).endOf('day').toISOString());
    if (payment_type) {
      params = params.set('payment_type', payment_type);
    }
    return params;
  }


  downloadQRCodePDF(idMerchant) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: this.getHeaders()
    };
    return this.http.get<any>(this.PRINT_URL + idMerchant, httpOptions);
  }

  downloadQRCodeImage(pathToFile) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: this.getHeaders()
    };
    return this.http.get<any>(pathToFile, httpOptions);
  }

  downloadReceipt(idReceipt: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept-Language', 'it');
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: headers
    };
    return this.http.get<any>(this.RECEIPT_URL + idReceipt, httpOptions);
  }

  openOrderExport(_id: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    return this.http.get(this.PAYMENTEXPORT_URL + "/open/" + _id, { headers, responseType: 'blob' });
  }

  pushInformation(_id: string, information: Information): Observable<{} | Payment[]> {
    const headers = this.getHeaders();
    return this.http.put(this.PAYMENTPUSHINFO_URL + '/' + _id, information, { headers });
  }
  pushRecurrent(_idPayment: string, idReccurent: string): Observable<{} | Payment[]> {
    const headers = this.getHeaders();
    return this.http.put(this.PAYMENT_URL + '/' + _idPayment + '/recurrent/' + idReccurent, null, { headers });
  }
  confermaPagamento(_id: string, object: any): Observable<any> {
    const headers = this.getHeaders();
    if (this.authService.user.type === 'HOLDING') {
      object.merchant = this.merchantService.currentMerchant._id;
    }
    return this.http.put<any>(this.CONFERMAPAGAMENTO_URL + '/' + _id, object, { headers }).pipe(
      map(response => response.item)
    )
  }
  stornaCredit(_id: string, object: any): Observable<any> {
    const headers = this.getHeaders();
    if (this.authService.user.type === 'HOLDING') {
      object.merchant = this.merchantService.currentMerchant._id;
    }
    return this.http.put<any>(this.STORNACREDIT_URL + '/' + _id, object, { headers }).pipe(
      map(response => response.item)
    )
  }
  stornaVoid(_id: string, object: any): Observable<any> {
    const headers = this.getHeaders();
    if (this.authService.user.type === 'HOLDING') {
      object.merchant = this.merchantService.currentMerchant._id;
    }
    return this.http.put<any>(this.STORNAVOID_URL + '/' + _id, object, { headers }).pipe(
      map(response => response.item)
    )
  }
  history(_id: string): Observable<{} | Payment[]> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    if (this.authService.user.type === 'HOLDING') {
      params = params.set('merchant', this.merchantService.currentMerchant._id);
    }
    return this.http.get(this.HISTORY_URL + '/' + _id, { headers, params });
  }

  generateWhatsAppUrl(payment: Payment, isMobile: boolean): any {
    const interpolateParams = {
      merchant_name: payment.merchant.name,
      amount: payment.amount,
      link: payment.payment_link,
      expires: moment(payment.to_date).format('DD-MM-YYYY')
    };
    let paymentMessage: string;

    if (!payment.isAmountFree) {
      if (payment.isDonation) {
        paymentMessage = this.translate.instant('DIALOG.DONATE_WHATSAPP_MESSAGE', interpolateParams);
      } else {
        paymentMessage = this.translate.instant('DIALOG.PAYMENT_WHATSAPP_MESSAGE', interpolateParams);
      }
    } else {
      if (payment.isDonation) {
        paymentMessage = this.translate.instant('DIALOG.FREE_DONATE_WHATSAPP_MESSAGE', interpolateParams);
      } else {
        paymentMessage = this.translate.instant('DIALOG.FREE_PAYMENT_WHATSAPP_MESSAGE', interpolateParams);
      }
    }
    let paymentLink: string;
    if (payment.isPaymentOpen) {
      paymentLink = `?text=${paymentMessage}`;
    } else {
      paymentLink = `?phone=${payment.area_code}${payment.smartphone}&text=${paymentMessage}`;
    }
    let whatsappURL: string;
    let whatsappTarget: string;
    if (isMobile) {
      whatsappURL = 'whatsapp://send';
      paymentLink = `${whatsappURL}${paymentLink}`;
      whatsappTarget = '_self';
    } else {
      whatsappURL = 'https://api.whatsapp.com/send';
      paymentLink = `${whatsappURL}${paymentLink}`;
      whatsappTarget = '_blank';
    }
    return {
      link: paymentLink,
      target: whatsappTarget
    };
  }

}
