<div *ngIf="transactions?.length < 1" style="padding: 20px;">{{'CONTROLLA.NO_TRANSACTIONS' | translate}}</div>

<!--LAYOUT DETTAGLIO SINGOLE-->
<mat-tab-group *ngIf="element?.payment_type === 'SINGLE' && transactions?.length > 0"
  (selectedTabChange)="getTransactions()" [(selectedIndex)]="selectedTabIndex">
  <mat-tab>
    <ng-template mat-tab-label>{{ 'OPERAZIONI.TRANSACTIONS' | translate }}</ng-template>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="30%" style="padding: 20px;"></div>
      <div fxFlex="70%" style="height: 860px; overflow-y: scroll; padding: 20px">

        <!--SCORRO LE TRANSAZIONI UTENTE-->
        <div *ngFor="let transaction of transactions; index as i">
          <div fxLayout="row" style="margin-bottom: 20px;">
            <div fxFlex="none">
              <img alt="unknown state icon" *ngIf="!transaction.response?.rc" class="transactionStateIcon"
                [src]="ASSETS_BASE + 'svg/iconsvg/riepilogo-unknown.svg'">
              <img alt="payment ok icon" *ngIf="transaction.response?.rc==='IGFS_000'" class="transactionStateIcon"
                [src]="ASSETS_BASE + 'svg/iconsvg/riepilogo-ok.svg'">
              <img alt="payment ko icon" *ngIf="transaction.response?.rc && transaction.response?.rc!=='IGFS_000'"
                class="transactionStateIcon" [src]="ASSETS_BASE + 'svg/iconsvg/riepilogo-ko.svg'">
            </div>
            <div fxFlex="70%" fxLayout="column">
              <div *ngIf="transaction.response.accountName" class="paymentInfo">
                <strong>{{ 'OPERAZIONI.USER' | translate }}: </strong>{{transaction.response.accountName}}
              </div>
              <div class="paymentInfo"><strong>{{ 'OPERAZIONI.DATE' | translate }}:
                </strong>{{transaction.date | date:'dd/MM/yyyy HH:mm:ss' }}</div>
              <div class="paymentInfo capitalize"><strong>{{ 'OPERAZIONI.OUTCOME' | translate }}:
                </strong>{{'CONTROLLASTATOTRANSA.'+ transaction.response.rc | translate}}</div>
              <div *ngIf="transaction.response.pendingAmount" class="paymentInfo">
                <strong>{{ 'OPERAZIONI.PENDING' | translate }}: </strong>{{
                transaction.response.pendingAmount.replace('.', ',') }}
              </div>
              <div *ngIf="transaction.response.paymentID || transaction.receipt" fxLayout="row">
                <div class="paymentInfo"><strong>{{ 'OPERAZIONI.RECEIPT' | translate }}: </strong></div>
                <button class="iconButton" (click)="downloadReceipt(transaction)">
                  <mat-icon svgIcon="download"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf='!transaction.response.shopID' class='tranRow'></div>
          <!--BOTTONIERA PER CONFERMA/STORNA/STORNA AUTH-->
          <div *ngIf='transaction.response.shopID'>
            <app-operation-buttons
              *ngIf="authService.user?.isConfirmActive && element?.transaction_type !== 'VERIFY' && transaction.response.rc === 'IGFS_000' && adminOp.length === 0"
              [isAdmin]="false" [type]="element?.transaction_type" [maxAmount]="element?.amount"
              [paymentId]="element._id" [tranId]="success.response.tranID" [shopID]="success.response.shopID"
              (refreshOutput)="refreshTransactions()"></app-operation-buttons>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab [disabled]="isOperationsDisabled">
    <ng-template mat-tab-label>{{ 'OPERAZIONI.OPERATIONS' | translate }}</ng-template>

    <!--SCORRO LE EVENTUALI OPERAZIONI DI STORNA/CONFERMA SINGOLE EFFETTUATE DALL'ADMIN-->
    <div #containerOps style="overflow-y: scroll; padding: 20px" class="transactionCol">
      <div fxLayout="row" fxLayoutAlign="left" #transactions *ngFor="let transaction of adminOp; index as i"
        style="padding: 20px; border-bottom: 1px solid #d5d5d5;">
        <div fxLayout="column">
          <div class="paymentInfo"><strong>{{ 'OPERAZIONI.TOTAL_AMOUNT' | translate }}:
            </strong>{{success.amount.replace('.', ',') }}</div>
          <div class="paymentInfo capitalize"><strong>{{ 'OPERAZIONI.USER' | translate }}: </strong>{{adminName}}
          </div>
          <div class="paymentInfo"><strong>{{ 'OPERAZIONI.DATE' | translate }}:
            </strong>{{transaction.date | date:'dd/MM/yyyy HH:mm:ss' }}</div>
          <div class="paymentInfo capitalize"><strong>{{ 'OPERAZIONI.OUTCOME' | translate }}:
            </strong>{{'CONTROLLASTATOTRANSA.'+ transaction.response.rc | translate}}</div>
          <div *ngIf="transaction.type==='CONFIRM' && transaction.amount" class="paymentInfo">
            <strong>{{ 'OPERAZIONI.CONFIRMED_AMOUNT' | translate }}: </strong>
            {{ transaction.amount }}
          </div>
          <div *ngIf="(transaction.type==='CREDIT' || transaction.type==='VOID') && transaction.amount"
            class="paymentInfo">
            <strong>{{ 'OPERAZIONI.TRANSFERED_AMOUNT' | translate }}: </strong>
            {{ transaction.amount }}
          </div>
          <div *ngIf="transaction.response.pendingAmount" class="paymentInfo">
            <strong>{{ 'OPERAZIONI.PENDING' | translate }}: </strong>{{
            transaction.response.pendingAmount.replace(".", ",") }}
          </div>
        </div>
      </div>

      <div *ngFor="let transaction of operations">
        <!--BOTTONIERA PER CONFERMA/STORNA/STORNA AUTH-->
        <div #buttons>
          <app-operation-buttons
            *ngIf="authService.user?.isConfirmActive && element?.transaction_type !== 'VERIFY' && transaction.pendingAmount !== '0.00'"
            [isAdmin]="true" [type]="transaction.operation" [maxAmount]="transaction.pendingAmount"
            [paymentId]="element._id" [tranId]="transaction.refTranId" [shopID]="transaction.refShopID"
            (refreshOutput)="refreshTransactions()"></app-operation-buttons>
        </div>
      </div>
      <div class="extraBlock"></div>
    </div>
  </mat-tab>
</mat-tab-group>

<!--LAYOUT DETTAGLIO RICORRENTI E DILAZIONATI-->
<mat-tab-group *ngIf="element?.payment_type !== 'SINGLE' && transactions?.length > 0"
  (selectedTabChange)="getTransactions()" [(selectedIndex)]="selectedTabIndex">
  <mat-tab>
    <ng-template mat-tab-label>{{ 'OPERAZIONI.TRANSACTIONS' | translate }}</ng-template>

    <!--LAYOUT DEDICATO DETTAGLIO TRX-->
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="30%" style="padding: 20px;">

      </div>
      <div fxFlex="70%" style="height: 860px; overflow-y: scroll; padding: 20px">

        <!--SCORRO LE TRANSAZIONI UTENTE-->
        <div *ngFor="let transaction of transactions; index as i">
          <div fxLayout="row" style="margin-bottom: 20px;">

            <div fxFlex="90%" fxLayout="column">
              <div *ngIf="transaction.response.accountName" class="paymentInfoRecurrent">
                <strong>{{ 'OPERAZIONI.USER' | translate }}: </strong>{{transaction.response.accountName}}
              </div>
              <div class="paymentInfoRecurrent"><strong>{{ 'OPERAZIONI.DATE' | translate }}:
                </strong>{{transaction.date | date:'dd/MM/yyyy HH:mm:ss' }}</div>
              <div class="paymentInfoRecurrent"><strong>{{ 'RICHIEDIPAGAMENTO.IMPORTO' | translate }}: </strong>{{
                transaction.amount | number: '1.0' : 'it' }} €</div>
              <div *ngIf="transaction.response.paymentID" class="paymentInfoRecurrent capitalize"><strong>{{
                  'OPERAZIONI.PAYMENTID' | translate }}:
                </strong>{{ transaction.response.paymentID }} </div>
              <div *ngIf="transaction.response.shopID" class="paymentInfoRecurrent capitalize">
                <strong>Shop ID:</strong> {{ transaction.response.shopID }}
              </div>
              <div *ngIf="transaction.recurrent" class="paymentInfoRecurrent capitalize">
                <strong>{{'OPERAZIONI.RATAID' | translate }}:</strong> {{ transaction.recurrent }}
              </div>
              <div *ngIf="transaction.occurence" class="paymentInfoRecurrent capitalize">
                <strong>{{'OPERAZIONI.NUMERORATA' | translate }}:</strong> {{ transaction.occurence }}
              </div>
              <div *ngIf="transaction.response.authCode" class="paymentInfoRecurrent capitalize">
                <strong>Auth Code:</strong> {{ transaction.response.authCode }}
              </div>
              <div *ngIf="transaction.response.maskedPan" class="paymentInfoRecurrent capitalize">
                <strong>{{'OPERAZIONI.NUMEROCARTA' | translate }}:</strong> {{ transaction.response.maskedPan }}
              </div>
              <div class="paymentInfoRecurrent capitalize flex-center-vertical"><strong>{{ 'OPERAZIONI.CIRCUITO' |
                  translate }}:</strong>
                <img alt="card icon" *ngIf="transaction.response.brand" style="width: 40px; margin-left: 5px;"
                  [src]="ASSETS_BASE + 'svg/circuiti/'+ transaction.response.brand + '.png'">
                <img alt="unknown card icon" *ngIf="!transaction.response.brand" style="width: 40px; margin-left: 5px;"
                  [src]="ASSETS_BASE + 'svg/circuiti/credit-card-empty-icon.png'">
              </div>
              <div class="paymentInfoRecurrent capitalize flex-center-vertical"><strong>{{ 'OPERAZIONI.ESITO3DS' |
                  translate }}:</strong>
                <img alt="shield icon" style="height: 30px;"
                  [src]="ASSETS_BASE + 'svg/iconsvg/scudo-'+ calcShieldColor(transaction.response) +'.svg'">
              </div>
              <div class="paymentInfoRecurrent capitalize flex-center-vertical"><strong>{{ 'OPERAZIONI.OUTCOME' |
                  translate }}:</strong>
                <img alt="done icon" *ngIf="transaction.response?.rc === 'IGFS_000'"
                  style="height: 20px; margin-left: 5px;" [src]="ASSETS_BASE + 'svg/iconsvg/ok.svg'">
                <img alt="active icon" *ngIf="transaction.response ? transaction.response?.rc !== 'IGFS_000' : true"
                  style="height: 20px; margin-left: 5px;" [src]="ASSETS_BASE + 'svg/iconsvg/x.svg'">
              </div>

              <div *ngIf="transaction.response.pendingAmount" class="paymentInfoRecurrent">
                <strong>{{ 'OPERAZIONI.PENDING' | translate }}: </strong>{{
                transaction.response.pendingAmount.replace('.', ',') }}
              </div>
              <div *ngIf="transaction.response.paymentID || transaction.receipt" fxLayout="row">
                <div class="paymentInfoRecurrent"><strong>{{ 'OPERAZIONI.RECEIPT' | translate }}: </strong></div>
                <button class="iconButton" (click)="downloadReceipt(transaction)">
                  <mat-icon svgIcon="download"></mat-icon>
                </button>
              </div>
            </div>
          </div>

          <!--BOTTONIERA PER CONFERMA/STORNA/STORNA AUTH-->
          <div style="margin-bottom: 20px;">
            <app-operation-buttons
              *ngIf="transaction.response.rc === 'IGFS_000' && transaction?.operation_type === 'PURCHASE'"
              [isAdmin]="false" [type]="element?.transaction_type" [maxAmount]="transaction?.amount"
              [paymentId]="element._id" [tranId]="transaction.response.tranID" [shopID]="transaction.response.shopID"
              [recurrentId]="transaction?.recurrent" (refreshOutput)="refreshTransactions()"></app-operation-buttons>

            <button *ngIf="transaction.response.rc !== 'IGFS_000' && transaction.recurrent" class="overlayButton"
              (click)="notification(transaction.recurrent)">{{ 'CONTROLLA.SOLLECITA' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>{{ 'OPERAZIONI.OPERATIONS' | translate }}</ng-template>

    <!--SCORRO LE EVENTUALI OPERAZIONI DI STORNA-->
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="30%" style="padding: 20px;">

      </div>
      <div fxFlex="70%" style="height: 860px; overflow-y: scroll; padding: 20px">

        <!--SCORRO LE TRANSAZIONI UTENTE-->
        <div *ngFor="let transaction of operations; index as i">
          <div fxLayout="row" style="margin-bottom: 20px;">

            <div fxFlex="90%" fxLayout="column">
              <div *ngIf="transaction.response.accountName" class="paymentInfoRecurrent">
                <strong>{{ 'OPERAZIONI.USER' | translate }}: </strong>{{transaction.response.accountName}}
              </div>
              <div class="paymentInfoRecurrent"><strong>{{ 'OPERAZIONI.DATE' | translate }}:
                </strong>{{transaction.date | date:'dd/MM/yyyy HH:mm:ss' }}</div>
              <div class="paymentInfoRecurrent"><strong>{{ 'RICHIEDIPAGAMENTO.IMPORTO' | translate }}: </strong>{{
                transaction.amount | number: '1.0' : 'it' }} €</div>
              <div *ngIf="transaction.response.paymentID" class="paymentInfoRecurrent capitalize"><strong>{{
                  'OPERAZIONI.PAYMENTID' | translate }}:
                </strong>{{ transaction.response.paymentID }} </div>
              <div *ngIf="transaction.response.paymentID" class="paymentInfoRecurrent capitalize"><strong>{{
                  'OPERAZIONI.OPERATION_TYPE' | translate }}:
                </strong>{{ 'OPERAZIONI.' + transaction.operation_type | translate }} </div>
              <div class="paymentInfoRecurrent capitalize flex-center-vertical"><strong>{{ 'OPERAZIONI.OUTCOME' |
                  translate }}:</strong>
                <img alt="done icon" *ngIf="transaction.response?.rc === 'IGFS_000'"
                  style="height: 20px; margin-left: 5px;" [src]="ASSETS_BASE + 'svg/iconsvg/ok.svg'">
                <img alt="active icon" *ngIf="transaction.response?.rc && transaction.response?.rc !== 'IGFS_000'"
                  style="height: 20px; margin-left: 5px;" [src]="ASSETS_BASE + 'svg/iconsvg/x.svg'">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>