import {AbstractControl, ValidationErrors} from '@angular/forms';

export function FilterValidator(formGroup: AbstractControl): ValidationErrors | null {
  for (const key in formGroup.value) {
    if (formGroup.value.hasOwnProperty(key)) {
      if (formGroup.value[key]) {
        return null;
      }
    }
  }
  return {noValorizedField: true};
}
