export const DEFAULT_TEMPLATE_PAGE = `


    

    
<style type="text/css">
    body,h1,h2,h3,h4,h5,h6,p,div,section,span{
        font-family:'Inter',sans-serif !important;
        color:#3c3c3b;
    }
    body{
        background-color:white;
    }
    h1{
        font-weight:600;
    }
    h2{
        font-weight:400;
    }
    h3{
        font-size:21px;
        font-weight:200;
        margin:10px 0;
        font-family:'Inter',sans-serif !important;
    }
    main,.main{
        display:block;
        min-width:290px;
        margin:auto;
    }

    .section{
        max-width:940px;
        margin:auto;
    }
    .main-style{
        color:#3c3c3b;
        background-color:white;
        margin:0;
        font-family:'Inter',sans-serif !important;
    }

    
@media (max-width: 801px){
    
    .payment{
        width:100%;
        height:auto;
        margin:20px 0;
    }
    .payment h1{
        font-size:65px;
    }
    .label-fixed-payment{
        font-size:25px;
        font-weight:200;
        margin:10px 0;
        color:#3c3c3b;
    }
    .label-free-payment{
        font-size:25px;
        font-weight:200;
        margin:10px 0;
        color:#3c3c3b;
    }
    .amount{
        font-weight:400;
        font-size:70px;
        /* margin:0 20px; */
        color:#3C3C3B;
    }
}
@media (max-width: 801px){
    .amount{
        font-size:3rem !important;
        /* margin:0 20px; */
        color:#3C3C3B;
    }

}   @media (max-width: 801px){
    .label-fixed-payment{
        font-size:23px;
    }

}   @media (max-width: 520px){
    .amount{
        font-size:2.2rem !important;
        margin:0 20px;
        color:#3C3C3B;
    }

}   

@media (max-width: 520px){
    .label-fixed-payment{
        font-size:20px;
    }

}   
    .button-pay{
        width:100%;
        height:auto;
        margin-top:30px;
    }
    .box-button{
        margin:0 auto;
    }
    .send-link{
        width:200px;
        background-color:#45beaa;
        padding:20px 0;
        color:#fff;
        font-size:18px;
        border-radius:60px;
        margin:20px auto;
        display:block;
        text-align:center;
        text-decoration:none;
    }
    
    #submit-button {
        width:200px;
        background-color:#45beaa;
        padding:20px 0;
        color:#fff;
        font-size:18px;
        border-radius:60px;
        margin:20px auto;
        display:block;
        text-align:center;
        text-decoration:none;
        border: none;
        cursor: pointer;
    }
    #submit-button:disabled {
        background-color: #A1A1A1;
        cursor: not-allowed;
    }

    #submit-button:focus {
        outline: none;
    }

    a:link { color:#fff; text-decoration: none; }
    a:visited { color:#fff; text-decoration: none; }
    a:hover { color:#fff; text-decoration: none; }
    a:active { color:#fff; text-decoration: none; }
    
</style>

<div class="main-style">
    <div class="main-container">
        <form name="paymentForm" action="/clicpay/execute" method="post">

            <div class="payment">
                <div class="section">
                    <div class="container">
                        {{#unless isAmountFree}}
                            {{#if isDonation}}
                                <h3 class="label-free-payment">Importo della donazione</h3>
                            {{else}}
                                <h3 class="label-fixed-payment">Importo:</h3>
                            {{/if}}
                            <h1 class="amount">{{amount}} €</h1>
                        {{else}}
                            {{#if isDonation}}
                                <h3 class="label-free-payment">Importo della donazione</h3>
                            {{else}}
                                <h3 class="label-fixed-payment">Importo:</h3>
                            {{/if}}
                            <div class="mui-form-group">
                                <input type="tel" id="maskInput" onkeypress='validate(event)' onkeydown="send(event)" onkeyup="send(event)" min="0.01" step="0.01" max="250000" value="0,00" class="mui-form-control" required> €
                                <span class="bar"></span>
                                <input type="hidden" id="input" name="amount">
                            </div>
                            
                        {{/unless}}

                        {{#each informations}}
                        <div class="mui-form-group">
                            <span class="label-note">{{label}}</span>
                            <span class="bar"></span>
                        </div>
                        {{/each}}

                        {{#each dynamic_informations}}
                         {{#if isVisible}}
                                <div class="mui-form-group">
                                    {{#if isEnabled}}
                                        <h3 class="label-note">{{label}}</h3>
                                    {{else}}
                                        <h3 class="label-note" style="color: grey;">{{label}}</h3>
                                    {{/if}}
                                    <input id="{{name}}" type="{{type}}" name="{{name}}" value="{{{default}}}" {{#if isRequired}}required{{/if}} {{#unless isEnabled}}disabled{{/unless}} onchange="send(event)" onload="send(event)" onkeydown="send(event)" onkeyup="send(event)" class="dynamic_info"></input>
                                </div>
                            {{/if}}
                        {{/each}}

                    </div>
                </div>
            </div>

            <div class="button-pay">
                <div class="section">
                    <div class="container">
                        <div class="box-button">
                            <button id="submit-button" onclick="document.paymentForm.submit()">{{#if isDonation}}DONA{{else}}PAGA{{/if}}</button>
                            <input type="hidden" name="payment" value="{{_id}}"/>
                        </div>
                        {{#if attachment}}
                            <div class="attachment-download">
                                <div class="attachment-download">
                                    <a href="{{attachment}}">
                                        <span>{{attachment_label}}</span>
                                        <img src="https://clicpay.worldlineitalia.it/clicpay/images/attachment.png" class="attachment-icon">
                                    </a>
                                </div>
                            </div>
                        {{/if}}
                    </div>
                </div>
            </div>

        </form>

    </div>
</div>
{{#if isQueryString}}
    <script src="../../clicpay/js/query-string.js"></script>
{{/if}}
<script src="../../clicpay/js/simple-mask-money.js"></script>
<script src="../../clicpay/js/simple-mask-money-impl.js"></script>


`