import { Template } from './../_models/template';
import { Payment } from '../_models/payment';

// eslint-disable-next-line no-restricted-imports
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { map, catchError } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MerchantService } from './merchant.service';

@Injectable()
export class TemplateService {
  private readonly TEMPLATE_URL: string = environment.BASE_URL + 'template';
  variablesSchema: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translate: TranslateService,
    private merchantService: MerchantService) {

    this.variablesSchema = {
      amount: { label: this.translate.instant('RICHIEDIPAGAMENTO.IMPORTO'), value: '{{amount}}' },
      currency: { label: this.translate.instant('PROFILO.VALUTA'), value: '{{currency}}' },
      description: { label: this.translate.instant('PROFILO.DESCRIZIONE'), value: '{{description}}' },
      name: { label: this.translate.instant('PROFILO.NOME'), value: '{{name}}' },
      merchant: { label: this.translate.instant('PROFILO.MERCHANT'), value: '{{merchant.name}}' },
      insegna: { label: this.translate.instant('PROFILO.INSEGNA'), value: '{{merchant.description}}' },
      facebook: { label: this.translate.instant('PROFILO.FACEBOOK'), value: '{{merchant.facebook}}' },
      twitter: { label: this.translate.instant('PROFILO.TWITTER'), value: '{{merchant.twitter}}' },
      linkedin: { label: this.translate.instant('PROFILO.LINKEDIN'), value: '{{merchant.linkedin}}' },
      site: { label: this.translate.instant('PROFILO.SITO'), value: '{{merchant.website}}' },
      info_1: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_1'), value: '{{informations.[0]}}' },
      info_2: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_2'), value: '{{informations.[1]}}' },
      info_3: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_3'), value: '{{informations.[2]}}' },
      info_4: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_4'), value: '{{informations.[3]}}' },
      info_5: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_5'), value: '{{informations.[4]}}' }
    };
    this.onChangeLang();
   }

   onChangeLang() {
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      return this.variablesSchema = {
        amount: { label: this.translate.instant('RICHIEDIPAGAMENTO.IMPORTO'), value: '{{amount}}' },
        currency: { label: this.translate.instant('PROFILO.VALUTA'), value: '{{currency}}' },
        description: { label: this.translate.instant('PROFILO.DESCRIZIONE'), value: '{{description}}' },
        name: { label: this.translate.instant('PROFILO.NOME'), value: '{{name}}' },
        merchant: { label: this.translate.instant('PROFILO.MERCHANT'), value: '{{merchant.name}}' },
        insegna: { label: this.translate.instant('PROFILO.INSEGNA'), value: '{{merchant.description}}' },
        facebook: { label: this.translate.instant('PROFILO.FACEBOOK'), value: '{{merchant.facebook}}' },
        twitter: { label: this.translate.instant('PROFILO.TWITTER'), value: '{{merchant.twitter}}' },
        linkedin: { label: this.translate.instant('PROFILO.LINKEDIN'), value: '{{merchant.linkedin}}' },
        site: { label: this.translate.instant('PROFILO.SITO'), value: '{{merchant.website}}' },
        info_1: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_1'), value: '{{informations.[0]}}' },
        info_2: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_2'), value: '{{informations.[1]}}' },
        info_3: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_3'), value: '{{informations.[2]}}' },
        info_4: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_4'), value: '{{informations.[3]}}' },
        info_5: { label: this.translate.instant('VARIABLES_SCHEMA.INFO_5'), value: '{{informations.[4]}}' },
      };
    });
   }

  /**
   * Metodo per recuperare la lista dei payment
   * @param payment (string) - nome del payment per effetture la ricerca
   */
  list(params: HttpParams): Observable <{} | Template[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.get<any>(this.TEMPLATE_URL, { headers, params })
    .pipe(
      map(
        (response: any) => response.items as Template[]
      ),
      catchError(
        _ => of([])
      )
    )
  }

  /**
   * Metodo per creare un nuovo payment
   * @param payment Model payment del payment
   */
  create(payment: Payment): Observable <{} | any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.post(this.TEMPLATE_URL, payment, { headers });
  }

  search( nome: string): Observable <{} | any[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let params = new HttpParams();

    if (nome != null && nome !== undefined && nome !== '') {
      params = params.set('name', nome);
    }

    return this.http.get<{} | any[]>(this.TEMPLATE_URL, { headers, params });
  }

  getById( id: string): Observable <{} | any[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    return this.http.get<{} | any[]>(this.TEMPLATE_URL + '/' + id, { headers }).pipe(
      map((response: any) => response.item as Template)
    )
  }

  getStandard(): Observable <Array<Template>> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    let params = new HttpParams();
    params = params.set('type', 'CUSTOMER');
    params = params.set('active', 'true');
    params = params.set('class', 'STANDARD');

    return this.http.get<{} | any[]>(this.TEMPLATE_URL, { headers, params })
    .pipe(
      map(
        (response: any) => response.items as Template[]
      ),
      catchError(
        _ => of([])
      )
    )
  }

  getCustomByMerchant(merchantId: string): Observable <Array<Template>> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    let params = new HttpParams();
    params = params.set('type', 'CUSTOMER');
    params = params.set('active', 'true');
    params = params.set('class', 'CUSTOM');
    params = params.set('merchant', merchantId);

    return this.http.get<{} | any[]>(this.TEMPLATE_URL, { headers, params })
    .pipe(
      map(
        (response: any) => response.items as Template[]
      ),
      catchError(
        _ => of([])
      )
    )

  }

  getDefaultTemplate(): Observable <Template> {
    const nomeTemplate = 'CLICPAY_EMAIL_LNG_WL';
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let params = new HttpParams();

    params = params.set('name', nomeTemplate);

    return this.http.get<any>(this.TEMPLATE_URL, { headers, params }).pipe(
      map(value => value.items[0])
    )
  }

  createNewTemplate(body) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.post(this.TEMPLATE_URL, body, { headers });
  }

  addContentSms(_id: string, sms: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    let body = {
      state: 'EDIT',
      sms: sms
    };

    if (this.authService.user.type == "HOLDING"){
      body['merchant'] = this.merchantService.currentMerchant._id;
    }

    return this.http.put(this.TEMPLATE_URL + '/' + _id, body, { headers })
    .pipe(map((res: any) => res.item as Template))
  }

  addContentEmailPage(_id: string, body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let encodBody = new HttpParams();
    encodBody = encodBody.set('merchant', body.merchant);
    encodBody = encodBody.set('content', body.content);
    encodBody = encodBody.set('subject', body.subject);
    encodBody = encodBody.set('language', body.language);
    encodBody = encodBody.set('type', body.type);
    encodBody = encodBody.set('text', body.text);
    encodBody = encodBody.set('title', body.title);

    return this.http.put(this.TEMPLATE_URL + '/add/content/' + _id, encodBody, { headers })
    .pipe(
      map((res: any) => res.item as Template)
    )
  }

  delete(templateId: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.delete(this.TEMPLATE_URL + '/' + templateId, { headers });
  }

  update(_id: string, body: any): Observable<Template> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    if (this.authService.user.type == "HOLDING"){
      body = body || {};
      if (!body['merchant']) {
        body['merchant'] = this.merchantService.currentMerchant._id;
      }
    }

    return this.http.put(this.TEMPLATE_URL + '/' + _id, body, { headers }).pipe(
      map((res: any) =>res.item)
    )
  }

}
