import { Subscription } from 'rxjs';
import { LoaderService } from '../../_services/loader.service';
import {Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked} from '@angular/core';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})

export class LoaderComponent implements OnDestroy, AfterViewChecked {
  loaderStateSubscription: Subscription;
  isStartGeneral = false;

  public lottieConfig: Object;
  private anim: any;
  private animationSpeed = 1;

  constructor(private loaderService: LoaderService, private cdRef: ChangeDetectorRef) {
    this.lottieConfig = {
      path: 'assets/loader-clicpay.json',
      autoplay: true,
      loop: true
    };
  }

  ngOnDestroy() {
    if (this.loaderStateSubscription != undefined) {
      this.loaderStateSubscription.unsubscribe();
    }

  }
  ngAfterViewChecked() {
    this.loaderStateSubscription = this.loaderService.generalLoader$.subscribe((state) => {
      this.isStartGeneral = state;
      this.cdRef.detectChanges();

    });
    this.animationSpeed = 2.5;
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }
}
