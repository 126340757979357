<span [ngClass]="theme">
  <h2 mat-dialog-title class="titleCentered">{{"DIALOG.PAYMENT_LINK" | translate}}</h2>

  <mat-dialog-content class="dialogContent">
    <div fxLayout="row" fxLayoutAlign="space-around" style="padding-top: 7px;">
      <div *ngIf="displayedLink" class="dettaglio">{{displayedLink}}</div>
      <mat-icon class="action-icons" svgIcon="duplica" matTooltip="{{'DIALOG.COPY_TOOLTIP' | translate}}"
        (click)="copyToClipboard()"></mat-icon>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="singleButtonDialog respectPadding">
    <button mat-flat-button color="primary" [mat-dialog-close]="true"
      class="rounded-button buttonDialog">{{'DIALOG.CLOSE' | translate}}</button>
  </mat-dialog-actions>
</span>
