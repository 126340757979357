import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { counties } from '../../_models/country';
import { environment } from '../../../environments/environment';
import { AvailableEmailValidator } from '../../_validators/available-email.directive';
import { ProfileService } from '../../_services/profile.service';
import { Profile } from '../../_models/profile';
import { User } from '../../_models/user';
import { UserEmailValidator } from '../../_validators/user-email-validator';
import { MerchantService } from '../../_services/merchant.service';
import { WritableService } from '../../_services/writable.service';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent implements OnInit {
  // @Input() disabledFields = [];
  @Input() isSelfEnrollment?: boolean = false;
  @Input() userList?: User[];
  // @Input() isProfile: boolean = false;
  @Input() set userData(value: User) {
    if (!value) {return};
    this._userData = value;
    this.buildUserForm();
    //NEL CASO IN CUI STO AGGIUNGENDO UTENTI NELLA VIEW SELF ENROLLMENT
    //ESCO SENZA FARE SUBSCRIBE AI CAMBIAMENTI DEL FORM (INVIO MANUALE DEI VALORI)
    if (!this.userData._id && this.isSelfEnrollment) {return; }

    //PER LE ALTRE VISTE INVECE FACCIO SUBSCRIBE AI CAMBIAMENTI E VALORIZZO IL FORM CON I DATI DELL'UTENTE NOTO
    this.onChanges();
    this.userForm.patchValue(this.userData);
    if (this.merchantService.currentMerchant && localStorage.hasOwnProperty('clicpay-merchant')) this.userForm.get('merchant').setValue(this.merchantService.currentMerchant.name);
    if (!this.merchantService.currentMerchant && this.userData._id) this.userForm.get('type').disable();
  };

  @Input() set disabledFields(fields: string[]) {
    if (fields) this.checkDisabledFields(fields);
  };

  @Input() set isProfile (value: boolean) {
    this._isProfile = value;
  };

  @Output() outputFormValues: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() newUser: EventEmitter<User> = new EventEmitter<User>();

  ASSETS_BASE: string = environment.ASSETS_BASE_PATH;

  userForm: UntypedFormGroup;

  _userData: User;
  get userData() {
    return this._userData;
  }

  _isProfile: boolean = false;

  ruolo = [
    { value: 'ADMIN', viewValue: 'Admin' },
    { value: 'OPERATOR', viewValue: 'Operatore' },
    { value: 'USER', viewValue: 'Utente' }
  ];
  tipo = [
    { value: 'SUPPLIER', viewValue: 'BNL Positivity' },
    { value: 'HOLDING', viewValue: 'Cliente Holding' }
  ];
  stato = [
    { value: true, viewValue: 'Attivo' },
    { value: false, viewValue: 'Disattivo' }
  ];
  language = [
    { value: 'IT', viewValue: 'Italiano' },
    { value: 'EN', viewValue: 'Inglese' }
  ];

  countryPrefix = counties;

  profili: Array<Profile> = null;

  writable: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private availableValidator: AvailableEmailValidator,
    private profileService: ProfileService,
    private writableService: WritableService,
    public merchantService: MerchantService,
  ) {
    this.writableService.isWritable.subscribe((bool) => {
      this.writable = bool;
    });
  }

  ngOnInit() {
    // CARICO I PROFILI DISPONIBILI PER IL MERCHANT CORRENTE SE QUESTO ESISTE
    if (this.merchantService.currentMerchant) {
      this.profileService.searchProfiles(
        [{key: 'merchant', value: this.merchantService.currentMerchant._id}],
        'ASC', null, 0, true)
        .subscribe( res => {
        if (res.items.length > 0) {
          this.profili = <Profile[]>res.items;
        }
      });
    }
  }

  buildUserForm() {
    this.userForm = this.fb.group({
      email: [ this.userData ? this.userData.email : null, [Validators.required, Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/)], [this.availableValidator.validateEmail(this.userData ? this.userData.email : null)]],
      first_name: [ null, [Validators.required] ],
      last_name: [ null, [Validators.required] ],
      password: [ null, this.userData?._id ? null : [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?["£$%&/()=?])[a-zA-Z0-9"£$%&/()=?]{8,20}$')]],
      phone: [ '' ],
      smartphone: [ null , [Validators.required] ],
      fax: [ null ],
      language: [ 'IT' ],
      type: [ 'CUSTOMER', !this.merchantService.isAdminMode ? [Validators.required] : null ],
      merchant: [ null, this.userData.type !== 'CUSTOMER' ? null : [Validators.required] ],
      role: [null, [Validators.required] ],
      active: [ true ],
      area_code: [ '+39' ],

      isCreatePaymentRequestActive: [false],
      isConfirmActive: [false],
      isBulkActive: [false],
      isEmailActive: [false],
      isSmsActive: [false],
      isQrcodeActive: [false],
      isLinkActive: [false],
      isWhatsAppActive: [false],
      isSupervisor: [false],

      profile: [null],

    });

    //NEL CASO IN CUI STIA AGGIUNGENDO UTENTI NELLA VIEW SELF ENROLLMENT SETTO
    //IL VALIDATOR CHE SFOGLIA LA LISTA DEGLI UTENTI IN ATTESA
    //E CONTROLLA CHE NON VE NE SIANO CON LA STESSA MAIL
    //ESCO SENZA FARE SUBSCRIBE AI CAMBIAMENTI DEL FORM (INVIO MANUALE DEI VALORI)
    if (!this.userData._id && this.isSelfEnrollment) {
      this.userForm.controls.email.setValidators([Validators.required, Validators.pattern(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,12})?$/), UserEmailValidator(this.userList)]);
    }
    // this.checkDisabledFields();
  }

  sceltatipo(event: any) {
    if (event.value === 'HOLDING') {
      this.userForm.controls.role.disable();
      this.userForm.controls.role.setValue('');
    } else {
      this.userForm.controls.role.enable();
    }
  }

  onChanges(): void {
    this.userForm.statusChanges.subscribe(() => {
      this.outputFormValues.emit(this.userForm);
    });
  }


  changedProfile($event: any) {
    const currentProfile: Profile = this.profili.find(x => x._id === $event.value);
    this.userForm.patchValue(currentProfile);
  }

  //METODO PER IL PUSH NUOVI UTENTI NELLA VISTA SELF ENROLLMENT
  pushUser() {
    this.newUser.emit(this.userForm.value);
    this.buildUserForm();
  }

  checkDisabledFields(fields: string[]) {
    fields.forEach((field: string) => {
      this.userForm.get(field).disable();
    });
  }

}
