<mat-card class="footer" style="border-top: solid 1px #e2e2e2" fxFlex="100" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="center center" ngClass.xs="footerXS">
    <div>
      <a class="linkfooter" ngClass.xs="borderRight" href="{{url + 'chi-siamo'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.DATISOCIETARI' | translate }}">{{'MENUFOOTER.DATISOCIETARI' | translate }}</a>
      <a class="linkfooter" href="{{url + 'chi-siamo'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.REQUISITIDISISTEMA' | translate }}">{{'MENUFOOTER.REQUISITIDISISTEMA' | translate }}</a>
    </div>
    <div ngStyle.xs="margin-top: 5px">
      <a class="linkfooter" ngClass.xs="borderRight" href="{{url + 'privacy'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.PRIVACY' | translate }}">{{'MENUFOOTER.PRIVACY' | translate }}</a>
      <a class="linkfooter" ngClass.xs="borderRight" href="{{url + 'cookie'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.COOKIE' | translate }}">{{'MENUFOOTER.COOKIE' | translate }}</a>
      <a class="linkfooter" href="{{url + 'codice-etico'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.CODICEETICO' | translate }}">{{'MENUFOOTER.CODICEETICO' | translate }}</a>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" ngStyle.lt-md="margin-top: 5px" ngClass.xs="footerXS">
    <div ngStyle.xs="width: 100%" >
      <a class="linkfooter" href="{{url + 'reclami-ricorsi-conciliazione'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.RECLAMI' | translate }}-{{'MENUFOOTER.RICORSI' | translate }}-{{'MENUFOOTER.CONCILIAZIONE' | translate}}" >{{'MENUFOOTER.RECLAMI' | translate }}-{{'MENUFOOTER.RICORSI' | translate }}-{{'MENUFOOTER.CONCILIAZIONE' | translate}}
      </a>
    </div>
    <div ngStyle.xs="margin-top: 5px">
      <a class="linkfooter" ngClass.xs="borderRight" href="{{url + 'lavora-con-noi'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.CARRIERE' | translate }}" >{{'MENUFOOTER.CARRIERE' | translate }}</a>
      <a class="linkfooter" ngClass.xs="borderRight" href="{{url + 'note-legali'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.NOTELEGALI' | translate }}" >{{'MENUFOOTER.NOTELEGALI' | translate }}</a>
      <a class="linkfooter" ngStyle.xs="padding: 0 20px; margin-top: 0" href="{{url + 'settori'}}" target="_blank" attr.aria-label="{{'MENUFOOTER.SITEMAP' | translate }}" >{{'MENUFOOTER.SITEMAP' | translate }}</a>
    </div>
  </div>
</mat-card>
