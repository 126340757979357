import { HoldingService } from './../../_services/holding.service';
import { switchMap, debounceTime, skipWhile, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { IAsyncFiltersHolding } from './../../_models/filters-holding';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '../../_services/loader.service';
import { DialogService } from '../../_services/dialog.service';
import { HoldingStatsInfo, HoldingTableMerchant } from '../../_models/holding-table-merchant';

@Component({
  selector: 'app-holding-home',
  templateUrl: './holding-home.component.html',
  styleUrls: ['./holding-home.component.scss']
})
export class HoldingHomeComponent implements OnInit {

  private asyncFiltersSbj: BehaviorSubject<IAsyncFiltersHolding> = new BehaviorSubject(null);
  private syncFiltersSbj: BehaviorSubject<string> = new BehaviorSubject(null);

  set asyncFilters(newValue) {
    if (!newValue || (!newValue.from_date && !newValue.paymentId && !newValue.province)) {
      this.asyncFiltersSbj.next(null)
    } else {
      this.asyncFiltersSbj.next(newValue)
    }
  }

  get asyncFilters() {
    return this.asyncFiltersSbj.getValue();
  }

  set syncFilters(newValue: string) {
    this.syncFiltersSbj.next(newValue)
  }

  infoStatusPayment: HoldingStatsInfo = new HoldingStatsInfo();
  merchantsFiltred: MatTableDataSource<HoldingTableMerchant> = new MatTableDataSource();

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private holdingService: HoldingService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
  ) {
    this.iconRegistry.addSvgIcon(
      'downloadIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/download.svg')
    );
  }

  ngOnInit() {
    this.subscribeToAsyncFilters();

    this.subsribeToSyncFilters();
  }



  getInitialStats() {
    return this.holdingService.getStatsHolding(null).pipe(
      tap(
        results => {
          this.infoStatusPayment = new HoldingStatsInfo(results.total)
          this.updateTableWithStatistics(results.result);
        },
      ))
  }


  initialize() {
    return this.holdingService.getMerchantsHolding().pipe(
      tap(
        merchants => {
          this.merchantsFiltred.data = merchants;
          this.loaderService.stopGeneral();
        },
      ),
      switchMap(_ => this.getInitialStats())
    )
  }

  refreshTable(filters: IAsyncFiltersHolding) {
    this.loaderService.startGeneral();
    return !filters ? this.initialize() : this.holdingService.getStatsHolding(filters);
  }


  calcAmountAfterSyncFilter(filteredData: HoldingTableMerchant[]) {
    this.infoStatusPayment = HoldingStatsInfo.calcTotal(filteredData);
  }

  updateTableWithStatistics(merchantStats: HoldingTableMerchant[]) {
    this.merchantsFiltred.data.forEach(merchant => {
      let statsData = merchantStats.find(stat => stat._id === merchant._id);
      merchant.assignStatsData(statsData);
    })
  }

  subscribeToAsyncFilters() {
    this.asyncFiltersSbj.pipe(
      switchMap((filters: IAsyncFiltersHolding) => this.refreshTable(filters)))
      .subscribe(
        (response: any) => {
          this.infoStatusPayment = new HoldingStatsInfo(response.total);
          if (this.asyncFilters) {
            this.merchantsFiltred.filter = '';
            this.merchantsFiltred.data = response.result;
          }
          this.loaderService.stopGeneral();
        },
        error => {
          this.loaderService.stopGeneral();
          this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
        }
      )
  }

  subsribeToSyncFilters() {
    this.syncFiltersSbj.pipe(
      skipWhile(filters => !filters),
      debounceTime(500),
    ).subscribe(
      syncFilters => {
        this.merchantsFiltred.filterPredicate = (data: any, filter: any) =>  data.name && data.name.toLowerCase().includes(filter) ? true : data.insegna && data.insegna.toLowerCase().includes(filter) ? true : false;
        this.merchantsFiltred.filter = syncFilters;
        this.calcAmountAfterSyncFilter(this.merchantsFiltred.filteredData)
      }
    )
  }


}
