import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../_services/auth.service';
import { MerchantService } from '../../../../_services/merchant.service';
import { LoaderService } from '../../../../_services/loader.service';
import { DialogService } from '../../../../_services/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription, forkJoin, filter } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../_services/user.service';
import { WritableService } from '../../../../_services/writable.service';
import { User } from '../../../../_models/user';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = ['last_name', 'role', 'email', 'smartphone', 'first_access', 'actions'];
  dataSource: MatTableDataSource<User> = new MatTableDataSource();

  private watcher: Subscription;
  private type: string;
  private idMerchant: string;

  constructor(
    public authService: AuthService,
    private merchantService: MerchantService,
    private userService: UserService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private router: Router,
    private mediaObserver: MediaObserver,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private writableService: WritableService,
  ) {
    this.iconRegistry.addSvgIcon('template-custom', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iconsvg/approvazione-template.svg'));
    this.writableService.isWritable.next(false);
  }

  ngOnInit() {
    this.observeLayoutChanges();
    if (this.merchantService.currentMerchant) {
      this.idMerchant = this.merchantService.currentMerchant._id;
      this.getMerchantUsers();
    } else {
      this.getAxeptaUsers();
    }
  }

  ngOnDestroy() {
    if (!this.watcher) {return; }
    this.watcher.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (!this.sort) {return; }
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  }

  getAxeptaUsers() {
    this.loaderService.startGeneral();
    forkJoin([
      this.userService.list(null, 'SUPPLIER'),
      this.userService.list(null, 'HOLDING')
    ]).subscribe(
      data => {
        this.dataSource.data = data[0].concat(data[1]);
        this.loaderService.stopGeneral();
      },
      error => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    )
  }

  getMerchantUsers() {
    this.loaderService.startGeneral();
    this.userService.list(this.idMerchant).subscribe(
      data => {
        this.dataSource.data = data;
        this.loaderService.stopGeneral();
      },
      error => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
      }
    )
  }

/*   clicNuovoUtente() {
    this.router.navigate(['users', 'new'], { relativeTo: this.activatedRoute });
  }


  modificaUtente(row: User) {
    this.router.navigate(['users', row._id], { relativeTo: this.activatedRoute });
  } */


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  observeLayoutChanges() {
    this.watcher = this.mediaObserver.asObservable().pipe(filter((change: MediaChange[]) => {
      switch(change[0].mqAlias) {
        case 'xs': this.displayedColumns = ['last_name', 'smartphone'];
        break;
        case 'sm': this.displayedColumns = ['last_name', 'role', 'smartphone'];
        break;
        case 'md': this.displayedColumns = ['last_name', 'role', 'email', 'smartphone', 'actions'];
        break;
        case 'lg': this.displayedColumns = ['last_name', 'role', 'email', 'smartphone', 'first_access', 'actions'];
        break;
        case 'xl': this.displayedColumns = ['last_name', 'role', 'email', 'smartphone', 'first_access', 'actions'];
        break;
      }
      return true;
    })).subscribe();
  }

}
