import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { UserService } from "../../_services/user.service";
import { LoaderService } from "../../_services/loader.service";
import { MerchantService } from "../../_services/merchant.service";
import { AuthService } from "../../_services/auth.service";
import { User } from "../../_models/user";
import { DialogService } from "../../_services/dialog.service";
import {
  SelectMerchant,
  HoldingMerchant,
} from "../../_models/holding-merchant";

@Component({
  selector: "app-holding-merchants",
  templateUrl: "./holding-merchants.component.html",
  styleUrls: ["./holding-merchants.component.scss"],
})
export class HoldingMerchantsComponent implements OnInit {
  @Input() user: User;
  @Output() outputPushed: EventEmitter<HoldingMerchant[]> = new EventEmitter();
  @Output() outputPulled: EventEmitter<HoldingMerchant> = new EventEmitter();

  ruolo = [
    { value: "ADMIN", viewValue: "Admin" },
    { value: "OPERATOR", viewValue: "Operatore" },
    { value: "USER", viewValue: "Utente" },
  ];

  merchantControl = new UntypedFormControl();
  merchantRoleForm = new UntypedFormControl();

  matSelectMerchants: SelectMerchant[] = []; //all merchants to build mat select options
  merchantMatChips: SelectMerchant[] = []; //holding users's currently active merchants

  constructor(
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.merchantService.get(null, null, null, "false", "name _id").subscribe(
      (items) => {
        for (let item of items) {
          const merchantOption = new SelectMerchant(item._id, item.name);
          this.matSelectMerchants.push(merchantOption);
        }
        if (
          !this.user ||
          !this.user.merchants ||
          this.user.merchants.length === 0
        ) {
          return;
        }
        this.merchantRoleForm.setValue(this.user.merchants[0].role);
        this.populateMerchants();
      },
      (error) => {
        this.loaderService.stopGeneral();
        this.dialogService.openDialogWARN(
          "DIALOG.GENERIC_ERROR_TITLE",
          "DIALOG.GENERIC_ERROR_MESSAGE"
        );
      }
    );
  }

  pushMerchant() {
    this.merchantMatChips = this.merchantControl.value;
    if (this.merchantMatChips.length === 0) {
      this.merchantRoleForm.enable();
    } else {
      this.merchantRoleForm.disable();
    }
    this.outputData();
  }

  remove(item: SelectMerchant): void {
    const indexChips = this.merchantMatChips.indexOf(item);
    if (indexChips !== -1) {
      this.merchantMatChips.splice(indexChips, 1);
    }
    if (this.merchantMatChips.length === 0) {
      this.merchantRoleForm.enable();
    } else {
      this.merchantRoleForm.disable();
    }
    this.merchantControl.setValue(this.merchantMatChips);
    this.outputPulled.emit(
      new HoldingMerchant(item.merchant, this.merchantRoleForm.value)
    );
  }

  outputData() {
    const outputList = this.merchantControl.value.map(
      (el: SelectMerchant) =>
        new HoldingMerchant(el.merchant, this.merchantRoleForm.value)
    );
    this.outputPushed.emit(outputList);
  }

  populateMerchants() {
    const activeMerchantsArray = [];
    this.user.merchants.forEach((userMerchant: HoldingMerchant) => {
      const activeMerchant = this.matSelectMerchants.find(
        (el: SelectMerchant) => el.merchant === userMerchant.merchant
      );
      activeMerchantsArray.push(activeMerchant);
    });
    this.merchantControl.setValue(activeMerchantsArray);
    this.merchantMatChips = activeMerchantsArray;
    if (this.merchantMatChips.length === 0) {
      this.merchantRoleForm.enable();
    } else {
      this.merchantRoleForm.disable();
    }
  }
}
