import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ProfileService } from '../../../../_services/profile.service';
import { ProfilesDataSource } from '../../../../_cdk/profilesDataSource';
import { AuthService } from '../../../../_services/auth.service';
import { LoaderService } from '../../../../_services/loader.service';
import { DialogService } from '../../../../_services/dialog.service';
import { User } from '../../../../_models/user';
import { MerchantService } from '../../../../_services/merchant.service';


@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})

export class ProfilesComponent implements OnInit {
  user: User;
  dataSource: ProfilesDataSource;
  displayedColumns: string[] = ['name', 'description','action'];
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  pageSizeList: number = 5;
  isAsc: boolean = true;
  sort: string = 'type';
  recipient: string;
  filter: any[];
  settings: any;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  cardDataSource: any;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private merchantService: MerchantService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private dialogService: DialogService
  )
  {
    this.dataSource = new ProfilesDataSource(this.profileService);
    if (!this.merchantService.currentMerchant) {return; }
    this.dataSource.loadProfiles([{key: 'merchant', value: this.merchantService.currentMerchant._id}], this.sort, 0, this.pageSize);
    this.cardDataSource = this.dataSource.connect();
  }

  ngOnInit() {
    this.user = this.authService.user;
  }

  ngAfterViewInit() {
    if (!this.merchantService.currentMerchant) {return; }
    this.paginator.page
      .pipe(
        tap(() => this.dataSource.loadProfiles(
          [{key: 'merchant', value: this.merchantService.currentMerchant._id}],
          this.sort,
          this.paginator.pageIndex,
          this.paginator.pageSize
        ))
      )
      .subscribe();
  }

  loadProfiles(filter?: object[]) {
    this.dataSource.loadProfiles(
      filter,
      this.sort,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  sortData(sort: Sort) {
    if (!this.merchantService.currentMerchant) {return; }

    if (!sort.active || sort.direction === '') {
      return;
    }

    this.isAsc = sort.direction === 'asc';
    this.sort = this.isAsc ? sort.active : `-${sort.active}`;
    this.loadProfiles([{key: 'merchant', value: this.merchantService.currentMerchant._id}]);
  }

  eliminaProfilo(id: string) {
    if (!this.merchantService.currentMerchant) {return; }
    const dialogRef = this.dialogService.openDialogDELETE('', 'PROFILOUTENTI.CANCELLAZIONE')
    dialogRef.afterClosed().subscribe(
      data => {
        if (!data) return;
        this.loaderService.startGeneral();
        this.profileService.delete(id).subscribe(
          result => {
            this.dialogService.openDialogPRIMARY('DIALOG.SUCCESS_TITLE', 'DIALOG.GENERIC_SUCCESS_MESSAGE');
            this.loaderService.stopGeneral();
            this.loadProfiles([{key: 'merchant', value: this.merchantService.currentMerchant._id}]);
          },
          error => {
            this.loaderService.stopGeneral();
            this.dialogService.openDialogWARN('DIALOG.GENERIC_ERROR_TITLE', 'DIALOG.GENERIC_ERROR_MESSAGE', error.status);
          }
        );
      }
    );

  }

  openProfile(id: string) {
    this.router.navigate(['/merchant/my-company/profiles', id]);
  }

  createProfile() {
    this.router.navigate(['/merchant/my-company/profiles/new/']);
  }


}
