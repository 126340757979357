import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private _theme: BehaviorSubject<string> = new BehaviorSubject<string>(
    "wordline"
  );
  theme = this._theme.asObservable();

  constructor() {}

  setTheme(value: string) {
    this._theme.next(value);
  }
}
