import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { DialogService } from './_services/dialog.service';
import { AuthService } from './_services/auth.service';
import { User } from './_models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
      {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
      {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
      {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    ],
})
export class AppComponent implements OnInit {
  browserLang: string;

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private translateService: TranslateService,
    private adapter: DateAdapter<any>,
    private http: HttpClient,
    private swUpdate: SwUpdate,
    private dialogService: DialogService,
    private authService: AuthService,
  ) {

    this.authService.user$.subscribe(
      (response: User) => {
        if (response) {
          this.initI18n(response.language.toLowerCase());
        } else {
          this.initI18n();
        }
      },
    )


    moment.locale(this.browserLang);
    this.adapter.setLocale(this.browserLang);
    this.appendGaTrackingCode();
    angulartics2GoogleAnalytics.startTracking();

  }

  initI18n(userLang?: string): Promise<any> {
    let defaultLanguage = 'en';
    this.translateService.setDefaultLang(defaultLanguage);

    this.browserLang = navigator.language;
    if (userLang) {
      this.browserLang = userLang;
    }

    if(this.browserLang.length > 2){
      this.browserLang = this.browserLang.substring(0,2);
    }

    return new Promise((resolve) => {
      this.http.get(`./assets/i18n/${this.browserLang}.json`).subscribe(() => {
        this.translateService.setDefaultLang(this.browserLang);
        this.translateService.use(this.browserLang);
        this.translateService.resetLang(this.browserLang);
        this.translateService.reloadLang(this.browserLang);
        resolve(true);
      });
    });
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '${environment.ANALYTICS}', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe( $event => {
        const dialogRef = this.dialogService.openDialogNewVersion('', 'DIALOG.NEW_VERSION');
        dialogRef.afterClosed().subscribe(_ => window.location.reload())
      });
    }
  }
}
