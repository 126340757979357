<div fxLayout="column" style="border-right: 2px solid #eeeeee;" class="authorCol" fxLayoutAlign="start">

  <!--PRIMO QUADRO: CARD AUTORE-->
  <div style="padding: 20px 20px" class="">



    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="authorCard">

      <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center center">
        <div style="margin-bottom: 50px; width: 100%; text-align: left; font-weight: 400; white-space: nowrap;">{{ 'OPERAZIONI.AUTHOR' | translate }}</div>
        <div class="imgContainer">
            <img alt="profile icon" height="50px" class="profileImage" [src]="ASSETS_BASE + 'svg/iconsvg/profilo-icona.svg'"/>
        </div>
      </div>

      <div fxLayout="column" fxFlex="50%" style="margin: 5px;">
        <div class="capitalize"><strong>{{ 'OPERAZIONI.AUTHOR_NAME' | translate }}: </strong>{{author.name}}</div>
        <div class="capitalize"><strong>{{ 'OPERAZIONI.AUTHOR_ROLE' | translate }}: </strong>{{author.role}}</div>
        <div class="capitalize" style="overflow-wrap: break-word;"><strong>{{ 'OPERAZIONI.AUTHOR_PROFILE' | translate }}: </strong>{{author.profile}}</div>
      </div>

    </div>
  </div>
  <!--SECONDO QUADRO: ESITO SMS/EMAIL-->
  <div *ngIf='element?.mail_status || element?.sms_status' fxLayout="column" fxLayoutAlign="start space-around" class="infoBox">
    <div *ngIf='element?.mail_status' style="margin: 10px 0; width: 100%; text-align: left;"><strong>{{ 'OPERAZIONI.EMAIL_OUTCOME' | translate }}</strong></div>
    <div *ngIf='element?.sms_status' style="margin: 10px 0; width: 100%; text-align: left;"><strong>{{ 'OPERAZIONI.SMS_OUTCOME' | translate }}</strong></div>

    <div *ngIf='element?.mail_status' class="paymentInfo">
        <strong>{{ 'OPERAZIONI.REQUEST_SUBJECT' | translate }}: </strong>
        {{request.subject}}
    </div>
    <div class="paymentInfo">
        <strong>{{ 'OPERAZIONI.REQUEST_RECIPIENT' | translate }}: </strong>
        {{request.recipient}}</div>
    <div class="paymentInfo">
        <strong>{{ 'OPERAZIONI.REQUEST_STATE' | translate }}: </strong>
        {{request.state}}
    </div>
    <div *ngIf='element?.sms_status' class="paymentInfo">
        <strong>{{ 'OPERAZIONI.REQUEST_ERROR' | translate }}: </strong>
        {{request.error}}
    </div>
    <div *ngIf='element?.sms_status' class="paymentInfo">
      <strong>{{ 'OPERAZIONI.SENT_DATE' | translate}}: </strong>
      {{request.sentDate}}
  </div>
  <div *ngIf='element?.sms_status' class="paymentInfo">
    <strong>{{ 'OPERAZIONI.RECEIVED_DATE' | translate }}: </strong>
    {{request.receivedDate}}
  </div>
</div>

  <!-- ESITO TRANSAZIONI -->
  <div *ngIf="element.payment_type !== 'SINGLE'"  style="padding: 20px 20px; margin-left: 20px; margin-right: 20px; margin-bottom:200px; background-color: #eeeeee" class="">

        <div style="text-align: left; font-weight: 400">{{ 'PIANO PAGAMENTI' | translate }}</div>
        <table mat-table [dataSource]="element.recurrents.length > 3 && show ? dataSource : dataSource.filteredData | slice:0:3" aria-describedby="Payment scheduling">

          <ng-container class="tbody" matColumnDef="rata">
            <th scope="col" mat-header-cell *matHeaderCellDef class="center">Rate</th>
            <td mat-cell *matCellDef="let element" class="rata-cell">
              <div [ngClass]="{'completed-payment': element.isBold, 'rata-cell-standard': !element.isBold, 'rata-cell-inverted': element.state === 'ACTIVE'}" class="rata-format">{{element.occurence}}</div>
            </td>
          </ng-container>

          <ng-container class="tbody" matColumnDef="data">
            <th scope="col" mat-header-cell *matHeaderCellDef class="center">Data</th>
            <td mat-cell *matCellDef="let element" class="rata-cell">
              <div [ngClass]="element.isBold ? 'bold' : 'normal' ">{{formattedPaymentData(element)}}</div>
            </td>
          </ng-container>

          <ng-container class="tbody" matColumnDef="amount">
            <th scope="col" mat-header-cell *matHeaderCellDef class="center">Importo</th>
            <td mat-cell *matCellDef="let element" class="rata-cell">
              <div [ngClass]="element.isBold ? 'bold' : 'normal' ">{{element.amount}} €</div>
            </td>
          </ng-container>

          <ng-container class="tbody" matColumnDef="esito">
            <th scope="col" mat-header-cell *matHeaderCellDef class="center">Esito</th>
            <td mat-cell *matCellDef="let element" class="rata-cell">

              <div fxLayout="row" class="statoControl">
                <div [ngSwitch]="element.state">
                  <img alt="done icon" *ngSwitchCase="'COMPLETED'" style="height: 20px;"
                    [src]="ASSETS_BASE + 'svg/iconsvg/ok.svg'">
                  <img alt="active icon" *ngSwitchCase="'ERROR'" style="height: 20px;"
                    [src]="ASSETS_BASE + 'svg/iconsvg/x.svg'">
                </div>
              </div>
            </td>
          </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="element.recurrents.length > 3" class="show-more" (click)="showMore()">{{show ? 'Vedi meno...' : 'Vedi tutto...' }}</div>

</div>


<!--TERZO QUADRO: RIFERIMENTO LINK/QRCODE/WHATSAPP-->
<div class="info" *ngIf='(element?.operation_type === "LINK" || element?.operation_type === "QRCODE" || element?.operation_type === "WHATSAPP") && element?.state !== "WAIT"' fxLayout="column" fxLayoutAlign="start space-around" >
  <div style="margin-bottom: 20px; width: 100%; text-align: left;"><strong>{{ element?.operation_type }}</strong></div>
  <div *ngIf="element?.operation_type === 'LINK'" class="paymentInfo" style="overflow-wrap: break-word">
    {{element?.payment_link}}
  </div>
  <a [href]="whatsappLink" [target]="whatsappTarget" *ngIf="whatsappLink" class="paymentInfo" style="overflow-wrap: break-word">
    {{ whatsappLink?.changingThisBreaksApplicationSecurity}}
  </a>
  <div *ngIf="element?.qrcode" style="text-align: center;">
    <img alt="qrcode" height="160px" class="qrImage" [src]="URL + element?.qrcode._id"  >
  </div>





</div>
