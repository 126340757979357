<mat-toolbar class="myToolbar">
  <!--TOOLBAR PRINCIPALE-->
  <mat-toolbar-row class="myToolbarRow mainToolbar" ngClass.lt-md="myToolbarRowMobile">
    <!--LOGO-->
    <a class="logoToolbar" routerLink="/home">
      <img alt="white logo icon" [src]="ASSETS_BASE + 'svg/logoClicPayBianco.svg'" class="logoClic">
    </a>

    <!--SUPPORTO - VISIBILE SOLO SU DESKTOP E IN VISTA CUSTOMER-->
    <div *ngIf="!isAdmin || (isAdmin && (nomeAziendaHeader$ | async))" fxHide.xs fxHide.sm style="margin-bottom: 50px;">
      <mobilesoft-support [language]="translate?.currentLang" [isLoggedIn]="true"
        (supportValues)="createSupportRequest($event)"></mobilesoft-support>
    </div>

    <div fxHide.lt-lg class="menuHeader">
      <app-breadcrumbs></app-breadcrumbs>
    </div>

    <!--COLONNA BUFFER ESPANDIBILE-->
    <div fxHide.sm class="flex-fill-container-space"></div>

    <!--BLOCCO NOME UTENTE / AZIENDA /LOGOUT-->
    <div fxLayout="row" fxLayoutAlign="end center" style="width: 70%;">
      <div fxLayout="column" fxLayoutAlign="start end">
        <!--NOME UTENTE - VISIBILE SOLO SU DESKTOP -->
        <a class="flex-header-labelH" fxHide.xs fxHide.sm matTooltip="{{'APP.PROFILO' | translate }}"
          routerLink="/user-profile">
          {{'APP.WELCOME' | translate}} {{authService.user?.first_name + ' ' + authService.user?.last_name | uppercase
          }}
        </a>
        <!-- AZIENDA SUPPLIER RESPONSIVE -->
        <div fxHide.xs *ngIf="(nomeAziendaHeader$ | async) && isAdmin" ngClass.gt-sm="headerMerchant"
          ngClass.lt-md="headerMerchantXsSupplier">
          <div fxLayout style="position: relative;">
            <mat-icon fxHide.sm class='iconaAzienda' svgIcon='azienda'></mat-icon>
            <span class="borderAzienda" ngClass.lt-md="borderAziendaSm">
              {{ nomeAziendaHeader$ | async }}

            </span>
            <button mat-icon-button (click)="exitImpersonifica()"
              style="position: absolute; right: -6px; bottom: -6px;">
              <mat-icon ngStyle.lt-md="color: #FFFFFF" matTooltip="{{'APP.NONIMPERSONIFICA' | translate }}">clear
              </mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="!(nomeAziendaHeader$ | async) && isAdmin" style="height: 15px;"></div>


        <!-- AZIENDA HOLDING RESPONSIVE  -->
        <!-- <div fxHide.xs *ngIf="(nomeAziendaHeader$ | async) && isHolding" class="headerMerchantHolding" >
                    <div fxLayout ngClass.lt-md="headerMerchantXs">
                        <mat-icon fxHide.sm class='iconaAzienda' svgIcon='azienda'></mat-icon>
                        <mat-form-field color="primary" id="selectAzienda" #selectAzienda >
                            <mat-select [formControl]="aziendaSel" (selectionChange)="showChangeMerchantDialog($event.value)">
                                <mat-option *ngFor="let azienda of accrediti;" [value]="azienda">
                                    {{ azienda.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->

      </div>

      <!--COLONNA BUFFER ESPANDIBILE-->
      <div fxHide.gt-sm fxHide.xs class="flex-fill-container-space"></div>


      <!--LOGOUT BUTTON-->
      <button mat-icon-button class="logoutButton" ngClass.lt-md="logoutButtonSm">
        <mat-icon matTooltip="{{'APP.QUIT' | translate }}" (click)="logout()" class="quit icon-logout ">
        </mat-icon>
      </button>

    </div>

  </mat-toolbar-row>
  <!--FINE TOOLBAR PRINCIPALE-->

  <!--TOOLBAR AGGIUNTIVA PER DISPLAY MERCHANT SOLO SU DISPOSITIVI XS -->
  <mat-toolbar-row class="myToolbarRow xsAdditionalBar" ngClass.lt-md="myToolbarRowMobile" fxHide.gt-xs>
    <!--SUPPLIER-->
    <div *ngIf="(nomeAziendaHeader$ | async) !== '' && isAdmin" class="headerMerchantXsSupplier">
      <div fxLayout style="position: relative;">
        <span class="borderAzienda" ngClass.lt-md="borderAziendaSm">
          {{ nomeAziendaHeader$ | async }}

        </span>
        <button mat-icon-button (click)="exitImpersonifica()" style="position: absolute; right: -6px; bottom: -6px;">
          <mat-icon ngStyle.lt-md="color: #FFFFFF" matTooltip="{{'APP.NONIMPERSONIFICA' | translate }}">clear</mat-icon>
        </button>
      </div>

    </div>
    <!--HOLDING-->
    <!-- <div *ngIf="isHolding" class="headerMerchantXs">
            <mat-form-field>
                <mat-select [formControl]="aziendaSel" (selectionChange)="showChangeMerchantDialog($event.value)">
                    <mat-option *ngFor="let azienda of accrediti;" [value]="azienda">
                        {{ azienda.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
  </mat-toolbar-row>
  <!--FINE TOOLBAR AGGIUNTIVA-->

  <!--BARRA BREADCRUMBS VISIBILE SU TUTTI I DISPOSITIVI TRANNE XL-->
  <mat-toolbar-row class="breadcrumbsBar" fxHide.gt-md>
    <app-breadcrumbs></app-breadcrumbs>
  </mat-toolbar-row>

</mat-toolbar>
