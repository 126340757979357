<div class="wizard-container" ngClass.lt-lg="wizard-container-small">


  <form [formGroup]="wizardForm" fxLayout="row" fxFlexAlign="start center">
    <div *ngIf="!isEditMode && merchant?.isImportMassivelyActive && authService.user?.isBulkActive"
      class="request-type-box">
      <div class="request-type-title">RICHIESTA PAGAMENTO</div>
      <div fxLayout="column" fxLayoutAlign="space-around center" class="buttons-wrapper">
        <a class="request-type-button" [routerLink]="'../new'" [class.active-request-type]="!isImport"
          mat-flat-button>CREA</a>
        <div *ngIf="!recurrentSelected">
          <a class="request-type-button" [routerLink]="'../import'" [class.active-request-type]="isImport"
          mat-flat-button>IMPORTA</a>
        </div>
      </div>
    </div>

    <!--TIPO DI RICHIESTA (STANDARD/DONAZIONE)-->
    <div fxLayout="column" class="step">
      <app-payment-wizard-header [isDisabled]="false" [step]="0" [label]="'PAYMENT_WIZARD.TIPO_RICHIESTA' | translate">
      </app-payment-wizard-header>
      <div fxLayout fxLayoutAlign="center" class="opts-container">
        <!--ARROW BUTTON-->
        <button color="primary" *ngIf="value?.isDonation === null && !wizardState[0]" mat-icon-button
          class="forwardButton" (click)="toggleWizard(0);">
          <mat-icon class="wizardIcon" svgIcon="right_arrow"></mat-icon>
        </button>

        <!--SCELTA SELEZIONATA-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" *ngIf="value?.isDonation !== null && !wizardState[0]" formControlName="isDonation"
          [options]="availableRequestTypes" [isShowcase]="true"
          (click)="toggleWizard(0); wizardForm.controls.isDonation.reset();"></app-my-radio-group>

        <!--OPZIONI-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" @openClose *ngIf="wizardState[0]" formControlName="isDonation"
          [options]="availableRequestTypes"></app-my-radio-group>
      </div>
    </div>

    <!--PAYMENT TYPE (SINGLE/RECURRENT/DEFERRED)-->
    <div fxLayout="column" class="step">
      <app-payment-wizard-header [isDisabled]="value?.isDonation === null && !value?.payment_type" [step]="1"
        [controlName]="'payment_type'" [label]="'PAYMENT_WIZARD.CARATTERISTICHE' | translate">
      </app-payment-wizard-header>

      <div fxLayout fxLayoutAlign="center" class="opts-container">
        <!--ARROW BUTTON-->
        <button color="primary" *ngIf="!value?.payment_type && !wizardState[1]" mat-icon-button class="forwardButton"
          (click)="toggleWizard(1);" [disabled]="value?.isDonation === null">
          <mat-icon class="wizardIcon" svgIcon="right_arrow"></mat-icon>
        </button>

        <!--SCELTA SELEZIONATA-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" *ngIf="value?.payment_type && !wizardState[1]" formControlName="payment_type"
          [options]="availablePaymentTypes" [isShowcase]="true"
          (click)="toggleWizard(1); wizardForm.controls.payment_type.reset();"></app-my-radio-group>

        <!--OPZIONI-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" @openClose *ngIf="wizardState[1]" formControlName="payment_type"
          [options]="availablePaymentTypes"></app-my-radio-group>
      </div>
    </div>
    <!--INVIO (MEZZO DI INVIO)-->
    <div fxLayout="column" class="step">
      <app-payment-wizard-header [isDisabled]="!value?.payment_type && !value?.operation_type" [step]="2"
        [controlName]="'operation_type'" [label]="'PAYMENT_WIZARD.INVIO' | translate"></app-payment-wizard-header>

      <div fxLayout fxLayoutAlign="center" class="opts-container">
        <!--ARROW BUTTON-->
        <button color="primary" *ngIf="!value?.operation_type && !wizardState[2]" mat-icon-button class="forwardButton"
          (click)="toggleWizard(2);" [disabled]="!value?.payment_type">
          <mat-icon class="wizardIcon" svgIcon="right_arrow"></mat-icon>
        </button>

        <!--SCELTA SELEZIONATA-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" *ngIf="value?.operation_type && !wizardState[2]" formControlName="operation_type"
          [options]="availableSendOpt" [isShowcase]="true"
          (click)="toggleWizard(2); wizardForm.controls.operation_type.reset();"></app-my-radio-group>

        <!--OPZIONI-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" @openClose *ngIf="wizardState[2]" formControlName="operation_type"
          [options]="availableSendOpt"></app-my-radio-group>
      </div>
    </div>


    <!--ASPETTO (TEMPLATE)-->
    <div fxLayout="column" class="step">
      <app-payment-wizard-header [isDisabled]="!value.operation_type && !value?.template" [step]="3"
        [controlName]="'template'" [label]="'PAYMENT_WIZARD.ASPETTO' | translate"></app-payment-wizard-header>
      <div fxLayout fxLayoutAlign="center" class="opts-container">
        <!--ARROW BUTTON-->
        <button color="primary" *ngIf="!value?.template && !wizardState[3]" mat-icon-button class="forwardButton"
          (click)="toggleWizard(3); wizardForm.controls.template.reset();" [disabled]="!value?.operation_type">
          <mat-icon class="wizardIcon" svgIcon="right_arrow"></mat-icon>
        </button>

        <!--SCELTA SELEZIONATA-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" *ngIf="value?.template && !wizardState[3]" formControlName="template" [isShowcase]="true"
          [options]="templateOpts" (click)="toggleWizard(3); wizardForm.controls.template.reset();">
        </app-my-radio-group>

        <!--OPZIONI-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" @openClose *ngIf="wizardState[3]" formControlName="template" [options]="templateOpts">
        </app-my-radio-group>
      </div>
    </div>

    <!--MODALITA ADDEBITO (PURCHASE/AUTH/VERIFY)-->
    <div fxLayout="column" class="step">
      <app-payment-wizard-header [isDisabled]="!value?.template && !value?.transaction_type" [step]="4"
        [controlName]="'transaction_type'" [label]="'PAYMENT_WIZARD.MODALITA_ADDEBITO' | translate">
      </app-payment-wizard-header>
      <div fxLayout fxLayoutAlign="center" class="opts-container">
        <!--ARROW BUTTON-->
        <button color="primary" *ngIf="!value?.transaction_type && !wizardState[4]" mat-icon-button
          class="forwardButton" (click)="toggleWizard(4); wizardForm.controls.transaction_type.reset();"
          [disabled]="!value?.template">
          <mat-icon class="wizardIcon" svgIcon="right_arrow"></mat-icon>
        </button>

        <!--SCELTA SELEZIONATA-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" *ngIf="value?.transaction_type && !wizardState[4]" formControlName="transaction_type"
          [options]="availableTransType" [isShowcase]="true"
          (click)="toggleWizard(4); wizardForm.controls.transaction_type.reset();"></app-my-radio-group>

        <!--OPZIONI-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" @openClose *ngIf="wizardState[4]" formControlName="transaction_type"
          [options]="availableTransType"></app-my-radio-group>
      </div>
    </div>

    <!--NOTIFICA-->
    <div fxLayout="column" class="step" *ngIf="showNotificationOption">
      <app-payment-wizard-header [isDisabled]="!value?.transaction_type && value?.isNotificationRequired === null"
        [step]="5" [controlName]="'isNotificationRequired'" [label]="'PAYMENT_WIZARD.NOTIFICA' | translate">
      </app-payment-wizard-header>
      <div fxLayout fxLayoutAlign="center" class="opts-container">
        <!--ARROW BUTTON-->
        <button color="primary" *ngIf="value?.isNotificationRequired === null && !wizardState[5]" mat-icon-button
          class="forwardButton" (click)="toggleWizard(5); wizardForm.controls.isNotificationRequired.reset();"
          [disabled]="!value?.transaction_type">
          <mat-icon class="wizardIcon" svgIcon="right_arrow"></mat-icon>
        </button>

        <!--SCELTA SELEZIONATA-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}"
          *ngIf="(value?.isNotificationRequired === true || value?.isNotificationRequired === false) && !wizardState[5]"
          formControlName="isNotificationRequired" [options]="notifOpts" [isShowcase]="true"
          (click)="toggleWizard(5); wizardForm.controls.isNotificationRequired.reset();"></app-my-radio-group>

        <!--OPZIONI-->
        <app-my-radio-group [ngClass]="{'not-clickable-element': wizardForm.disabled}" @openClose *ngIf="wizardState[5]" formControlName="isNotificationRequired"
          [options]="notifOpts"></app-my-radio-group>
      </div>
    </div>

    <!--OPZIONI (PAYMENT FEATURES)-->
    <div [formGroup]="featureForm" fxLayout="column" *ngIf="!isImport" class="step">
      <app-payment-wizard-header
        [isDisabled]="value?.isNotificationRequired === null && !features?.isAmountFree && !features?.isPaymentOpen && !features?.isFastCheckout"
        [step]="6" [controlName]="'features'" [label]="'PAYMENT_WIZARD.OPZIONI' | translate">
      </app-payment-wizard-header>

      <div fxLayout fxLayoutAlign="center" class="opts-container">
        <!--ARROW BUTTON-->
        <button color="primary" *ngIf="!wizardState[6] &&
                    !features?.isAmountFree && !features?.isPaymentOpen && !features?.isFastCheckout" mat-icon-button
          class="forwardButton" (click)="toggleWizard(6); resetFeatures();"
          [disabled]="value?.isNotificationRequired === null">
          <mat-icon class="wizardIcon" svgIcon="right_arrow"></mat-icon>
        </button>

        <!--SCELTE SELEZIONATE-->
        <div *ngIf="!wizardState[6] &&
                    (features?.isAmountFree || features?.isPaymentOpen || features?.isFastCheckout)" fxLayout
          style="height: 100px">
          <div *ngFor="let key of featureArray">
            <app-my-checkbox [ngClass]="{'not-clickable-element': wizardForm.disabled}" (click)="toggleWizard(6); resetFeatures();" *ngIf="features[key]" [name]="key"
              [isShowcase]="true" [formControlName]="key"></app-my-checkbox>
          </div>
        </div>

        <!--OPZIONI-->
        <div @openClose *ngIf="wizardState[6]" [ngClass]="{'not-clickable-element': wizardForm.disabled}" fxLayout>

          <app-my-checkbox [setDisabled]="wizardForm.disabled" *ngIf="merchant?.isFastCheckoutActive && !features?.isPaymentOpen && !features?.isAmountFree"
            name="isFastCheckout" formControlName="isFastCheckout"></app-my-checkbox>
          <app-my-checkbox [setDisabled]="wizardForm.disabled" *ngIf="!isImport && this.wizardForm.value.payment_type !== 'DEFERRED' && this.wizardForm.value.payment_type !== 'RECURRENT'" name="isAmountFree" formControlName="isAmountFree">
          </app-my-checkbox>
          <app-my-checkbox [setDisabled]="wizardForm.disabled" *ngIf="merchant?.isPaymentOpenActive && this.wizardForm.value.payment_type !== 'DEFERRED' && this.wizardForm.value.payment_type !== 'RECURRENT'" name="isPaymentOpen" formControlName="isPaymentOpen">
          </app-my-checkbox>
          <app-my-checkbox
             [setDisabled]="wizardForm.disabled" *ngIf="merchant?.isQueryStringActive && features?.isPaymentOpen && features?.isAmountFree && featureForm.controls.isQueryString"
            name="isQueryString" formControlName="isQueryString"></app-my-checkbox>
        </div>
      </div>

    </div>
  </form>
</div>
