<div fxLayout="column" fxLayoutAlign="space-around stretch" class="barraFiltro" style="margin-top: 30px;">

  <div class="titoloFiltro">
    {{'PROFILO.INSERTMERCHANTS' | translate}}
  </div>

  <mat-chip-list>
    <mat-chip *ngFor="let item of merchantMatChips" style="margin-top: 20px; margin-bottom: 10px;" [removable]="true"
      (removed)="remove(item)">
      {{item.name}}
      <mat-icon style="margin-left: 5px; margin-right: 0;" matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <div class="flex-row">
    <span class="flex-col">
      <mat-form-field class="leftInputField">
        <mat-select placeholder="{{'PROFILO.MERCHANT' | translate}}" [formControl]="merchantControl" multiple>
          <mat-select-trigger>
            {{merchantControl.value?.length>0 ? merchantControl.value[0].name : ''}}
            <span *ngIf="merchantControl.value?.length > 1" class="example-additional-selection">
              (+{{merchantControl.value.length - 1 }} Merchant)
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of matSelectMerchants" [value]="item">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </span>

    <span class="flex-col">
      <mat-form-field class="rightInputField">
        <mat-select placeholder="{{ 'NUOVOUTENTE.RUOLO' | translate}}" required [formControl]="merchantRoleForm">
          <mat-option *ngFor="let type of ruolo" [value]="type.value">{{ type.viewValue }}</mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </div>

  <div fxLayout="column" fxLayoutAlign="space-evenly end">
    <!-- ADD MERCHANT -->
    <button mat-flat-button color="primary" (click)="pushMerchant()" class="rounded-button"
      [disabled]="!merchantRoleForm.value || (!merchantControl.value || merchantControl.value.length === 0)">
      {{'PROFILO.AGGIUNGI' | translate}}
    </button>
  </div>

</div>
