<span [ngClass]="theme">
  <div class="topRow" [ngClass]="{'actionHeight': element.payment_type !== 'SINGLE' && element.recurrents_status !== 'COMPLETED'}">
    <div class="sliderActions" *ngIf="element.payment_type !== 'SINGLE' && element.recurrents_status !== 'COMPLETED'">
      <div class="actioButtonCol" *ngIf="element.firstRecurrentIDFailed">
        <button class="actionButton"
          (click)="notification(element.firstRecurrentIDFailed)">
          <mat-icon [ngClass]="element.firstRecurrentIDFailed ? 'edit' : 'editDisable' " svgIcon="sollecita"
            aria-hidden="false" aria-label="sollecita"></mat-icon>
        </button>
        <span>SOLLECITA</span>
      </div>
      <div class="actioButtonCol">
        <button class="actionButton" 
          (click)="deactivateRecurrentPlan(element)">
          <mat-icon class="edit my-icon-close">{{element.isRecurrentPlanActive ? 'close' : 'replay'}}</mat-icon>
        </button>
        <span> {{element.isRecurrentPlanActive ? 'DISATTIVA PIANO' : 'ATTIVA PIANO'}}</span>
      </div>
    </div>
    <div class="dotIndicator" [ngStyle]="{'margin-left': element.payment_type !== 'SINGLE' && element.recurrents_status !== 'COMPLETED' ? '0px' : '64px'}">
      <div class="dot" [ngClass]="{'active': activeIndex===0}"></div>
      <div class="dot" [ngClass]="{'active': activeIndex===1}"></div>
      <div class="dot" [ngClass]="{'active': activeIndex===2}"></div>
    </div>
    <button class="closeButton" style="background-color: transparent; float: right" (click)="closeDetail()"
      [ngClass]="{'closeButtonAbsolute': element.payment_type !== 'SINGLE' && element.recurrents_status !== 'COMPLETED'}">
      <mat-icon class="overlayIcons" svgIcon="close" aria-hidden="false" aria-label="close">
      </mat-icon>
    </button>
  </div>
  <swiper [config]="config" #mySlider id="mySwiper"
    [ngClass]="{'swipper-container': element.payment_type !== 'SINGLE' && element.recurrents_status !== 'COMPLETED'}">
    <div class="swiper-wrapper page">

      <div class="swiper-slide">
        <app-operations-detail-first [element]="element"
          [transactionList]="transactionList"></app-operations-detail-first>
      </div>
      <div class="swiper-slide">
        <app-operations-detail-second [element]="element"
          [transactionList]="transactionList"></app-operations-detail-second>
      </div>
      <div class="swiper-slide">
        <app-operations-detail-third [element]="element" [transactionList]="transactionList" [isMobile]="true"
          [isActive]="activeIndex" (refreshOutcomes)="refreshTransactions($event)"></app-operations-detail-third>
      </div>
    </div>
  </swiper>
</span>