import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { Bulk } from '../_models/bulk';
import { map, catchError } from 'rxjs/operators';
import { Payment } from '../_models/payment';
import { MerchantService } from './merchant.service';

@Injectable()
export class BulkService {

  private readonly BULK_URL: string = environment.BASE_URL + 'bulk';

  merchantId: string;

  constructor(
    private http: HttpClient,
    private merchantService: MerchantService,
    private authService: AuthService
  ) { }

  elimina(element: Bulk){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    return this.http.delete(this.BULK_URL+"/"+element._id, {headers})
  }

  populate(element: Bulk){
    const merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let body: any = {};
    if (this.authService.user.type === 'HOLDING') {
      body.merchant = merchantId;
    }
    return this.http.put(this.BULK_URL+"/populate/"+element._id, body,{headers})
  }

  send(element: Bulk){
    const merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');
    let body: any = {};
    if (this.authService.user.type === 'HOLDING') {
      body.merchant = merchantId;
    }
    return this.http.put(this.BULK_URL+"/send/"+element._id, body,{headers})
  }

  suspend(element: Bulk){
    const merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    const body: any = {
      state: "ABORTED"
    }
    if (this.authService.user.type === 'HOLDING') {
      body.merchant = merchantId;
    }
    return this.http.put(this.BULK_URL+"/"+element._id,  body ,{ headers})
  }

  

  getBulks(skip: number, limit: number, sort: string){
    const merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    let params = new HttpParams();
    params = params.set('skip', limit && skip ? (skip * limit).toString() : '');
    params = params.set('limit', limit ? limit.toString() : '');
    params = params.set('sort', sort);
    //params = params.set('populate', 'false');

    if(this.authService.user.type == 'HOLDING'  || this.authService.user.type == 'SUPPLIER' ){
      params = params.set('merchant', merchantId);
    }

    return this.http.get(this.BULK_URL, { headers, params })
  }

  public getBulkPayments(id: string){
    const merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    let params = new HttpParams();
    params = params.set('populate', 'true');

    if(this.authService.user.type == 'HOLDING' || this.authService.user.type == 'SUPPLIER' ){
      params = params.set('merchant', merchantId);
    }

    return this.http.get(this.BULK_URL + '/' + id, { headers, params })
    .pipe(
      map((response: any) => response.item.payments as Payment[]),
      catchError(_ => of([]))
    )
  }

  public caricaBulk(merchant_id: string, bulkForm: any, file: any, terminal: string, template: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');

    const body = new Bulk(merchant_id, bulkForm, terminal, template, file)
    return this.http.post(this.BULK_URL, body, { headers })
  }

  export(bulkId: string) {
    const merchantId = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');    
    let params = new HttpParams();

    if(this.authService.user.type == 'HOLDING'){
      params = params.set('merchant', merchantId);
    }
    return this.http.get(this.BULK_URL + '/export/' + bulkId, {headers, params, responseType: 'blob'});
  }

  sendEnabled() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', this.authService.token);
    headers = headers.set('Accept-Language', 'it');  
    return this.http.get(this.BULK_URL + '/send/enabled', { headers });
  }
}
